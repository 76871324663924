import {
   APP_UPLOAD_DOCUMENT,
   APP_UPLOAD_DOCUMENT_ERROR,
   APP_UPLOAD_DOCUMENT_SUCCESS
} from "./constant";

const uploadApplicationDocumentReducer = (state = {}, action) => {
   switch (action.type) {
      case APP_UPLOAD_DOCUMENT:
         return { ...state, loading: true, error: '' };
      case APP_UPLOAD_DOCUMENT_ERROR:
         return { ...state, type: APP_UPLOAD_DOCUMENT_ERROR, loading: false, error: action.payload };
      case APP_UPLOAD_DOCUMENT_SUCCESS:
         return { ...state, type: APP_UPLOAD_DOCUMENT_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default uploadApplicationDocumentReducer;