import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import { updateWorkItemCallAPI } from 'store/workItem/updateWorkItem/action';
import { UPDATE_WORK_ITEM } from 'store/workItem/updateWorkItem/constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import CheckValidation from './validation';
import { LocaleContext } from 'routes/Router';

const style = {
	width: '100%',
	height: 'fit-content',
	maxWidth: 1260,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '16px',
};

function ExpensesModal({ modalActive, handleModalPopup, expenseDetails, getTotalExpenses }) {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isError, setIsError] = useState(false);
	const { locale } = useContext(LocaleContext);
	const workItemListData = useSelector((state) => state.applicationListWorkItemReducer && state.applicationListWorkItemReducer?.payload?.data);
	const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state.fetchWorkItemReducer?.payload?.data?.data);
	const updateWorkItemLoader = useSelector((state) => state?.updateWorkItemReducer?.loading);
	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);

	const [formValue, setFormValue] = useState({
		groceriesExpenses: "",
		healthcareExpenses: "",
		educationExpenses: "",
		housingExpenses: "",
		homeLaborExpenses: "",
		communicationAndTransExpenses: "",
		insuranceExpenses: "",
		futureExpenses: "",
		waterAndElectrExpenses: ""
	});

	useEffect(() => {
		if (FetchWorkItemData?.basicDetails && (FetchWorkItemData?.basicDetails?.["expenseDetails"] === undefined)) {
			setFormValue((prev) => ({
				...prev,
				['groceriesExpenses']: +expenseDetails?.FoodExpenses ?? 0,
				['healthcareExpenses']: +expenseDetails?.HealthCareExpenses ?? 0,
				['educationExpenses']: +expenseDetails?.EducationExpenses ?? 0,
				['housingExpenses']: +expenseDetails?.HousingAndServicesExpenses ?? 0,
				['homeLaborExpenses']: +expenseDetails?.HouseHelpExpenses ?? 0,
				['communicationAndTransExpenses']: +expenseDetails?.TransportationAndCommunicationExpenses ?? 0,
				['insuranceExpenses']: +expenseDetails?.InsuranceExpenses ?? 0,
				['futureExpenses']: +expenseDetails?.FutureExpenses ?? 0,
				['waterAndElectrExpenses']: +expenseDetails?.WaterAndElectricityExpenses ?? 0
			}));
		} else {
			setFormValue((prev) => ({
				...prev,
				['groceriesExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.groceriesExpenses ?? 0,
				['healthcareExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.healthcareExpenses ?? 0,
				['educationExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.educationExpenses ?? 0,
				['housingExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.housingExpenses ?? 0,
				['homeLaborExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.homeLaborExpenses ?? 0,
				['communicationAndTransExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.communicationAndTransExpenses ?? 0,
				['insuranceExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.insuranceExpenses ?? 0,
				['futureExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.futureExpenses ?? 0,
				['waterAndElectrExpenses']: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.waterAndElectrExpenses ?? 0
			}));
		}
	}, []);

	const TotalExpense = formValue?.groceriesExpenses ?? 0 + formValue?.healthcareExpenses ?? 0 + formValue?.educationExpenses ?? 0 + formValue?.housingExpenses ?? 0
		+ formValue?.homeLaborExpenses ?? 0 + formValue?.communicationAndTransExpenses ?? 0 + formValue?.insuranceExpenses ?? 0 + formValue?.futureExpenses ?? 0 + formValue?.waterAndElectrExpenses ?? 0;

	const handleChange = (e) => {
		const { name, value, keyCode } = e.target;
		let parsedValue = parseFloat(value);

		if (isNaN(parsedValue) || value === '') {
			parsedValue = null;
		};

		setFormValue((prev) => ({
			...prev,
			[name]: parsedValue
		}));
	};

	const handleBlur = (e) => {
		const { name, value, keyCode } = e.target;
		let parsedValue = parseFloat(value);
		let minValue = 0;

		if (FetchWorkItemData?.basicDetails && (FetchWorkItemData?.basicDetails?.["expenseDetails"] === undefined)) {
			switch (name) {
				case 'groceriesExpenses':
					minValue = +expenseDetails?.FoodExpenses ?? 0;
					break;
				case 'healthcareExpenses':
					minValue = +expenseDetails?.HealthCareExpenses ?? 0;
					break;
				case 'educationExpenses':
					minValue = +expenseDetails?.EducationExpenses ?? 0;
					break;
				case 'housingExpenses':
					minValue = +expenseDetails?.HousingAndServicesExpenses ?? 0;
					break;
				case 'homeLaborExpenses':
					minValue = +expenseDetails?.HouseHelpExpenses ?? 0;
					break;
				case 'communicationAndTransExpenses':
					minValue = +expenseDetails?.TransportationAndCommunicationExpenses ?? 0;
					break;
				case 'insuranceExpenses':
					minValue = +expenseDetails?.InsuranceExpenses ?? 0;
					break;
				case 'futureExpenses':
					minValue = +expenseDetails?.FutureExpenses ?? 0;
					break;
				case 'waterAndElectrExpenses':
					minValue = +expenseDetails?.WaterAndElectricityExpenses ?? 0;
					break;
				default:
					minValue = 0;
					break;
			}
		} else {
			minValue = FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.[name] !== undefined ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.[name] : 0;
		}

		if (isNaN(parsedValue) || value === '') {
			parsedValue = 0;
			parsedValue = Math.max(parsedValue, minValue);
		};

		if (!isNaN(parsedValue)) {
			parsedValue = Math.max(parsedValue, minValue);
		};

		setFormValue((prev) => ({
			...prev,
			[name]: parsedValue
		}));
	};

	const UpdateWorkItem = () => {
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			if (FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails["expenseDetails"] === undefined)) {
				const addresObject = [{
					"housingExpenses": formValue?.housingExpenses,
					"groceriesExpenses": formValue?.groceriesExpenses,
					"waterAndElectrExpenses": formValue?.waterAndElectrExpenses,
					"homeLaborExpenses": formValue?.homeLaborExpenses,
					"educationExpenses": formValue?.educationExpenses,
					"communicationAndTransExpenses": formValue?.communicationAndTransExpenses,
					"healthcareExpenses": formValue?.healthcareExpenses,
					"insuranceExpenses": formValue?.insuranceExpenses,
					"futureExpenses": formValue?.futureExpenses,
				}];
				FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails['expenseDetails'] = addresObject);
			} else {
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['housingExpenses'] = formValue?.housingExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['groceriesExpenses'] = formValue?.groceriesExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['waterAndElectrExpenses'] = formValue?.waterAndElectrExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['homeLaborExpenses'] = formValue?.homeLaborExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['educationExpenses'] = formValue?.educationExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['communicationAndTransExpenses'] = formValue?.communicationAndTransExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['healthcareExpenses'] = formValue?.healthcareExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['insuranceExpenses'] = formValue?.insuranceExpenses);
				FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['futureExpenses'] = formValue?.futureExpenses);
			}
			FetchWorkItemData && (FetchWorkItemData['operation'] = "save");
			dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleSubmit, navigate: navigate, run: true }));
		}
	};

	const handleSubmit = () => {
		handleModalPopup(false);
		getTotalExpenses(TotalExpense);
	};

	return (
		<Modal
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				overflow: 'scroll',
				'@media (max-width: 900px)': {
					alignItems: 'flex-start !important', // Adjust for xs breakpoint
				},
			}}
			open={modalActive}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<Box sx={style}>
				<div className="header">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Text size="40" marginTop="0" color="lightDark" fontWeight="800" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} >
								<FormattedMessage id="autoFinance.expenses" />
							</Text>
						</Grid>
					</Grid>
				</div>
				<div className="content">
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.groceriesExpenses}
								label={<FormattedMessage id="autoFinance.groceriesExpenses" />}
								placeholder="Groceries Expenses"
								name="groceriesExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.groceriesExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.groceriesExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.groceriesExpenses?.error && isError?.groceriesExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.housingExpenses}
								label={<FormattedMessage id="autoFinance.housingExpenses" />}
								placeholder="Housing Expenses"
								name="housingExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.housingExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.housingExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.housingExpenses?.error && isError?.housingExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.waterAndElectrExpenses}
								label={<FormattedMessage id="autoFinance.waterElectricity" />}
								placeholder="Water & Electricity"
								name="waterAndElectrExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.waterAndElectrExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.waterAndElectrExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.waterAndElectrExpenses?.error && isError?.waterAndElectrExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.homeLaborExpenses}
								label={<FormattedMessage id="autoFinance.homeLaborExpenses" />}
								placeholder="Home Labor Expenses"
								name="homeLaborExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.homeLaborExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.homeLaborExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.homeLaborExpenses?.error && isError?.homeLaborExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.educationExpenses}
								label={<FormattedMessage id="autoFinance.educationExpenses" />}
								placeholder="Education Expenses"
								name="educationExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.educationExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.educationExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.educationExpenses?.error && isError?.educationExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.communicationAndTransExpenses}
								label={<FormattedMessage id="autoFinance.communicationTransport" />}
								placeholder="Communication & Transport"
								name="communicationAndTransExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.communicationAndTransExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.communicationAndTransExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.communicationAndTransExpenses?.error && isError?.communicationAndTransExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.healthcareExpenses}
								label={<FormattedMessage id="autoFinance.healthcareExpenses" />}
								placeholder="Healthcare Expenses"
								name="healthcareExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.healthcareExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.healthcareExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.healthcareExpenses?.error && isError?.healthcareExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.insuranceExpenses}
								label={<FormattedMessage id="autoFinance.insuranceExpenses" />}
								placeholder="Insurance Expenses"
								name="insuranceExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.insuranceExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.insuranceExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.insuranceExpenses?.error && isError?.insuranceExpenses?.errorMessage}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LabelWithInput
								value={formValue?.futureExpenses}
								label={<FormattedMessage id="autoFinance.futureExpenses" />}
								placeholder="Future Expenses"
								name="futureExpenses"
								type="number"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">SAR</InputAdornment>
									)
								}}
								inputProps={{ min: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.futureExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.futureExpenses : 0 }}
								onChange={(e) => handleChange(e)}
								onBlur={(e) => handleBlur(e)}
								errorText={isError && isError?.futureExpenses?.error && isError?.futureExpenses?.errorMessage}
							/>
						</Grid>
					</Grid>
				</div>
				<div className="footer" style={{ marginTop: "30px" }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className="content" style={{ display: "flex", justifyContent: "end" }}>
								<Box component="form">
									<Stack spacing={2} direction="row">
										<ButtonInput
											title="Cancel"
											variant="text"
											bgColor="transparent"
											textColor="purple"
											borderRadius="100"
											TextTransform="uppercase"
											onClick={() => handleModalPopup(false)}
										/>
										<ButtonInput
											title="Save"
											variant="contained"
											bgColor="yellow"
											textColor="purple"
											borderRadius="100"
											TextTransform="capitalize"
											disabled={updateWorkItemLoader}
											endIcon={updateWorkItemLoader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
											onClick={() => { UpdateWorkItem() }}
										/>
									</Stack>
								</Box>
							</div>
						</Grid>
					</Grid>
				</div>
			</Box>
		</Modal>
	);
}

export default ExpensesModal;