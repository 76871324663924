import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import LayoutStyleWrapper from "styles/common.styles";
import SlickCarousel from "components/SlickCarousel"
import FinancailProduct from "features/Dashboard/FinancialProduct";
import AvailableService from "features/Dashboard/AvailableService";
import ActiveFinance from "features/Dashboard/ActiveFinance";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import { LocaleContext } from 'routes/Router';
import H5Text from "components/Text/H5Text";
import { FormattedMessage } from "react-intl";
import ButtonInput from "components/Input/ButtonInput";
import MuiSnackbar from "components/MuiSnackbar";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Car_Finnance_Icon from "assets/images/Dashboard/greenBorderCar.png";
import { Box } from "@mui/system";
import { screenCallAPI } from "store/screen/action";
import { activeFinanceCallAPI } from "store/dashboard/activeFinance/action";
import { GET_CAMPAIGNS_LIST } from "store/screen/constant";
import { getRevisedFinancingInitialDataURL } from "routes/AppUrls";
import { ACTIVE_FINANCE } from "store/dashboard/activeFinance/constant";
import { APP_STATIC_IMAGE } from "store/dashboard/appStaticImage/constant";
import { appStaticImageCallAPI } from "store/dashboard/appStaticImage/action";

const Dashboard = ({ ...props }) => {
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();
    const ResumeFormData = useSelector((state) => state.ResumeOnlineFormReducer?.data);
    const campaignData = useSelector((state) => state.screenReducer?.payload?.data);
    const campaignListLoader = useSelector((state) => state.screenReducer?.loading);
    const appStaticImage = useSelector((state) => state.appStaticImageReducer?.payload?.data);
    const appStaticImageloader = useSelector((state) => state.appStaticImageReducer?.loading);
    const activeFinance = useSelector((state) => state.activeFinanceReducer?.payload?.data?.['data']?.['S:Envelope']?.['S:Body']?.['ns2:fetchCustomerLoanDetailsResponse']?.['return']?.['customerLoanDetailList']);
    const activeFinanceloader = useSelector((state) => state.activeFinanceReducer?.loading);
    const userDetails = useSelector((state) => (state.userReducer && state.userReducer?.payload?.data));

    useEffect(() => {
        dispatch(screenCallAPI(GET_CAMPAIGNS_LIST));
        ActiveFinanceDetails();
        AppStaticImageDetails();
    }, []);

    const ActiveFinanceDetails = (value) => {
        let body = {
            "customerID": userDetails?.data?.nationalId,
        }
        dispatch(activeFinanceCallAPI(ACTIVE_FINANCE, body, { navigate: navigate }));
    };

    const AppStaticImageDetails = (value) => {
        dispatch(appStaticImageCallAPI(APP_STATIC_IMAGE, locale, { navigate: navigate }));
    };

    return (
        <LayoutStyleWrapper background={"#EFE8F3"} locale={locale}>
            <Grid container>
                <Grid item xs={12}>
                    <SlickCarousel banner={true} campaignData={campaignData?.data} loader={campaignListLoader} />
                </Grid>
                <Grid item xs={12} marginTop='24px'>
                    <MuiSnackbar
                        message={
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={8} display='flex' alignItems={'center'}>
                                        <Box>
                                            <img src={Car_Finnance_Icon} alt='icon' />
                                        </Box>
                                        <Box marginRight={locale == 'ar' && '24px'} marginLeft={locale == 'en' && '24px'}>
                                            <H5Text color="dark" size="24" marginTop="0" lineHeight="24" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                                                <FormattedMessage id="autoFinance"/>
                                            </H5Text>
                                            <H5Text color="dark" size="20" marginTop="0" lineHeight="20" fontFamily={locale=="en"?'Karbon_light':"Luma_light"}>
                                                <FormattedMessage id="continueYourJourney"/>
                                            </H5Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} textAlign={{ xs: 'start', md: 'end' }} paddingLeft={locale == 'ar' && '50px'} paddingRight={locale == 'en' && '20px'} marginTop={{ xs: '20px', md: '0px' }} >
                                        <ButtonInput
                                            title={<FormattedMessage id="continue" />}
                                            variant="contained"
                                            bgColor={"yellow"}
                                            textColor={"black"}
                                            borderRadius="100"
                                            TextTransform="capitalize"
                                            endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                            onClick={() => navigate(getRevisedFinancingInitialDataURL(locale))}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        open={ResumeFormData?.resumeForm}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "35px" }}>
                    <ActiveFinance locale={locale} data={activeFinance} loader={activeFinanceloader} />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "35px" }}>
                    <FinancailProduct locale={locale} data={appStaticImage?.data?.non_logged_in} loader={appStaticImageloader} />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "35px" }}>
                    <AvailableService locale={locale} data={appStaticImage?.data?.available_e_services} loader={appStaticImageloader} />
                </Grid>
            </Grid>
        </LayoutStyleWrapper>
    );
};

export default Dashboard;
