import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiCards from "components/MuiCards";
import Text from "components/Text";
import TermsConditionComponents from "components/TermsCondition";
import ButtonInput from "components/Input/ButtonInput";
import { setLoginDetails } from "store/login/action";
import CircularProgress from '@mui/material/CircularProgress';
import { authSendOtpCallAPI } from "store/auth/otpSend/action";
import { AUTH_SEND_OTP } from "store/auth/otpSend/constant";
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";

function TermsCondition({ handleCurStep, postFormData }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isMobile = window.innerWidth < 900;
	const [isButtonDisable, setIsButtonDisable] = useState(true);
	const loader = useSelector((state) => state.authSendOtpReducer?.loading);
	const { locale } = useContext(LocaleContext);
	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
		if (bottom === true) {
			setIsButtonDisable(false)
		}
	};

	const handleSubmit = () => {
		postFormData.nationalId = postFormData?.nationalId;
		postFormData.contactNumber = postFormData?.contactNumber?.substring(4);
		postFormData.countryCode = "966";
		dispatch(setLoginDetails(postFormData));
		dispatch(authSendOtpCallAPI(AUTH_SEND_OTP, postFormData, { handleStep: handleCurStep, navigate: navigate, step: 2, errorStep: 1 }));
	};

	return (
		<Box style={{ display: 'flex', backgroundColor: '#F1EDF5', paddingBottom: "30px", minHeight: '90vh' }} marginTop={{ xs: '-3%', md: "0px" }}>
			<Container maxWidth={'xl'} style={{ marginTop: '5px' }} disableGutters>
				<Grid container style={{ marginTop: "30px" }}>
					<Grid item xs={12}>
						<MuiCards borderRadius="16" padding={{ xs: '0', md: '30px' }}>
							<CardContent>
								<Container style={{ padding: '0' }}>
									<Grid container>
										<Grid item xs={12}>
											<Text color="black" lineHeight="60" size="40" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}TextAlign="center">
												<FormattedMessage id="termsConditions" />
											</Text>
										</Grid>
									</Grid>
									<Grid container >
										<Grid item xs={12}>
											<TermsConditionComponents onScroll={handleScroll} padding={isMobile ? '20px' : '30px 45px 0px'}>
												<Text size="24" fontWeight="800" lineHeight="19" color="black">
													Lorem ipsum
												</Text>
												<div className="content">
													<Text size="18" fontWeight="600" lineHeight="34" color="darkPrimary">
														Lorem ipsum dolor sit amet consectetur. Et nascetur vulputate et suspendisse pharetra
														cras in at vitae. Ullamcorper eget nisl id sit massa donec habitasse quam felis.
														Eget hendrerit sit volutpat molestie orci velit nisi amet.
														Cras nulla risus turpis porttitor urna. Fermentum mauris lacus nunc lectus id praesent.
														Sed sodales viverra enim eros nunc at eget. Dictumst purus nunc mi viverra in congue tempor
														risus egestas. In sed eu auctor quis arcu aenean mi. Ultrices ullamcorper massa vulputate ac
														magna. Feugiat in elementum sit enim ut pretium. Dui odio id ultrices vitae habitasse.
													</Text>
													<Text size="18" fontWeight="600" lineHeight="34" color="darkPrimary">
														Porttitor dolor malesuada vitae lorem orci eget vel. Turpis eget amet quis nibh vivamus
														donec porttitor egestas. Nascetur nunc habitant ut parturient. Eget metus quis habitasse
														aliquam. In fermentum massa ac ullamcorper. Auctor amet facilisis eget a tempus.
														Ac quis at cursus blandit volutpat. Ullamcorper est eu semper sollicitudin at eget enim.
														Facilisis nisl consectetur mauris lorem parturient tortor auctor sed.
														Commodo faucibus tincidunt arcu sagittis lacus malesuada suspendisse vel.
														Et vitae ut pellentesque convallis in id maecenas nunc.
													</Text>
													<Text size="18" fontWeight="600" lineHeight="34" color="darkPrimary">
														Quis ullamcorper cursus ultrices augue massa non enim amet commodo. Duis euismod odio
														at tellus aliquet. Vulputate sit vestibulum quam sed eget interdum nunc. Diam elit
														quam neque ipsum at viverra. Facilisi pharetra non blandit dui vitae bibendum turpis.
														Lacinia donec praesent pharetra blandit odio enim tortor ac ornare. Venenatis tortor
														nisi etiam vitae tempor neque integer feugiat vitae. Ridiculus porta accumsan
														adipiscing etiam lorem nibh nisi varius. Semper lorem lectus malesuada enim consequat
														gravida venenatis morbi. Sem purus massa nisl nunc tellus feugiat. Euismod nec leo
														phasellus turpis. Consequat donec eget purus dui massa varius diam scelerisque.
														Cursus erat velit tellus pharetra consequat massa pharetra integer. Et augue
														scelerisque eu libero egestas fames diam. Ut fusce tellus ut ut egestas nulla
														pellentesque arcu. Id neque non sem gravida. Urna iaculis enim tincidunt ut penatibus
														hendrerit nunc habitasse tincidunt. Mauris egestas commodo sit netus. Sed et aenean at
														urna erat diam elementum dui.
													</Text>
													<Text size="18" fontWeight="600" lineHeight="34" color="darkPrimary">
														Amet eget malesuada laoreet quisque integer nibh nunc
														scelerisque. Vestibulum porttitor donec pellentesque in. Lectus enim vulputate amet
														elementum vitae vivamus vel non. Quis nec odio posuere rutrum. Mattis vitae ullamcorper
														tristique nulla. Lacus amet accumsan sem urna sagittis ut mauris. Pulvinar varius
														sem semper pharetra nec tortor felis augue. Amet sit odio a quisque est elit lacus.
														Aliquam nullam magna massa urna nunc gravida in. Nunc sed lacus dignissim turpis
														viverra sed porta. Amet augue purus ipsum ultrices massa mi. Donec et sollicitudin
														arcu feugiat lectus. Posuere neque sed elit pellentesque in mattis adipiscing.
														Velit facilisis elit consequat aliquam. Cursus in sollicitudin sit eget scelerisque
														velit mi dolor.
													</Text>
													<Text size="18" fontWeight="600" lineHeight="34" color="darkPrimary">
														Lorem ipsum dolor sit amet consectetur. Et nascetur vulputate et suspendisse pharetra
														cras in at vitae. Ullamcorper eget nisl id sit massa donec habitasse quam felis.
														Eget hendrerit sit volutpat molestie orci velit nisi amet.
														Cras nulla risus turpis porttitor urna. Fermentum mauris lacus nunc lectus id praesent.
														Sed sodales viverra enim eros nunc at eget. Dictumst purus nunc mi viverra in congue tempor
														risus egestas. In sed eu auctor quis arcu aenean mi. Ultrices ullamcorper massa vulputate ac
														magna. Feugiat in elementum sit enim ut pretium. Dui odio id ultrices vitae habitasse.
													</Text>
												</div>
											</TermsConditionComponents>
										</Grid>
									</Grid>

									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<Text size="20" lineHeight="24" fontWeight="600" color="darkPrimary">
												<FormattedMessage id="termsConditionsAgree" />
											</Text>
										</Grid>
									</Grid>

									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<div className="content" style={{ display: "flex", justifyContent: "end" }}>
												<Box component="form">
													<Stack spacing={2} direction="row">
														<ButtonInput
															title={<FormattedMessage id="confirmed" />}
															variant="contained"
															bgColor={isButtonDisable ? "" : "yellow"}
															textColor="purple"
															borderRadius="100"
															TextTransform="capitalize"
															disabled={isButtonDisable || loader}
															endIcon={loader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
															onClick={() => handleSubmit()}
														/>
													</Stack>
												</Box>
											</div>
										</Grid>
									</Grid>
								</Container>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default TermsCondition;