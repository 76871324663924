import React, { useState, useContext } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MuiCards from "components/MuiCards";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";
import { useNavigate } from "react-router-dom";
import { getDashboardURL, getRevisedFinancingURL } from "routes/AppUrls";
import { ResumeOnlineFormCall } from "store/resumeOnlineForm/actions";
import { RESUME_ONLINE_FORM } from "store/resumeOnlineForm/constant";

const ApplicationApproved = () => {
	const { locale } = useContext(LocaleContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: getRevisedFinancingURL(locale)
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])
	const handleSubmit = () => {
		let body = {
			resumeForm: false,
			step: 0
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
		navigate(getDashboardURL(locale));
	};

	return (
		<div style={{ display: 'flex', backgroundColor: '#F1EDF5', paddingBottom: "30px", minHeight: "100vh" }}>
			<Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
				{/* <Grid container>
					<Grid item xs={12}>
						<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
					</Grid>
				</Grid> */}
				{/* <Box margin='32px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box> */}

				<Grid container  >
					<Grid item xs={12}>
						<MuiCards borderRadius="16" padding="30px 0">
							<CardContent>
								<Container style={{ maxWidth: '689px' }}>
									<Grid container textAlign={'center'}>
										<Grid item xs={12}>
											<Grid container>
												<Grid item xs={12}>
													<div style={{ textAlign: "center" }}>
														<CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />
													</div>
												</Grid>
											</Grid>
											<Grid container display='flex' justifyContent='center'>

												<Grid item xs={12} marginTop='32px'>
													<Text color="black" size="40" fontWeight="800" lineHeight={'48px'} fontFamily="KarbonSemiBold" TextAlign="center">
														<FormattedMessage id="applicationSubmitted" />
													</Text>
												</Grid>
												{/* <Grid item xs={12}>
													<Text color="secondaryBlack" size="24" lineHeight={'28.8'} fontWeight="800" TextAlign="center">
														<FormattedMessage id="ourApplication" />
													</Text>
												</Grid> */}

												{/* <Grid item xs={12} marginTop='16px'>
													<div style={{ display: "flex", justifyContent: "center", flexWrap:'wrap' }}>
														<Text color="lightBlack" size="24" fontWeight="800" TextAlign="center">
															<FormattedMessage id="withReferenceNumber" />:
														</Text>
														<Text color="purple" size="24" fontWeight="800" TextAlign="center" fontFamily="KarbonMedium">
															&nbsp; 7897-897987-89
														</Text>

													</div>
<<<<<<< HEAD
												</Grid>
												<Grid item xs={12} style={{maxWidth:'569px'}}>
													<H5Text color="gray" size="24" fontFamily={locale=="en"?'Karbon_light':"Luma_light"} TextAlign="center"  >
=======
												</Grid> */}
												<Grid item xs={12} style={{ maxWidth: '569px' }}>
													<H5Text color="gray" size="24" fontFamily="Karbon_light" TextAlign="center"  >
														<FormattedMessage id="ourTelemarketers" />
													</H5Text>
												</Grid>
											</Grid>

											{/* <Grid container>
												<Grid item xs={12}>
													<MuiCards bgColor="pink" borderRadius="8" boxShadow={false} padding="10px 15px" margin="30px 0 0 0">
														<Grid container paddingLeft='17px'>
															<Grid item xs={11} display='flex' justifyContent='start' alignItems='center'>
																<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0" style={{textAlign: 'start'}}>
																	<FormattedMessage id="downloadYourQuotation" />
																</H5Text>
															</Grid>
															<Grid item xs={1}>
																<div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
																	<SaveAltIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
																</div>
															</Grid>
														</Grid>
													</MuiCards>
												</Grid>

												<Grid item xs={12}>
													<MuiCards bgColor="pink" borderRadius="8" boxShadow={false} padding="10px 15px" margin="10px 0 0 0">
														<Grid container paddingLeft='17px'>
															<Grid item xs={11} display='flex' justifyContent='start' alignItems='center'>
																<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0" style={{textAlign: 'start'}}>
																	<FormattedMessage id="downloadYourQuotation" />
																</H5Text>
															</Grid>
															<Grid item xs={1}>
																<div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
																	<SaveAltIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
																</div>
															</Grid>
														</Grid>
													</MuiCards>
												</Grid>
											</Grid> */}

											<Grid container style={{ marginTop: "30px" }}>
												<Grid item xs={12}>
													<div className="content" style={{ display: "flex", justifyContent: "end" }}>
														<Box component="form">
															<Stack spacing={2} direction="row">
																<ButtonInput
																	title={<FormattedMessage id="gotoDashboard" />}
																	variant="contained"
																	bgColor="yellow"
																	textColor="purple"
																	borderRadius="100"
																	TextTransform="capitalize"
																	endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
																	onClick={handleSubmit}
																/>
															</Stack>
														</Box>
													</div>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Container>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default ApplicationApproved;
