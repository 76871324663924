import React, { useContext } from "react";
import LayoutStyleWrapper from "styles/common.styles";
import { LocaleContext } from 'routes/Router';

const Support = () => {
    const { locale } = useContext(LocaleContext);
    
    return (
        <LayoutStyleWrapper locale={locale}>
            <h1>Support</h1>
        </LayoutStyleWrapper>
    );
};

export default Support;