import React, { useContext } from 'react';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from 'components/MuiLink';
import { LocaleContext } from 'routes/Router';
import { useNavigate } from 'react-router-dom';

const WrapperBreadcrumbs = styled(Breadcrumbs)`
	.MuiBreadcrumbs-separator {
		background: #333333;
	    width: 8px;
	    height: 8px;
	    border-radius: 20px;
	}
`;

function MuiBreadcrumbs({ list }) {
	const { locale } = useContext(LocaleContext);
	const navigate = useNavigate()
	const handleClick = (link)=>{
		navigate(link)
	}
	return (
		<Stack spacing={2}>
			<WrapperBreadcrumbs separator="" aria-label="breadcrumb">
				{list.length > 0 && list.map((value) => (
					<>
						{value.isDisbale ? (
							<MuiLink cursor="default" textColor="black" fontWeight="600" fontFamily={locale=="en"?'Karbon_light':"Luma_light"}>{value.title}</MuiLink>
						) : (
							<MuiLink onClick={()=>handleClick(value.Link)}>{value.title}</MuiLink>
						)}
					</>
				))}
			</WrapperBreadcrumbs>
		</Stack>
	);
}

export default MuiBreadcrumbs;