import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getRevisedFinancingURL } from 'routes/AppUrls';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import YesNoInput from "components/Input/YesNoInput";
import ButtonInput from "components/Input/ButtonInput";
import DateTimePickerInput from "components/Input/DateTimePickerInput";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MaskInput from "components/Input/MaskInput";
import { LocaleContext } from "routes/Router";
import SelectAmongs from "components/SelectAmongs";
import { financingYakeenUserInfoCallAPI } from "store/financing/yakeenUserInfo/action";
import { YAKEEN_USER_INFO_NON_SAUDI, YAKEEN_USER_INFO_SAUDI } from "store/financing/yakeenUserInfo/constant";
import { uploadApplicationDocumentCallAPI } from "store/uploadDocument/uploadApplicationDocument/action";
import { APP_UPLOAD_DOCUMENT } from "store/uploadDocument/uploadApplicationDocument/constant";
import { screenScrollTop } from "utils/general";
import { YAKEEN_ADDRESS_NONSAUDI_DATA, YAKEEN_ADDRESS_SAUDI_DATA } from "store/financing/yakeenUserAddress/constant";
import { financingYakeenUserAddressCallAPI } from "store/financing/yakeenUserAddress/action";
import { UPDATE_WORK_ITEM } from "store/workItem/updateWorkItem/constant";
import { updateWorkItemCallAPI } from "store/workItem/updateWorkItem/action";
import { fetchWorkItemCallAPI } from "store/workItem/fetchWorkItem/action";
import { FETCH_WORK_ITEM } from "store/workItem/fetchWorkItem/constant";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import CheckValidation from "./validation";
import { RESUME_ONLINE_FORM } from "store/resumeOnlineForm/constant";
import { ResumeOnlineFormCall } from "store/resumeOnlineForm/actions";

const WrapperDiv = styled.div`
	margin-bottom: 30px;
	border-radius:8px;
	background-color:white;
`;

const maritalStatus = [
	{
		id: 1,
		type: <FormattedMessage id="married" />
	},
	{
		id: 2,
		type: <FormattedMessage id="unmarried" />
	},
];

function InitialDataForm({ handleCurStep }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isError, setIsError] = useState(false);
	const { locale } = useContext(LocaleContext);

	const gosiPension = useSelector((state) => state).basicReducer;
	const yakeenSearchData = useSelector((state) => state.financingYakeenUserAddressReducer && state?.financingYakeenUserAddressReducer?.payload?.data);
	const userMeData = useSelector((state) => state.userReducer && state?.userReducer?.payload?.data);
	const financingCategoryDropdown = useSelector((state) => state.financingDropDownReducer && state?.financingDropDownReducer?.payload?.data);
	const workItemListData = useSelector((state) => state.applicationListWorkItemReducer && state?.applicationListWorkItemReducer?.payload?.data);
	const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state?.fetchWorkItemReducer?.payload?.data?.data);
	const updateWorkItemLoader = useSelector((state) => state?.updateWorkItemReducer?.loading);

	const updateWorkItemData = useSelector((state) => state?.updateWorkItemReducer && state?.updateWorkItemReducer?.payload?.data);
	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);
	const [yakeenData, setYakeenData] = useState([]);
	const [fileName, setFileName] = useState('');
	const [houseType, setHouseType] = useState(FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.houseType ?? '');
	const [residenceType, setResidenceType] = useState(FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.residenceType ?? '');


	const [formValue, setFormvalue] = useState({
		idNumber: userMeData?.data?.nationalId ? userMeData?.data?.nationalId : '',
		levelEducation: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.levelOfEducation ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.levelOfEducation : '',
		maritalTypeStatus: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.maritalStatus ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.maritalStatus : 'Married',
		license: "",
		licenseType: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseType ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseType : '',
		expiryDate: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseExpiry ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseExpiry : '',
		licenseYear: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.noOfYears ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.noOfYears : '',
		relativeGovtPost: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestGovtRelated ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestGovtRelated : 'No',
		govtFinanceName: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestGovtRelatedName ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestGovtRelatedName : '',
		govtDesignation: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestGovtRelatedDesignation ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestGovtRelatedDesignation : '',
		relativeManagement: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelated ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelated : 'No',
		relativeName: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelatedName ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelatedName : '',
		relativeDesignation: FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelatedDesignation ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelatedDesignation : '',
	});

	useEffect(() => {
		getYakeenDetails();
		fetchWorkItemApi();
	}, []);

	const fetchWorkItemApi = () => {
		let body = {
			appId: workItemAppId
		};
		dispatch(fetchWorkItemCallAPI(FETCH_WORK_ITEM, body, { navigate: navigate }));
	};

	useEffect(() => {
		if (userMeData?.data?.is_saudi) {
			setYakeenData(yakeenSearchData && yakeenSearchData?.AddressList6?.[0]);
		} else {
			setYakeenData(yakeenSearchData && yakeenSearchData?.AddressList7?.[0]);
		}
	}, [yakeenSearchData]);

	const getSecondPart = (str) => {
		const hyphenIndex = str.indexOf('-');
		const afterHyphen = str.slice(hyphenIndex + 1);
		return afterHyphen;
	};

	const getYakeenDetails = () => {
		if (userMeData?.data?.is_saudi) {
			let body = {
				Nin: userMeData?.data?.nationalId,
				DateOfBirthHijri: getSecondPart(userMeData?.data?.HijriDOB),
				ReferenceNumber: null,
				AddressLanguage: 'e'
			}
			dispatch(financingYakeenUserAddressCallAPI(YAKEEN_ADDRESS_SAUDI_DATA, body, { navigate: navigate }));
			let formbody = {
				Nin: userMeData?.data?.nationalId,
				DateOfBirth: getSecondPart(userMeData?.data?.HijriDOB),
				ReferenceNumber: '',
				AddressLanguage: 'e'
			};
			dispatch(financingYakeenUserInfoCallAPI(YAKEEN_USER_INFO_SAUDI, formbody, { navigate: navigate }));
		} else {
			let body = {
				IqamaNumber: userMeData?.data?.nationalId,
				AlienDateOfBirthG: getSecondPart(userMeData?.data?.DOB),
				ReferenceNumber: '',
				AddressLanguage: 'e'
			};
			dispatch(financingYakeenUserAddressCallAPI(YAKEEN_ADDRESS_NONSAUDI_DATA, body, { navigate: navigate }));
			let formbody = {
				IqamaNumber: userMeData?.data?.nationalId,
				DateOfBirth: getSecondPart(userMeData?.data?.DOB),
				ReferenceNumber: '',
				AddressLanguage: 'e'
			};
			dispatch(financingYakeenUserInfoCallAPI(YAKEEN_USER_INFO_NON_SAUDI, formbody, { navigate: navigate }));
		}
	};

	const fileInputRef = useRef(null);
	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0]; // Get the first selected file
		if (file) {
			setFileName(file.name); // Update the state with the file name
			setFormvalue((prev) => ({
				...prev,
				['license']: file.name
			}));
			let formData = new FormData();
			formData.append("file", e.target.files[0]);
			formData.append("loanAppNumber", updateWorkItemData?.data?.appId);
			formData.append("documentId", 103);
			dispatch(uploadApplicationDocumentCallAPI(APP_UPLOAD_DOCUMENT, formData));
		}
	};

	const UpdateWorkItem = () => {
		const error = CheckValidation(formValue);
		setIsError(error);
		let index = financingCategoryDropdown?.level_of_education.findIndex((obj) => obj?.Description === formValue?.levelEducation);
		const levelOfEducationId = financingCategoryDropdown?.level_of_education?.[index]?.ID;

		if (error.submit) {
			if (FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails["addressDetails"] === undefined)) {
				const addresObject = [{
					buildingNumber: yakeenData?.buildingNumberField,
					street: yakeenData?.streetNameField,
					district: yakeenData?.districtField,
					city: yakeenData?.cityField,
					additionalNumber: yakeenData?.additionalNumberField,
					postCode: yakeenData?.postCodeField,
					unitNumber: yakeenData?.unitNumberField,
					idType: userMeData?.data?.is_saudi ? 'National ID' : 'Iqama ID',
					idNumber: userMeData?.data?.nationalId,
					givenNameEg: yakeenData?.EnglishFirstName ?? '',
					addressType: ""
				}];
				FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails['addressDetails'] = addresObject);
			}
			FetchWorkItemData && (FetchWorkItemData['operation'] = "save");
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['licenseExpiry'] = formValue?.expiryDate);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['noOfYears'] = formValue?.licenseYear);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['licenseType'] = formValue?.licenseType);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['maritalStatus'] = formValue?.maritalTypeStatus === "Married" ? "1" : "2");
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['idNumber'] = formValue?.idNumber);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['levelOfEducation'] = String(levelOfEducationId));
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['isGuestGovtRelated'] = formValue?.relativeGovtPost);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['isGuestGovtRelatedDesignation'] = formValue?.govtDesignation);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['isGuestGovtRelatedName'] = formValue?.govtFinanceName);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['isGuestALJFSRelated'] = formValue?.relativeManagement);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['isGuestALJFSRelatedDesignation'] = formValue?.relativeDesignation);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['isGuestALJFSRelatedName'] = formValue?.relativeName);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['residenceType'] = residenceType);
			FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['houseType'] = houseType);

			dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleSubmit, navigate: navigate, run: true }));
		}
	};

	const handleSubmit = () => {
		handleCurStep(1);
		screenScrollTop();
		let body = {
			resumeForm: true,
			step: 1
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormvalue((prev) => ({
			...prev,
			[name]: value
		}));
	};

	const getSelectedValue = (val) => {
		if (val === 1) {
			setFormvalue((prev) => ({
				...prev,
				['maritalTypeStatus']: "Married"
			}));
		} else if (val === 2) {
			setFormvalue((prev) => ({
				...prev,
				['maritalTypeStatus']: "Unmarried"
			}));
		}
	};

	const handleRelativeManagement = (val) => {
		setFormvalue((prev) => ({
			...prev,
			['relativeManagement']: val
		}));
		if (val === "No") {
			setFormvalue((prev) => ({
				...prev,
				['relativeName']: '',
				['relativeDesignation']: ''
			}));
		};
	};

	const handleRelativeGovernment = (val) => {
		setFormvalue((prev) => ({
			...prev,
			['relativeGovtPost']: val
		}));
		if (val === "No") {
			setFormvalue((prev) => ({
				...prev,
				['govtFinanceName']: '',
				['govtDesignation']: ''
			}));
		};
	};

	const handleExpiryDate = (e) => {
		if (e?.$d) {
			setFormvalue((prev) => ({
				...prev,
				['expiryDate']: moment(e?.$d).format("MM/DD/YYYY")
			}));
		} else {
			setFormvalue((prev) => ({
				...prev,
				['expiryDate']: ''
			}));
		}
	};

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'0px'}>
						<FormattedMessage id={'autoFinance.personalInformation'} />
						<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>
					<Box component="form" sx={{ padding: "15px 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id='IDNumber' />}
									placeholder="ID Number"
									value={formValue?.idNumber}
									cursor={'not-allowed'}
									name={"idNumber"}
									errorText={isError && isError.idNumber.error && isError.idNumber.errorMessage}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									data={financingCategoryDropdown?.level_of_education}
									label={<FormattedMessage id={"autoFinance.levelofEducation"} />}
									placeholder="Level of Education"
									value={formValue?.levelEducation}
									name={"levelEducation"}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError.levelEducation.error && isError.levelEducation.errorMessage}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2} sx={{ marginTop: "15px" }}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<SelectAmongs
									data={maritalStatus}
									selected={formValue?.maritalTypeStatus === "Married" ? 1 : 2}
									getSelected={(val) => getSelectedValue(val)}
									label={<FormattedMessage id="cashFinance.maritalStatus" />}
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<H5Text size='24' lineHeight='36' borderRadius="8" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'revisedFinance.address'} />
					</H5Text>

					<Box component="form" sx={{ padding: "0 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.buildingNumber'} />}
									placeholder="Building Number"
									value={yakeenData?.buildingNumberField}
									cursor={yakeenData?.buildingNumberField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.street'} />}
									placeholder="Street"
									value={yakeenData?.streetNameField}
									cursor={yakeenData?.streetNameField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.district'} />}
									placeholder={"District"}
									value={yakeenData?.districtField}
									cursor={yakeenData?.districtField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.city'} />}
									placeholder={"City"}
									value={yakeenData?.cityField}
									cursor={yakeenData?.cityField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.additionalNumber'} />}
									placeholder="Additional Number"
									value={yakeenData?.additionalNumberField}
									cursor={yakeenData?.additionalNumberField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.postalCode'} />}
									placeholder="Postal Code"
									value={yakeenData?.postCodeField}
									cursor={yakeenData?.postCodeField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.unitNumber'} />}
									placeholder="Unit Number"
									value={yakeenData?.unitNumberField}
									cursor={yakeenData?.unitNumberField !== "" ? "pointer" : 'not-allowed'}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<SelectInput
									data={financingCategoryDropdown?.residence_type}
									label={<FormattedMessage id={'autoFinance.residenceType'} />}
									placeholder="Residence Type"
									value={residenceType}
									onChange={(e) => setResidenceType(e.target.value)}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<SelectInput
									data={financingCategoryDropdown?.house_type}
									label={<FormattedMessage id="autoFinance.houseType" />}
									placeholder="House Type"
									value={houseType}
									onChange={(e) => setHouseType(e.target.value)}
								/>
							</Grid>

						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="autoFinance.drivingLicense" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<input
									ref={fileInputRef}
									type="file"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<MaskInput
									type="text"
									disabled={false}
									maskChar=""
									label={<FormattedMessage id="driversLicense" />}
									name="Driver’s License"
									value={fileName && fileName}
									endAdornment={true}
									InputProps={{
										endAdornment: (
											<>
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadClick} />
												<MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
											</>
										),
									}}
									errorText={isError && isError.license.error && isError.license.errorMessage}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<SelectInput
									label={<FormattedMessage id="autoFinance.licenseType" />}
									placeholder="License Type"
									data={financingCategoryDropdown?.license_type}
									value={formValue?.licenseType}
									name={"licenseType"}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError.licenseType.error && isError.licenseType.errorMessage}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<DateTimePickerInput
									disablePast={FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseExpiry ? true : true}
									label={<FormattedMessage id="autoFinance.drivingLicenseExpiryDate" />}
									value={formValue?.expiryDate && moment(formValue?.expiryDate).format("MM/DD/YYYY")}
									onChange={(e) => handleExpiryDate(e)}
									name={"expiryDate"}
									errorText={isError && isError.expiryDate.error && isError.expiryDate.errorMessage}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.LicenseHeldforYears" />}
									placeholder="License Held for (Years)"
									rightText={false}
									value={formValue?.licenseYear}
									name={"licenseYear"}
									type='number'
									inputProps={{ maxLength: 2 }}
									onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, e.target.maxLength) }}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError.licenseYear.error && isError.licenseYear.errorMessage}
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Grid container sx={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="autoFinance.relativesInformation" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ marginTop: "15px", padding: "15px 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<YesNoInput
									label={<FormattedMessage id="autoFinance.doHaveRelationGovernmentPost" />}
									selectedYesNoValue={formValue?.relativeGovtPost}
									getSelectedYesNoValue={(val) => handleRelativeGovernment(val)}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={6} lg={6}>
							</Grid>

							{formValue?.relativeGovtPost === "Yes" &&
								<>

									<Grid item xs={12} sm={6} md={6} lg={6}>
										<LabelWithInput
											label={<FormattedMessage id="autoFinance.fullName" />}
											placeholder="Full Name"
											value={formValue?.govtFinanceName}
											name={"govtFinanceName"}
											onChange={(e) => handleChange(e)}
											errorText={isError && isError.govtFinanceName.error && isError.govtFinanceName.errorMessage}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6}>
										<LabelWithInput
											label={<FormattedMessage id="autoFinance.designation" />}
											placeholder="Designation"
											value={formValue?.govtDesignation}
											name={"govtDesignation"}
											onChange={(e) => handleChange(e)}
											errorText={isError && isError.govtDesignation.error && isError.govtDesignation.errorMessage}
										/>
									</Grid>
								</>
							}

							<Grid item xs={12} sm={12} md={6} lg={6}>
								<YesNoInput
									label={<FormattedMessage id="autoFinance.guestRelationManagement" />}
									selectedYesNoValue={formValue?.relativeManagement}
									getSelectedYesNoValue={(val) => handleRelativeManagement(val)}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={6} lg={6}>
							</Grid>

							{formValue?.relativeManagement === "Yes" &&
								<>

									<Grid item xs={12} sm={6} md={6} lg={6}>
										<LabelWithInput
											label={<FormattedMessage id="autoFinance.fullName" />}
											placeholder="Full Name"
											value={formValue?.relativeName}
											name={"relativeName"}
											onChange={(e) => handleChange(e)}
											errorText={isError && isError.relativeName.error && isError.relativeName.errorMessage}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6}>
										<LabelWithInput
											label={<FormattedMessage id="autoFinance.designation" />}
											placeholder="Designation"
											value={formValue?.relativeDesignation}
											name={"relativeDesignation"}
											onChange={(e) => handleChange(e)}
											errorText={isError && isError.relativeDesignation.error && isError.relativeDesignation.errorMessage}
										/>
									</Grid>
								</>
							}

						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form" sx={{padding: "20px 30px" }}>
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									minWidth="100px"
									marginRight="30"
									padding="0px"
									onClick={() => navigate(getRevisedFinancingURL(locale))}
								/>
								<ButtonInput
									title={<><Box display={{ xs: 'block', md: 'none' }}><FormattedMessage id="saveProceed" /></Box><Box display={{ xs: 'none', md: 'block' }}><FormattedMessage id="revisedFinance.proceedtoWork" /></Box></>}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									disabled={updateWorkItemLoader}
									endIcon={updateWorkItemLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									// onClick={() => { handleCurStep(1); screenScrollTop() }}
									onClick={() => UpdateWorkItem()}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default InitialDataForm;