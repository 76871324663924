import { Box, Grid } from '@mui/material'
import MuiBreadcrumbs from 'components/MuiBreadcrumbs'
import H5Text from 'components/Text/H5Text'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import backArrow from 'assets/images/icon/backArrow.png';
import { LocaleContext } from 'routes/Router';
import styled from 'styled-components';
import FinancingResultsData from 'features/CashFinancing/FinancingResults';
import MuiCards from 'components/MuiCards';
import OneWaySlider from 'components/RangeSlider/OneWaySlider';
import CheckboxInput from 'components/Input/CheckboxInput';
import ButtonInput from 'components/Input/ButtonInput';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCashFinancingURL, getDashboardURL } from 'routes/AppUrls';
import MaximumAllowedAmount from 'features/CashFinancing/MaximumAllowedAmount';
import LocalStorageManager from 'managers/LocalStorageManger';
import AllowedAmount from 'features/CashFinancing/AllowedAmount'

const ContainerStyle = styled.div`
padding: 0 5% 5% 5%;
min-height: 90vh;
.BackHeading{
    display: flex;
    align-items: center;
    img{
        margin-top: 7px;
        margin-right:24px;
    }
}
@media screen and (max-width:768px){
    .BackHeading{
        img{
            margin-top: 8px;
            margin-right:12px !important;
            height:18px;
        }
    }
}
background:#f1edf5;
`
const ApplyForCashFinance = () => {
  const { locale } = useContext(LocaleContext);
  const [monthlyAmount, setMonthlyAmount] = useState(80000);
  const [period, setPeriod] = useState(12);
  const location = useLocation()
  const [curStep, setCurStep] = useState(location?.state?.step ? location?.state?.step : 0);
  const navigate = useNavigate();
  LocalStorageManager.removeItem('approved');
  const handleCurStep = (value) => {
    setCurStep(value);
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="home" />,
      Link: ""
    },
    {
      title: <FormattedMessage id="applyFinance" />,
      Link: "",
      isDisbale: true,
    },
  ]);

  return (
    <ContainerStyle locale={locale}>
      <Box paddingTop='32px'>
        <MuiBreadcrumbs
          list={breadcrumbsList}
        />
      </Box>
      <div className='BackHeading'>
        <div onClick={() => navigate(getDashboardURL(locale))} style={{ cursor: "pointer" }}>
          <img src={backArrow}
            // onClick={() => navigate(getFindVehicleURL('en'))} 
            alt="" style={{ cursor: "pointer", transform: locale === 'ar' ? 'scaleX(-1)' : '', margin: locale === 'en' ? '7px 24px 0 0' : '7px 0 0 24px' }} />
        </div>
        <H5Text size="32" marginTop={'14'} color={'purple'}><FormattedMessage id="cashFinance.applyForCashFinancing" /></H5Text>
      </div>
      <Grid container marginTop={'40px'} spacing={2}>
        <Grid item xs={12} lg={4} xl={3}>
          <FinancingResultsData title="financingResults" bgColor={"white"} />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <MuiCards borderRadius="16" padding="24px 28px">
            {curStep === 1 ? (
              // <PersonalWorkForm handleCurStep={handleCurStep} />
              <MaximumAllowedAmount handleCurStep={handleCurStep} />
            ) :
              curStep === 2 ? (
                // <PersonalWorkForm handleCurStep={handleCurStep} />
                <AllowedAmount handleCurStep={handleCurStep} />) :
                (
                  <>
                    <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
                      <FormattedMessage id="cashFinance.financialDetails" />
                      {/* <img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} /> */}
                    </H5Text>
                    <Grid container padding={'24px 16px'}>
                      <Grid item xs={12} lg={6} >
                        <Grid item xs={12} md={10} >
                          <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={10} endLimit={90000} label={<FormattedMessage id="cashFinance.monthlyObligations" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6} >
                        <Grid item xs={12} md={10} >
                          <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={10} endLimit={90000} label={<FormattedMessage id="cashFinance.otherMonthlyObligations" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6} marginTop='24px'>
                        <Grid item xs={12} md={10} >
                          <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={10} endLimit={90000} label={<FormattedMessage id="cashFinance.totalLimit" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} >
                        <CheckboxInput
                          label={<FormattedMessage id="cashFinance.iHaveRead" />}
                          size="18"
                          fontWeight="600"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} marginTop='64px'>
                      <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                        <ButtonInput
                          title={<FormattedMessage id="cancel" />}
                          variant="text"
                          bgColor="transparent"
                          textColor="purple"
                          borderRadius="100"
                          TextTransform="capitalize"
                          // onClick={() => handleCurStep(2)}
                          onClick={() => navigate(getCashFinancingURL(locale))}
                        />
                        <ButtonInput
                          title={<FormattedMessage id="proceed" />}
                          variant="contained"
                          bgColor={"yellow"}
                          textColor="purple"
                          borderRadius="100"
                          TextTransform="capitalize"
                          endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                          onClick={() => handleCurStep(1)}
                        />
                      </Box>
                    </Grid>
                  </>
                )}

          </MuiCards>
        </Grid>
      </Grid>
    </ContainerStyle>
  )
}

export default ApplyForCashFinance;
