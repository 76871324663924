import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";
import H1Text from "components/Text/H1Text";
import H5Text from "components/Text/H5Text";
import { Box, Skeleton } from "@mui/material";
import { USER_API, USER_PROFILE_UPDATE_API, USER_UPDATE_API } from "store/user/constant";
import ModalWithInput from "components/Modals/ModalWithInput";
import { userCallApi } from "store/user/action";
import { LocaleContext } from 'routes/Router';
import profileCameraIcon from "assets/icons/ProfileIcon.png";
import defaultProfile from "assets/images/Account/defaultProfile.png";
import { useNavigate } from "react-router-dom";
import { Emailverification } from "utils/general";
import CommonModal from "components/Modals/CommonModal";
import ButtonInput from "components/Input/ButtonInput";
import SideBySideText from "components/SideBySideText";
import Text from "components/Text";
import CheckboxInput from "components/Input/CheckboxInput";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";

const StyledWrapper = styled.div` 
    padding:10px 20px 20px 20px;
    border-bottom: 1px solid #DEDEDE;
    .hexagone {
        display:inline-block;
        width:160px;
        color:transparent;
        filter:url(#round);
        z-index:0;
      }
      .hexagone::before {
         content:"";
         display:block;
         height:160px;
         width:160px;
         background:currentColor;
        clip-path: polygon( 50% 0%, 105% 42%, 75% 100%, 25% 100%, -5% 42%);
        background-image: url(${props => props?.profilePic});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    .hexagoneDemo {
        display:inline-block;
        width:160px;
        color:#cfcfcf;
        filter:url(#round);
        z-index:0;
      }
      .hexagoneDemo::before {
         content:"";
         display:block;
         height:160px;
         width:160px;
         background:currentColor;
        clip-path: polygon( 50% 0%, 105% 42%, 75% 100%, 25% 100%, -5% 42%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
`

const AccountNavbar = ({ ...props }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalActive, setIsModalActive] = useState(false);
    const [mobileModalActive, setMobileModalActive] = useState(false);
    const [addressModalActive, setAddressModalActive] = useState(false);
    const { locale } = useContext(LocaleContext);
    const userDetails = useSelector((state) => (state.userReducer && state.userReducer?.payload?.data));
    const userError = useSelector((state) => (state.userReducer?.error?.data?.message));
    const loader = useSelector((state) => state.userReducer?.loading);
    const [emailValue, setEmailValue] = useState(userDetails?.data?.email ? userDetails?.data?.email : "");
    const [AddressValue, setAddressValue] = useState(userDetails?.data?.email ? userDetails?.data?.email : "");

    useEffect(() => {
        if (!loader) {
            setIsModalActive(false);
            setMobileModalActive(false);
            setAddressModalActive(false);
        }
    }, [loader])

    const getUserMeApi = () => {
        dispatch(userCallApi(USER_API, {}, { navigate: navigate }));
    };

    const handleContactUpdate = (value) => {
        let body = {
            "contactNumber": value?.substring(4)
        }
        dispatch(userCallApi(USER_UPDATE_API, body, { handleFunction: getUserMeApi, navigate: navigate, run: true }));
    };

    const handleEmailUpdate = (value) => {
        setEmailValue(value)
        let body = {
            "email": value
        }
        dispatch(userCallApi(USER_UPDATE_API, body, { handleFunction: getUserMeApi, navigate: navigate, run: true }));
    };
    const handleAddressUpdate = (value) => {
        setAddressValue(value)
        let body = {
            "address": value
        }
        dispatch(userCallApi(USER_UPDATE_API, body, { handleFunction: getUserMeApi, navigate: navigate, run: true }));
    };

    const handleProfileImageUpdate = async (e) => {
        let formData = new FormData();
        formData.append("file-image", e.target.files[0]);
        dispatch(userCallApi(USER_PROFILE_UPDATE_API, formData, { handleFunction: getUserMeApi, navigate: navigate, run: true }));
    }

    const handleCloseModal = (value) => {
        setIsModalActive(value);
        setMobileModalActive(value);
        setAddressModalActive(value);
    };

    const [isCommonModalActive, setIsCommonModalActive] = useState(false);
    const handleModalPopup = (value) => {
        setIsCommonModalActive(value);
    }

    return (
        <StyledWrapper profilePic={userDetails?.data?.user_profile_pic ? userDetails?.data?.user_profile_pic : defaultProfile}>
            <Grid container alignItems={'flex-start'}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={4} display='flex' flexWrap={'wrap'}>
                    {!loader ?
                        <Box marginRight={locale == "en" && '30px'} marginLeft={locale == "ar" && '30px'} sx={{ display: "flex" }}>
                            {/* <img style={{ width: "120px", height: "120px", borderRadius: "100px" }} src={userDetails?.data?.user_profile_pic ? userDetails?.data?.user_profile_pic : defaultProfile} alt='icon' /> */}
                            <>
                                <div class="hexagone"></div>

                                <svg style={{ visibility: "hidden", position: "absolute" }} width="0" height="0" >
                                    {/* <img style={{ width: "120px", height: "120px", borderRadius: "100px" }} src={userDetails?.data?.user_profile_pic ? userDetails?.data?.user_profile_pic : defaultProfile} alt='icon' /> */}
                                    <defs>
                                        <filter id="round">
                                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                        </filter>
                                    </defs>
                                </svg>
                            </>
                            <label for="profile" style={{ zIndex: '1', cursor: "pointer", alignSelf: "end", marginLeft: locale == "en" && "-50px", marginRight: locale == "ar" && "-50px" }}>
                                <img src={profileCameraIcon} />
                            </label>
                            <input id="profile" name="ProfilePic" style={{ display: "none" }} type="file" onChange={(e) => handleProfileImageUpdate(e)} />
                        </Box>
                        :
                        <Box marginRight={locale == "en" && '30px'} marginLeft={locale == "ar" && '30px'} sx={{ display: "flex" }}>
                            {/* <Skeleton variant="circular" animation="wave" height="120px" width="120px" /> */}
                            <>
                                <div class="hexagoneDemo"></div>

                                <svg style={{ visibility: "hidden", position: "absolute" }} width="0" height="0" >
                                    <defs>
                                        <filter id="round">
                                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                        </filter>
                                    </defs>
                                </svg>
                                <label for="profile" style={{ zIndex: '1', alignSelf: "end", marginLeft: locale == "en" && "-50px", marginRight: locale == "ar" && "-50px" }}>
                                    <Skeleton variant="circular" animation="wave" height="32px" width="32px" />
                                </label>
                            </>
                        </Box>
                    }
                    <Box >
                        {!loader ?
                            <Box maxWidth={{ xs: "100%", xl: "260px" }}>
                                <H1Text size='40' lineHeight={'43.11'} fontFamily='Karbon_bold' >
                                    {userDetails?.data?.first_name} {userDetails?.data?.last_name} {userDetails?.data?.third_name}
                                </H1Text>
                            </Box>
                            :
                            <Skeleton animation="wave" height={20} width="100%" />
                        }
                        <Box display={'flex'}>
                            {!loader ?
                                <>
                                    <H5Text size='18' lineHeight={'21.6'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='dark' marginTop={'0'}>{'@'}&nbsp;</H5Text>
                                    <H5Text size='18' lineHeight={'21.6'} fontFamily='Karbon_medium' color='dark' marginTop={'0'}>{userDetails?.data?.nationalId}</H5Text>
                                </>
                                :
                                <Skeleton animation="wave" height={20} width="100%" />
                            }
                        </Box>
                        <H5Text size='19' lineHeight={'19'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='lightGrey' marginTop={'0'}><FormattedMessage id="account.lastLoginAt" />{' 12:30pm '}</H5Text>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={4} display='flex flex-column' sx={{ justifyContent: { xs: 'start', lg: 'center' }, marginTop: { xs: '16px', xl: '16px' } }}>
                    <Box display={'flex'} alignItems='center' flexWrap={'wrap'} width='100%' justifyContent={{ sm: 'start', lg: 'start' }}>
                        <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='dark' marginTop={'0'}><FormattedMessage id="email" />:&nbsp;</H5Text>
                        {!loader ?
                            <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='lightGrey' marginTop={'0'}>{userDetails?.data?.email ? userDetails?.data?.email : ''}</H5Text> :
                            <Skeleton animation="wave" height={20} width="40%" />
                        }
                        <ModalWithInput email={userDetails?.data?.email ? userDetails?.data?.email : ''} modalActive={isModalActive} handleCloseModal={handleCloseModal} title={"Edit Email"} handleEmailUpdate={handleEmailUpdate} loader={loader} error={userError} />
                        {Emailverification(emailValue) && <img alt="" src={require('assets/icons/verified.png')} height='21px' width='21px' style={{ marginLeft: locale === 'en' && '4px', marginRight: locale === 'ar' && '4px' }} />}
                        <img alt="" src={require('assets/icons/edit.png')} height='13px' width='13px' style={{ marginLeft: locale === 'en' && '12px', marginRight: locale === 'ar' && '12px', cursor: "pointer" }} onClick={() => setIsModalActive(true)} />
                    </Box>
                    <Box display={'flex'} alignItems='center' flexWrap={'wrap'} width='100%' justifyContent={{ sm: 'start', lg: 'start' }} marginTop='20px'>
                        <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='dark' marginTop={'0'}><FormattedMessage id="National Address" />:&nbsp;</H5Text>
                        {!loader ?
                            <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='lightGrey' marginTop={'0'}>{userDetails?.data?.national_address ? userDetails?.data?.national_address : ''}</H5Text> :
                            <Skeleton animation="wave" height={20} width="40%" />
                        }
                        {/* <ModalWithInput modalActive={isModalActive} handleCloseModal={handleCloseModal} title={"Email Update"} handleEmailUpdate={handleEmailUpdate} loader={loader} error={userError} />
                        {Emailverification(emailValue) && <img alt="" src={require('assets/icons/verified.png')} height='21px' width='21px' style={{ marginLeft: locale === 'en' && '4px', marginRight: locale === 'ar' && '4px' }} />}
                        <img alt="" src={require('assets/icons/edit.png')} height='13px' width='13px' style={{ marginLeft: locale === 'en' && '12px', marginRight: locale === 'ar' && '12px', cursor: "pointer" }} onClick={() => setIsModalActive(true)} /> */}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={4} sx={{ justifyContent: { xs: 'start', lg: 'start' }, marginTop: { xs: '16px', xl: '16px' } }}>
                    <Box display={'flex'} alignItems='center' flexWrap={'wrap'}>
                        <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='dark' marginTop={'0'}><FormattedMessage id="contact" />:&nbsp;</H5Text>
                        {!loader ?
                            <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='lightGrey' marginTop={'0'}>{userDetails?.data?.contactNumber ? userDetails?.data?.contactNumber : ''}</H5Text> :
                            <Skeleton animation="wave" height={20} width="40%" />
                        }
                        <ModalWithInput modalActive={mobileModalActive} handleCloseModal={handleCloseModal} handleContactUpdate={handleContactUpdate} title={"Edit Mobile No."} loader={loader} error={userError} />
                        <img alt="" src={require('assets/icons/edit.png')} height='13px' width='13px' style={{ marginLeft: locale === 'en' && '12px', marginRight: locale === 'ar' && '12px', cursor: "pointer" }} onClick={() => setMobileModalActive(true)} />
                    </Box>
                    <Box display={'flex'} alignItems='center' flexWrap={'wrap'} width='100%' justifyContent={{ sm: 'start', lg: 'start' }} marginTop='20px'>
                        <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='dark' marginTop={'0'}><FormattedMessage id="Shipping Address" />:&nbsp;</H5Text>
                        {!loader ?
                            <H5Text size='20' lineHeight={'24'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='lightGrey' marginTop={'0'}>{userDetails?.data?.shipping_address ? userDetails?.data?.shipping_address : ''}</H5Text> :
                            <Skeleton animation="wave" height={20} width="40%" />
                        }
                        <Box  dispaly="flex">
                            
                            <img height='13px' width='13px' alt="" src={require('assets/icons/edit.png')} onClick={() => setIsCommonModalActive(true)}></img>

                        </Box>
                        {/* <ModalWithInput maxWidth={'65%'} modalActive={addressModalActive} handleCloseModal={handleCloseModal} title={"Edit Address"} handleAddressUpdate={handleAddressUpdate} loader={loader} error={userError} />
                        <img alt="" src={require('assets/icons/edit.png')} height='13px' width='13px' style={{ marginLeft: locale === 'en' && '12px', marginRight: locale === 'ar' && '12px', cursor: "pointer" }} onClick={() => setAddressModalActive(true)} /> */}
                    </Box>
                    <CommonModal padding={'32px 48px'} width={'700px'} modalActive={isCommonModalActive} innerContent={
                        <>
                            <Grid item xs={10}>
                                <Text size="40" lineHeight={"40"} marginTop="0" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} >
                                    <FormattedMessage id={"Edit Address"} />
                                </Text>
                            </Grid>
                            <Box width="100%" marginTop={'20px'}>
                                <H5Text size='24' lineHeight='36' fontFamily="karbon_semiBold" color="purple">
                                    National Address
                                </H5Text>
                                <Box width="100%" maxWidth={'652px'} border='0.5px solid #B8B8B8' borderRadius={'16px'} marginTop='12px'>
                                    <SideBySideText
                                        leftText={<FormattedMessage id="Building Number" />}
                                        RightText="40.65 SR"
                                        paddingAll={'32'}
                                        pBottom={'6'}
                                        style={{ paddingTop: '3px' }}
                                        rightTextColor='purple'
                                        leftFontFamily="Karbon_light"
                                        rightFontFamily="Karbon_semiBold"
                                    />
                                    <SideBySideText
                                        leftText={<FormattedMessage id="Street" />}
                                        RightText="40.65 SR"
                                        paddingAll={'32'}
                                        pBottom={'6'}
                                        style={{ paddingTop: '3px' }}
                                        rightTextColor='purple'
                                        leftFontFamily="Karbon_light"
                                        rightFontFamily="Karbon_semiBold"
                                    />
                                    <SideBySideText
                                        leftText={<FormattedMessage id="District" />}
                                        RightText="40.65 SR"
                                        paddingAll={'32'}
                                        pBottom={'6'}
                                        style={{ paddingTop: '3px' }}
                                        rightTextColor='purple'
                                        leftFontFamily="Karbon_light"
                                        rightFontFamily="Karbon_semiBold"
                                    />
                                    <SideBySideText
                                        leftText={<FormattedMessage id="City" />}
                                        RightText="40.65 SR"
                                        paddingAll={'32'}
                                        pBottom={'6'}
                                        style={{ paddingTop: '3px' }}
                                        rightTextColor='purple'
                                        leftFontFamily="Karbon_light"
                                        rightFontFamily="Karbon_semiBold"
                                    />
                                    <SideBySideText
                                        leftText={<FormattedMessage id="Postal Code" />}
                                        RightText="40.65 SR"
                                        paddingAll={'32'}
                                        pBottom={'6'}
                                        style={{ paddingTop: '3px' }}
                                        rightTextColor='purple'
                                        leftFontFamily="Karbon_light"
                                        rightFontFamily="Karbon_semiBold"
                                    />
                                </Box>
                                <Grid item xs={12} md={12} lg={12} marginTop='28px'>
                                    <CheckboxInput
                                        label={<FormattedMessage id="Shipping address same as national address" />}
                                        size="18"
                                        fontWeight="600"
                                    />
                                </Grid>
                                {/* <Grid container sx={{ marginTop: "15px" }}> */}
                                    <Grid item xs={12}>
                                        <H5Text size='24' lineHeight='36' borderRadius="8" padding="8px 0px" color="purple">
                                            <FormattedMessage id="Shipping Address" />
                                        </H5Text>

                                        <Box component="form" >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id="revisedFinance.buildingNumber" />}
                                                        placeholder="Building Number"
                                                    />
                                                </Grid><Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id="revisedFinance.street" />}
                                                        placeholder="Street"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectInput label={<FormattedMessage id="revisedFinance.district" />} placeholder="District" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectInput label={<FormattedMessage id="revisedFinance.city" />} placeholder="City" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id="revisedFinance.postalCode" />}
                                                        placeholder="Postal Code"
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                {/* </Grid> */}
                                <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                    <ButtonInput
                                        title={<FormattedMessage id="cancel" />}
                                        variant="text"
                                        bgColor="transparent"
                                        textColor="purple"
                                        borderRadius="100"
                                        TextTransform="uppercase"
                                        onClick={() => setIsCommonModalActive(false)}

                                    />
                                    <ButtonInput
                                        title={"Submit"}
                                        variant="contained"
                                        bgColor={"yellow"}
                                        textColor="purple"
                                        borderRadius="100"
                                        // disabled={!isValid || loader}
                                        TextTransform="uppercase"
                                        // endIcon={loader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
                                        // onClick={() => handlePostData()}
                                    // endIcon={<ArrowForwardIcon />}
                                    // onClick={() => handlePostData()}
                                    />

                                </Box>
                            </Box>
                        </>
                    } />
                </Grid>
            </Grid>

        </StyledWrapper>
    );
};


export default AccountNavbar;
