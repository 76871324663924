import { FINANCING_TYPE, LANGUAGE_TOGGLE, SIDEBAR_TOGGLE, NET_INCOME, CONTINUE_WITH, WORK_ITEM_APP_ID } from "./constant";

export const ToggleSideBar = (type, data) => {
    switch (type) {
        case SIDEBAR_TOGGLE:
            return {
                type: type,
                data: data
            }
    }
};

export const ToggleLanguage = (type, data) => {
    switch (type) {
        case LANGUAGE_TOGGLE:
            return {
                type: type,
                data: data
            }
    }
};

export const financingType = (type, data) => {
    switch (type) {
        case FINANCING_TYPE:
            return {
                type: type,
                data: data
            }
    }
};

export const continueWith = (type, data) => {
    switch (type) {
        case CONTINUE_WITH:
            return {
                type: type,
                data: data
            }
    }
};

export const NetIncome = (type, data) => {
    switch (type) {
        case NET_INCOME:
            return {
                type: type,
                data: data
            }
    }
};

export const WorkItemAppId = (type, data) => {
    switch (type) {
        case WORK_ITEM_APP_ID:
            return {
                type: type,
                data: data
            }
    }
};

