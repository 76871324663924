import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckValidation from "./validation";
import { authCallAPI } from "store/auth/action";
import { AUTH_SET_PASSWORD } from "store/auth/constant";
import { useNavigate } from "react-router-dom";
import { SET_PASSWORD } from "store/auth/setPassword/constant";
import { setPasswordCallAPI } from "store/auth/setPassword/action";
import { LocaleContext } from "routes/Router";

function PasswordVerify({ handleCurStep }) {
	const isMobile = window.innerWidth < 900;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [isError, setIsError] = useState(false);
	const [isPassVisible, setIsPassVisible] = useState(false);
	const [isRePassVisible, setIsRePassVisible] = useState(false);
	const [formValue, setFormValue] = useState({
		password: "",
		rePassword: ""
	});
	// const AuthError = useSelector((state) => (state.authReducer?.error?.data?.message));
	// const AuthData = useSelector((state) => (state.authReducer && state.authReducer?.payload?.data));
	// const loader = useSelector((state) => state.authReducer?.loading);

	const verifyOtpData = useSelector((state) => (state?.otpVerifyReducer?.payload?.data));
	const setPasswordError = useSelector((state) => (state.setPasswordReducer?.error?.data?.message));
	const setPasswordloader = useSelector((state) => state.setPasswordReducer?.loading);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValue((prevState) => ({
			...formValue,
			[name]: value,
		}));
	};

	const handleVisiblePassword = (value) => {
		setIsPassVisible(!value);
	};

	const handleVisibleRePassword = (value) => {
		setIsRePassVisible(!value);
	};

	const handleSubmit = () => {
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			let postBody = {};
			postBody.nationalId = verifyOtpData?.data?.nationalId;
			postBody.password = formValue.password;
			postBody.action = "forgot-password";
			dispatch(setPasswordCallAPI(SET_PASSWORD, postBody, { handleStep: handleCurStep, navigate: navigate, step: 'success' }));
			// dispatch(authCallAPI(AUTH_SET_PASSWORD, postBody, { handleStep: handleCurStep, navigate: navigate, step: 'success' }));
		}
	};

	return (
		<>
			<H1Text color="primary" size={isMobile ? "40" : "64"} lineHeight={isMobile ? "40" : "96"}>
				<FormattedMessage id="forgot.forgot" />
			</H1Text>

			<Text marginTop="0" size="24" color="lightDark" lineHeight="36">
				<FormattedMessage id="forgot.forgotSubText" />f
			</Text>

			<Grid container>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<LabelWithInput
						label={<FormattedMessage id="forgot.newPassword" />}
						placeholder="New Password"
						name="password"
						type={isPassVisible ? "text" : "password"}
						onChange={handleInputChange}
						InputProps={{
							endAdornment: (
								<>
									{isPassVisible ? (
										<VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
									) : (
										<VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
									)}
								</>
							)
						}}
						errorText={isError && isError.password.error && isError.password.errorMessage}
						helperInfoPassword={true}
					/>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<LabelWithInput
						label={<FormattedMessage id="forgot.confirmPassword" />}
						placeholder="Confirm Password"
						name="rePassword"
						type={isRePassVisible ? "text" : "password"}
						onChange={handleInputChange}
						InputProps={{
							endAdornment: (
								<>
									{isRePassVisible ? (
										<VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleRePassword(isRePassVisible)} />
									) : (
										<VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleRePassword(isRePassVisible)} />
									)}
								</>
							)
						}}
						errorText={isError && isError.rePassword.error && isError.rePassword.errorMessage}
					/>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<Grid container>
						<Grid item xs={12}>
							<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="back" />}
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										minWidth="100px"
										marginRight="40"
										onClick={() => handleCurStep(2)}
									/>
									<ButtonInput
										title={<FormattedMessage id="continue" />}
										variant="contained"
										bgColor={"yellow"}
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={setPasswordloader}
										endIcon={setPasswordloader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> :  locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
										onClick={() => handleSubmit()}
									/>
								</Stack>
							</Box>
						</Grid>

						{!setPasswordloader &&
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
								{setPasswordError &&
									<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
										{setPasswordError}
									</Text>
								}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default PasswordVerify;