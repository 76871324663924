import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import TimerWithCode from "components/TimerWithCode";
import AuthenticationImage from "assets/images/icon/microsoft-authenticator.png";
import MuiLink from 'components/MuiLink';
import { nafathCallAPI } from 'store/nafath/action';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { tahakukCallAPI } from 'store/tahakuk/action';
import { AUTH_TAHAKKUQ_VERIFY } from 'store/tahakuk/constant';
import { nafathAuthCallAPI } from 'store/nafathAuth/action';
import { AUTH_NAFATH_AUTHENTICATION } from 'store/nafathAuth/constant';
import { NAFATH_INITIAL_STATE, NAFATH_VERIFY } from 'store/nafath/constant';
import { useNavigate } from 'react-router-dom';
import { nafathPollAPI } from 'store/nafathStatus/action';
import { NAFATH_POLLING } from 'store/nafathStatus/constant';

const WrapperDiv = styled.div`
	.content{
		display:flex;
		justify-content:end;
	}
	
	@media screen and (max-width:900px){
		.content{
			justify-content:center;
		}
	}
`;

function ApproveRequest({ handleCurStep, postFormData, handleFormData }) {
	const isMobile = window.innerWidth < 900;
	const [isError, setIsError] = useState(false);
	const [timeCountValue, setTimeCountValue] = useState("02:30");
	const [minSecondsLeft, setMinSecondsLeft] = useState(150);
	const [timeoutIds, setTimeoutIds] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const nafathVerficationData = useSelector((state) => (state.nafathReducer?.payload?.data?.data));
	const nafathAuthData = useSelector((state) => (state.nafathAuthReducer?.payload?.data?.data));
	const tahakukVerificationData = useSelector((state) => (state.tahakukReducer?.payload?.data?.data));
	const AuthError = useSelector((state) => (state.nafathAuthReducer?.error?.data?.message || state.nafathReducer?.error?.data?.message || state.tahakukReducer?.error?.data?.message));
	const loader = useSelector((state) => state.nafathAuthReducer?.loading || state.nafathReducer?.loading || state.tahakukReducer?.loading);
	const nafathLoader = useSelector(state => state.nafathReducer?.loading);
	const nafathPollApiData = useSelector(state => state.nafathPollReducer?.payload?.data);
	const nafathAuthError = useSelector(state => state?.nafathAuthReducer && state?.nafathAuthReducer?.error?.data?.message);


	useEffect(() => {
		dispatch(nafathCallAPI(NAFATH_INITIAL_STATE));
		getNafathFirstTimeCode();
	}, []);

	const getNafathStatus = (val = false) => {
		let body = {
			nationalId: postFormData.nationalId
		}
		dispatch(nafathPollAPI(NAFATH_POLLING, body, { navigate: navigate, handleFunction: (val === true) ? "" : (response) => handleRecallNafathStatus(response), run: true }));
	};


	const handleRecallNafathStatus = (val = false) => {
		if (val?.data?.data?.code === 1) {
			tahakkuqVerify();
		} else {
			if (val !== true) {
				const id = setTimeout(() => { getNafathStatus(val) }, 3000);
				setTimeoutIds(prevIds => [...prevIds, id]);
			}
		}
	};

	const clearAllTimeouts = () => {
		timeoutIds.forEach(id => clearTimeout(id));
		setTimeoutIds([]);
	};

	const TimeCountUpdate = (val) => {
		setTimeCountValue(val);
		if (val === "00:00") {
			clearAllTimeouts();
			getNafathStatus(true);
		};
	};

	const getNafathFirstTimeCode = () => {
		let bodyValue = {};
		bodyValue.nationalId = postFormData.nationalId;
		bodyValue.service = "PersonalLoan";
		dispatch(nafathAuthCallAPI(AUTH_NAFATH_AUTHENTICATION, bodyValue, { handleFunction: getNafathStatus(false), handleFunctionError: nafathAuthErrorCalllback, navigate: navigate, run: true }));
	};

	const getNafathCode = () => {
		setTimeCountValue("02:30");
		setMinSecondsLeft(150);
		handleCurStep('nafathAuthSucccess');
		let bodyValue = {};
		bodyValue.nationalId = postFormData.nationalId;
		bodyValue.service = "PersonalLoan";
		dispatch(nafathAuthCallAPI(AUTH_NAFATH_AUTHENTICATION, bodyValue, { handleFunction: getNafathStatus(false), handleFunctionError: nafathAuthErrorCalllback, navigate: navigate, run: true }));
	};

	const nafathAuthErrorCalllback = () => {
		handleCurStep('nafathAuthError');
	};

	const handleSubmit = () => {
		const bodyValue = {};
		bodyValue.nationalId = postFormData.nationalId;
		bodyValue.transactionID = nafathAuthData?.Model?.transId;
		bodyValue.randomNumber = nafathAuthData?.Model?.random;
		bodyValue.countryCode = postFormData.countryCode;
		bodyValue.contactNumber = postFormData.contactNumber;
		handleFormData(bodyValue);
		clearAllTimeouts();
		getNafathStatus(true);
		dispatch(nafathCallAPI(NAFATH_VERIFY, bodyValue, { handleStep: handleCurStep, navigate: navigate, step: "nafath-verification", handleFunction: tahakkuqVerify, run: true }));
	};

	const tahakkuqVerify = () => {
		let formValue = {};
		formValue.nationalId = postFormData.nationalId;
		formValue.contactNumber = postFormData.contactNumber;
		formValue.countryCode = postFormData.countryCode;
		formValue.user_role = 'app-user';
		dispatch(tahakukCallAPI(AUTH_TAHAKKUQ_VERIFY, formValue, { handleStep: handleCurStep, navigate: navigate, step: 4, errorStep: "tahakuk-verification" }));
	};

	return (
		<Grid paddingRight={{ xs: '0', md: '40px' }}>
			{!(nafathVerficationData?.Model?.status === 1) ?
				nafathAuthError ?
					<>
						<Grid sx={{ textAlign: "center", justifyContent: 'center' }}>
							<Grid item={12} sx={{ textAlign: "center", justifyContent: 'center' }}>
								<Grid sx={{ marginBottom: "30px" }}>
									<CancelOutlinedIcon sx={{ width: "130px", height: "130px", color: "red" }} />
								</Grid>
								<Text marginTop="0px" size="24" color="lightDark" lineHeight="36" fontFamily='KarbonMedium' TextAlign="center">
									<FormattedMessage id={"Nafath Auth Generate Error"} />
								</Text>
							</Grid>

							<Grid item xs={12} sx={{ textAlign: "center", justifyContent: 'center', marginTop: "50px" }}>
								<Grid container>
									<Grid item xs={12} md={12} sx={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
										<MuiLink size="16" TextDecoration="underline" onClick={() => getNafathCode()}>
											&nbsp;<FormattedMessage id="resendCode" />
										</MuiLink>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
					:
					<WrapperDiv>
						<H1Text color="primary" size={isMobile ? "40" : "64"} lineHeight={isMobile ? "40" : "96"}>
							<FormattedMessage id="register.approveTitle" />
						</H1Text>

						<Text marginTop="0" size="24" color="lightDark" lineHeight="36" fontFamily='KarbonMedium'>
							<FormattedMessage id="register.approveSubText" />
						</Text>

						<Grid container sx={{ marginTop: "30px" }}>
							<Grid item xs={12} md={9}>
								<div style={{ textAlign: "center" }}>
									<img alt="" style={{ margin: '20px' }} src={AuthenticationImage} />
								</div>
							</Grid>

							<Grid item xs={12} md={9}>
								<TimerWithCode authCode={nafathAuthData?.Model?.random} maxSecond={60} minSecondsLeft={minSecondsLeft} timeCountValue={timeCountValue} timerValue={(val) => TimeCountUpdate(val)} minSecondsValue={(val) => setMinSecondsLeft(val)} />
							</Grid>

							<Grid item xs={12} lg={9} sx={{ marginTop: "30px" }}>
								<Grid container>
									{timeCountValue === "00:00" &&
										<>
											<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
												<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
													<FormattedMessage id="Your code has expired" />
												</Text>
											</Grid>

											<Grid item xs={12} md={12} sx={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
												<MuiLink size="16" TextDecoration="underline" onClick={() => getNafathCode()}>
													&nbsp;<FormattedMessage id="resendCode" />
												</MuiLink>
											</Grid>
										</>
									}
								</Grid>
							</Grid>

							{(timeCountValue !== "00:00" && nafathPollApiData?.data?.code !== 1) &&
								<Grid item xs={12} lg={9} sx={{ marginTop: "30px", justifyContent: "center", display: "flex" }}>
									<Text style={{ borderBottom: "2px solid #3C1053" }} size="18" marginTop="0" color="lightDark" fontWeight="600" cursor="pointer" lineHeight="27" fontFamily='KarbonMedium' onClick={() => !nafathLoader && handleSubmit()}>
										<FormattedMessage id="Verify Nafath" />
									</Text>
									{nafathLoader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px', marginLeft: "20px" }} />}
								</Grid>
							}


						</Grid>
					</WrapperDiv>
				:
				<Grid sx={{ textAlign: "center" }}>
					<Grid item={12}>
						{nafathPollApiData?.data?.code === 2 ?
							<CancelOutlinedIcon sx={{ width: "130px", height: "130px", color: "red" }} />
							:
							<CheckCircleOutlineIcon sx={{ width: "130px", height: "130px", color: "#1D8841" }} />
						}
						<H1Text color="primary" size="64" lineHeight="96">
							<FormattedMessage id={nafathPollApiData?.data?.code === 2 ? "Verification Failed" : "Verification Successful!"} />
						</H1Text>

						<Text marginTop="0" size="24" color="lightDark" lineHeight="36" fontFamily='KarbonMedium' TextAlign="center">
							<FormattedMessage id={nafathPollApiData?.data?.code === 2 ? "Wrong code entered" : "Continuing your journey"} />
						</Text>

						{nafathPollApiData?.data?.code === 1 &&
							<div style={{ marginTop: "30px" }}>
								<Text marginTop="0px" size="24" color="sucessColor" lineHeight="36" fontFamily='KarbonMedium' TextAlign="center">
									<FormattedMessage id={"Checking Result from Tahaquq"} />
								</Text>
							</div>
						}
					</Grid>
				</Grid>
			}
		</Grid >
	)
}

export default ApproveRequest;