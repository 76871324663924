import styled from 'styled-components';

const LayoutStyleWrapper = styled.div`
   margin-${(props) => props.locale === 'en' ? 'left' : 'right' } : 312px;
   padding: 20px 2.5%;
   height: 90vh;
   overflow: auto;
   background: ${(props) => props.background ? props.background : "" };
 
   h1 {
      font: Karbon;
      font-weight: 800;
      font-size: 40px;
      line-height: 60px;
      color: #3C1053;
   };

   @media screen and (max-width:1199px) {
      margin-${(props) => props.locale === 'en' ? 'left' : 'right' } : 0;
   }
`

export default LayoutStyleWrapper;