import React, { useEffect, useState, useContext } from "react";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import H5Text from "components/Text/H5Text";
import { LocaleContext } from 'routes/Router';
import { accountCallAPI } from "store/account/action";
import { ACCOUNT_AUDIT_LOG_DATA } from "store/account/constant";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyleWrapper = styled(Box)`
    align-items: center;
    margin:${props => props.margin};
    .application_top{
        display:flex;
        align-items:center;
    };
`

const AccountAuditLogs = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AuditLogData = useSelector((state) => (state.accountReducer && state.accountReducer?.payload?.data));
    const error = useSelector((state) => (state.accountReducer?.error?.data?.message));
    const loader = useSelector((state) => state.accountReducer?.loading);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getAuditLogAPI = () => {
        dispatch(accountCallAPI(ACCOUNT_AUDIT_LOG_DATA, {}, { navigate: navigate }));
    };

    useEffect(() => {
        getAuditLogAPI();
    }, []);

    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                <FormattedMessage id="account.auditLogs" />
            </H5Text>

            {!loader ?
                AuditLogData?.data.length > 0 && AuditLogData?.data?.map((k) => (
                    <Grid container >
                        <Grid item xs={12} sm={10} md={12} lg={10} xl={8}>
                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Text color="lightGrey" size="20" fontWeight="600" marginTop="0" lineHeight="24">
                                        {k?.event}
                                    </Text>
                                </Grid>
                                <Grid item xs={6} textAlign={'right'}>
                                    <Text color="lightDark" size="20" fontWeight="700" TextAlign="right" marginTop="0" lineHeight="24">
                                        {moment(k?.time).format("hh:mm a, DD/MM/YYYY")}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid >
                ))
                :
                Array.from({ length: 8 }).map((data) => (
                    <Grid container >
                        <Grid item xs={12} sm={10} md={12} lg={10} xl={8}>
                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Skeleton animation="wave" height={20} width={'60%'} />
                                </Grid>
                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }} >
                                    <Skeleton animation="wave" height={20} width={'60%'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid >
                ))
            }

        </StyleWrapper >
    );
};

export default AccountAuditLogs;
