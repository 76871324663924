import React, { useState, useContext, useEffect } from "react";
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import MuiCards from "components/MuiCards";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import StepperHorizontalWithTitle from "components/StepperHorizontalWithTitle";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';
import InitialDataForm from "features/RevisedFinancing/InitialDataForm";
import ExpensesBankForm from "features/RevisedFinancing/ExpensesBankForm";
import PersonalWorkForm from "features/RevisedFinancing/PersonalWorkForm";
import AttachApplyForm from "features/RevisedFinancing/AttachApplyForm";
import OrderSummary from "features/RevisedFinancing/OrderSummary";
import RevisedFinancingForm from "features/RevisedFinancing/RevisedFinancingForm";
import CertificateStatement from "features/RevisedFinancing/CertificateStatement";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import FinancingResultsData from "features/RevisedFinancing/FinancingResults";
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";
import ProductDetails from "features/RevisedFinancing/ProductDetails";
import RevisedAccountFetch from "features/RevisedFinancing/AmountFetch";
import { getContractDetailsURL, getDashboardURL, getInvoiceSuccess, getRevisedFinancingURL } from "routes/AppUrls";
import H5Text from "components/Text/H5Text";
import Text from "components/Text";
import CheckboxInputDesignable from "components/Input/CheckboxInputDesignable";
import ButtonInput from "components/Input/ButtonInput";
import { Stack } from "@mui/system";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import SelectInput from "components/Input/SelectInput";
import DateTimePickerInput from "components/Input/DateTimePickerInput";
import { useNavigate, useParams } from "react-router-dom";

export const WrapperBox = styled(Box)`
    min-height:90vh;
    display: "flex";
    background-color: ${(props) => props.theme.lightSecondary}
`;

const InvoiceGeneration = () => {
    const [curStep, setCurStep] = useState(0);
    const [innerCurStep, setInnerCurStep] = useState('PersonalWorkForm');
    const { locale } = useContext(LocaleContext);
    const gosiPension = useSelector((state) => state.basicReducer.financingType);
    const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);
    const ResumeFormData = useSelector((state) => state.ResumeOnlineFormReducer?.data);
    const navigate = useNavigate();
    const vehicleDetailData = useSelector((state) => state.vehicleDetailsReducer && state.vehicleDetailsReducer?.payload?.data);
    const params = useParams();
    const handleCurStep = (value) => {
        setCurStep(value);
    };
    const handleInnerCurStep = (value) => {
        setInnerCurStep(value);
    };

    useEffect(() => {
        if (ResumeFormData?.resumeForm) {
            setCurStep(ResumeFormData?.step);
        }
    }, []);
    console.log(params.postId)

    const steps = [
        <FormattedMessage id="autoFinance.personalDrivingInformation" />,
        <FormattedMessage id="autoFinance.workIncomeDetails" />,
        <FormattedMessage id="autoFinance.expensesBank" />,
        <FormattedMessage id="autoFinance.insuranceRevisedFinancing" />,
    ];

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: getRevisedFinancingURL(locale)
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])

    return (
        <WrapperBox>
            {curStep !== "AccountFetch" ?
                <Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>


                    <Grid container>
                        <Grid item xs={12} md={4} display='flex' alignItems={'end'}>
                            <Box margin='10px 0 12px 0'>
                                <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} xl={3}>
                            <ProductDetails printInvoice={true} style={{ marginTop: "0px" }} bgColor={"white"} locale={locale} data={vehicleDetailData} />
                        </Grid>
                        {
                            params.postId == "Issue_Authorization_Letter" &&
                            <>
                                <Grid item xs={12} lg={8} xl={9}>
                                    <MuiCards borderRadius="16" padding="0">
                                        <CardContent>
                                            <RevisedFinancingForm handleCurStep={handleCurStep} />
                                        </CardContent>
                                    </MuiCards>
                                </Grid>

                            </>
                        }
                        {params.postId !== "Issue_Authorization_Letter" &&
                            <Grid item xs={12} lg={8} xl={9}>
                                <MuiCards borderRadius="16" padding="0">
                                    <CardContent>
                                        <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
                                            {params.postId == "Print_Invoices" ? <FormattedMessage id="Invoice Generation" /> : <FormattedMessage id="Account Statement Generation" />}
                                            <img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
                                        </H5Text>
                                        <Grid padding={'0 12px'}>
                                            {params.postId == "Print_Invoices" &&
                                                <Grid item xs={12} md={6}>
                                                    <SelectInput label={<FormattedMessage id="Request Type" />} />
                                                </Grid>
                                            }
                                            <Text marginTop="20"
                                                color="purple"
                                                fontWeight="800" size="18" fontFamily={locale === "en" ? "KarbonSemiBold" : "LumaSemiBold"} lineHeight="27">
                                                Choose from custom dates
                                            </Text>
                                            <Grid container spacing={2} marginTop="-28px">
                                                <Grid item xs={12} md={3}>
                                                    <DateTimePickerInput
                                                        label={<Text size="18" marginTop="0"><FormattedMessage id="from" /></Text>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <DateTimePickerInput
                                                        label={<Text size="18" marginTop="0"><FormattedMessage id="to" /></Text>}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Text marginTop="16"
                                                color="purple"
                                                fontWeight="800" size="18" fontFamily={locale === "en" ? "KarbonSemiBold" : "LumaSemiBold"} lineHeight="27">
                                                Or select a time period
                                            </Text>
                                            <Grid item xs={12} marginTop="-29px !important">
                                                {/* < CheckboxInputDesignable heading={checkfreeService() && <FormattedMessage id="autoFinance.freeServices" />} label={k?.Description} check={true} /> */}
                                                <CheckboxInputDesignable label="Last 1 Month" hideCheckIcon={true} />
                                                <CheckboxInputDesignable label="Last 2 Month" hideCheckIcon={true} />
                                                <CheckboxInputDesignable label="Last 3 Month" hideCheckIcon={true} />
                                                <CheckboxInputDesignable label="Last 6 Month" hideCheckIcon={true} />
                                                <CheckboxInputDesignable label="Last Financial Year" hideCheckIcon={true} />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ marginTop: "30px" }}>
                                            <Grid item xs={12}>
                                                <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                                    <Box component="form">
                                                        <Stack spacing={2} direction="row">
                                                            <ButtonInput
                                                                title={<FormattedMessage id="back" />}
                                                                variant="text"
                                                                bgColor="transparent"
                                                                textColor="purple"
                                                                borderRadius="100"
                                                                TextTransform="uppercase"
                                                                minWidth="100px"
                                                                marginRight="30"
                                                                padding="0px"
                                                                onClick={() => navigate(-1)}
                                                            />
                                                            <ButtonInput
                                                                title={<>{params.postId == "Print_Invoices" ? <FormattedMessage id="submit" /> : <FormattedMessage id="Generate Statement" />}</>}
                                                                variant="contained"
                                                                bgColor="yellow"
                                                                textColor="purple"
                                                                borderRadius="100"
                                                                TextTransform="capitalize"
                                                                // disabled={updateWorkItemLoader}
                                                                endIcon={false ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
                                                                // onClick={() => { handleCurStep(1); screenScrollTop() }}
                                                                // onClick={() => UpdateWorkItem()}
                                                                onClick={() => navigate(getContractDetailsURL(locale) + '/' + params.postId + '/success')}
                                                            />
                                                        </Stack>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </MuiCards>
                            </Grid>
                        }
                    </Grid>

                    <Grid container style={{ marginTop: "15px" }}>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                </Container>
                :
                <RevisedAccountFetch handleCurStep={handleCurStep} />}
        </WrapperBox>
    );

};

export default InvoiceGeneration;