import React from "react";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {
	const formError = {
        submit: true,
		acknowledge: {
      		error:false,
      		errorMessage: "please accept the acknowledge"
    	},
    	agree: {
      		error:false,
      		errorMessage: "please agree the requesting data form"
    	}
  	};

  	const emptyKeys = [
  		"acknowledge",
  		"agree"
	];
  	
    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }
    
    return formError;
}

export default CheckValidation;