import React from "react";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        idNumber: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        levelEducation: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        maritalTypeStatus: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        license: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        licenseType: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        expiryDate: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        licenseYear: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        relativeGovtPost: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        govtFinanceName: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        govtDesignation: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        relativeManagement: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        relativeName: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        relativeDesignation: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        }
    };

    const emptyKeys = [
        "idNumber",
        "levelEducation",
        "maritalTypeStatus",
        "license",
        "licenseType",
        "expiryDate",
        "licenseYear",
        "relativeGovtPost",
        "relativeManagement"
    ];

    if (value?.relativeGovtPost === "Yes") {
        emptyKeys.push("govtFinanceName");
        emptyKeys.push("govtDesignation");
    };
    if (value?.relativeManagement === "Yes") {
        emptyKeys.push("relativeName");
        emptyKeys.push("relativeDesignation");
    };

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    };

    return formError;
}

export default CheckValidation;