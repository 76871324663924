import Login from 'screens/Pages/Login';
import Register from 'screens/Pages/Register';
import Error404 from 'screens/Pages/404/404';
import ForgotPassword from 'screens/Pages/ForgotPassword';
import ResetPassword from 'screens/Pages/ResetPassword/ResetPassword';
import Dashboard from 'screens/Dashboard';
import Contracts from 'screens/Contracts';
import ContractDetails from 'screens/Contracts/ContractsDetails';
import Application from 'screens/Applications';
import ApplicationDetails from 'screens/Applications/ApplicationDetails';
import RevisedFinancingInitialData from 'screens/Pages/RevisedFinancing/Financing';
import Account from 'screens/Account';
import Support from 'screens/Support';
import FindVehicle from 'screens/Pages/FindVehicle';
import FinanceCalculator from 'screens/Pages/FinanceCalculator/FinanceCalculator';
import VehicleComparison from 'screens/Pages/VehicleComparison';
import RevisedFinancingCongratulations from 'screens/Pages/RevisedFinancing/Congratulations';
import RevisedFinancingConsentForm from 'screens/Pages/RevisedFinancing/ConsentForm';
import RevisedFinancingVerification from 'screens/Pages/RevisedFinancing/Verification';
import RevisedFinancingWaitingPayment from 'screens/Pages/RevisedFinancing/WaitingPayment';
import RevisedFinancingApplicationApproved from 'screens/Pages/RevisedFinancing/ApplicationApproved';
import RevisedFinancingVehicleDelivered from 'screens/Pages/RevisedFinancing/VehicleDelivered';
import CashFinancingInitialData from 'screens/Pages/CashFinancing/Financing';
import CashFinancingCongratulations from 'screens/Pages/CashFinancing/Congratulations';
import CashFinancingConsentForm from 'screens/Pages/CashFinancing/ConsentForm';
import CashFinancingVerification from 'screens/Pages/CashFinancing/Verification';
import CashFinancingWaitingPayment from 'screens/Pages/CashFinancing/WaitingPayment';
import CashFinancingApplicationApproved from 'screens/Pages/CashFinancing/ApplicationApproved';
import CashFinancingVehicleDelivered from 'screens/Pages/CashFinancing/VehicleDelivered';

import { 
    getLandingURL, 
    getSignInURL, 
    getSignUpURL,
    getForgotPasswordURL,
    getResetPasswordURL,
    get404URL,
    consentForm,
    getDashboardURL,
    getContractsURL,
    getContractDetailsURL,
    getApplicationsURL,
    getApplicationDetailsURL,
    getAccountURL,
    getAccountWithIdURL,
    getSupportURL,
    getRevisedFinancingURL,
    getCashFinancingURL,
    getFindVehicleURL,
    getCampaignDetailsURL,
    getFinanceCalculatorURL,
    getVehicleComparisonURL,
    getRevisedFinancingInitialDataURL,
    getApplicationRejected,
    getRevisedFinancingCongratulationsURL,
    getRevisedFinancingConsentFormURL,
    getRevisedFinancingVerificationURL,
    getRevisedFinancingWaitingPaymentURL,
    getRevisedFinancingApplicationApprovedURL,
    getRevisedFinancingVehicleDeliveredURL,
    getCashFinancingDetailsURL,
    getCashFinancingConsentFormURL,
    getCashFinancingInitialDataURL,
    getCashFinancingCongratulationsURL,
    getCashFinanceApplicationRejected,
    getCashFinancingVerificationURL,
    getotpVerification,
    getotpSuccess,
    getInvoiceGeneration,
    getInvoiceSuccess,
    getCashFinancingWaitingPaymentURL,
    getCashFinancingApplicationApprovedURL,
    getAccountFetch,
    getVerificationCall,
    getRequestIneligible,
    getSurveyIneligible,
    getRevisedAccountFetch,
    getRequestCallback
} from './AppUrls';
import RevisedFinancing from 'screens/Pages/RevisedFinancing';
import ApplicationRejected from 'screens/Pages/RevisedFinancing/ApplicationRejected';
import CashFinancing from 'screens/Pages/CashFinancing';
import ApplyForCashFinance from 'screens/Pages/CashFinancing/ApplyForCashFinance';
import CashFinanceApplicationRejected from 'screens/Pages/CashFinancing/ApplicationRejected';
import ConsentForm from 'features/Login/ConsentForm';
import CampaignDetails from 'screens/Pages/CampaignDetails';
import AmountFetch from 'features/CashFinancing/AmountFetch';
import VerificationCall from 'features/CashFinancing/VerificationCall';
import RequestIneligible from 'features/CashFinancing/RequestIneligible';
import SurveyIneligible from 'features/CashFinancing/SurveyIneligible';
import RevisedAccountFetch from 'features/RevisedFinancing/AmountFetch';
import RequestCallback from 'features/RevisedFinancing/RequestCallback';
import Verification from 'features/Account/Verification';
import Success from 'features/Account/Success';
import InvoiceGeneration from 'screens/Contracts/InvoiceGeneration';
import InvoiceSuccess from 'screens/Contracts/InvoiceSuccess';
const publicRoutes = [
    {
        path: locale => getLandingURL(locale),
        element: <Login />,
        isNoSidebar: true

    },
    {
        path: locale => getSignInURL(locale),
        element: <Login />,
        isNoSidebar: true,
    },
    {
        path: locale => getSignUpURL(locale),
        element: <Register />,
        isNoSidebar: true,
    },
    {
        path: locale => get404URL(locale),
        element: <Error404 />,
        isNoSidebar: true,
    },
    {
        path: locale => consentForm(locale),
        element: <ConsentForm />,
        isNoSidebar: true,
    },
    {
        path: locale => getForgotPasswordURL(locale),
        element: <ForgotPassword />,
        isNoSidebar: true,
    },
    {
        path: locale => getResetPasswordURL(locale),
        element: <ResetPassword />,
        isNoSidebar: true,
    }
];

const accountsRoutes = [
    {
        path: locale => getAccountWithIdURL(locale),
        element: <Account />,
        isPrivate: true,
        isSiderBarIcon: true
    }
];

const privateRoutes = [
    {
        path: locale => getDashboardURL(locale),
        element: <Dashboard />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getContractsURL(locale),
        element: <Contracts />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getContractDetailsURL(locale),
        element: <ContractDetails />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getApplicationsURL(locale),
        element: <Application />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getApplicationDetailsURL(locale),
        element: <ApplicationDetails />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getAccountURL(locale),
        element: <Account />,
        isPrivate: true,
        isSiderBarIcon: true,
        children: accountsRoutes
    },
    {
        path: locale => getSupportURL(locale),
        element: <Support />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getRevisedFinancingURL(locale),
        element: <RevisedFinancing />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedFinancingInitialDataURL(locale),
        element: <RevisedFinancingInitialData />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getApplicationRejected(locale),
        element: <ApplicationRejected/>,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedFinancingCongratulationsURL(locale),
        element: <RevisedFinancingCongratulations />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedFinancingConsentFormURL(locale),
        element: <RevisedFinancingConsentForm />,
        isNoSidebar: true,
        isPrivate: true
    },
   
    {
        path: locale => getRevisedFinancingVerificationURL(locale),
        element: <RevisedFinancingVerification />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedFinancingWaitingPaymentURL(locale),
        element: <RevisedFinancingWaitingPayment />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedFinancingApplicationApprovedURL(locale),
        element: <RevisedFinancingApplicationApproved />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedFinancingVehicleDeliveredURL(locale),
        element: <RevisedFinancingVehicleDelivered />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRevisedAccountFetch(locale),
        element: <RevisedAccountFetch />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingURL(locale),
        element: <CashFinancing />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingDetailsURL(locale),
        element: <ApplyForCashFinance />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingConsentFormURL(locale),
        element: <CashFinancingConsentForm />,
        isNoSidebar: true,
        isPrivate: true
    },
   {
        path: locale => getCashFinancingInitialDataURL(locale),
        element: <CashFinancingInitialData />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingCongratulationsURL(locale),
        element: <CashFinancingCongratulations />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinanceApplicationRejected(locale),
        element: <CashFinanceApplicationRejected/>,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingVerificationURL(locale),
        element: <CashFinancingVerification />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getotpVerification(locale),
        element: <Verification />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getotpSuccess(locale),
        element: <Success />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getInvoiceGeneration(locale),
        element: <InvoiceGeneration />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getInvoiceSuccess(locale),
        element: <InvoiceSuccess />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingWaitingPaymentURL(locale),
        element: <CashFinancingWaitingPayment />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingApplicationApprovedURL(locale),
        element: <CashFinancingApplicationApproved />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getAccountFetch(locale),
        element: <AmountFetch />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getVerificationCall(locale),
        element: <VerificationCall />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRequestIneligible(locale),
        element: <RequestIneligible />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getSurveyIneligible(locale),
        element: <SurveyIneligible />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRequestCallback(locale),
        element: <RequestCallback />,
        isNoSidebar: true,
        isPrivate: true
    },
];

const commonRoutes = [
    {
        path: locale => getFindVehicleURL(locale),
        element: <FindVehicle />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => getCampaignDetailsURL(locale),
        element: <CampaignDetails />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => getFinanceCalculatorURL(locale),
        element: <FinanceCalculator />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => getVehicleComparisonURL(locale),
        element: <VehicleComparison />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    }
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];