import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import LayoutStyleWrapper from "styles/common.styles";
import styled from 'styled-components';
import Payments from "screens/Applications/payments";
import Statement from "screens/Contracts/Statement";
import InsuranceDetail from "screens/Contracts/InsuranceDetail";
import ContractInfo from "screens/Contracts/ContractInfo";
import Documents from "screens/Applications/Documents";
import calendarIcon from "assets/images/Contracts/calendar.png";
import letterIcon from "assets/images/Contracts/letter.png";
import timeIcon from "assets/images/Contracts/timeIcon.png";
import rightIcon from "assets/images/Contracts/rightIcon.png";
import Text from 'components/Text';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { LocaleContext } from 'routes/Router';
import Details from "./Details";
import { paymentHistoryCallAPI } from "store/contracts/paymentHistory/action";
import { PAYMENT_HISTORY } from "store/contracts/paymentHistory/constant";
import SideBySideText from "components/SideBySideText";
import { PAYMENT_SCHEDULE } from "store/contracts/paymentSchedule/constant";
import { paymentScheduleCallAPI } from "store/contracts/paymentSchedule/action";
import { DETAILS_LOAN } from "store/contracts/detailsLoan/constant";
import { detailsLoanCallAPI } from "store/contracts/detailsLoan/action";
import { useNavigate } from "react-router-dom";
import LoanDetails from "./LoanDetails";
import LocalStorageManager from "managers/LocalStorageManger";

const StyleWrapper = styled.div`
    align-items: center;
 
    .application_detail_top{
        display:flex;
    };

    .payment_history{
        background:#3F1956;
        height:60px;
        border-radius:8px;
        color:#FFFFFF;
        display:flex;
        justify-content:space-between;
        align-items:center;
        cursor:pointer;
        padding: 8px 24px 8px 24px;
    }
    // .MuiAccordionDetails-root{
    //     padding:16px !important;
    // }
`

const ContractDetails = () => {

    const list = ["home", "Contracts & Services", "Contract Details"];
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const LoanStateData = JSON.parse(LocalStorageManager.getItem("loanData"));
    const paymentHistory = useSelector((state) => state.paymentHistoryReducer?.payload?.data?.['data']?.['S:Envelope']?.['S:Body']?.['ns2:fetchPaymentHistoryResponse']?.['return']?.['paymentList']);
    const paymentHistoryloader = useSelector((state) => state.activeFinanceReducer?.loading);
    const detailLoan = useSelector((state) => state.detailsLoanReducer?.payload?.data?.['data']?.['S:Envelope']?.['S:Body']?.['ns2:fetchLoanDetailResponse']?.['return']);
    const detailLoanloader = useSelector((state) => state.activeFinanceReducer?.loading);

    useEffect(() => {
        paymentHistoryCall();
        paymentScheduleCall();
        detailsLoanCall();
    }, []);

    const paymentHistoryCall = (value) => {
        let body = {
            "loanId": LoanStateData?.loanId
        }
        dispatch(paymentHistoryCallAPI(PAYMENT_HISTORY, body, { navigate: navigate }));
    };

    const paymentScheduleCall = (value) => {
        let body = {
            "loanNo": LoanStateData?.loanNo
        }
        dispatch(paymentScheduleCallAPI(PAYMENT_SCHEDULE, body, { navigate: navigate }));
    };

    const detailsLoanCall = (value) => {
        let body = {
            "loanNo": LoanStateData?.loanNo
        }
        dispatch(detailsLoanCallAPI(DETAILS_LOAN, body, { navigate: navigate }));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    };

    return (
        <LayoutStyleWrapper background={"#FCF8FE"} locale={locale}>
            <StyleWrapper>
                <Grid container className="application_detail_top" spacing={4}>
                    <Grid item lg={7} md={12} xs={12} sm={12}>
                        <div style={{ marginRight: "10px" }}>
                            <Row>
                                {LoanStateData?.loanNo === "GTSLCRIRUH0010000184" ?
                                    <LoanDetails />
                                    :
                                    <Details headerList={list} title={"Contract Details"} e_service={true} paddingLeft='10' data={detailLoan} />
                                }
                            </Row>
                        </div>
                    </Grid>

                    <Grid item lg={5} md={12} xs={12} sm={12} >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ContractInfo data={detailLoan} extraLoanData={LoanStateData} />
                            </Grid>

                            <Grid item xs={12} >
                                <Payments />
                            </Grid>

                            <Grid item xs={12} >
                                <Statement />
                            </Grid>
                            <Grid item xs={12} >
                                <Accordion >
                                    <AccordionSummary className="payment_history"
                                        expandIcon={
                                            <IconButton style={{ color: 'white' }} aria-label="expand">
                                                {/* Add your custom image here */}
                                                <img src={rightIcon} alt="Custom Icon" />
                                            </IconButton>
                                        }
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    // onClick={handleAccordionChange}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={timeIcon} alt="icon" height={"25px"} width={"24px"} style={{ marginRight: locale === 'en' && "16px", marginLeft: locale === 'ar' && "16px" }} />
                                            <Text size="24" marginTop="6" color="lightWhite" lineHeight="36">
                                                <FormattedMessage id="contract.seePaymentHistory" />
                                            </Text>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: "20px !important" }}>
                                        {paymentHistory && paymentHistory?.length > 0 ? paymentHistory?.map((data) => (
                                            <SideBySideText
                                                leftText={`+${data?.instrumentAmount}.00 SAR`.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                                RightText={`${formatDate(data?.instrumentDate)}`}
                                                leftfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                                rightfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                                leftTextColor="green"
                                                rightTextColor="purple"
                                                leftFontWight="800"
                                                size='20'
                                                pBottom="5"
                                            />
                                        ))
                                            :
                                            <SideBySideText
                                                leftText={`+${paymentHistory?.instrumentAmount}.00 SAR`.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                                RightText={`${formatDate(paymentHistory?.instrumentDate)}`}
                                                leftfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                                rightfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                                leftTextColor="green"
                                                rightTextColor="purple"
                                                leftFontWight="800"
                                                size='20'
                                                pBottom="5"
                                            />
                                        }

                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} >
                                <Accordion >
                                    <AccordionSummary className="payment_history"
                                        expandIcon={
                                            <IconButton style={{ color: 'white' }} aria-label="expand">
                                                {/* Add your custom image here */}
                                                <img src={rightIcon} alt="Custom Icon" />
                                            </IconButton>
                                        }
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    // onClick={handleAccordionChange}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={calendarIcon} alt="icon" height={"25px"} width={"24px"} style={{ marginRight: locale === 'en' && "16px", marginLeft: locale === 'ar' && "16px" }} />
                                            <Text size="24" marginTop="6" color="lightWhite" lineHeight="36">
                                                <FormattedMessage id="contract.paymentSchedule" />
                                            </Text>
                                        </div>
                                    </AccordionSummary>
                                    {/* <AccordionDetails style={{padding:"20px !important"}}>
                                        {paymentHistory.map((data)=>(
                                        <SideBySideText
                                            leftText={`+${data.instrumentAmount}.00 SAR`.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                            RightText={`${formatDate(data.instrumentDate)}`}
                                            leftfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                            rightfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                            leftTextColor="green"
                                            rightTextColor="purple"
                                            leftFontWight="800"
                                            size='20' 
                                            pBottom="5"
                                        />
                                        ))}
                                    </AccordionDetails> */}
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} >
                                <Accordion >
                                    <AccordionSummary className="payment_history"
                                        expandIcon={
                                            <IconButton style={{ color: 'white' }} aria-label="expand">
                                                {/* Add your custom image here */}
                                                <img src={rightIcon} alt="Custom Icon" />
                                            </IconButton>
                                        }
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    // onClick={handleAccordionChange}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={letterIcon} alt="icon" height={"25px"} width={"24px"} style={{ marginRight: locale === 'en' && "16px", marginLeft: locale === 'ar' && "16px" }} />
                                            <Text size="24" marginTop="6" color="lightWhite" lineHeight="36">
                                                <FormattedMessage id="contract.authorizationLetter" />
                                            </Text>
                                        </div>
                                    </AccordionSummary>
                                    {/* <AccordionDetails style={{padding:"20px !important"}}>
                                        {paymentHistory.map((data)=>(
                                        <SideBySideText
                                            leftText={`+${data.instrumentAmount}.00 SAR`.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                            RightText={`${formatDate(data.instrumentDate)}`}
                                            leftfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                            rightfontFamily={locale=="en"?'Karbon_light':"Luma_light"}
                                            leftTextColor="green"
                                            rightTextColor="purple"
                                            leftFontWight="800"
                                            size='20' 
                                            pBottom="5"
                                        />
                                        ))}
                                    </AccordionDetails> */}
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} >
                                <InsuranceDetail data={detailLoan?.insuranceDetail} />
                            </Grid>

                            <Grid item xs={12} >
                                <Documents />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyleWrapper>
        </LayoutStyleWrapper>
    );
};

export default ContractDetails;