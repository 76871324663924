import { takeLatest, put } from "redux-saga/effects";
import {
	UPDATE_WORK_ITEM,
	UPDATE_WORK_ITEM_SUCCESS,
	UPDATE_WORK_ITEM_ERROR
} from "./constant";
import url from "utils/services/urls.json";
import { postMethodWithToken } from "utils/services/apis";
import { ClearTokenRedirectLogin } from "utils/general";
import { unsetClient } from "store/token/action";

function* updateWorkItem(action) {
	try {
		const response = yield postMethodWithToken(url.updateWorkItem + "?wid=" + action?.external?.workItemAppId, action.data);
		if (response.status === 200 || response.status === 201) {
			yield put({ type: UPDATE_WORK_ITEM_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: UPDATE_WORK_ITEM_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: UPDATE_WORK_ITEM_ERROR, payload: error });
	}
};

function* updateWorkItemSaga() {
	yield takeLatest(UPDATE_WORK_ITEM, updateWorkItem);
};

export default updateWorkItemSaga;
