import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useContext } from 'react';
import { LocaleContext } from 'routes/Router';

const WrapperAutocompleteField = styled(Autocomplete)`
	margin-top: 20px;
	border-radius: ${(props) => props.borderRadius + "px"} !important;
	font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)}; !important;
	font-size: ${(props) => props.fontSize + "px"} !important;
	color: ${(props) => props.theme[props.textColor]} !important;
	height: 56px;

	${(props) =>
		props.bgColor && `
    		background-color:  ${props.theme[props.bgColor]};
 	`}

 	.MuiFormControl-root {
 		.MuiFormLabel-root {
 			opacity: 0 !important;
 		}
 		.MuiButtonBase-root {
 			margin-right: 5px;

 			.MuiSvgIcon-root {
				border: 1px solid ${(props) => props.theme.lightDark};
				border-width: 0 1px 1px 0;
				display: inline-block;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				padding: 3px;
				font-size: 16px;
				color: transparent;
				width: 10px;
				height: 10px;
			}
 		}

 		.MuiChip-root.MuiChip-filled {
			opacity: 0;
			margin: 0;
			height: 0;
			max-width: 0;
		}

		.MuiInputBase-input {
			min-width: 60px !important;
		}

		.MuiAutocomplete-endAdornment  {
			.MuiAutocomplete-clearIndicator {
				display: none;
			}
			.MuiAutocomplete-popupIndicatorOpen {
				margin-top: 5px;
			}
		}

 		.MuiInputBase-root {
 			.MuiOutlinedInput-notchedOutline {
 				border: 1px solid ${(props) => props.theme.lightDarkSecondary};

		 		${(props) =>
		props.borderNone && `
			 	    	border: none;
			 	`}
 			}
 		}
 	}
`;

function MultiSelectAutoInput({ ...props }) {
	const { locale } = useContext(LocaleContext);
	return (
		<WrapperAutocompleteField
		locale={locale}
			{...props}
			multiple
			id="checkboxes-tags"
			disableCloseOnSelect
			renderInput={(params) => (
				<TextField {...params}
					label={props.label}
					placeholder={props.label}
					InputLabelProps={{
						shrink: true
					}}
				/>
			)}
		/>
	);
};

MultiSelectAutoInput.defaultProps = {
	borderRadius: 4,
	textColor: "lightGray"
};

export default MultiSelectAutoInput;