import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCashFinancingDetailsURL } from 'routes/AppUrls';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import YesNoInput from "components/Input/YesNoInput";
import ButtonInput from "components/Input/ButtonInput";
import DateTimePickerInput from "components/Input/DateTimePickerInput";
import SelectAmongs from "components/SelectAmongs";
import { LocaleContext } from "routes/Router";
import { financingDropDownCallAPI } from "store/financingDropDown/action";
import { FINANCING_DROPDOWN } from "store/financingDropDown/constant";

const WrapperDiv = styled.div`
	margin-bottom: 30px;
`;

function InitialDataForm({ handleCurStep }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);
	const financingTransaction = useSelector((state) => (state.financingDropDownReducer && state.financingDropDownReducer));
	useEffect(() => {
		getCarCategory();
	}, []);

	const getCarCategory = () => {
		dispatch(financingDropDownCallAPI(FINANCING_DROPDOWN, {}, { navigate: navigate }));
	};

	const maritalStatus = [
		{
			id: 1,
			type: <FormattedMessage id="married" />
		},
		{
			id: 2,
			type: <FormattedMessage id="unmarried" />
		},
	]
	const purposeofLoan = [
		{
			id: 1,
			type: <FormattedMessage id="personal" />
		},
		{
			id: 2,
			type: <FormattedMessage id="familyFriends" />
		},
	]

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<SelectAmongs data={purposeofLoan} label={<FormattedMessage id="autoFinance.purposeofLoan" />} />
						</Grid>
						<Grid item xs={12} md={6}>
							<SelectInput label={<FormattedMessage id="autoFinance.usedfor" />} placeholder="Used for" marginTop='8' />
						</Grid>
					</Grid>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'24'}>
						<FormattedMessage id={'autoFinance.personalInformation'} />
						<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>
					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="IDNumber" />}
									placeholder="ID Number"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DateTimePickerInput label={<FormattedMessage id="dateofBirth" />} />
							</Grid>
							<Grid item xs={12} display='flex' justifyContent='end'>
								<ButtonInput
									title={<>YAKEEN <FormattedMessage id={"revisedFinance.search"} /></>}
									variant="contained"
									bgColor="purple"
									textColor="white"
									borderRadius="100"
									TextTransform="capitalize"
									borderColor="purple"
									width='189px'
									height='54'
									fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}
									padding='0'
									size='16'
								// onClick={() => handleSubmit(props?.actionText)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="name.text" />}
									placeholder="Name"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DateTimePickerInput label={<FormattedMessage id="revisedFinance.iDExpirationDate" />} />
							</Grid>
							{/* <Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.numberofDependents" />}
									placeholder="Contact Number"
								/>
							</Grid> */}
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="Contact Number" />}
									placeholder="Contact Number"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="cashFinance.levelofEducationType" />} placeholder="Level of Education Type" />
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectAmongs data={maritalStatus} label={<FormattedMessage id="cashFinance.maritalStatus" />} />
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Grid container sx={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text size='24' lineHeight='36' borderRadius="8" padding="8px 16px" color="lightDark">
						<FormattedMessage id="revisedFinance.address" />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="revisedFinance.buildingNumber" />}
									placeholder="Building Number"
								/>
							</Grid><Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="revisedFinance.street" />}
									placeholder="Street"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="revisedFinance.district" />} placeholder="District" />
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="revisedFinance.city" />} placeholder="City" />
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="revisedFinance.additionalNumber" />}
									placeholder="Additional Number"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="revisedFinance.postalCode" />}
									placeholder="Postal Code"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="revisedFinance.unitNumber" />}
									placeholder="Unit Number"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="autoFinance.residenceType" />} placeholder="Residence Type" />
							</Grid>
							{/* <Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="autoFinance.houseType" />} placeholder="House Type" />
							</Grid> */}
						</Grid>

					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="autoFinance.relativesInformation" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ marginTop: "15px", padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<YesNoInput
									label={<FormattedMessage id="autoFinance.doHaveRelationGovernmentPost" />}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.fullName" />}
									placeholder="Full Name"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.designation" />}
									placeholder="Designation"
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<YesNoInput
									label={<FormattedMessage id="autoFinance.guestRelationManagement" />}
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
	
			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => navigate(getCashFinancingDetailsURL(locale))}
								/>
								<ButtonInput
									title={<FormattedMessage id="cashFinance.SaveProceedFinanceInfo" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => handleCurStep(1)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default InitialDataForm;