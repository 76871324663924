import React, { useState, useContext } from "react";
import { Row } from "react-bootstrap";
import LayoutStyleWrapper from "styles/common.styles";
import styled from 'styled-components';
import DeliveryStatus from "screens/Applications/DeliveryStatus";
import Documents from "screens/Applications/Documents";
import Payments from "screens/Applications/payments";
import Details from "screens/Applications/Details";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import ButtonInput from "components/Input/ButtonInput";
import DetailRequireModal from "components/Modals/DetailRequireModal";
import MuiSnackbar from "components/MuiSnackbar";
import H5Text from "components/Text/H5Text";
import { FormattedMessage } from "react-intl";

import { LocaleContext } from 'routes/Router';
import LoanDetails from "./LoanDetails";
import { useLocation } from "react-router-dom";

const StyleWrapper = styled.div`
    align-items: center;
 
    .application_detail_top{
        margin-top:0;
    };
`

const ApplicationDetails = () => {
    const list = ["home", "Contracts & Services", "Application Details"];
    const { locale } = useContext(LocaleContext);
    const location = useLocation();
    const [isModalActive, setIsModalActive] = useState(false);

    const handleModalPopup = (value) => {
        setIsModalActive(value);
    }
    return (
        <LayoutStyleWrapper background={"#FCF8FE"} locale={locale}>
            <StyleWrapper>
                <MuiSnackbar
                    message={
                        <>
                            <Grid container>

                                <Grid item xs={12} xl={8} display='flex' alignItems={'center'}>
                                    <H5Text marginTop={0} fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} size='24'>
                                        <FormattedMessage id="Remind the customer to pay the application money of 54,000.00 SAR" />
                                    </H5Text>
                                </Grid>
                                <Grid item xs={12} xl={4} textAlign={{ xs: 'start', xl: 'end' }}>
                                    <ButtonInput
                                        title="Remind Customer"
                                        variant="contained"
                                        bgColor="white"
                                        textColor="purple"
                                        borderRadius="100"
                                        borderColor='purple'
                                        TextTransform="capitalize"
                                    // onClick={() => handleModalPopup(false)}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
                <Grid container className="application_detail_top" spacing={5}>
                    <Grid item lg={7} md={12} xs={12} sm={12}>
                        {location?.state?.loanData?.loanNo === "" ?
                            <LoanDetails />
                            :
                            <Details headerList={list} title={"Application Details"} locale={locale} />
                        }
                    </Grid>

                    <Grid item lg={5} md={12} xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Payments />
                            </Grid>

                            <Grid item xs={12} >
                                <DeliveryStatus />
                            </Grid>

                            <Grid item xs={12} >
                                <Documents />
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonInput
                                    width="100%"
                                    title="Cancel Application"
                                    variant="contained"
                                    bgColor="transparent"
                                    textColor="lightRed"
                                    borderRadius="8"
                                    borderColor='lightRed'
                                    TextTransform="capitalize"
                                    fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}
                                    size="24"
                                    onClick={() => setIsModalActive(true)}
                                // onClick={() => handleModalPopup(false)}
                                />
                            </Grid>
                            <DetailRequireModal modalActive={isModalActive} handleModalPopup={handleModalPopup} />
                        </Grid>
                    </Grid>
                </Grid>
            </StyleWrapper>
        </LayoutStyleWrapper>
    );
};

export default ApplicationDetails;