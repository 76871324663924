import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LeftBarImage from "components/LeftBarImage";
import Register from "features/Register/Register";
import TermsCondition from "features/Register/TermsCondition";
import Verification from "features/Register/Verification";
import ApproveRequest from "features/Register/ApproveRequest";
import PasswordVerify from "features/Register/PasswordVerify";
import Successfull from "features/Register/Successfull";
import layoutImage from "assets/images/LayoutImage/layoutImage.png";
import layoutImageSuccess from "assets/images/LayoutImage/Sucess.png";
import layoutImageError from "assets/images/LayoutImage/Error.png";
import LabelImage from "assets/images/icon/encrypted.png";
import Text from "components/Text";
import StepperFlow from "components/StepperFlow/StepperFlow";
import { LocaleContext } from 'routes/Router';
import TahakukMobileVerification from "features/Register/TahakukMobileVerification";

export const BottomText = styled.div`
position: fixed;
bottom: 30px;
display: flex;

@media screen and (max-width: 900px) {
    position: unset;
    bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}
@media screen and (max-height: 800px) {
    position: unset;
    bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}
@media screen and (min-height: 900px) {
    position:absolute;
    bottom:30px;
    margin-top: 15px;
    margin-bottom: 15px;
 }
`;

export const StepperContainer = styled.div`
    display: flex;
    max-width: 535px;
    align-items: baseline;
`;

export const StepStyle = styled.div`
    font-size: 32px;
    color: ${(props) => (props.theme.lightDark)};
    line-height: 48px;

    @media screen and (max-width: 700px) {
        font-size: 28px;
        line-height: 36px;
    }
`

const SignUp = () => {
    const location = useLocation();
    const [curStep, setCurStep] = useState(1);
    const [isTermsCondition, setIsTermsCondition] = useState(false);
    const [isSuccessFull, setIsSuccessFull] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isNafathVerification, setIsNafathVerification] = useState(false);
    const [formValue, setFormValue] = useState('');
    const { locale } = useContext(LocaleContext);

    const handleCurStep = (value) => {
        if (value === 'terms') {
            setIsTermsCondition(true);
        } else if (value === 'success') {
            setIsSuccessFull(true);
        } else if (value === 'nafathAuthError') {
            setIsError(true);
        } else if (value === 'nafathAuthSucccess') {
            setIsError(false);
        }
        else if (value === 'nafath-verification') {
            setIsNafathVerification(true);
        }
        else {
            setCurStep(value);
            setIsTermsCondition(false);
        }
    };

    const handleFormData = (value) => {
        setFormValue(value);
    };

    return (
        <Box>
            <Grid container spacing={{ md: 10 }} >
                {!isTermsCondition &&
                    <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                        <LeftBarImage bgImage={isSuccessFull ? layoutImageSuccess : isError ? layoutImageError : layoutImage} width="100%" bgColor="lightPurple" />
                    </Grid>
                }
                <Grid item xs={12} md={isTermsCondition ? 12 : 7} marginTop={{ xs: '3%', md: "0px" }} padding={{ xs: !isTermsCondition && '10px', md: "0px" }} sx={{ display: "flex", alignItems: "center" }} paddingRight={{ xs: isTermsCondition && '0', md: !isTermsCondition && '40px' }}>
                    <Grid container padding={{ xs: !isTermsCondition && "0 15px", md: "0px" }} >
                        {!isTermsCondition && !isSuccessFull && !isNafathVerification && !isError &&
                            <Grid item xs={12}>
                                {location.state !== "success" &&
                                    <StepperContainer>
                                        <StepStyle>
                                            <FormattedMessage id="step" /> {curStep} <FormattedMessage id="of" /> 4
                                        </StepStyle>

                                        <StepperFlow step={curStep} locale={locale} />
                                    </StepperContainer>
                                }
                            </Grid>
                        }

                        <Grid item xs={12}>

                            {isSuccessFull ? (
                                <Successfull handleCurStep={handleCurStep} />
                            )
                                :
                                curStep === "tahakuk-verification" ? (
                                    <TahakukMobileVerification handleCurStep={handleCurStep} postFormData={formValue} />
                                )
                                    : (
                                        <>
                                            {curStep === 2 ? (
                                                <Verification handleCurStep={handleCurStep} postFormData={formValue} />
                                            ) : curStep === 3 ? (
                                                <ApproveRequest handleCurStep={handleCurStep} postFormData={formValue} handleFormData={handleFormData} />
                                            ) : curStep === 4 ? (
                                                <PasswordVerify handleCurStep={handleCurStep} />
                                            ) : (
                                                <>
                                                    {isTermsCondition ? (
                                                        <TermsCondition handleCurStep={handleCurStep} postFormData={formValue} />
                                                    ) : (
                                                        <Register handleCurStep={handleCurStep} handleFormData={handleFormData} />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                        </Grid>

                        {!isTermsCondition &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <BottomText>
                                    <img width="24" height="24" style={{ marginRight: '8px', marginLeft: locale === 'ar' ? '8px' : '0' }} src={LabelImage} alt="" />

                                    <Text marginTop="3" size="14" color="footerGrayText" lineHeight="16" fontWeight="700">
                                        <FormattedMessage id="footerText" />
                                    </Text>
                                </BottomText>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SignUp;