import React from "react";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        salaryCertificate: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        bankStatement: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        }
    };

    const emptyKeys = [
        "salaryCertificate",
        "bankStatement"
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }

    return formError;
}

export default CheckValidation;