import {
   NAFATH_POLLING,
   NAFATH_POLLING_ERROR,
   NAFATH_POLLING_SUCCESS
} from "./constant";

const nafathPollReducer = (state = {}, action) => {
   switch (action.type) {
      case NAFATH_POLLING:
         return { ...state, loading: true, error: '' };
      case NAFATH_POLLING_ERROR:
         return { ...state, type: NAFATH_POLLING_ERROR, loading: false, error: action.payload };
      case NAFATH_POLLING_SUCCESS:
         return { ...state, type: NAFATH_POLLING_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default nafathPollReducer;