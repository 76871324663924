import {
    FINANCE_GET_QUOTE,
    FINANCE_GET_QUOTE_ERROR,
    FINANCE_GET_QUOTE_SUCCESS
} from "./constant";


const financingGetQuoteReducer = (state = {}, action) => {
    switch (action.type) {
        case FINANCE_GET_QUOTE:
            return { ...state, loading: true, error: '' };
        case FINANCE_GET_QUOTE_ERROR:
            return { ...state, type: FINANCE_GET_QUOTE_ERROR, loading: false, error: action.payload };
        case FINANCE_GET_QUOTE_SUCCESS:
            return { ...state, type: FINANCE_GET_QUOTE_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default financingGetQuoteReducer;