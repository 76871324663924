import MaskInput from 'components/Input/MaskInput'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Grid, InputAdornment, Stack } from '@mui/material';
import ButtonInput from 'components/Input/ButtonInput';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from 'components/Text/H5Text';
import { getAccountFetch, getCashFinancingCongratulationsURL, getCashFinancingConsentFormURL, getCashFinancingInitialDataURL, getCashFinancingURL } from 'routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import Text from 'components/Text';
import NotePencilIcon from "assets/images/icon/NotePencil.png";
import CommonModal from 'components/Modals/CommonModal';
import LabelWithInput from 'components/Input/LabelWithInput';
import SelectInput from 'components/Input/SelectInput';
import { LocaleContext } from 'routes/Router';
import OneWaySlider from 'components/RangeSlider/OneWaySlider';
import SideBySideText from 'components/SideBySideText';
import { Superscript } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const AllowedAmount = ({ handleCurStep }) => {
	const navigate = useNavigate();
	const [isModalActive, setIsModalActive] = useState(false);
	const { locale } = useContext(LocaleContext);
	const [monthlyAmount, setMonthlyAmount] = useState(4000);
	const [tenure, setTenure] = useState(6);
	const [promoCode, setPromoCode] = useState('');
	const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);
	const gosiPension = useSelector((state) => state).basicReducer.financingType;
	const handleModalPopup = (value) => {
		setIsModalActive(value);
	}

	return (
		<>
			<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
				<Grid container display='flex' alignItems='center'>
					<Grid item xs={12} md={6}>
						<FormattedMessage id="cashFinance.maximumAllowedAmount" />: 20,000.00 SAR

					</Grid>
					{/* <Grid item xs={12} md={6} display='flex' justifyContent={{xs:'start',md:'end'}}>
						<ButtonInput
							title={<FormattedMessage id="cashFinance.recalculateLoanAmount" />}
							variant="contained"
							bgColor="purple"
							textColor="white"
							minWidth="201"
							borderRadius="100"
							TextTransform="capitalize"
							padding="0px 15px"
							height='56'
							startIcon={<img src={require('assets/images/solar_calculator-linear.png')} style={{ padding: '0 10px' }} />}
						/>
					</Grid> */}
				</Grid>
			</H5Text>
			<Grid item xs={12} padding='0 16px'>
				<Box display='flex' marginTop='30px' alignItems={{ xs: 'baseline', md: 'center' }}>
					<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
						<FormattedMessage id="cashFinance.netIncome" /> {gosiPension=="gosi"?<FormattedMessage id="gosi" />:gosiPension=="pension"?<FormattedMessage id="pension" />:""} : 20,000.00 <FormattedMessage id="sar" />
					</Text>
					{!userMeData.data.is_saudi&&<div style={{ marginLeft: "15px", cursor: "pointer" }}>
						<img src={NotePencilIcon} onClick={() => setIsModalActive(true)} />
					</div>}
				</Box>
				<Grid container>
					<Grid item xs={12} lg={10} >
						<Grid container>
							<Grid item xs={12} lg={6} display={"flex"}>
								<H5Text size={"20"} fontFamily={locale=="en"?'Karbon_light':"Luma_light"}>
									<FormattedMessage id="cashFinance.monthlyObligations" />:
								</H5Text>
								<H5Text size={"20"} fontFamily="Karbon_medium">
									&nbsp;2,000 <sup>SAR</sup>
								</H5Text>
							</Grid>
							<Grid item xs={12} lg={6} display={"flex"}>
							<H5Text size={"20"} fontFamily={locale=="en"?'Karbon_light':"Luma_light"}>
							<FormattedMessage id="cashFinance.totalLimit" />:
								</H5Text>
								<H5Text size={"20"} fontFamily="Karbon_medium">
									&nbsp;2,000 <sup>SAR</sup>
								</H5Text>
							</Grid>
						</Grid>
						{/* <H5Text size="20" lineHeight={'21.23'} color="lightGrey" fontFamily={locale=="en"?'Karbon_light':"Luma_light"}> <FormattedMessage id="Monthly Obligations:" /></H5Text> */}
					</Grid>
				</Grid>
				<Grid container spacing={4} marginTop="9px">

					<Grid item xs={12} lg={6} >
						<Grid item xs={12} md={10} >
							<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={20000} label={<FormattedMessage id="cashFinance.cashNeeded" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
						</Grid>
					</Grid>
					<Grid item xs={12} lg={6} >
						<Grid item xs={12} md={10} >
							<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={1} endLimit={60} label={<FormattedMessage id="tenure" />} defaultValue={tenure} value={tenure} setValue={(data) => setTenure(data)} />
						</Grid>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Grid item xs={12} md={10} >
							<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={20000} label={<FormattedMessage id="cashFinance.monthlyInstallment" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<SelectInput label={<FormattedMessage id="autoFinance.houseType" />} placeholder="House Type" />
					</Grid>
					<Grid item xs={12} md={6} >
						<LabelWithInput
							label={<FormattedMessage id="autoFinance.numberofDependents" />}
							placeholder="Number of Dependents"
						/>
					</Grid>
					<Grid item xs={12} lg={6} >
						<div className='labelInput'>
							<LabelWithInput
								label={
									<span className='labelDesign'>
										<span><FormattedMessage id="promoCode" /></span>
									</span>
								}
								InputProps={{
									endAdornment: (
										<span className='ApplyButton'><FormattedMessage id="apply" /></span>
									)
								}}
								name="promoCode"
								value={promoCode}
							// onChange={(e) => handleChange(e)}
							/>
						</div>
					</Grid>
				</Grid>

				{/* <ExpensesModal modalActive={isModalActive} handleModalPopup={handleModalPopup} /> */}
				<CommonModal padding={'32px 48px'} width={'1260px'} modalActive={isModalActive} innerContent={
					<Box >
						<div className="header">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Text size="40" lineHeight={'60'} marginTop="0" color="lightDark" fontWeight="800" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
										<FormattedMessage id="revisedFinance.incomeDetails" />
									</Text>
								</Grid>
							</Grid>
						</div>
						<div className="content">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<SelectInput
										label={<FormattedMessage id="revisedFinance.incomeCertificateType" />}
										placeholder="Current Monthly Obligation Source"
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<LabelWithInput
										label={<FormattedMessage id="revisedFinance.monthlyIncome" />}
										placeholder="0.00"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">SAR</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<SelectInput
										label={<FormattedMessage id="cashFinance.deductionType" />}
										placeholder=""
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<LabelWithInput
										label={<FormattedMessage id="revisedFinance.contributaryWage" />}
										placeholder="0.00"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">SAR</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<LabelWithInput
										label={<FormattedMessage id="cashFinance.GOSIAmount" />}
										placeholder="0.00"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">SAR</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<LabelWithInput
										label={<FormattedMessage id="revisedFinance.netMonthlyIncome" />}
										placeholder="0.00"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">SAR</InputAdornment>
											)
										}}
									/>
								</Grid>
							</Grid>
						</div>
						<div className="footer" style={{ marginTop: "64px" }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="content" style={{ display: "flex", justifyContent: "end" }}>
										<Box component="form">
											<Stack spacing={2} direction="row">
												<ButtonInput
													title={<FormattedMessage id="cencel" />}
													variant="text"
													bgColor="transparent"
													textColor="purple"
													borderRadius="100"
													TextTransform="uppercase"
													onClick={() => handleModalPopup(false)}
												/>
												<ButtonInput
													title={<FormattedMessage id="save" />}
													variant="contained"
													bgColor="yellow"
													textColor="purple"
													borderRadius="100"
													TextTransform="capitalize"
													onClick={() => handleModalPopup(false)}
												/>
											</Stack>
										</Box>
									</div>
								</Grid>
							</Grid>
						</div>
					</Box>
				} />
			</Grid>
			<Grid container sx={{ marginTop: "79px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="cancel" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									onClick={() => navigate(getCashFinancingURL(locale))}
								/>
								<ButtonInput
									title={<FormattedMessage id="proceed" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									// onClick={() => navigate(handleCurStep("amountFetch"))}
									onClick={() => navigate(getAccountFetch(locale))}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default AllowedAmount
