import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridViewIcon from '@mui/icons-material/GridView';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckIcon from '@mui/icons-material/Check';
import MuiBreadcrumbs from 'components/MuiBreadcrumbs';
import H1Text from 'components/Text/H1Text';
import Text from 'components/Text';
import MuiCards from 'components/MuiCards';
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckboxInput from "components/Input/CheckboxInput";
import MultiSelectInput from "components/Input/MultiSelectInput";
import MultiSelectAutoInput from "components/Input/MultiSelectAutoInput";
import SelectInput from "components/Input/SelectInput";
import MuiRangeSlider from 'components/MuiRangeSlider';
import { LocaleContext } from 'routes/Router';
import CardList from 'features/Vehicle/CardList';
import {
	GET_VEHICAL_LIST,
	GET_VEHICAL_CATEGORY,
	SCREEN_API_ERROR,
	SCREEN_API_SUCCESS,
	GET_VEHICAL_CATEGORY_SUCCESS
} from 'store/screen/constant';
import { screenCallAPI } from 'store/screen/action';
import { Box, Skeleton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SwitchWithLabel from "components/Input/SwitchWithLabelInput";
import H5Text from 'components/Text/H5Text';
import { usedCarsCallAPI } from 'store/screen/usedCar/action';
import { USED_CARS } from 'store/screen/usedCar/constant';
import { getUsedCarsURL } from 'routes/AppUrls';
import { useLocation, useNavigate } from 'react-router-dom';
import UsedCarsList from 'features/Vehicle/UsedCarsList';
import { campaignDetailsCallAPI } from 'store/screen/campaignDetails/action';
import { CAMPAIGN_DETAILS } from 'store/screen/campaignDetails/constant';
import CampaignCarsList from 'features/Vehicle/CampaignCarsList';
import campaignSearchReducer from 'store/screen/campaignDetails/campaignSearch/reducer';
import { CAMPAIGN_SEARCH } from 'store/screen/campaignDetails/campaignSearch/constant';
import { campaignSearchCallAPI } from 'store/screen/campaignDetails/campaignSearch/action';

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" sx={{ color: "#8c5ba5" }} />;

const WrapperMenuItemField = styled(MenuItem)`
	padding: 10px 15px !important;
	&.Mui-selected {
		background-color: ${(props) => props.theme.selectMenuItemBg} !important;
	}
	&:hover {
		background-color: ${(props) => props.theme.selectMenuItemBg} !important;
	}
`;

const WrapperTypeItemField = styled(MenuItem)`
	display: block !important;
	padding: 10px 15px !important;
	&.Mui-selected {
		background-color: ${(props) => props.theme.transparent} !important;
	}
	&:hover {
		background-color: ${(props) => props.theme.transparent} !important;
	}
`;

const WrapperTypeDiv = styled.div`
	background-color: ${(props) => props?.select ? '#3F1956' : '#fff'} !important;
    border-radius: 8px;
    border: 1px solid #8F8D95;
    width: 78px;
    height: 58px;
    align-items: center;
    justify-content: center;
    display: flex;
    color:${(props) => props?.select ? '#fff' : ''} !important;
    &:hover {
    	background-color: #3F1956;
    }
`;

const FilterValueWrap = styled.div`
	cursor: pointer;
	font-size: 12px;
	background-color: #e0e0e0;
	box-shadow: 0 2px 4px 0 hsla(0,0%,100%,.5);
	border-radius: 3px;
	margin: 2px 4px;
	overflow: hidden;
	transition: background-color .1s;
	max-width: 200px;
	padding: 8px;
	max-height:40px;
	display:flex;
	align-items:center;
`;

const ClearFilterValueWrap = styled.div`
	cursor: pointer;
	font-size: 12px;
	background-color: #FECB26;
	box-shadow: 0 2px 4px 0 hsla(0,0%,100%,.5);
	border-radius: 3px;
	margin: 2px 4px;
	overflow: hidden;
	transition: background-color .1s;
	max-width: 200px;
	padding: 8px;
	max-height:40px;
	display:flex;
	align-items:center;
`;

const WrapperTypeListItemText = styled(ListItemText)`
	.MuiTypography-root {
		font-size: 18px;
		line-height: 27px;
		font-family: ${(props) => props.locale=="en" ? 'Karbon_light' : 'Luma_light'};
		color: #3D3D4E;
		margin-top: 5px !important;
		font-weight: 600;
		text-align: center;
	}
`;

const WrapperLiField = styled.li`
	&:hover {
		background-color: #F4F1F6 !important;
	}
	&:focus {
		background-color: #F4F1F6 !important;
	}
	&.Mui-focused {
		background-color: #F4F1F6 !important;
	}
`;

const WrapperColorField = styled.span`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: ${(props) => props.colorCode};
	margin-right: 10px;
`;

const CampaignDetails = () => {
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);
	const [page, setPage] = useState(1);
	const [next, setNext] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState([]);
	const [isGridView, setIsGridView] = useState(true);
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="home" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="individuals" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="autoFinancing" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="newVehiclesFinancialLeasing" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="vehicles" />,
			Link: "",
			isDisbale: true,
		}
	]);
	const [vehicalListData, setVehicalListData] = useState([]);
	const navigate = useNavigate();
	const [vehicalCount, setVehicalCount] = useState(0);
	const [isSearchApply, setIsSearchApply] = useState(false);
	const [vehicalBrandList, setVehicalBrandList] = useState([]);
	const [vehicalYearList, setVehicalYearList] = useState([]);
	const [vehicalTypeList, setVehicalTypeList] = useState([]);
	const [vehicalColorList, setVehicalColorList] = useState([]);
	const location = useLocation();
	const { campaignCode } = location.state;
	const [vehicalFilterValue, setVehicalFilterValue] = useState({
		brand: [],
		year: [],
		vihical_type: "",
		colour: [],
		search: "",
		min_range: 0,
		max_range: 10000,
		offers: false,
		available_for_fast_financing: false
	});
	const [campaignSearchValue, setCampaignSearchValue] = useState("");
	const getVehicleList = useSelector((state) => (state.screenReducer && state.screenReducer));
	const campaignDetailsData = useSelector((state) => state.campaignDetailsReducer?.payload?.data);
	const campaignDetailsDataloader = useSelector((state) => state.campaignDetailsReducer?.loading);
	// const Error = useSelector((state) => (state.screenReducer?.error?.data?.message));
	// const loader = useSelector((state) => state.screenReducer?.loading);

	useEffect(() => {
		if (getVehicleList && getVehicleList.type === SCREEN_API_SUCCESS && getVehicleList.payload.data && getVehicleList.payload.data.results) {
			let data = getVehicleList.payload.data.results;
			if (isSearchApply) {
				setVehicalListData(data);
			} else {
				setVehicalListData([...vehicalListData, ...data]);
			}
			setVehicalCount(getVehicleList.payload.data.count);
			setLoading(false);
			setIsSearchApply(false);
			if (getVehicleList.payload.data.next) {
				setNext(true);
			} else {
				setNext(false);
			}
		} else if (getVehicleList && getVehicleList.type === GET_VEHICAL_CATEGORY_SUCCESS && getVehicleList.payload.data && getVehicleList.payload.data.data) {
			setVehicalBrandList(getVehicleList.payload.data.data.Brand);
			setVehicalYearList(getVehicleList.payload.data.data.Year);
			setVehicalTypeList(getVehicleList.payload.data.data.Type);
			setVehicalColorList(getVehicleList.payload.data.data.Color);
		}
	}, [getVehicleList]);

	const getVehicleListHandler = (page) => {
		setPage(page);
		setLoading(true);
		if (vehicalFilterValue.colour.length > 0) {
			let colour = vehicalFilterValue.colour.map(x => `'${x}'`);
			vehicalFilterValue.colour = colour;
		}
		vehicalFilterValue.page = page;
		vehicalFilterValue.offset = 20;
		dispatch(screenCallAPI(GET_VEHICAL_LIST, vehicalFilterValue,{navigate: navigate}));
	};

	const getVehicleCategory = () => {
		dispatch(screenCallAPI(GET_VEHICAL_CATEGORY,{},{navigate: navigate}));
	};

	useEffect(() => {
		// getVehicleListHandler(1);
		// getVehicleCategory();
		getcampaignDetails();
	}, []);

	window.onscroll = function () {
		var totalPageHeight = document.body.scrollHeight;
		var scrollPoint = window.scrollY + window.innerHeight;
		if (scrollPoint >= totalPageHeight) {
			!loading
				? next
					? getVehicleListHandler(page + 1)
					: console.log()
				: console.log();
		}
	};

	const handleListGridView = () => {
		setIsGridView(!isGridView);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			[name]: value,
		}));
	};

	const debounce = (func, delay) => {
		let timeoutId;
		return function (...args) {
			const context = this;
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func.apply(context, args), delay);
		};
	};

	const handleSearchInputChange = (e) => {
		const { name, value } = e.target;
		setCampaignSearchValue(value);
		const body = {
			campaign_code: campaignDetailsData?.data?.CampaignCode,
			search_text: value
		}
		dispatch(campaignSearchCallAPI(CAMPAIGN_SEARCH, body,{navigate: navigate}));
	};

	// Debounce the handler function
	const debouncedHandleInputChange = debounce(handleSearchInputChange, 500);

	const handleMulitSelectChange = (e, key) => {
		const { name, value } = e.target;
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			[name]: typeof value === 'string' ? value?.split(',') : value,
		}));
		if (selectedFilter.filter(e => e.value === key?.props?.children?.[1]?.props?.primary).length > 0) {
			selectedFilter.splice(selectedFilter.findIndex(a => a.value === key?.props?.children?.[1]?.props?.primary), 1)
		} else {
			selectedFilter.push({ "name": name, "value": key?.props?.children?.[1]?.props?.primary, "id": key?.props?.value });
		}
	};

	const handleVehicleTypeSelect = (e, val) => {
		const { name, value } = e.target;
		let filterValue = [...selectedFilter];
		filterValue = filterValue.filter(obj => obj.name !== 'vihical_type');
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			['vihical_type']: value,
		}));
		filterValue.push({ "name": "vihical_type", "value": value, "id": value });
		setSelectedFilter(filterValue);
	};

	const handleCancelFilterValue = (data) => {
		let filterValue = [...selectedFilter];
		filterValue.splice(filterValue.findIndex(a => a.value === data.value), 1)
		setSelectedFilter(filterValue);
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			['brand']: filterValue.filter(e => e.name === 'brand').map((key) => key.id),
			['year']: filterValue.filter(e => e.name === 'year').map((key) => key.value),
			['vihical_type']: "",
			['colour']: filterValue.filter(e => e.name === 'colour').map((key) => key.value),
			['min_range']: filterValue.filter(e => e.name === 'max_range').length > 0 ? filterValue.filter(e => e.name === 'min_range').map((key) => key.value) : 0,
			['max_range']: filterValue.filter(e => e.name === 'max_range').length > 0 ? filterValue.filter(e => e.name === 'max_range').map((key) => key.value) : 10000,
		}));
	};

	const handleCancelAllFilterValue = () => {
		setSelectedFilter([]);
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			['brand']: [],
			['year']: [],
			['vihical_type']: "",
			['colour']: [],
			['min_range']: 0,
			['max_range']: 10000,
		}));
	}
	const [formValue, setFormValue] = useState({
		carType: true,
	})
	const handleMulitAutoChange = (e, val) => {
		const list = [];
		let filterValue = [...selectedFilter];
		filterValue = filterValue.filter(obj => obj.name !== 'colour');
		val.forEach((value) => {
			list.push(value.BodyColour);
			filterValue.push({ "name": "colour", "value": value.BodyColour, "id": value.SupplierColourCode });
		});
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			['colour']: typeof list === 'string' ? list?.split(',') : list,
		}));
		setSelectedFilter(filterValue);
	};

	const handleCheckboxChange = (e, name) => {
		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			[name]: e.target.checked,
		}));
	};
	const handleCheckboxChangeFormValue = (e, name) => {
		setFormValue((prevState) => ({
			...formValue,
			[name]: e.target.checked,
		}));
		// if(e.target.checked){
		// 	navigate(getUsedCarsURL(locale))
		// }
	};
	const handleSliderChange = (event, value) => {
		let filterValue = [...selectedFilter];
		let filterIndexMin = filterValue.map((e, i) => e.name === 'min_range' ? i : undefined).filter(x => x);
		filterValue.splice(filterIndexMin[0], 1);
		let filterIndexMax = filterValue.map((e, i) => e.name === 'max_range' ? i : undefined).filter(x => x);
		filterValue.splice(filterIndexMax[0], 1);

		setVehicalFilterValue((prevState) => ({
			...vehicalFilterValue,
			['min_range']: value[0],
			['max_range']: value[1],
		}));
		filterValue.push({ "name": "min_range", "value": `₹${(value[0] > 0) ? value[0] : "min"} - ${(value[1] < 10000) ? value[1] : "₹10000"}`, "id": value[0] });
		setSelectedFilter(filterValue);
	};

	const handleSubmit = () => {
		setIsSearchApply(true);
		setLoading(true);
		getVehicleListHandler(1);
	};
	
	const getcampaignDetails = (value) => {
		let body = `?campaign_code=${campaignCode}`
		dispatch(campaignDetailsCallAPI(CAMPAIGN_DETAILS, body,{navigate: navigate}));
	};

	return (
		<div style={{ backgroundColor: '#F1EDF5', padding: "30px 0px", minHeight: '100vh' }}>
			<Grid style={{ marginTop: '5px', padding: '0% 6% 0% 6%' }} >
				<Grid container>
					<Grid item xs={12}>
						<MuiBreadcrumbs
							list={breadcrumbsList}
						/>
					</Grid>
					<Grid item xs={12}>
						<H1Text color="primary" size="40" lineHeight="60">
							<FormattedMessage id="findVehicle" />
						</H1Text>
					</Grid>
				</Grid>
				{/* <Grid item xs={12}>
					<Box display='flex' alignItems={'center'}>
						<H5Text size='18' fontFamily='Karbon_medium' lineHeight='24' marginTop={0}>Car Type</H5Text>
						<img src={require('assets/images/icon/exclamation.png')} alt="" className='image' style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
					</Box>
					<Box style={{ marginTop: '-18px' }}>
						<SwitchWithLabel
							height={'82px'}
							thumbHeight={'62px'}
							thumbWidth={'160px'}
							backgroundColor={'white'}
							thumbMargin={'8px'}
							borderRadius={'8px'}
							thumbBorderRadius={'16px'}
							style={{ borderRadius: '20px' }}
							locale={locale}
							onText={<H5Text size='18' lineHeight='24' fontFamily='Karbon_semibold' marginTop={0}>New Car</H5Text>}
							offText={<H5Text size='18' lineHeight='24' fontFamily='Karbon_semibold' marginTop={0}>Used Car</H5Text>}
							checked={formValue.carType}
							onChange={(e) => handleCheckboxChangeFormValue(e, 'carType')}
							name="carType"
						/>
					</Box>
				</Grid> */}

				<Grid container spacing={1} >
					<Grid item >
						<LabelWithInput
							placeholder="Search"
							bgColor="white"
							borderNone={true}
							borderRadius="8"
							fontSize="18"
							textColor="lightDark"
							marginTop="0"
							name="search_text"
							type="text"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end"><SearchIcon sx={{ color: "#8F8D95", fontSize: "20px" }} /></InputAdornment>
								)
							}}
							onChange={debouncedHandleInputChange}

						/>
					</Grid>

				</Grid>

				{!formValue.carType &&
					<>
						<Grid container spacing={1} >
							<Grid item >
								<LabelWithInput
									placeholder="Search by brands,year,etc."
									bgColor="white"
									borderNone={true}
									borderRadius="8"
									fontSize="18"
									textColor="lightDark"
									marginTop="0"
									value={vehicalFilterValue.search}
									name="search"
									type="text"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end"><SearchIcon sx={{ color: "#8F8D95", fontSize: "20px" }} /></InputAdornment>
										)
									}}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item minWidth={'223px'}>
								<MultiSelectInput
									bgColor="white"
									borderRadius="8"
									borderNone={true}
									fontSize="18"
									textColor="lightDark"
									label="Monthly payment range"
								>
									<div className="test" style={{ width: "400px", height: "200px", padding: "30px 30px 10px" }}>
										<Grid container >
											<Grid item xs={12}>
												<MuiRangeSlider
													min={0}
													max={10000}
													step={10}
													value={[vehicalFilterValue.min_range, vehicalFilterValue.max_range]}
													onChange={handleSliderChange}
												/>
											</Grid>
										</Grid>

										<Grid container display='flex' justifyContent='space-between'>
											<Grid item xs={5}>
												<LabelWithInput
													label="From"
													placeholder="0"
													name="min_range"
													value={vehicalFilterValue.min_range}
													onChange={handleInputChange}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">SAR</InputAdornment>
														)
													}}
												/>
											</Grid>
											<Grid item >
											</Grid>
											<Grid item xs={5}>
												<LabelWithInput
													label="To"
													placeholder="0"
													name="max_range"
													value={vehicalFilterValue.max_range}
													onChange={handleInputChange}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">SAR</InputAdornment>
														)
													}}
												/>
											</Grid>
										</Grid>
									</div>
								</MultiSelectInput>
							</Grid>

							<Grid item minWidth={'160px'}>
								<MultiSelectInput
									bgColor="white"
									borderRadius="8"
									borderNone={true}
									fontSize="18"
									textColor="lightDark"
									label="Brands/Model"
									name="brand"
									multiple
									onChange={handleMulitSelectChange}
									value={vehicalFilterValue.brand}
								// renderValue={(selected) => selected.join(', ')}
								>
									{vehicalBrandList.length > 0 && vehicalBrandList.map((value) => (
										<WrapperMenuItemField key={value.tBrandID} value={value.tBrandID}>
											{vehicalFilterValue.brand.indexOf(value.tBrandID) > -1 &&
												<CheckIcon sx={{ color: "#3D3D4E" }} />
											}
											<ListItemText primary={value.Description} />
										</WrapperMenuItemField>
									))}
								</MultiSelectInput>
							</Grid>

							<Grid item minWidth={'96px'} >
								<MultiSelectInput
									bgColor="white"
									borderRadius="8"
									borderNone={true}
									fontSize="18"
									textColor="lightDark"
									onChange={handleMulitSelectChange}
									label="Year"
									name="year"
									multiple
									value={vehicalFilterValue.year}
								// renderValue={(selected) => selected.join(', ')}
								>
									{vehicalYearList.length > 0 && vehicalYearList.map((value) => (
										<WrapperMenuItemField key={value.Year} value={value.Year}>
											{vehicalFilterValue.year.indexOf(value.Year) > -1 &&
												<CheckIcon sx={{ color: "#3D3D4E" }} />
											}
											<ListItemText primary={value.Year} />
										</WrapperMenuItemField>
									))}
								</MultiSelectInput>
							</Grid>

							<Grid item minWidth={'160px'} >
								<MultiSelectInput
									bgColor="white"
									borderRadius="8"
									borderNone={true}
									fontSize="18"
									textColor="lightDark"
									label="Vehicle Type"
									MenuProps={{
										classes: {
											list: "vehicle-type-list"
										},
									}}
									name="VehicleType"
									onChange={(e, val) => handleVehicleTypeSelect(e, val)}
									value={vehicalFilterValue.vihical_type}
								>
									{vehicalTypeList.length > 0 && vehicalTypeList.map((value) => (
										<WrapperTypeItemField key={value.tProductID} value={value.tProductID}>
											<WrapperTypeDiv select={vehicalFilterValue.vihical_type === value.tProductID}>
												{value.tProductID}
											</WrapperTypeDiv>
										</WrapperTypeItemField>
									))}
								</MultiSelectInput>
							</Grid>

							<Grid item minWidth={'101px'}>
								<MultiSelectAutoInput
									bgColor="white"
									borderRadius="8"
									borderNone={true}
									fontSize="18"
									textColor="lightDark"
									label="Colour"
									options={vehicalColorList}
									onChange={(e, val) => handleMulitAutoChange(e, val)}
									name="colour"
									multiple
									componentsProps={{
										popper: {
											style: {
												minWidth: "225px"
											}
										}
									}}
									getOptionLabel={(option) => option.BodyColour}
									renderOption={(props, option, { selected }) => (
										<WrapperLiField {...props}>
											<Checkbox
												icon={icon}
												checkedIcon={checkedIcon}
												style={{ marginRight: 5 }}
												checked={vehicalFilterValue.colour.indexOf(option.BodyColour) > -1 && selected}
											/>
											<WrapperColorField colorCode={'#000'}>&nbsp;</WrapperColorField>
											{option.BodyColour}
										</WrapperLiField>
									)}
								/>
							</Grid>

							<Grid item minWidth={'111px'}>
								<CheckboxInput
									label="Offers"
									size="16"
									fontWeight="600"
									isBackground={true}
									checked={vehicalFilterValue.offers}
									checkColor={'#B8B8B8'}
									name="offers"
									onChange={(val) => handleCheckboxChange(val, 'offers')}
								/>
							</Grid>

							<Grid item minWidth={'254px'}>
								<CheckboxInput
									label="Available for fast financing"
									size="16"
									fontWeight="600"
									isBackground={true}
									checked={vehicalFilterValue.available_for_fast_financing}
									name="available_for_fast_financing"
									checkColor={'#B8B8B8'}
									onChange={(val) => handleCheckboxChange(val, 'available_for_fast_financing')}
								/>
							</Grid>

							<Grid item sx={{ marginTop: "20px" }}>
								<ButtonInput
									title={<FormattedMessage id="applyFilter" />}
									variant="contained"
									bgColor="purple"
									textColor="white"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => handleSubmit()}
								/>
							</Grid>
						</Grid>

						<Grid container sx={{ marginTop: "20px", alignItems: "center" }}>
							{selectedFilter.map((key) => (
								<FilterValueWrap>
									{key.value}
									<CancelIcon style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => handleCancelFilterValue(key)} />
								</FilterValueWrap>
							))}
							{selectedFilter.length > 0 &&
								<ClearFilterValueWrap>
									Clear All Filter
									<CancelIcon style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => handleCancelAllFilterValue()} />
								</ClearFilterValueWrap>
							}
						</Grid>
					</>}
				<Grid container sx={{ marginTop: "20px" }}>
					<Grid item xs={6}>
						<Text size="24" lineHeight="36" fontWeight="700" color="lightDark">
							{campaignDetailsData?.data?.campaign_cars?.length} <FormattedMessage id="results" />
						</Text>
					</Grid>
					<Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
						<MuiCards borderRadius="4" padding="10px" margin={locale === 'en' ? "0px 10px 0px 0px" : "0px 0px 0px 10px"}>
							<FilterListIcon sx={{ cursor: "pointer", fontSize: "24px", color: "#8F8D95" }} />
						</MuiCards>
						<MuiCards borderRadius="4" padding="10px" onClick={() => handleListGridView()}>
							{isGridView ? (
								<SplitscreenIcon sx={{ cursor: "pointer", fontSize: "24px", color: "#8F8D95" }} />
							) : (
								<GridViewIcon sx={{ cursor: "pointer", fontSize: "24px", color: "#8F8D95" }} />
							)}
						</MuiCards>
					</Grid>
				</Grid>

				{!formValue.carType ?
					<Grid container spacing={2} style={{ marginTop: "10px" }}>
						{!loading ? (
							<>
								{vehicalListData?.length > 0 && vehicalListData.map((data, index) => {
									return (
										<Grid item xs={isGridView ? '12' : '12'} sm={isGridView ? '12' : '12'} md={isGridView ? '6' : '12'} lg={isGridView ? '4' : '12'} xl={isGridView ? '3' : '12'}>
											<CardList locale={locale} viewFlag={isGridView} carData={data} index={index} />
										</Grid>
									)
								})}
							</>
						) : (
							<>
								{Array.from({ length: 8 }).map((data) => (
									<Grid item xs={isGridView ? '12' : '12'} sm={isGridView ? '12' : '12'} md={isGridView ? '6' : '12'} lg={isGridView ? '4' : '12'} xl={isGridView ? '3' : '12'} >
										<MuiCards borderRadius="12" padding='0' style={{ display: isGridView ? '' : 'flex' }} flexDirection={isGridView ? 'column' : 'row'} >
											<Grid item xs={isGridView ? '12' : '3'} >
												<Skeleton sx={{ height: isGridView ? 190 : '100%', width: '100%' }} animation="wave" variant="rectangular" />
											</Grid>
											<Grid item xs={isGridView ? '12' : '9'} padding="16px" >
												<Box display={'flex'}>
													<Grid xs={2}>
														<Skeleton animation="wave" variant="circular" width={40} height={40} />
													</Grid>
													<Grid xs={10}>
														<Skeleton animation="wave" height={10} width="40%" />
														<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
													</Grid>
												</Box>
												<Box display={'flex'} marginTop="20px">
													<Grid xs={6}>
														<Skeleton animation="wave" height={10} width="40%" />
														<Skeleton animation="wave" height={40} width="60%" />
													</Grid>
													<Grid xs={6} display='flex' flexDirection={'column'} alignItems='end'>
														<Skeleton animation="wave" height={10} width="40%" />
														<Skeleton animation="wave" height={40} width="60%" />
													</Grid>
												</Box>
												<Grid container spacing={2} marginTop="8px" display='flex' justifyContent={'end'}>
													<Grid item xs={5}>
														<Skeleton animation="wave" height={40} />
													</Grid>
													<Grid item xs={5}>
														<Skeleton animation="wave" height={40} />
													</Grid>
												</Grid>
											</Grid>
										</MuiCards>
									</Grid>
								))
								}
							</>
						)}
					</Grid> :
					<Grid container spacing={2} style={{ marginTop: "10px" }}>
						{!campaignDetailsDataloader ? (
							<>
								{campaignDetailsData?.data?.campaign_cars?.length > 0 && campaignDetailsData.data.campaign_cars.map((data, index) => {
									return (
										<Grid item xs={isGridView ? '12' : '12'} sm={isGridView ? '12' : '12'} md={isGridView ? '6' : '12'} lg={isGridView ? '4' : '12'} xl={isGridView ? '3' : '12'}>
											<CampaignCarsList locale={locale} viewFlag={isGridView} carData={data} index={index} />
										</Grid>
									)
								})}
							</>
						) : (
							<>
								{Array.from({ length: 8 }).map((data) => (
									<Grid item xs={isGridView ? '12' : '12'} sm={isGridView ? '12' : '12'} md={isGridView ? '6' : '12'} lg={isGridView ? '4' : '12'} xl={isGridView ? '3' : '12'} >
										<MuiCards borderRadius="12" padding='0' style={{ display: isGridView ? '' : 'flex' }} flexDirection={isGridView ? 'column' : 'row'} >
											<Grid item xs={isGridView ? '12' : '3'} >
												<Skeleton sx={{ height: isGridView ? 190 : '100%', width: '100%' }} animation="wave" variant="rectangular" />
											</Grid>
											<Grid item xs={isGridView ? '12' : '9'} padding="16px" >
												<Box display={'flex'}>
													<Grid xs={2}>
														<Skeleton animation="wave" variant="circular" width={40} height={40} />
													</Grid>
													<Grid xs={10}>
														<Skeleton animation="wave" height={10} width="40%" />
														<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
													</Grid>
												</Box>
												<Box display={'flex'} marginTop="20px">
													<Grid xs={6}>
														<Skeleton animation="wave" height={10} width="40%" />
														<Skeleton animation="wave" height={40} width="60%" />
													</Grid>
													<Grid xs={6} display='flex' flexDirection={'column'} alignItems='end'>
														<Skeleton animation="wave" height={10} width="40%" />
														<Skeleton animation="wave" height={40} width="60%" />
													</Grid>
												</Box>
												<Grid container spacing={2} marginTop="8px" display='flex' justifyContent={'end'}>
													<Grid item xs={5}>
														<Skeleton animation="wave" height={40} />
													</Grid>
													<Grid item xs={5}>
														<Skeleton animation="wave" height={40} />
													</Grid>
												</Grid>
											</Grid>
										</MuiCards>
									</Grid>
								))
								}
							</>
						)}
					</Grid>
				}
			</Grid>
		</div>
	);
}

export default CampaignDetails;