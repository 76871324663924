import React, { useContext, useState } from "react";
import MuiCards from "components/MuiCards/";
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import Car_Finnance_Icon from "assets/images/Dashboard/car_finance.png";
import Cash_Finance_Icon from "assets/images/Dashboard/cash_finance.png";
import Micro_Finance_Icon from "assets/images/Dashboard/mico_finance.png";
import styled from 'styled-components'
import { Row } from "react-bootstrap";
import OneWaySlider from "components/RangeSlider/OneWaySlider";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import carouselRight from "assets/icons/carouselRight.png";
import H5Text from "components/Text/H5Text";
import { Box, Skeleton } from "@mui/material";
import { getContractsURL } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import { useNavigate } from "react-router-dom";

const StyledWrapper = styled.div` 

`
const ContainerStyle = styled.div`
    .slick-active{
        z-index:1;
    }
    .slick-list{
        overflow:visible;
    }
    .iconImg{
        margin-top:0px;
    }
    .slick-slide{
        margin-bottom:0px;
    }
    .slick-dots{
        z-index:0;
        display:none !important;
    }
    .slick-prev:before,
    .slick-next:before {
        content: '';
        background-size: contain;
        display: block;
        width: 40px; 
        height: 40px; 
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .slick-prev:before{
        background-image: url(${carouselRight}) !important; 
        transform: rotate(180deg) !important; 
    }
    .slick-next:before{
        background-image: url(${carouselRight}) !important; 
        transform: rotate(360deg) !important; 
    }
    .slick-next:before, .slick-prev:before {
        font-size: 24px;
        line-height: 1;
        opacity: 1;
        color:#3F1956;
    }

    .slick-prev {
        ${(props) => props.locale == 'en' ? 'left' : 'right'}: 92.5%;
    }

    .slick-next{
        ${(props) => props.locale == 'en' ? 'right' : 'left'}:25px;
    }

    .slick-next, .slick-prev {
        top: 105%;
    }

    .httobK {
        margin: 8px!important;
    }

    .slick-list{
        background: #f4e1e500 !important;
    }
    .slick-track{
        // float:${(props) => props.locale == 'en' ? 'left' : 'right'} !important;
    }
    .MuiSlider-valueLabelOpen{
        height:20px;
        background:#FFEDB1;
        color:#5C0F8B;
        padding:0 8px;
    }
    // class="MuiSlider-valueLabelLabel"
    .MuiSlider-thumb{
        // left:2% !important;
    }

.MuiSlider-valueLabelOpen{
    direction: ltr !important;
  }
    .MuiSlider-root{
        // margin:${(props) => props.locale == 'en' ? '0 8px 0 8px' : '0 0 0 8px'};
        // transform:${(props) => props.locale == 'en' ? '' : 'rotateY(160deg)'}; 
    }
    .MuiSlider-valueLabelCircle{
        // transform: ${(props) => props.locale == 'en' ? '' : 'rotateY(180deg)'}; 
    }
    @media screen and (max-width:1620px){
        .slick-prev {
            ${(props) => props.locale == 'en' ? 'left' : 'right'}: 91%;
        }
    }
    @media screen and (max-width:1400px){
        .slick-prev {
            ${(props) => props.locale == 'en' ? 'left' : 'right'}: 89%;
        }
    }
    @media screen and (max-width:900px){
        .slick-prev {
            ${(props) => props.locale == 'en' ? 'left' : 'right'}: 85%;
        }
    }
    @media screen and (min-width:768px){
        .slick-track{
            float:${(props) => props.locale == 'en' ? 'left' : 'right'} !important;
        }
    }
    @media screen and (max-width:768px){
        .slick-prev:before, .slick-next:before {
            display:none !important;
            background:none;
        }
    }
    .MuiPaper-root{
        overflow:visible !important;
    }
`

const ActiveFinance = ({ ...props }) => {
    const [period, setPeriod] = useState(60);
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <StyledWrapper>
            {!props?.loader && props?.data &&
                <Row style={{ marginBottom: "8px" }}>
                    <H5Text color="purple" size="24" marginTop="0" lineHeight="24" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                        {<FormattedMessage id="dashboard.activeFinance" />}
                    </H5Text>
                </Row>
            }

            {!props?.loader ?
                <ContainerStyle {...props} locale={props.locale}>
                    <Slider {...settings} >
                        {props?.data &&
                            <>
                                {props?.data && props?.data?.length > 0 ? props.data.map((k) => (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <MuiCards borderRadius="16" boxShadow={"0px 1px 69px 0px #00000017"} backgroundSize="cover" bgColor="white" padding="20px 16px" >
                                                <Grid container onClick={() => navigate(getContractsURL(locale))} style={{ cursor: "pointer" }}>
                                                    <Grid item xs={1}>
                                                        {/* <img src={k.image} alt='icon' /> */}
                                                        <img src={Cash_Finance_Icon} alt='icon' />
                                                    </Grid>
                                                    <Grid item xs={8} marginLeft={props.locale == 'en' && '50px'} marginRight={props.locale == 'ar' && '50px'}>
                                                        <Text color="purple" size="24" marginTop="0" lineHeight="24" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>

                                                        </Text>
                                                        <div style={{ display: "flex" }}>
                                                            <Text color="darkGray" size="16" marginTop="0" lineHeight="18">
                                                                <FormattedMessage id="accountNo" />:
                                                            </Text>
                                                            <Text color="purple" size="16" marginTop="0" lineHeight="18" fontFamily="KarbonMedium">
                                                                &nbsp;{k?.loanNo}
                                                            </Text>
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Text color="darkGray" size="16" marginTop="0" lineHeight="18">
                                                                <FormattedMessage id="remainingInstallment" />:
                                                            </Text>
                                                            <Text color="purple" size="16" marginTop="0" lineHeight="18" fontFamily="KarbonMedium">
                                                                &nbsp;{k?.remainingAmount}
                                                            </Text>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: '36px', padding: '0 10px' }}>
                                                        <OneWaySlider valueLabelDisplay={true} notChangeable={true} startLimitText={<FormattedMessage id="paid" />}
                                                            percentage={true}
                                                            marginLeft={"0"} value={(k?.loanReceivedInstlAmount / (k?.totalInstall - k?.loanReceivedInstlAmount)) * 100} setValue={(data) => setPeriod(data)}
                                                            color="#8924C6" height="16" width="16" leftmeasure={k?.loanReceivedInstlAmount} rightmeasure={k?.totalInstall - k?.loanReceivedInstlAmount} border="none"
                                                            sliderBgColor="#8924C6" endLimitText={<FormattedMessage id="remaining" />}
                                                            leftTextColor={(props) => props?.theme?.purple} rightTextColor={(props) => props?.theme?.disabledPurple} fontSize={"18px"} fontFamily={"KarbonMedium"}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} marginTop={"16px"}>
                                                        <Grid container>
                                                            <Grid item xs={4} flexDirection="column" display={"flex"}>
                                                                <Text color="lightDark" size="18" marginTop="0" lineHeight="21.6" fontFamily="KarbonMedium">
                                                                    <FormattedMessage id="sar" /> {k?.loanEmiAmount}
                                                                </Text>
                                                                <Text color="primaryPurple" size="16" marginTop="8" lineHeight="13" >
                                                                    <FormattedMessage id="monthlyPayment" />
                                                                </Text>
                                                            </Grid>
                                                            <Grid item xs={4} borderRight={"1px solid #EFE8F3"} borderLeft={"1px solid #EFE8F3"} alignItems={"center"} textAlign={"center"} flexDirection="column" display={"flex"}>
                                                                <Text color="lightDark" size="18" marginTop="0" lineHeight="21.6" TextAlign="center" fontFamily="KarbonMedium">
                                                                    {new Date(k.nextDuedate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/')}
                                                                </Text>
                                                                <Text color="primaryPurple" size="16" marginTop="8" lineHeight="13" TextAlign="center">
                                                                    <FormattedMessage id="nextDueDate" />
                                                                </Text>
                                                            </Grid>
                                                            <Grid item xs={4} flexDirection="column" display={"flex"}>
                                                                <Text color="lightDark" size="18" marginTop="0" lineHeight="21.6" TextAlign={"right"} fontFamily="KarbonMedium">
                                                                    <FormattedMessage id="sar" /> {k?.balloonPayment}
                                                                </Text>
                                                                <Text color="primaryPurple" size="16" marginTop="8" lineHeight="13" TextAlign={"right"}>
                                                                    <FormattedMessage id="balloonPayment" />
                                                                </Text>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </MuiCards>
                                        </Grid>
                                    </Grid>
                                )) :
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <MuiCards borderRadius="16" boxShadow={"0px 1px 69px 0px #00000017"} backgroundSize="cover" bgColor="white" padding="20px 16px" >
                                                <Grid container onClick={() => navigate(getContractsURL(locale))} style={{ cursor: "pointer" }}>
                                                    <Grid item xs={1}>
                                                        <img src={Cash_Finance_Icon} alt='icon' />
                                                    </Grid>
                                                    <Grid item xs={8} marginLeft={props.locale == 'en' && '50px'} marginRight={props.locale == 'ar' && '50px'}>
                                                        <Text color="purple" size="24" marginTop="0" lineHeight="24" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
                                                        </Text>
                                                        <div style={{ display: "flex" }}>
                                                            <Text color="darkGray" size="16" marginTop="0" lineHeight="18">
                                                                <FormattedMessage id="accountNo" />:
                                                            </Text>
                                                            <Text color="purple" size="16" marginTop="0" lineHeight="18" fontFamily="KarbonMedium">
                                                                &nbsp;{props?.data?.loanNo}
                                                            </Text>
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Text color="darkGray" size="16" marginTop="0" lineHeight="18">
                                                                <FormattedMessage id="remainingInstallment" />:
                                                            </Text>
                                                            <Text color="purple" size="16" marginTop="0" lineHeight="18" fontFamily="KarbonMedium">
                                                                &nbsp;{props?.data?.totalInstallment - props?.data?.billedInstallment}
                                                            </Text>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: '36px', padding: '0 10px' }}>
                                                        <OneWaySlider valueLabelDisplay={true} notChangeable={true} startLimitText={<FormattedMessage id="paid" />}
                                                            percentage={true}
                                                            marginLeft={"0"} value={(props?.data?.loanReceivedInstlAmount / (props?.data?.totalInstall - props?.data?.loanReceivedInstlAmount)) * 100} setValue={(data) => setPeriod(data)}
                                                            color="#8924C6" height="16" width="16" leftmeasure={props?.data?.loanReceivedInstlAmount} rightmeasure={props?.data?.totalInstall - props?.data?.loanReceivedInstlAmount} border="none"
                                                            sliderBgColor="#8924C6" endLimitText={<FormattedMessage id="remaining" />}
                                                            leftTextColor={(props) => props.theme.purple} rightTextColor={(props) => props.theme.disabledPurple} fontSize={"18px"} fontFamily={"KarbonMedium"}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} marginTop={"16px"}>
                                                        <Grid container>
                                                            <Grid item xs={4} flexDirection="column" display={"flex"}>
                                                                <Text color="lightDark" size="18" marginTop="0" lineHeight="21.6" fontFamily="KarbonMedium">
                                                                    <FormattedMessage id="sar" /> {props?.data?.loanEmiAmount}
                                                                </Text>
                                                                <Text color="primaryPurple" size="16" marginTop="8" lineHeight="13" >
                                                                    <FormattedMessage id="monthlyPayment" />
                                                                </Text>
                                                            </Grid>
                                                            <Grid item xs={4} borderRight={"1px solid #EFE8F3"} borderLeft={"1px solid #EFE8F3"} alignItems={"center"} textAlign={"center"} flexDirection="column" display={"flex"}>
                                                                <Text color="lightDark" size="18" marginTop="0" lineHeight="21.6" TextAlign="center" fontFamily="KarbonMedium">
                                                                    {new Date(props?.data?.nextDuedate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/')}
                                                                </Text>
                                                                <Text color="primaryPurple" size="16" marginTop="8" lineHeight="13" TextAlign="center">
                                                                    <FormattedMessage id="nextDueDate" />
                                                                </Text>
                                                            </Grid>
                                                            <Grid item xs={4} flexDirection="column" display={"flex"} >
                                                                <Text color="lightDark" size="18" marginTop="0" lineHeight="21.6" TextAlign={locale=="en"?"right":"left"} fontFamily="KarbonMedium">
                                                                    <FormattedMessage id="sar" /> {props?.data?.balloonPayment}
                                                                </Text>
                                                                <Text color="primaryPurple" size="16" marginTop="8" lineHeight="13" TextAlign={locale=="en"?"right":"left"}>
                                                                    <FormattedMessage id="balloonPayment" />
                                                                </Text>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </MuiCards>
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        }
                    </Slider>
                </ContainerStyle> :
                <>
                    <ContainerStyle {...props} locale={props.locale}>
                        <Slider {...settings} >
                            {Array.from({ length: 2 }).map(() => (
                                <Grid container spacing={2} >
                                    <Grid item xs={12} md={12} lg={12} xl={12} >
                                        <MuiCards borderRadius="12" padding='0' height={250}  >
                                            <Grid container padding='20px' >
                                                <Grid item xs={12} display="flex" >
                                                    <Skeleton animation="wave" variant="rectangular" width={60} height={60} style={{ borderRadius: "10px" }} />
                                                    <Box width="100%" marginLeft={props.locale == 'en' && "20px"} marginRight={props.locale == 'ar' && "20px"}>
                                                        <Skeleton animation="wave" height={20} width="40%" />
                                                        <Skeleton animation="wave" height={20} width="40%" />
                                                        <Skeleton animation="wave" height={20} width="40%" />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: '36px' }}>
                                                    <Skeleton animation="wave" height={20} width="100%" />
                                                    <Box display={"flex"} justifyContent="space-between">
                                                        <Skeleton animation="wave" height={20} width="30%" />
                                                        <Skeleton animation="wave" height={20} width="30%" />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} marginTop={"36px"}>
                                                    <Grid container>
                                                        <Grid item xs={4} flexDirection="column" display={"flex"}>
                                                            <Skeleton animation="wave" height={20} width="40%" />
                                                            <Skeleton animation="wave" height={20} width="40%" />
                                                        </Grid>
                                                        <Grid item xs={4} borderRight={"1px solid #EFE8F3"} borderLeft={"1px solid #EFE8F3"} alignItems={"center"} textAlign={"center"} flexDirection="column" display={"flex"}>
                                                            <Skeleton animation="wave" height={20} width="40%" />
                                                            <Skeleton animation="wave" height={20} width="40%" />
                                                        </Grid>
                                                        <Grid item xs={4} flexDirection="column" display={"flex"} justify-content="end" alignItems="end">
                                                            <Skeleton animation="wave" height={20} width="40%" />
                                                            <Skeleton animation="wave" height={20} width="40%" />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </MuiCards>
                                    </Grid>
                                </Grid>
                            ))}
                        </Slider>
                    </ContainerStyle>
                </>
            }
        </StyledWrapper>
    );
};


export default ActiveFinance;
