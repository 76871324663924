import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from 'styled-components';
import ShareIcon from "assets/images/Application/ShareNetwork.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import carServiceImage from "assets/icons/E_Service/carService.png";
import carServiceImageWhite from "assets/icons/E_Service/carServiceWhite.png";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import { Box, Grid } from "@mui/material";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { FormattedMessage } from "react-intl";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import { getApplicationsURL, getContractDetailsURL, getContractsURL, getDashboardURL, getRevisedFinancingURL } from 'routes/AppUrls';
import SideBySideText from "components/SideBySideText";
const StyleWrapper = styled.div`
    align-items: center;

    .left_container{
          background:#ffffff;
          border-radius:16px;
          padding: 30px 20px;
      };
    
    ul{
        display: inline;
      };
    
    li {
        // display: inline-block;
        // padding-right:30px;
        // float: left;
      };

    .service_box{
        height:100px;
        // width:234px;
        background:#F5ECFF;
        padding:16px 8px 16px 8px;
        color:#6D4C7E;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
    };

    .service_box_selected{
        height:100px;
        // width:234px;
        background:#3F1956;
        padding:16px 8px 16px 8px;
        color:#ffffff;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
    };
 
`

const LoanDetails = (props) => {
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="contractsServices" />,
            Link: getContractsURL(locale)
        },
        {
            title: <FormattedMessage id="contractDetails" />,
            Link: "",
            isDisbale: true,
        },
    ])
    

    return (
        <StyleWrapper>
            <Row className="left_container">
                <MuiBreadcrumbs
                    list={breadcrumbsList}
                />

                <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "26px" }}>
                    <Grid item xs={12} lg={8} style={{ display: "flex", alignItems: "flex-start" }}>
                        <div style={{ marginBottom: '15px' }}>
                            <ArrowBackIcon sx={{ color: "#3F1956", fontSize: "24", cursor: 'pointer' }} onClick={() => navigate(getApplicationsURL(locale))} />
                        </div>
                        <Box display="flex" flexDirection={"column"}>
                        <H5Text size="32" marginTop="0" color="purple" style={{ paddingLeft: '20px' }} >
                            1234 5521 2312 5521 4453
                        </H5Text>
                        <Text size="20" lineHeight="21.23" fontFamily={locale=="en"?"KarbonLight":"LumaLight"} marginTop="8" color="lightGrey" style={{ paddingLeft: '20px' }}> <FormattedMessage id="contractNumber"/></Text>
                        </Box>
                        <ContentCopyOutlinedIcon style={{ marginLeft: '15px', marginBottom: '15px' }} />
                    </Grid>

                    <Grid item xs={12} lg={4} justifyContent={'end'} display={'flex'}>
                        <img src={ShareIcon} alt="share" />
                    </Grid>

                    <Grid item xs={12} lg={8} sx={{ marginTop: "30px" }}>
                        <Text size="18" color={"purple"} textAlign="center" fontWeight="800" lineHeight="27" fontFamily="KarbonBold">
                            <FormattedMessage id="loanDetails"/>
                        </Text>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} lg={6} sx={{ marginTop: "20px", background: "#F4F1FF",borderRadius:"8px" }}>
                            <SideBySideText
                                leftText={<Text size="20" lineHeight="21.23" fontFamily={locale=="en"?"KarbonLight":"LumaLight"} marginTop="0"><FormattedMessage id="loanAmount"/>:</Text>}
                                RightText={<Text size="22" lineHeight="23.59" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} marginTop="0">20,000 SAR</Text>}
                                paddingAll="15"
                                leftFontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}
                                leftTextColor={"lightPrimary"}
                                leftFontWight={"600"}
                                rightTextColor={"purple"}
                                rightFontFamily={"KarbonBold"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} lg={6} sx={{ marginTop: "20px", background: "#F4F1FF",borderRadius:"8px" }}>
                            <SideBySideText
                                leftText={<Text size="20" lineHeight="21.23" fontFamily={locale=="en"?"KarbonLight":"LumaLight"} marginTop="0"><FormattedMessage id="tenure"/>:</Text>}
                                leftFontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}
                                leftTextColor={"lightPrimary"}
                                leftFontWight={"600"}
                                rightTextColor={"purple"}
                                rightFontFamily={"KarbonBold"}
                                RightText={<Text size="22" lineHeight="23.59" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} marginTop="0">30 Months</Text>}
                                paddingAll="15"
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} lg={6} sx={{ marginTop: "20px", background: "#F4F1FF",borderRadius:"8px" }}>
                            <SideBySideText
                                leftText={<Text size="20" lineHeight="21.23" fontFamily={locale=="en"?"KarbonLight":"LumaLight"} marginTop="0"><FormattedMessage id="monthlyAmount"/>:</Text>}
                                RightText={<Text size="22" lineHeight="23.59" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} marginTop="0">3,000 SAR</Text>}
                                paddingAll="15"
                                leftFontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}
                                leftTextColor={"lightPrimary"}
                                leftFontWight={"600"}
                                rightTextColor={"purple"}
                                rightFontFamily={"KarbonBold"}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Row>
        </StyleWrapper>
    );
};

export default LoanDetails;