import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from "components/Text/H5Text";
import ButtonInput from "components/Input/ButtonInput";
import SideBySideText from "components/SideBySideText";
import LabelWithInput from 'components/Input/LabelWithInput';
import { InputAdornment } from '@mui/material';
import { LocaleContext } from 'routes/Router';
import { screenScrollTop } from 'utils/general';
import { ResumeOnlineFormCall } from 'store/resumeOnlineForm/actions';
import { RESUME_ONLINE_FORM } from 'store/resumeOnlineForm/constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadDocumentCallAPI } from 'store/uploadDocument/action';
import { UPLOAD_DOCUMENT } from 'store/uploadDocument/constant';

const WrapperDiv = styled.div`
	border-radius:8px;
	background-color:white;

	.MuiInputBase-root{
		padding: ${(props) => props.locale == 'en' ? '15px 3px 15px 15px' : '15px 15px 15px 3px'}  !important;
	}
	.MuiInput-root{
		border:1px dashed #D1D6D8;
	}
`;

const WrapperContentDiv = styled.div`
	border: 0.5px solid #B8B8B8;
	border-radius: 16px;
	margin-top: 16px;
`;

function AttachApplyForm({ handleCurStep }) {
	const { locale } = useContext(LocaleContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [fileName, setFileName] = useState('');

	const financeCalculator = useSelector((state) => state.financeCalculatorReducer && state.financeCalculatorReducer?.payload?.data);

	const handleSubmit = () => {
		screenScrollTop();
		handleCurStep(5);
		let body = {
			resumeForm: true,
			step: 5
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
	};

	const fileInputRef = useRef(null);
	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0]; // Get the first selected file
		if (file) {
			setFileName(file.name); // Update the state with the file name
			let formData = new FormData();
			formData.append("document_for", "iban");
			formData.append("document", e.target.files[0]);
			formData.append("document_type", "png");
			dispatch(uploadDocumentCallAPI(UPLOAD_DOCUMENT, formData));
		}
	};

	return (
		<WrapperDiv locale={locale}>
			<Grid container>

				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'0px'}>
						<FormattedMessage id="autoFinance.uploadDocumentsApplicationFees" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px 30px" }}>
						<Grid container spacing={2}>
							{/* <Grid item xs={12} sm={8} md={8} lg={10}>
								<input
									ref={fileInputRef}
									type="file"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id="uploadLicense" />}
									placeholder={locale == 'en' ? 'Driving License' : 'رخصة قيادة'}
									value={fileName && fileName}
									InputProps={{
										endAdornment: (
											<ButtonInput
												title={<H5Text size="16" lineHeight='24' fontFamily='Karbon_bold' marginTop='0'><FormattedMessage id="upload" /></H5Text>}
												variant="text"
												bgColor="purple"
												textColor="white"
												borderRadius="4"
												borderColor="lightPink"
												TextTransform="capitalize"
												size="16"
												minWidth="112"
												height="45"
												onClick={handleUploadClick}
											/>
										)
									}}
								/>
							</Grid> */}
							<Grid item xs={12} sm={8} md={8} lg={10}>
								<WrapperContentDiv>
									<SideBySideText
										leftText={<FormattedMessage id="autoFinance.applicationFee" />}
										RightText={(financeCalculator?.issueExpense ?? 0) + ' SR'}
										paddingAll="15"
									/>
									<SideBySideText
										leftText={<FormattedMessage id="monthlyPayment" />}
										RightText={(financeCalculator?.emiAmt ?? 0) + ' SR'}
										paddingAll="15"
									/>
									<SideBySideText
										leftText={<FormattedMessage id="downPayment" />}
										RightText={(financeCalculator?.downPayment ?? 0) + ' SR'}
										paddingAll="15"
									/>
									<SideBySideText
										leftText={<FormattedMessage id="totalVAT" />}
										RightText={(financeCalculator?.totalVat ?? 0) + ' SR'}
										paddingAll="15"
									/>
									<SideBySideText
										leftText={<FormattedMessage id="autoFinance.totalIncludingVAT" />}
										RightText={((financeCalculator?.issueExpense ?? 0) + (financeCalculator?.emiAmt ?? 0) + (financeCalculator?.downPayment ?? 0) + (financeCalculator?.totalVat ?? 0)).toFixed(2)}
										subText={<FormattedMessage id="sar" />}
										leftFontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}
										leftTextColor="green"
										rightTextColor="green"
										leftFontWight="800"
										size="20"
										border={true}
										borderColor="green"
										pBottom="0"
										brTopLeft={false}
									/>
								</WrapperContentDiv>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form" sx={{padding:'20px 30px'}}>
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									minWidth="100px"
									marginRight="30"
									padding="0px"
									onClick={() => handleCurStep(3)}
								/>
								<ButtonInput
									title={<FormattedMessage id="autoFinance.saveProceedTowardsSummary" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									// onClick={() => { screenScrollTop(); handleCurStep(5) }}
									onClick={() => handleSubmit()}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	)
}


export default AttachApplyForm;