import React, { useContext, useState } from 'react';
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { string } from "prop-types";
import Box from '@mui/material/Box';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";
import { ResumeOnlineFormCall } from 'store/resumeOnlineForm/actions';
import { RESUME_ONLINE_FORM } from 'store/resumeOnlineForm/constant';
import { useDispatch } from 'react-redux';
import CommonModal from 'components/Modals/CommonModal';
import H5Text from 'components/Text/H5Text';
import { getDashboardURL } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { useNavigate } from 'react-router-dom';

const WrapperDiv = styled.div`
	background: linear-gradient(267deg, rgba(60, 16, 83, 0.79) 22.21%, rgba(60, 16, 83, 0) 36.67%), 
		linear-gradient(88.35deg, #3C1053 14.18%, rgba(60, 16, 83, 0) 72.19%);
	background-image: url('${(props) => (props.bgBanner)}');
	background-size:cover;
	background-repeat:no-repeat;
	width: 100%;
	border-radius: 15px;
	
`;

const WrapperLeftDiv = styled.div`
	padding: 35px;
	border-radius: 15px 35% 10% 15px;
	background: ${(props) => (props.theme[props.bgColor])};
	@media (max-width: 1200px) {
		background: none;
	  }
	@media (max-width: 1200px) {
		.h5text{
			font-size:32px !important;
		}
	  }
	height:100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
`;

const WrapperRightDiv = styled.div`
	padding: 35px;
	@media (max-width: 576px) {
		padding: 12px;
	  }
	border-radius: 65% 15px 15px 20%;
	background: ${(props) => (props.theme[props.bgColor])};
	height: 184px;
	height:100%;
	display: flex;
    flex-direction: column;
    justify-content: end;
`;

function HeaderBanner({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);
	const dispatch = useDispatch();

	const handleClick = () => {
		let body = {
			resumeForm: true,
			step: props?.curStep
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
		navigate(getDashboardURL(locale));
	};

	const handleCancelRequest = () => {
		setModal(true);
	};

	const handleRequestSubmit = () => {
		let body = {
			resumeForm: false,
			step: 0
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
		navigate(getDashboardURL(locale));
	};

	return (
		<WrapperDiv {...props}>
			<Grid container minHeight={'261px'}>
				<Grid item xs={12} md={8} lg={4}>
					<WrapperLeftDiv {...props}>
						<H1Text className="h5text" size="50" color="white" lineHeight="75">
							<FormattedMessage id="autoFinance.onlineFinancing" />
						</H1Text>
						<Text size="20" color="white" marginTop="0">
							<FormattedMessage id="autoFinance.onlineFinancingSubText" />
						</Text>
					</WrapperLeftDiv>
				</Grid>
				<Grid item xs={0} lg={4}>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
					<WrapperRightDiv {...props}>
						{props?.option &&
							<Box component="form" marginTop={{ sx: '0', lg: '70px' }}>
								<Grid container spacing={2} direction="row">
									<Grid item xs={6} sm={6} md={12} xl={6} xxl={6}>
										<ButtonInput
											title={<FormattedMessage id="cancelRequest" />}
											variant="text"
											bgColor="transparent"
											textColor="white"
											borderRadius="100"
											minWidth="201"
											padding="0px 15px"
											height='56'
											TextTransform="capitalize"
											onClick={() => handleCancelRequest()}
										/>
									</Grid>
									<Grid item xs={6} sm={6} md={12} xl={6} xxl={6}>
										<ButtonInput
											title={<FormattedMessage id="saveForLater" />}
											variant="contained"
											bgColor="white"
											textColor="purple"
											minWidth="auto"
											borderRadius="100"
											TextTransform="capitalize"
											padding="0px 15px"
											height='56'
											startIcon={<BookmarksIcon />}
											onClick={() => handleClick()}
										/>
									</Grid>
								</Grid>
							</Box>
						}
					</WrapperRightDiv>

					<CommonModal padding={'32px 48px'} width={'700px'} modalActive={modal} innerContent={
						<Box textAlign={'center'} width={'600px'} margin={'auto'}>
							<Box display='flex' justifyContent={'center'}>
								<img src={require('assets/images/Dashboard/cancelResumeForm.png')} style={{ height: '250px', width: '270px' }} />
							</Box>

							<Box textAlign={'center'} marginTop={'30px'}>
								<H5Text size={28} lineHeight={28} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} marginTop={0}>Are you sure you want to cancel the request?</H5Text>
							</Box>

							<Box textAlign={'center'} marginTop={'17px'}>
								<H1Text className="h5text" size="20" color="lightDark" lineHeight="30">
									Doing so will delete all the progress you’ve made & you’ll be redirected to the Home page.
								</H1Text>
							</Box>

							<Box marginTop={'50px'}>
								<Grid container spacing={2} direction="row">
									<Grid item xs={6} sm={6} md={12} xl={6} xxl={6}>
										<ButtonInput
											title={"No, Continue Application Process"}
											variant="text"
											bgColor="transparent"
											textColor="purple"
											borderRadius="100"
											minWidth="201"
											padding="0px 15px"
											height='56'
											TextTransform="capitalize"
											onClick={() => setModal(false)}
										/>
									</Grid>
									<Grid item xs={6} sm={6} md={12} xl={6} xxl={6}>
										<ButtonInput
											title={"Yes, Cancel Request"}
											variant="contained"
											bgColor="yellow"
											textColor="purple"
											minWidth="auto"
											borderRadius="100"
											TextTransform="capitalize"
											padding="0px 15px"
											height='56'
											onClick={handleRequestSubmit}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					} />

				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

HeaderBanner.propTypes = {
	bgColor: string.isRequired,
};


export default HeaderBanner;