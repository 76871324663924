import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import H5Text from 'components/Text/H5Text';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MaskInput from 'components/Input/MaskInput';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import { uploadDocumentIbanCallAPI } from 'store/uploadDocument/uploadDocumentIban/action';
import { UPLOAD_DOCUMENT_IBAN } from 'store/uploadDocument/uploadDocumentIban/constant';


function DetailRequireModal({ modalActive, handleModalPopup }) {

  const isSmallScreen = useMediaQuery('(max-width:901px)');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '95%' : 862,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
  };

  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const uploadDocumentIbanData = useSelector((state) => state.uploadDocumentIbanReducer && state.uploadDocumentIbanReducer?.payload?.data?.data);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    if (file) {
      let formData = new FormData();
      formData.append("document", e.target.files[0]);
      formData.append("document_for", "iban");
      formData.append("language", locale === "en" ? "eng" : "_ar");
      formData.append("ocr_required", 1);
      dispatch(uploadDocumentIbanCallAPI(UPLOAD_DOCUMENT_IBAN, formData, { navigate: navigate }));
    }
  };

  return (
    <Modal
      open={modalActive}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Grid container sx={style}>
        <Grid item xs={9}>
          <H5Text fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}  size='32' lineHeight='38.4' color='purple' marginTop='0' style={{ borderBottom: '0.5px solid #D5D5D5' }}>
            <FormattedMessage id="detailRequiredCancellation" />
          </H5Text>
        </Grid>
        <Grid item xs={3} display='flex' justifyContent='end'>
          <ClearOutlinedIcon style={{ width: '32', height: '32', cursor: 'pointer' }} onClick={() => handleModalPopup(false)} />
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='end' marginTop='8px'>
          <input
            ref={fileInputRef}
            type="file"
            disabled={false}
            style={{ display: 'none' }} // Hide the file input
            onChange={handleFileChange}
          />
          <MaskInput
            disabled={false}
            maskChar=""
            type="text"
            value={uploadDocumentIbanData?.ocr_result?.[0]?.IbanNumber}
            label={<>IBAN <FormattedMessage id="certificate" /></>}
            placeholder="4444 4444 4444 4444"
            name="IBAN Certificate"
            endAdornment={true}
            InputProps={{
              endAdornment: (
                <>
                  <img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadClick} />
                  <MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
                </>
              ),
              startAdornment: (
                <>
                  <H5Text color='purple' size='16' lineHeight='19.2' marginTop='0'>
                    SA&nbsp;|
                  </H5Text>
                  &nbsp;
                </>
              )
            }}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}

export default DetailRequireModal;