import { takeLatest, put } from "redux-saga/effects";
import {
	NAFATH_POLLING,
	NAFATH_POLLING_SUCCESS,
	NAFATH_POLLING_ERROR
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodParam } from "utils/services/apis";
import { ClearTokenRedirectLogin } from "utils/general";
import { unsetClient } from "store/token/action";


function* userNafathPollingApi(action) {
	try {
		const response = yield getMethodParam(url.nafathPollingApi, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: NAFATH_POLLING_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction(response);
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: NAFATH_POLLING_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: NAFATH_POLLING_ERROR, payload: error });
	}
};

function* nafathPollSaga() {
	yield takeLatest(NAFATH_POLLING, userNafathPollingApi);
};

export default nafathPollSaga;
