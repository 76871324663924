import React, { useContext } from "react";
import { Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import ButtonInput from 'components/Input/ButtonInput';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Box, Skeleton } from "@mui/material";
import AutoFinanceIcon from "assets/images/Card/auto_finance.png";
import { LocaleContext } from 'routes/Router';
import moment from "moment";

const CardWrraper = styled.div`
    margin-top:24px;
    box-shadow: 0px 4px 4px 0px #3C105314;
    border-radius:20px;
    .card_image{
        height:53px;
        width:53px;
    };

    .isoDotShow{
        display: inline-block;
        margin-left: -18px;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin-top: 35px;
        border:2px solid white;
    };
`

const CardApplication = (props) => {
    const { locale } = useContext(LocaleContext);
    return (
        <Row xs={1} md={3}>
            {!props?.loader ?
                <>
                    {props?.arr?.length > 0 && props?.arr.map((key, idx) => (
                        <Col key={idx} xs={12} md={6} lg={6} xl={6} xxl={4}>
                            <CardWrraper>
                                <Card style={{ borderRadius: '20px' }}>
                                    <Card.Body style={{ padding: '16px 20px' }}>
                                        <Grid container justifyContent="center" alignItems="flex-start">
                                            <Grid item xs={8} md={8}>
                                                <Grid container spacing={2}>
                                                    <Grid item style={{ display: 'flex' }}>
                                                        <Card.Img variant="top" src={AutoFinanceIcon} className='card_image' />
                                                        <Grid container className="isoDotShow" style={{ backgroundColor: "#1D8841", position: locale === 'ar' && 'relative', left: '15px' }} />
                                                    </Grid>
                                                    <Grid item >
                                                        <H5Text marginTop="0px" fontFamily='Karbon_medium'>
                                                            {key?.activityName}
                                                        </H5Text>
                                                        <Text size="16" marginTop="0px" color="lightDark">
                                                            {key?.applicationNo}
                                                        </Text>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={4} md={4} justifyContent="flex-end" >
                                                <Text size="16" marginTop="0px" display='inline-block' color="lightGrey">
                                                    {moment(key?.introductionDATETIME).format("Do MMM YYYY")}
                                                </Text>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} style={{ display: "flex", flexWrap: "wrap", marginTop: "0px" }}>
                                            <Grid item xs={6} >
                                                <Text size="16" marginTop="0px" color="lightGrey">
                                                    <FormattedMessage id="totalAmount" />
                                                </Text>
                                                <Text size="16" marginTop="0px" color="lightPrimary" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} >
                                                    {key?.financeAmount} <FormattedMessage id="sar" />
                                                </Text>
                                            </Grid>

                                            {/* <Grid item >
                                                <Text size="16" marginTop="0px" color="lightGrey">
                                                    <FormattedMessage id="Tenure" />
                                                </Text>
                                                <Text size="16" marginTop="0px" color="lightPrimary" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} >
                                                    {"60"} <FormattedMessage id="months" />
                                                    { } <FormattedMessage id="months" />
                                                </Text>
                                            </Grid> */}

                                            <Grid item xs={6} style={{ alignItems: "end", display: "flex", justifyContent: "end" }}>
                                                <ButtonInput
                                                    title={<FormattedMessage id="viewDetails" />}
                                                    variant="text"
                                                    bgColor="transparent"
                                                    textColor="purple"
                                                    borderRadius="130"
                                                    borderColor="lightPink"
                                                    TextTransform="capitalize"
                                                    fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} 
                                                    size="18"
                                                    minWidth="130"
                                                    padding='0'
                                                    height="40"
                                                    onClick={() => props.onClick(key?.loanNo)}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* <Grid container style={{ display: "flex", flexWrap: "wrap" }}>
                                            <Grid item xs={6} style={{ marginTop: "32px" }}>
                                                <Text size="16" marginTop="0px" color="lightGrey">
                                                    <FormattedMessage id="monthlyPayment" />
                                                </Text>
                                                <H5Text size="24" color="green" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}  marginTop={0}>
                                                    { } <FormattedMessage id="sar" />
                                                </H5Text>
                                            </Grid>

                                            <Grid item xs={6} style={{ marginTop: "32px", alignItems: "end", display: "flex", justifyContent: "end" }}>
                                                <ButtonInput
                                                    title={<FormattedMessage id="viewDetails" />}
                                                    variant="text"
                                                    bgColor="transparent"
                                                    textColor="purple"
                                                    borderRadius="130"
                                                    borderColor="lightPink"
                                                    TextTransform="capitalize"
                                                    fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} 
                                                    size="18"
                                                    minWidth="130"
                                                    padding='0'
                                                    height="40"
                                                    onClick={() => props.onClick(key?.loanNo)}
                                                />
                                            </Grid>
                                        </Grid> */}

                                    </Card.Body>
                                </Card>
                            </CardWrraper>
                        </Col>
                    ))}
                </>
                :
                <>
                    {Array.from({ length: 2 }).map((key, idx) => (
                        <Col key={idx} xs={12} md={6} lg={6} xl={6} xxl={4}>
                            <CardWrraper>
                                <Card style={{ borderRadius: '20px' }}>
                                    <Card.Body style={{ padding: '16px 20px' }}>
                                        <Grid container justifyContent="center" alignItems="flex-start">
                                            <Grid item xs={8} md={8}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} display="flex" >
                                                        <Skeleton animation="wave" variant="rectangular" width={60} height={50} style={{ borderRadius: "10px" }} />
                                                        <Box width="100%" marginLeft={props.locale == 'en' && "20px"} marginRight={props.locale == 'ar' && "20px"}>
                                                            <Skeleton animation="wave" height={20} width="60%" />
                                                            <Skeleton animation="wave" height={20} width="60%" />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={4} md={4} justifyContent="flex-end" >
                                                <Skeleton animation="wave" height={20} width="40%" />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} style={{ display: "flex", flexWrap: "wrap", marginTop: "0px" }}>
                                            <Grid item xs={4}>
                                                <Skeleton animation="wave" height={20} width="60%" />
                                                <Skeleton animation="wave" height={20} width="60%" />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Skeleton animation="wave" height={20} width="60%" />
                                                <Skeleton animation="wave" height={20} width="60%" />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Skeleton animation="wave" height={20} width="60%" />
                                                <Skeleton animation="wave" height={20} width="60%" />
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ display: "flex", flexWrap: "wrap" }}>
                                            <Grid item xs={6} style={{ marginTop: "32px" }}>
                                                <Skeleton animation="wave" height={20} width="60%" />
                                                <Skeleton animation="wave" height={20} width="60%" />
                                            </Grid>

                                            <Grid item xs={6} style={{ marginTop: "32px", alignItems: "end", display: "flex", justifyContent: "end" }}>
                                                <Skeleton variant="rectangular" animation="wave" height={30} width="60%" style={{ borderRadius: "130px" }} />
                                            </Grid>
                                        </Grid>

                                    </Card.Body>
                                </Card>
                            </CardWrraper>
                        </Col>
                    ))}
                </>
            }
        </Row>
    );
};

export default CardApplication;
