import axios from "axios";
import url from "utils/locale/env.json";
import LocalStorageManager from 'managers/LocalStorageManger';
import SessionStorageManager from "managers/SessionStorageManager";
import { getDeviceName, getDeviceVersion, getOSType, getDeviceId, getDeviceIMEI } from "utils/general";

//////BaseUrl////////////////////////////////////
var baseUrl = "";
if (process.env.NODE_ENV === 'production') {
   baseUrl = url.BASE_URL_PRODUCTION;
} else if (process.env.NODE_ENV === 'staging') {
   baseUrl = url.BASE_URL_STAGING;
} else {
   baseUrl = url.BASE_URL_DEVELOPMENT;
}
console.log("baseUrl :", baseUrl, "NODE_ENV :", process.env.NODE_ENV);
//////BaseUrl////////////////////////////////////

///////////token/////////////////////////////////
const getToken = () => {
   const token = LocalStorageManager.getItem("token");
   return token;
}

const getRefreshToken = () => {
   const token = LocalStorageManager.getItem("RefreshToken");
   return token;
};

///////////token/////////////////////////////////

///////////token/////////////////////////////////
const getLocale = () => {
   const locale = LocalStorageManager.getItem("locale");
   return locale;
}
///////////token/////////////////////////////////

////////////FcmToken/////////////////////////////
const getFcmToken = () => {
   let token = SessionStorageManager.getItem('fcmToken');
   return token;
};
////////////FcmToken/////////////////////////////

//////////////Hmac Key///////////////////////////
var CryptoJS = require("crypto-js");
const hmacConvert = (method, data) => {
   var string = `${url.hmacKey}-${method}-${data !== "" ? JSON.stringify(data) : ""}`;
   var hash = CryptoJS.HmacSHA256(string, url.hmacKey);
   var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
   return hashInBase64;
};
//////////////Hmac Key///////////////////////////

export const getMethod = async (url) => {
   let hKey = await hmacConvert("GET", "");
   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "Signing-key": hKey,
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const getMethodWithToken = async (url) => {
   let hKey = await hmacConvert("GET", "");
   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey,
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const getMethodProfileWithToken = async (url) => {
   let hKey = await hmacConvert("GET", "");
   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getRefreshToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey,
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postMethodWithToken = async (url, body) => {
   let hKey = await hmacConvert("POST", body);
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey,
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postMethodChangePasswordWithToken = async (url, body) => {
   let hKey = await hmacConvert("POST", body);
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getRefreshToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey,
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const getMethodParam = async (url, body) => {
   const queryString = new URLSearchParams(body).toString();
   let hKey = await hmacConvert("GET", "");

   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url + '?' + queryString,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "Signing-key": hKey,
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const getMethodParamWithToken = async (url, body) => {
   const queryString = new URLSearchParams(body).toString();
   let hKey = await hmacConvert("GET", "");

   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url + '?' + queryString,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const getMethodParamSlashWithToken = async (url, body) => {
   let hKey = await hmacConvert("GET", "");
   try {
      let data = await axios({
         method: "GET",
         url: baseUrl + url + body,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const postMethod = async (url, body) => {
   let hKey = await hmacConvert("POST", body);
   try {
      let data = await axios({
         method: "POST",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "Signing-key": hKey,
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const putMethodWithToken = async (url, body) => {
   let hKey = await hmacConvert("PUT", body);
   try {
      let data = await axios({
         method: "PUT",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const putMethod = async (url, body) => {
   let hKey = await hmacConvert("PUT", body);
   try {
      let data = await axios({
         method: "PUT",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "Signing-key": hKey,
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const patchMethod = async (url, body) => {
   let hKey = await hmacConvert("PATCH", body);
   try {
      let data = await axios({
         method: "PATCH",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const patchMethodToken = async (url, body) => {
   let hKey = await hmacConvert("PATCH", body);
   try {
      let data = await axios({
         method: "PATCH",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const patchMethodSimahToken = async (url, body) => {
   let hKey = await hmacConvert("PATCH", body);
   try {
      let data = await axios({
         method: "PATCH",
         url: baseUrl + url,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            "X-Custom-Authorization": "token " + getRefreshToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};

export const deleteMethod = async (url, body) => {
   const queryString = new URLSearchParams(body).toString();
   let hKey = await hmacConvert("DELETE", body);

   try {
      let data = await axios({
         method: "DELETE",
         url: baseUrl + url + '?' + queryString,
         headers: {
            "Accept": "application/json",
            "Device-Id": getDeviceId(),
            "Device-Name": getDeviceName(),
            "Device-Type": getDeviceName(),
            "Device-Version": getDeviceVersion(),
            "Fcm-Token": getFcmToken(),
            "Language": getLocale(),
            "OS-Type": getOSType(),
            "Device-IMEI": getDeviceIMEI(),
            // "X-Custom-Authorization": "token " + getToken(),
            Authorization: "API-Key 0abd0df55eb02d47bf2d3e301d3310c4d084e10b",
            "Signing-key": hKey
         },
         data: body,
      });

      return data;
   } catch (error) {
      return error.response;
   }
};
