import React, { useState, useContext } from "react";
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import MuiCards from "components/MuiCards";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import StepperHorizontalWithTitle from "components/StepperHorizontalWithTitle";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';
import PersonalWorkForm from "features/CashFinancing/PersonalWorkForm";
import ExpensesBankForm from "features/CashFinancing/ExpensesBankForm";
import RevisedFinancingForm from "features/CashFinancing/RevisedFinancingForm";
import AttachApplyForm from "features/CashFinancing/AttachApplyForm";
import OrderSummary from "features/CashFinancing/OrderSummary";
import InitialDataForm from "features/CashFinancing/InitialDataForm";
import CertificateStatement from "features/CashFinancing/CertificateStatement";
import FinancingResultsData from "features/CashFinancing/FinancingResults";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import HeaderBanner from "features/CashFinancing/HeaderBanner";

export const WrapperBox = styled(Box)`
    display: "flex";
    background-color: ${(props) => props.theme.lightSecondary}
`;

const Financing = () => {
	const [curStep, setCurStep] = useState(0);
	const { locale } = useContext(LocaleContext);

	const handleCurStep = (value) => {
		setCurStep(value);
	}

	const steps = [
		<FormattedMessage id="cashFinance.generalInformation" />,
		<FormattedMessage id="cashFinance.expenseDetails" />,
		<FormattedMessage id="cashFinance.workBankDetails" />,
	];
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="home" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="applyFinance" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="onlineFinancing" />,
			Link: "",
			isDisbale: true,
		},
	])
	return (
		<WrapperBox>
			<Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} option={true}/>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12} md={4} display='flex' alignItems={'end'}>
					<Box margin='0 0 12px 0'>
							<MuiBreadcrumbs size='20' list={breadcrumbsList} />
						</Box>
					</Grid>
					<Grid item xs={12} md={8} marginTop='40px'>
						<StepperHorizontalWithTitle steps={steps} curStep={curStep} />
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12} lg={4} xl={3}>
					
						<FinancingResultsData title="financingResults" bgColor={"white"} curStep={curStep} />
						{/* <ProductDetails bgColor={"white"} locale={locale} /> */}
					</Grid>
					<Grid item xs={12} lg={8} xl={9}>
						<MuiCards borderRadius="16" padding="0">
							<CardContent>
								{curStep === 1 ? (
									<ExpensesBankForm handleCurStep={handleCurStep} />
								) : curStep === 2 ? (
									<CertificateStatement handleCurStep={handleCurStep} />
								) : (
									<InitialDataForm handleCurStep={handleCurStep} />
								)}

							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>

				<Grid container style={{ marginTop: "15px" }}>
					<Grid item xs={12}>
					</Grid>
				</Grid>
			</Container>
		</WrapperBox>
	);

};

export default Financing;