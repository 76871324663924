import {
    YAKEEN_USER_INFO_NON_SAUDI,
    YAKEEN_USER_INFO_SAUDI
} from "./constant";

export const financingYakeenUserInfoCallAPI = (type, data, external = {}) => {
    switch (type) {

        case YAKEEN_USER_INFO_SAUDI:
            return {
                type: type,
                data: data,
                external: external
            }
        case YAKEEN_USER_INFO_NON_SAUDI:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};