import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import OtpInput from "components/Input/OtpInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckValidation from "./validation";
import CircularProgress from '@mui/material/CircularProgress';
import { authCallAPI } from "store/auth/action";
import { AUTH_INITIAL_STATE, AUTH_OTP_VERIFY, AUTH_API_ERROR_RESET } from "store/auth/constant";
import MuiLink from "components/MuiLink";
import { AUTH_NAFATH_AUTHENTICATION, AUTH_NAFATH_STATE } from "store/nafathAuth/constant";
import { nafathAuthCallAPI } from "store/nafathAuth/action";
import { authSendOtpCallAPI } from "store/auth/otpSend/action";
import { AUTH_SEND_OTP } from "store/auth/otpSend/constant";
import { useNavigate } from "react-router-dom";
import { otpVerifyCallAPI } from "store/auth/verifyOtp/action";
import { OTP_VERIFY, OTP_VERIFY_INITIAL_STATE } from "store/auth/verifyOtp/constant";
import { LocaleContext } from "routes/Router";

function Verification({ handleCurStep }) {
	const isMobile = window.innerWidth < 900;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [isError, setIsError] = useState(false);
	const [verifyOtp, setVerifyOtp] = useState('');
	const [resendCode, setResendCode] = useState(false);
	const [disableResendLink, setDisableResendLink] = useState(false);
	const [timerCount, setTimerCount] = useState("");
	const [minSecondsLeft, setMinSecondsLeft] = useState();
	const getLoginDetails = useSelector((state) => state.loginReducer?.data?.data);
	// const AuthError = useSelector((state) => (state.authReducer?.error?.data?.message || state.nafathAuthReducer?.error?.data?.message));
	// const AuthData = useSelector((state) => (state.authReducer && state.authReducer?.payload?.data?.data));
	// const loader = useSelector((state) => state.authReducer?.loading || state.nafathAuthReducer?.loading);
	const AuthSendOtpData = useSelector((state) => (state.authSendOtpReducer && state.authSendOtpReducer?.payload?.data?.data));
	const sendOtpLoader = useSelector((state) => state.authSendOtpReducer?.loading);
	const userLogin = useSelector((state) => (state?.userLoginReducer?.payload?.data));
	const verifyOtpLoader = useSelector((state) => (state?.otpVerifyReducer?.loading) || state?.nafathAuthReducer?.loading);
	const verifyOtpError = useSelector((state) => (state?.otpVerifyReducer?.error?.data?.message));
	const verifyOtpData = useSelector((state) => (state?.otpVerifyReducer?.payload?.data?.data));
	// const nafathError = useSelector(state => state?.nafathAuthReducer && state?.nafathAuthReducer?.error?.data?.message);

	useEffect(() => {
		dispatch(otpVerifyCallAPI(OTP_VERIFY_INITIAL_STATE, {}));
		setTimerCount(AuthSendOtpData?.resend_otp_time);
		let [hours, minutes, seconds] = AuthSendOtpData?.resend_otp_time?.split(":");
		let totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
		setMinSecondsLeft(totalSeconds)
		handleCountDownTimer();
	}, []);

	useEffect(() => {
		if (resendCode) {
			setTimeout(() => {
				countDowntiming();
			}, 1000)
		}
	}, [minSecondsLeft]);

	function pad2Digits(text) {
		let out = '00' + text;
		return out.substring(out.length - 2);
	};

	function countDowntiming() {
		let timing = pad2Digits(Math.floor(minSecondsLeft / 60)) + ':' + pad2Digits(minSecondsLeft % 60);
		setTimerCount(timing);
		if (minSecondsLeft > 0) {
			let timingLeft = minSecondsLeft - 1;
			setMinSecondsLeft(timingLeft);
			setDisableResendLink(true);
		} else {
			setDisableResendLink(false);
		}
	};

	const handleInputChange = (value) => {
		setVerifyOtp(value)
	};

	const getNafathCode = () => {
		let bodyValue = {};
		bodyValue.nationalId = getLoginDetails?.nationalId;
		bodyValue.service = "PersonalLoan";
		dispatch(nafathAuthCallAPI(AUTH_NAFATH_AUTHENTICATION, bodyValue, { handleStep: handleCurStep, navigate: navigate, step: 3 }));
	};

	const handleSubmit = () => {
		const error = CheckValidation(verifyOtp);
		setIsError(error);
		if (!error) {
			let bodyValue = {};
			bodyValue.nationalId = getLoginDetails?.nationalId;
			bodyValue.countryCode = getLoginDetails?.countryCode;
			bodyValue.contactNumber = getLoginDetails?.contactNumber;
			bodyValue.routingKey = getLoginDetails?.routingKey;
			bodyValue.otp = verifyOtp;
			// dispatch(otpVerifyCallAPI(OTP_VERIFY, bodyValue, { handleFunction: getNafathCode, navigate: navigate, run: true }));
			dispatch(otpVerifyCallAPI(OTP_VERIFY, bodyValue, { handleStep: handleCurStep, navigate: navigate, step: 3 }));
		}
	};

	const handleCountDownTimer = () => {
		let [hours, minutes, seconds] = AuthSendOtpData?.resend_otp_time?.split(":");
		let totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
		setMinSecondsLeft(totalSeconds);
		setResendCode(true);
		countDowntiming();
	};

	const ResendOTP = () => {
		let resetValue = {};
		dispatch(authCallAPI(AUTH_API_ERROR_RESET, resetValue));
		setVerifyOtp("");
		let bodyValue = {};
		bodyValue.nationalId = getLoginDetails?.nationalId;
		bodyValue.countryCode = getLoginDetails?.countryCode;
		bodyValue.contactNumber = getLoginDetails?.contactNumber;
		bodyValue.routingKey = getLoginDetails?.routingKey;
		dispatch(authSendOtpCallAPI(AUTH_SEND_OTP, bodyValue, { handleFunction: handleCountDownTimer, navigate: navigate, run: true, handleStep: handleCurStep, step: 2 }));
		dispatch(authCallAPI(AUTH_INITIAL_STATE))
		dispatch(nafathAuthCallAPI(AUTH_NAFATH_STATE, {}, { navigate: navigate }))
	};

	return (
		<Grid paddingRight={{ xs: '0', md: '40px' }}>
			<H1Text color="primary" size={isMobile ? "40" : "64"} lineHeight={isMobile ? "40" : "96"} marginBottom={isMobile ? "0" : "-25"}>
				<FormattedMessage id="enterVerificationCode" />
			</H1Text>

			<Text marginTop="0" size={isMobile ? "20" : "24"} color="lightDark" lineHeight="36">
				<FormattedMessage id="enterVerificationCode.sub_text" />&nbsp; *****{AuthSendOtpData?.contactNumber?.substring(5)}
			</Text>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid xs={12} sm={12} md={12} lg={8}>
					<OtpInput
						marginTop="30"
						TextFieldsProps={{ placeholder: 0 }}
						value={verifyOtp}
						validateChar={(val) => !isNaN(val)}
						length={6}
						justifyContent="start"
						onChange={handleInputChange}
						errorText={isError && <FormattedMessage id="enteredIncorrectOTP" />}
						gap={isMobile ? "6px" : "20px"}
					/>
				</Grid>

				<Grid item xs={12} md={12} >
					<div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
						<div style={{ display: "flex" }}>
							{resendCode ?
								<Text size="16" fontWeight="800" color="lightGrey" marginTop="0">
									<FormattedMessage id="resendCodeIn" /> <span style={{ color: disableResendLink ? "green" : "", width: "100px" }}>{timerCount == "aN:aN" ? "" : timerCount}&nbsp;</span>
								</Text>
								:
								<Text size="16" fontWeight="800" color="lightGrey" marginTop="0">
									<FormattedMessage id="DidNotGetTheCode" />
								</Text>
							}
							{sendOtpLoader ?
								<div style={{ marginLeft: "20px" }}>
									<CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />
								</div>
								:
								<MuiLink size="16" TextDecoration="underline" onClick={() => ResendOTP()} disabled={disableResendLink}>
									&nbsp;{timerCount != "aN:aN" ? <FormattedMessage id="resendCode" /> : ""}
								</MuiLink>
							}
						</div>
					</div>
				</Grid>

				<Grid item xs={12} lg={9} sx={{ marginTop: "30px" }}>
					<Grid container>
						<Grid item xs={12}>
							<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={isMobile ? <FormattedMessage id="goBack" /> : <FormattedMessage id="goBackToStep1" />}
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										minWidth="100px"
										marginRight="40"
										padding="0"
										onClick={() => handleCurStep(1)}
									/>
									<ButtonInput
										title={<FormattedMessage id="continue" />}
										variant="contained"
										bgColor={"yellow"}
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={verifyOtpLoader}
										endIcon={(verifyOtpLoader) ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> :  locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
										onClick={() => handleSubmit()}
									/>
								</Stack>
							</Box>
						</Grid>

						{!verifyOtpLoader &&
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
								{verifyOtpError ?
									<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
										{verifyOtpError}
									</Text>
									:
									<Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
										{verifyOtpData?.message}
									</Text>
								}
								{/* {nafathError &&
									<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
										{nafathError}
									</Text>
								} */}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Verification;