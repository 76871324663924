import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";
import { getRevisedFinancingApplicationApprovedURL } from "routes/AppUrls";
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const WaitingPayment = () => {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);

	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title:<FormattedMessage id="home"/>,
			Link: ""
		},
		{
			title:<FormattedMessage id="applyFinance"/>,
			Link: ""
		},
		{
			title:<FormattedMessage id="onlineFinancing"/>,
			Link: "",
			isDisbale: true,
		}
	])
	return (
		<div style={{  backgroundColor: '#F1EDF5', paddingBottom: "30px",minHeight:'100%' }}>
			<Grid margin={'1% 5% 1% 5%'} style={{ marginTop: '5px' }} disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} option={true}/>
					</Grid>
				</Grid>
				<Box margin='32px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box>
				<Grid container style={{ marginTop: "30px" }}>
					<Grid item xs={12}>
						<MuiCards borderRadius="16" height='600'>
							<CardContent>
								<Container>
									<Grid container>
										<Grid item xs={12}>
											<div style={{ textAlign: "center" }}>
												<AccessTimeFilledIcon sx={{ width: "130px", height: "130px", color: "#FF833E" }} />
											</div>
										</Grid>
									</Grid>
									<Grid container marginTop={'44px'}>
										<Grid item xs={12} display= 'flex' flexDirection= 'column' alignItems='center'>
											<Text color="black" size="40" lineHeight={'48'} marginTop='0' fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} TextAlign="center">
												<FormattedMessage id="waitingForPayment" />
											</Text>
											<Text color="lightBlack" size="24" fontWeight="400" TextAlign="center" marginTop="12" style={{maxWidth:'537px'}}>
												<FormattedMessage id="guestNoteExpiration" /> :
											</Text>
											<Text color="secondaryBlack" size="28" fontWeight="600" TextAlign="center" marginTop="17">
												0d : 4h : 23m
											</Text>
										</Grid>
									</Grid>

									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<div className="content" style={{ display: "flex", justifyContent: "end" }}>
												<Box component="form">
													<Stack spacing={2} direction="row">
														<ButtonInput
															title={<FormattedMessage id="cancel" />}
															variant="outlined"
															bgColor="transparent"
															borderColor="purple"
															textColor="purple"
															borderRadius="100"
															TextTransform="capitalize"
															onClick={() => navigate(-1)}
															sx={{marginRight: "15px !important"}}
														/>
														<ButtonInput
															title={<FormattedMessage id="ok"/>}
															variant="contained"
															bgColor="yellow"
															textColor="purple"
															borderRadius="100"
															TextTransform="capitalize"
															endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
															onClick={() => navigate(getRevisedFinancingApplicationApprovedURL(locale))}
														/>
													</Stack>
												</Box>
											</div>
										</Grid>
									</Grid>


								</Container>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

export default WaitingPayment;