import { takeLatest, put } from "redux-saga/effects";
import {
    FINANCE_GET_QUOTE_SERVICE,
    FINANCE_GET_QUOTE_SERVICE_SUCCESS,
    FINANCE_GET_QUOTE_SERVICE_ERROR
} from "./constant";
import url from "utils/services/urls.json";
import { postMethodWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";


function* getCarInsuranceQuoteService(action) {
    try {
        const response = yield postMethodWithToken(url.getQuoteServicesList, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: FINANCE_GET_QUOTE_SERVICE_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: FINANCE_GET_QUOTE_SERVICE_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: FINANCE_GET_QUOTE_SERVICE_ERROR, payload: error });
    }
};


function* financingGetQuoteServiceSaga() {
    yield takeLatest(FINANCE_GET_QUOTE_SERVICE, getCarInsuranceQuoteService);
};

export default financingGetQuoteServiceSaga;