import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ButtonInput from "components/Input/ButtonInput";
import { InputAdornment } from '@mui/material';
import DateTimePickerInput from 'components/Input/DateTimePickerInput';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	
`;

function PersonalWorkForm({ handleCurStep }) {
	const { locale } = useContext(LocaleContext);

	return (
		<WrapperDiv>
			<Grid container >
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'Work Information'} />
						<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span>Employment Type</span>
										<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Employment Type"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label="Employer Name"
									placeholder="Employer Name"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span>Occupation</span>
									</Box>}
									placeholder="Occupation"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<span>Employer Number <span>
										<span style={{ color: 'red' }}>*</span>
									</span>
									</span>}
									placeholder="Employer Number"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<DateTimePickerInput
									label={<FormattedMessage id="autoFinance.joiningDate" />} 
								/>
							</Grid>
							<Grid container sx={{ marginTop: "15px" }}>
								<Grid item xs={12}>
									<H5Text size='24' lineHeight='36' borderRadius="8" padding="8px 16px" color="lightDark">
										<FormattedMessage id={'Address'} />
									</H5Text>

									<Box component="form" sx={{ padding: "0 15px 15px 15px" }}>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<SelectInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span>Work City&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work City"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span>Work Address&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work Address"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span>Work Post Code&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work Post Code"
												/>
											</Grid>

										</Grid>

									</Box>
								</Grid>
							</Grid>



						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'Income Details'} />
						<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span>Income Certificate Type</span>
										<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Income Certificate Type"
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span> Monthly Income</span>
									</Box>}
									placeholder="0.00"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">SAR</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span> Contributary Wage </span>
									</Box>}
									placeholder="0.00"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">SAR</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span>Deduction Agency</span>
										<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Deduction Agency"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span>GOSI/Pension Amount</span>
									</Box>}
									placeholder="0.00"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">SAR</InputAdornment>
										)
									}}
								/>
							</Grid>
							

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span> Net Monthly Income</span>
									</Box>}
									placeholder="0.00"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">SAR</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(0)}
								/>
								<ButtonInput
									title={<FormattedMessage id="Save & Proceed " />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => handleCurStep(2)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default PersonalWorkForm;