import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getDashboardURL, getRevisedFinancingCongratulationsURL, getRevisedFinancingConsentFormURL, getRevisedFinancingWaitingPaymentURL } from 'routes/AppUrls';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import OtpInput from "components/Input/OtpInput";
import ButtonInput from "components/Input/ButtonInput";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { LocaleContext } from "routes/Router";
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";
import H5Text from "components/Text/H5Text";
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const InvoiceSuccess = ({ handleCurStep }) => {
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const [otp, setOtp] = useState("");
  const params = useParams();
  console.log(params.postId)
  return (
    <Box style={{ backgroundColor: '#F1EDF5', minHeight: '90vh' }}>
      <Grid margin={'1% 5% 1% 5%'} style={{ marginTop: '0px' }} disableGutters>
        <Grid container >
          <Grid item xs={12} marginTop={'40px'}>
            <MuiCards borderRadius="16" padding={{ sm: "0" }}>
              <CardContent style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px', minHeight: '75vh', height: 'fit-content' }}>
                <Grid width="100%" textAlign={'center'} display='flex' alignItems='center' flexDirection='column'>
                  <Container style={{ maxWidth: '689px' }}>
                    <Grid container textAlign={'center'}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <div style={{ textAlign: "center" }}>
                              <CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container display='flex' justifyContent='center'>

                          <Grid item xs={12} marginTop='32px'>
                            <Text color="black" size="40" fontWeight="800" lineHeight={'48px'} fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} TextAlign="center">
                              {params.postId == "Print_Invoices" && <FormattedMessage id="Invoice Generated" />}
                              {params.postId == "Account_Statement" && <FormattedMessage id="Account Statement Generated" />}
                              {params.postId == "Issue_Authorization_Letter" && <FormattedMessage id="Letter Generated" />}
                            </Text>
                            <Text size={'24'} lineHeight={'28.8'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='lightGrey' marginTop={'8'}>The Authorization letter has been generated</Text>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box marginTop={'30px'} backgroundColor='white' borderRadius={'8px 8px 0 0'} minHeight="244px" boxShadow='0px 3px 12px 0px #00000029'>
                            
                          </Box>
                          <MuiCards boxShadow={'0px 3px 12px 0px #00000029'} bgColor="pink" style={{borderRadius:"0 0 8px 8px"}} padding="10px 15px">
                            <Grid container paddingLeft='17px'>
                              <Grid item xs={6} display='flex' justifyContent='start' alignItems='center'>
                                <H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0">
                                  {params.postId == "Print_Invoices" && <FormattedMessage id="Download Invoice" />}
                                  {params.postId == "Account_Statement" && <FormattedMessage id="Download Account Statement" />}
                                  {params.postId == "Issue_Authorization_Letter" && <FormattedMessage id="Download Authorization Letter" />}
                                </H5Text>
                              </Grid>
                              <Grid item xs={6}>
                                <div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
                                  <SaveAltIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
                                </div>
                              </Grid>
                            </Grid>
                          </MuiCards>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>

                  <Grid container style={{ marginTop: "30px" }}>
                    <Grid item xs={12}>
                      <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                        <Grid container style={{ marginTop: "30px" }} >
                          <Grid item xs={12} marginRight={{ xs: "0", lg: "100px" }}>
                            <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                              <Box component="form">
                                <Stack spacing={2} direction="row">
                                  <ButtonInput
                                    title={<FormattedMessage id="done" />}
                                    variant="contained"
                                    bgColor="yellow"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => navigate(getDashboardURL(locale))}
                                  />
                                </Stack>
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </MuiCards>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

};

export default InvoiceSuccess;