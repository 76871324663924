const theme = {
	primary: '#3C1053',
	lightPrimary: "#6D4C7E",
	secondary: '#E6D9F5',
	lightSecondary: '#F1EDF5',

	purple: "#3F1956",
	lightPurple: "#ECE2F2",
	secondaryPurple: "#D6CAE3",
	primaryPurple: "#544B59",
	darkPurple: "#8924C6",
	disabledPurple: "#8B5BA5",

	checkboxPurple: "#D4BDE0",
	lightGrey: "#8F8D95",
	darkGrey: "#23272E",
	darkGreen:"#53AC70",
	lightBlue:"#456B7D",
	pinkLight:"#FEF4DB",
	darkPinkBrown:"#AD6700",

	black: "#000000",
	lightBlack: "#969696",
	secondaryBlack: "#636363",
	lightDark: "#3D3D4E",
	dark: "#1D1B20",
	orange: "#FF833E",
	darkPrimary: "#333333",
	darkSecondary: "#231F20",
	darkArrow: "#14303A",

	white: "#FFFFFF",
	lightWhite: "#F5F5F5",
	darkWhite: "#F9F9F9",
	primaryWhite: "#F2F1F6",
	green: "#1D8841",
	parrot: "#1DB510",
	lightParrot: "#DDFFDA",
	gray: "#B8B8B8",
	lightGray: "#909FA6",
	yellow: "#FECB26",
	darkBrown: "#9F2E35",
	darkGray: '#919191',
	light_Black_color:"#EFEFEF",
	lightYellow:"#FEEBAB",

	transparent: "transparent",
	black_secondary: '#051335',

	lightRed:"#EB5555",
	
	lightBlackBorder: "#AFAFAF",

	lightDarkSecondary: '#D1D6D8',

	lightPink: '#C7B7CF',
	pink: "#F4E1FF",
	darkPink: "#DFD3E7",
	secondaryPink: "#FEF7FF",

	KarbonBold: "Karbon_bold",
	KarbonSemiBold: "Karbon_semibold",
	KarbonMedium: "Karbon_medium",
	KarbonLight: "Karbon_light",
	
	LumaBold: "Luma_bold",
	LumaSemiBold: "Luma_semibold",
	LumaMedium: "Luma_medium",
	LumaLight: "Luma_light",

	footerGrayText: "#717173",

	errorColor: "#9F2E35",
	sucessColor:"#1D8841",

	selectMenuItemBg: "#F4F1F6",

	checkboxColor: "#8c5ba5"
};

export default theme;