import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import MuiBreadcrumbs from 'components/MuiBreadcrumbs';
import H1Text from 'components/Text/H1Text';
import MuiCards from 'components/MuiCards';
import Text from 'components/Text';
import SpanText from 'components/Text/SpanText';
import SubText from 'components/Text/Sub';
import AnimatedImage from 'components/AnimatedImage';
import IconWithText from 'components/IconWithText';
import ColorBox from 'components/ColorBox';
import ButtonInput from "components/Input/ButtonInput";
import SelectInput from "components/Input/SelectInput";
import { LocaleContext } from 'routes/Router';
import CarImage from 'assets/images/demoCar.png';
import BrandImage from "assets/images/Application/Monogram.png";
import CardList from 'features/Vehicle/CardList';
import H5Text from 'components/Text/H5Text';
import styled from 'styled-components';
import { getDashboardURL, getFindVehicleURL } from 'routes/AppUrls';

const ContainerStyle = styled.div`
@media screen and (max-width:1200px){
	.responsiveText{
		font-size:18px !important;
	}
}
@media screen and (max-width:778px){
	.responsiveText{
		font-size:12px !important;
	}
}
`
const VehicleComparison = () => {
	const [addClick, setAddClick] = useState(false);
	const { locale } = useContext(LocaleContext);
	const [isGridView, setIsGridView] = useState(true);
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="dashboard" />,
			Link: getDashboardURL(locale)
		},
		{
			title: <FormattedMessage id="vehicleList" />,
			Link: getFindVehicleURL(locale)
		},
		{
			title: <FormattedMessage id="vehicleComparison.compare" />,
			Link: "",
			isDisbale: true,
		}
	]);
	const handleAddClick = () => {
		setAddClick(!addClick);
	}

	const arr = [1, 2, 3]
	return (
		<ContainerStyle style={{ display: 'flex', backgroundColor: '#F1EDF5', padding: "30px 0px" }}>
			<Grid margin={{ xs: '0', sm: '0% 7%' }} style={{ marginTop: '5px' }} disableGutters >
				<Grid container>
					<Grid item xs={12}>
						<MuiBreadcrumbs
							list={breadcrumbsList}
						/>
					</Grid>
					<Grid item xs={12}>
						<H1Text color="primary" size="40" lineHeight="60">
							<FormattedMessage id="vehicleComparison.heading" />
						</H1Text>
					</Grid>
				</Grid>

				<Grid container  >
					<Grid item xs={12} >
						<MuiCards borderRadius="16" padding="0px 0px" margin="0px 0px 0px 0px">
							<Grid container>
								<Grid item xs={3} display={{ xs: 'none', lg: 'block' }}>

									<Grid container sx={{ padding: "15px 15px 173px", border: "1px solid #F5F5F5", height: '484px' }}>
										<Grid item xs={12} sx={{ margin: "15px 15px 50px" }}>
											<Text color="purple" size="32" lineHeight="48" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
												<FormattedMessage id="vehicleComparison.calculatorResults" />
											</Text>
										</Grid>

										<Grid item xs={12} sx={{ margin: "15px 15px 5px" }}>
											<RemoveIcon fontSize="large" sx={{ color: "#3F1956", fontSize: "64px" }} />
											<CloseIcon fontSize="large" sx={{ color: "#3F1956", fontSize: "64px" }} />
										</Grid>
										<Grid item xs={12} sx={{ margin: "0px 15px" }}>
											<AddIcon fontSize="large" sx={{ color: "#3F1956", fontSize: "64px" }} />
											<DragHandleIcon fontSize="large" sx={{ color: "#3F1956", fontSize: "64px" }} />
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												<FormattedMessage id="monthlyPayment" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												<FormattedMessage id="sellingPrice" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												<FormattedMessage id="sellingPriceVAT" />
												<InfoOutlinedIcon sx={{ marginLeft: "10px", color: "#3F1956", fontSize: "24px" }} />
												<SpanText color="purple" size="24" lineHeight="36" fontWeight="800" padding="0px 10px">
													15%
												</SpanText>
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												<FormattedMessage id="downPayment" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												<FormattedMessage id="financeAmountPricet" />
												<InfoOutlinedIcon sx={{ marginLeft: "10px", color: "#3F1956", fontSize: "24px" }} />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												<FormattedMessage id="period" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text marginTop="0" size="24" lineHeight="24" color="dark" padding="25px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">

												<FormattedMessage id="residualAmount" />
												<SpanText color="purple" size="24" lineHeight="36" fontWeight="800" padding="0px 10px">
													35%
												</SpanText>
											</Text>
										</Grid>
									</Grid>
								</Grid>
								{arr.map((data) => (
									<Grid item xs={arr.length == 2?6:4} lg={arr.length == 1 ? 6 : 3} >
										{/* <CardList locale={locale} viewFlag={isGridView}   /> */}
										<Grid container sx={{ padding: "15px", border: "1px solid #F5F5F5" }} height={{ xs: "fit-content", lg: '484px' }}>
											<Grid item xs={12} sx={{ margin: "15px", maxHeight: '200px' }}>
												<AnimatedImage src={CarImage} minHeight="100%" />
											</Grid>
											<Grid item xs={12} display={{ xs: 'none', lg: 'block' }}>
												<Grid container sx={{ backgroundColor: "#FEF7FF", padding: "15px" }}>
													<Grid item xs={12} >
														<IconWithText
															text={"Toyota COROLLA"}
															secondText={"2024 1.5 XLI EXE 4X2 1.5 PETROL SEDAN 4Dr CVT"}
															textSize={20}
															textLineHeight={30}
															textColor="lightDark"
															secondTextSize={16}
															secondLineHeight={22}
															secondTextColor="lightDark"
															BrandIcon={BrandImage}
															variant={'rounded'}
															BrandSize={40}
															bgColor="secondaryPink"
														/>
													</Grid>
													<Grid item xs={12}>
														<Grid container>
															<Grid item xs={6} sx={{ display: "inline-grid", justifyContent: "start" }}>
																<Text size={16} color="lightDark" lineHeight={16}>
																	Available at
																</Text>
																<Text size={32} color="lightDark" lineHeight={32} fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
																	2,099
																	<SpanText style={{ marginLeft: "5px" }} size={20} color="lightDark" lineHeight={20}>SAR</SpanText>
																</Text>
															</Grid>
															<Grid item xs={6} sx={{ display: "inline-grid", justifyContent: "end" }}>
																<Text size={16} color="lightDark" lineHeight={16}>
																	Colors
																</Text>

																<div style={{ display: "flex" }}>
																	<ColorBox width={28} color={'#3C1053'} />
																	<ColorBox width={28} color={'#3F1956'} />
																	<Text size={16} lineHeight="16" color="black">
																		+4
																	</Text>
																</div>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
															<ButtonInput
																title={<FormattedMessage id="Apply Now" />}
																variant="contained"
																bgColor="yellow"
																textColor="darkPrimary"
																borderRadius="100"
																TextTransform="capitalize"
																size={"14"}
																padding={"10px 16px"}
																minWidth={"140"}
																height={"40"}
															/>
														</Box>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} display={{ xs: 'block', lg: 'none' }} textAlign='center'>
												<Text color='lightDark' size={'12'} fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>Toyota COROLLA</Text>
											</Grid>
										</Grid>
										<Grid container >
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="monthlyPayment" />
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													1,845.00
													<SubText color="lightGrey" className="responsiveText"  size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														SAR
													</SubText>
												</Text>
											</Grid>
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="sellingPrice" />
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													4,845.00
													<SubText color="lightGrey" className="responsiveText" size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														SAR
													</SubText>
												</Text>
											</Grid>
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="sellingPriceVAT" />
														<InfoOutlinedIcon sx={{ marginLeft: "10px", color: "#3F1956", fontSize: "24px" }} className="responsiveText"/>
														<SpanText color="purple" size="24" lineHeight="36" fontWeight="800" padding="0px 10px" className="responsiveText">
															15%
														</SpanText>
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													654.00
													<SubText color="lightGrey" className="responsiveText" size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														SAR
													</SubText>
												</Text>
											</Grid>
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="downPayment" />
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													0.00
													<SubText color="lightGrey" className="responsiveText" size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														SAR
													</SubText>
												</Text>
											</Grid>
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="financeAmountPricet" />
														<InfoOutlinedIcon sx={{ marginLeft: "10px", color: "#3F1956", fontSize: "24px" }} className="responsiveText"/>
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													0.00
													<SubText color="lightGrey" className="responsiveText" size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														SAR
													</SubText>
												</Text>
											</Grid>
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="period" />
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													60
													<SpanText marginTop="0" className="responsiveText" color="lightGrey" size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														Months
													</SpanText>
												</Text>
											</Grid>
											<Grid item xs={12}>
												<Box textAlign={'center'} display={{ xs: 'block', lg: 'none' }}>
													<Text marginTop="0" size="20" lineHeight="24" color="dark" padding="8px" className="responsiveText" >
														<FormattedMessage id="residualAmount" />
														<SpanText color="purple" size="24" lineHeight="36" fontWeight="800" padding="0px 10px" className="responsiveText">
															35%
														</SpanText>
													</Text>
												</Box>
												<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
													1,845.00
													<SubText color="lightGrey" className="responsiveText" size="20" lineHeight="30" fontWeight="700" padding="0px 10px">
														SAR
													</SubText>
												</Text>
											</Grid>
										</Grid>
									</Grid>
								))}
								{
									arr.length <= 2&&
									<Grid item xs={arr.length == 2?12:4} lg={3}>
									<Grid container sx={{ backgroundColor: "#FCFAFF", padding: "15px", border: "1px solid #F5F5F5", height: '484px' }}>
										{addClick ? (
											<>
												<Grid item xs={12}>
													<SelectInput
														label="Brand"
														placeholder="Brand"
														height="44"
														inputPadding="8px 14px"
													/>
													<SelectInput
														label="Product"
														placeholder="Product"
														height="44"
														inputPadding="8px 14px"
													/>
													<SelectInput
														label="Year"
														placeholder="Year"
														height="44"
														inputPadding="8px 14px"
													/>
													<SelectInput
														label="Vehicle Model"
														placeholder="Vehicle Model"
														height="44"
														inputPadding="8px 14px"
													/>
												</Grid>

												<Grid item xs={12}>
													<Box component="form" sx={{ display: "flex", justifyContent: "end", marginTop: "32px" }}>
														<ButtonInput
															title={<FormattedMessage id="Apply" />}
															variant="contained"
															bgColor="yellow"
															textColor="darkPrimary"
															borderRadius="100"
															TextTransform="capitalize"
															size={"14"}
															padding={"10px 16px"}
															minWidth={"140"}
															height={"40"}
															onClick={handleAddClick}
														/>
													</Box>
												</Grid>
											</>
										) : (
											<Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "430px" }}>
												<Text style={{ cursor: "pointer" }} size="24" lineHeight="24" fontWeight="700" color="purple" onClick={handleAddClick} className="responsiveText">
													<AddIcon sx={{ color: "#3F1956", fontSize: "32px" }} /><FormattedMessage id="vehicleComparison.addAnotherCar" />
												</Text>
											</Grid>
										)}
									</Grid>

									<Grid container>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text className="responsiveText" TextAlign="center" fontWeight="800" marginTop="0" size="32" lineHeight="32" color="lightPrimary" padding="21px" borderColor="lightWhite" borderWidth="1px 1px 1px 0px">
												-
											</Text>
										</Grid>
									</Grid>
								</Grid>
								}
								
							</Grid>
						</MuiCards>
					</Grid>
				</Grid>
			</Grid>
		</ContainerStyle>
	)
}

export default VehicleComparison;