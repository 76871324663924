import {
    MANUAL_DBR,
    MANUAL_DBR_ERROR,
    MANUAL_DBR_SUCCESS
} from "./constant";


const manualDBRReducer = (state = {}, action) => {
    switch (action.type) {
        case MANUAL_DBR:
            return { ...state, loading: true, error: '' };
        case MANUAL_DBR_ERROR:
            return { ...state, type: MANUAL_DBR_ERROR, loading: false, error: action.payload };
        case MANUAL_DBR_SUCCESS:
            return { ...state, type: MANUAL_DBR_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default manualDBRReducer;