import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import H5Text from "components/Text/H5Text";
import { useSelector } from "react-redux";

import { LocaleContext } from 'routes/Router';

const ApplicationApproved = () => {
	const { locale } = useContext(LocaleContext);

	return (
		<div style={{ display: 'flex', backgroundColor: '#F1EDF5', paddingBottom: "30px", height: "90vh" }}>
			<Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
				<Grid container style={{ marginTop: "30px" }}>
					<Grid item xs={12}>
						<MuiCards borderRadius="16" padding={"6%"}>
							<CardContent>
								<Container  style={{maxWidth:'689px'}}>
									<Grid container>
										<Grid item xs={12}>
											<div style={{ textAlign: "center" }}>
												<CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />
											</div>
										</Grid>
									</Grid>

									<Grid container textAlign={'center'}>
										<Grid item xs={12}>
											<Text color="black" size="40" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} TextAlign="center">
												<FormattedMessage id="vehicleDelivered" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text color="secondaryBlack" size="24" fontWeight="800" TextAlign="center">
												<FormattedMessage id="congratulationGettingNewCar" />!
											</Text>
										</Grid>

										<Grid item xs={12} marginTop='50px'>
										<H5Text color="gray" size="24" fontFamily={locale=="en"?'Karbon_light':"Luma_light"} TextAlign="center"  >
												<FormattedMessage id="findSomeUsefulServicesDocuments" />...
											</H5Text>
										</Grid>
									</Grid>

									<Grid container marginTop='18px'>
										<Grid item xs={12}>
											<MuiCards bgColor="pink" borderRadius="8" boxShadow={false} padding="10px 15px" margin="30px 0 0 0">
												<Grid container paddingLeft='17px'>
													<Grid item xs={6} display='flex' justifyContent='start' alignItems='center'>
														<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0">
															<FormattedMessage id="downloadYourQuotation" />
														</H5Text>
													</Grid>
													<Grid item xs={6}>
														<div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
															<SaveAltIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
														</div>
													</Grid>
												</Grid>
											</MuiCards>
										</Grid>
										<Grid item xs={12}>
											<MuiCards bgColor="pink" borderRadius="8" boxShadow={false} padding="10px 15px" margin="30px 0 0 0">
												<Grid container paddingLeft='17px'>
													<Grid item xs={6} display='flex' justifyContent='start' alignItems='center'>
														<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0">
														<FormattedMessage id="eServices" />
														</H5Text>
													</Grid>
													<Grid item xs={6}>
														<div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
														<OpenInNewIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
														</div>
													</Grid>
												</Grid>
											</MuiCards>
										</Grid>
										
									</Grid>
								</Container>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Container>
		</div >
	)
}

export default ApplicationApproved;
