import {
    CAR_GOSI,
    CAR_PENSION,
    FINANCING_SUCCESS,
    FINANCING_ERROR
} from "./constant";


const financingReducer = (state = {}, action) => {
    switch (action.type) {
        case CAR_GOSI:
            return { ...state, loading: true, error: '' };
        case CAR_PENSION:
            return { ...state, loading: true, error: '' };
        case FINANCING_ERROR:
            return { ...state, type: FINANCING_ERROR, loading: false, error: action.payload };
        case FINANCING_SUCCESS:
            return { ...state, type: FINANCING_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default financingReducer;