import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import CrossIcon from "assets/icons/cancel_button.png";
import { Emailverification, PhoneNumberValidation } from 'utils/general';
import MaskInput from 'components/Input/MaskInput';
import { LocaleContext } from 'routes/Router';
import CheckboxInput from 'components/Input/CheckboxInput';
import SideBySideText from 'components/SideBySideText';
import H5Text from 'components/Text/H5Text';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    // maxWidth: 654,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
};

function ModalWithInput({ maxWidth, email, modalActive, handleCloseModal, title, handleEmailUpdate, handleContactUpdate, loader, error }) {
    const [value, setValue] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isSameEmail, setIsSameEmail] = useState(false);
    const [simpleNumber, setSimpleNumber] = useState('');
    const [number, setNumber] = useState(null);
    const { locale } = useContext(LocaleContext);
    useEffect(() => {
        setValue("");
        setIsValid(false);
    }, [modalActive]);

    const handleMobileChange = (e, value) => {
        // console.log(e.target.value.replace(/[\s+966]+/g, ''));
        // setSimpleNumber(e.target.value.replace(/[\s+966]+/g, ''));
        if (value == 'newNumber') {
            if (PhoneNumberValidation(e.target.value?.split(' ')?.join(''))) {
                setValue(e.target.value?.split(' ')?.join(''));
                setIsValid(true);
            } else {
                setValue(e.target.value);
                setIsValid(false);
            }
        }
        if (value == 'communicationNumber') {
            setNumber(e.target.value?.split(' ')?.join(''));
        }

    };

    const handleEmailChange = (e) => {
        setValue(e.target.value);
        if (Emailverification(e.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    const handlePostData = () => {
        if (title === "Edit Email") {
            if (isValid) {
                if (email == value) {
                    setIsSameEmail(true)
                } else {
                    setIsSameEmail(false)
                    handleEmailUpdate(value);
                }
            }
        }
        if (title === "Edit Mobile No.") {
            if (isValid) {
                handleContactUpdate(value);
            }
        }
        if (title === "Edit Address") {
            if (isValid) {
                handleContactUpdate(value);
            }
        }
    };
    const [checked, setChecked] = useState(false)
    const copyNumber = (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            setNumber(value)
            setChecked(true)
        } else {
            setNumber(null)
            setChecked(false)
        }
    }
    return (
        <Modal
            open={modalActive}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style} maxWidth={maxWidth ? maxWidth : 654}>
                <div className="header">
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Text size="40" lineHeight={"40"} marginTop="0" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} >
                                <FormattedMessage id={title} />
                            </Text>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "end" }}>
                            <img src={CrossIcon} alt='icon' style={{ cursor: "pointer" }} onClick={() => handleCloseModal(false)} />
                        </Grid>
                    </Grid>
                </div>

                <div className="content" style={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        {
                            title === "Edit Email" &&
                            <Grid item xs={12}>
                                <LabelWithInput
                                    label="Existing Email ID"
                                    placeholder="Enter Email"
                                    onChange={(e) => handleEmailChange(e)}
                                    value={email}
                                    disabled
                                />
                                <LabelWithInput
                                    label="New Email ID"
                                    placeholder="Enter New Email"
                                    onChange={(e) => handleEmailChange(e)}
                                    errorText={isSameEmail && "New Email Should be different"}
                                />
                            </Grid>}
                        {title === "Edit Mobile No." &&
                            <>
                                <Grid item xs={12} md={12} lg={12}>
                                    <MaskInput
                                        mask="+\966 999 999 999"
                                        label='New Mobile No.'
                                        disabled={false}
                                        maskChar=" "
                                        placeholder="eg: 978-9854-4666"
                                        name="contactNumber"
                                        onChange={(e) => handleMobileChange(e, "newNumber")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} marginTop='4px'>
                                    <CheckboxInput
                                        label={<FormattedMessage id="Mark this as communication number" />}
                                        size="18"
                                        fontWeight="600"
                                        onChange={copyNumber}
                                        checked={checked}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} paddingTop='0 !important'>
                                    <MaskInput

                                        mask="+\966 999 999 999"
                                        label='Communication no.'
                                        disabled={false}
                                        maskChar=" "
                                        placeholder="eg: 978-9854-4666"
                                        name="Communication no."
                                        onChange={(e) => handleMobileChange(e, "communicationNumber")}
                                        value={number}
                                    />
                                </Grid>
                            </>
                        }
                        {title === "Edit Address" &&
                            <>
                                <Box width="100%">
                                    <H5Text size='24' lineHeight='36' fontFamily="karbon_semiBold" color="purple">
                                        National Address
                                    </H5Text>
                                    <Box width="100%" maxWidth={'652px'} border='0.5px solid #B8B8B8' borderRadius={'16px'}>
                                        <SideBySideText
                                            leftText={<FormattedMessage id="autoFinance.applicationFee" />}
                                            RightText="40.65 SR"
                                            paddingAll="15"
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                    </Grid>
                </div>

                <div className="footer" style={{ marginTop: "30px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                {/* <Box component="form">
                                    <Stack spacing={2} direction="row">
                                        <ButtonInput
                                            title={<FormattedMessage id="save" />}
                                            variant="contained"
                                            bgColor={(!isValid || loader) ? "" : "yellow"}
                                            textColor="purple"
                                            borderRadius="100"
                                            disabled={!isValid || loader}
                                            TextTransform="capitalize"
                                            endIcon={loader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
                                            onClick={() => handlePostData()}
                                        />
                                    </Stack>
                                </Box> */}
                                <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                    <ButtonInput
                                        title={<FormattedMessage id="cancel" />}
                                        variant="text"
                                        bgColor="transparent"
                                        textColor="purple"
                                        borderRadius="100"
                                        TextTransform="uppercase"
                                        onClick={() => handleCloseModal(false)}

                                    />
                                    <ButtonInput
                                        title={title == 'Edit Email' && <FormattedMessage id="Send OTP" /> || title == 'Edit Mobile No.' && <FormattedMessage id="Validate" />}
                                        variant="contained"
                                        bgColor={(!isValid || loader) ? "" : "yellow"}
                                        textColor="purple"
                                        borderRadius="100"
                                        disabled={!isValid || loader}
                                        TextTransform="uppercase"
                                        endIcon={loader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
                                        onClick={() => handlePostData()}
                                    // endIcon={<ArrowForwardIcon />}
                                    // onClick={() => handlePostData()}
                                    />

                                </Box>
                            </div>
                        </Grid>

                        {!loader &&
                            <>
                                {error &&
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                        <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                            {error}
                                        </Text>
                                    </Grid>
                                }
                            </>
                        }
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
}

export default ModalWithInput;