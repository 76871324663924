import {
    EXPENSE_BANK_VALIDATION,
    EXPENSE_BANK_VALIDATION_ERROR,
    EXPENSE_BANK_VALIDATION_SUCCESS,
} from "./constant";


const expenseBankReducer = (state = {}, action) => {
    switch (action.type) {
        case EXPENSE_BANK_VALIDATION:
            return { ...state, loading: true, error: '' };
        case EXPENSE_BANK_VALIDATION_ERROR:
            return { ...state, type: EXPENSE_BANK_VALIDATION_ERROR, loading: false, error: action.payload };
        case EXPENSE_BANK_VALIDATION_SUCCESS:
            return { ...state, type: EXPENSE_BANK_VALIDATION_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default expenseBankReducer;