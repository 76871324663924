import React from "react";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        occupation: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        employmentType: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        employeName: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        employementStatus: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        employeNumber: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        },
        joiningDate: {
            error: false,
            errorMessage: <FormattedMessage id="This field is required" />
        }
    };

    const emptyKeys = [
        "occupation",
        "employmentType",
        "employeName",
        "employementStatus",
        "employeNumber",
        "joiningDate"
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }

    return formError;
}

export default CheckValidation;