import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from 'styled-components';
import carImage from "assets/images/Application/car.png";
import ShareIcon from "assets/images/Application/ShareNetwork.png";
import CarLogo from "assets/images/Application/Monogram.png";
import GaugeLogo from "assets/images/Application/Gauge.png";
import PlateLogo from "assets/images/Application/Plate.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import carServiceImage from "assets/icons/E_Service/carService.png";
import carServiceImageWhite from "assets/icons/E_Service/carServiceWhite.png";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton } from "@mui/material";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { FormattedMessage } from "react-intl";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import { getContractDetailsURL, getContractsURL, getDashboardURL, getInvoiceGeneration } from 'routes/AppUrls';
import { useDispatch } from "react-redux";
import { paymentHistoryCallAPI } from "store/contracts/paymentHistory/action";
import { PAYMENT_HISTORY } from "store/contracts/paymentHistory/constant";
import SideBySideText from "components/SideBySideText";
import rightIcon from "assets/icons/E_Service/arrowDownPurple.png";
import paperCross from "assets/icons/E_Service/paperCross.png";
import paperDownload from "assets/icons/E_Service/paperDownload.png";
import downloadIcon from "assets/icons/downloadIcon.png";

const StyleWrapper = styled.div`
    align-items: center;

    .left_container{
          background:#ffffff;
          border-radius:16px;
          padding: 30px 20px;
      };
    
    ul{
        display: inline;
      };
    
    li {
        // display: inline-block;
        // padding-right:30px;
        // float: left;
      };

    .service_box{
        height:74px;
        // width:234px;
        background:#F5ECFF;
        padding:12px 8px 12px 8px;
        color:#6D4C7E;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
        border-radius:8px;
    };

    .service_box_selected{
        height:74px;
        // width:234px;
        background:#3F1956;
        padding:12px 8px 12px 8px;
        color:#ffffff;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
        border-radius:8px;
    };
    .payment_history{
        background:#F5ECFF !important;
        height:60px;
        border-radius:8px;
        color:#FFFFFF;
        display:flex;
        justify-content:space-between;
        align-items:center;
        cursor:pointer;
        padding: 8px 24px 8px 24px;
    }
    .MuiAccordionDetails-root{
        padding:0 !important;
    }
    .innerItem:hover{
        background:#3F1956 !important;
        color:white !important;
    }
`

const Details = (props) => {
    const e_ser_arr = [
        { id: 1, name: "Renewal of Registration", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 2, name: "Buy Your Leased Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false },
        { id: 3, name: "Ownership Transfer", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 4, name: "Add Additional Driver", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false },
        { id: 5, name: "Retrieve Your Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 6, name: "Re-lease Your Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }
    ];
    const [selectArray, setSelectArray] = useState([]);
    const [serviceList, setServiceList] = useState(e_ser_arr);
    const { locale } = useContext(LocaleContext);

    const navigate = useNavigate();

    const handleSelect = (data) => {
        if (selectArray.includes(data.id)) {
            let index = selectArray.findIndex((obj) => obj === data.id);
            selectArray.splice(index, 1);
            let index1 = serviceList.findIndex((obj) => obj.id === data.id);
            setServiceList([...serviceList], serviceList[index1].is_select = false);
        } else {
            selectArray.push(data.id);
            let index = serviceList.findIndex((obj) => obj.id === data.id);
            setServiceList([...serviceList], serviceList[index].is_select = true);
        }
    };
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="contractsServices" />,
            Link: getContractsURL(locale)
        },
        {
            title: <FormattedMessage id="contractDetails" />,
            Link: "",
            isDisbale: true,
        },
    ])

    const contractTermination = [
        {
            id: 1,
            heading: "Buy your Leased Vehicle",
            subHeading: "Buy your Leased Vehicle",
        },
        {
            id: 2,
            heading: "Re-lease",
            subHeading: "Re-lease",
        },
        {
            id: 3,
            heading: "Total Loss Major Accident",
            subHeading: "Total Loss Major Accident",
        },
        {
            id: 4,
            heading: "Vehicle Theft",
            subHeading: "Vehicle Theft",
        },
        {
            id: 5,
            heading: "Transfer Contract",
            subHeading: "Transfer Contract",
        },
        {
            id: 6,
            heading: "Car Not Delivered",
            subHeading: "Car Not Delivered",
        },
    ]
    // const [selectedContractTermination, setSelectedContractTermination] = useState(null);

    // const handleSelectContractTermination = (data) => {
    //     setSelectedContractTermination(data.id === selectedContractTermination ? null : data.id);
    // };
    const documentGeneration = [
        {
            id: 1,
            heading: "Print Invoices",
            subHeading: "Print Invoices",
        },
        {
            id: 2,
            heading: "Account Statement",
            subHeading: "Account Statement",
        },
        {
            id: 3,
            heading: "Contract Copy",
            subHeading: "Contract Copy",
        },
        {
            id: 4,
            heading: "Insurance Certificate",
            subHeading: "Insurance Certificate",
        },
        {
            id: 5,
            heading: "Issue Authorization Letter",
            subHeading: "Issue Authorization Letter",
        },
        {
            id: 6,
            heading: "Change Car plate size",
            subHeading: "Change Car plate size",
        },
        {
            id: 7,
            heading: "Lost Istemara",
            subHeading: "Lost Istemara",
        },
        {
            id: 8,
            heading: "Missing/Duplicate Plate",
            subHeading: "Missing/Duplicate Plate",
        },
    ]
    const handleRedirect = (data)=>{
        switch(data.heading){
            case "Print Invoices" :
                // navigate(getInvoiceGeneration(locale+'/Print_Invoices'));
                navigate(getContractDetailsURL(locale) + '/Print_Invoices');
                break;
            case "Account Statement" :
                navigate(getContractDetailsURL(locale) + '/Account_Statement');
                break;
            case "Issue Authorization Letter" :
                navigate(getContractDetailsURL(locale) + '/Issue_Authorization_Letter');
                break;
        }
        
    }
    return (
        <StyleWrapper>
            <Row className="left_container">
                <MuiBreadcrumbs
                    list={breadcrumbsList}
                />

                <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "26px" }}>
                    <Grid item xs={12} lg={8} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginBottom: '15px' }}>
                            <ArrowBackIcon sx={{ color: "#3F1956", fontSize: "24", cursor: 'pointer' }} onClick={() => navigate(getContractsURL(locale))} />
                        </div>
                        <H5Text size="32" marginTop="0" color="purple" style={{ paddingLeft: '20px' }} >
                            {props?.data?.loanNo}
                        </H5Text>
                        <ContentCopyOutlinedIcon style={{ marginLeft: '15px', marginBottom: '15px' }} />
                    </Grid>
                    <Grid item xs={12} lg={4} justifyContent={'end'} display={'flex'}>
                        {/* <img src={ShareIcon} alt="share" /> */}
                    </Grid>
                </Grid>

                <div style={{ display: "flex", marginLeft: "35px" }}>
                    <Text size="18" marginTop="0" color="lighGrey">
                        <FormattedMessage id="contractNumber" />
                    </Text>
                </div>

                <div style={{ textAlign: "center" }}>
                    <img src={carImage} alt='carImage' style={{ width: "inherit" }} />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <img src={CarLogo} alt="icon" />
                    </div>
                    <div>
                        <H5Text size="32" marginTop="0" color="purple" lineHeight="38.4">
                            {"Kia Sonet X-Line"}
                        </H5Text>
                        <Text size="20" color={"gray"} textAlign="left" lineHeight="24">
                            2021 GU M/R 4x215
                        </Text>
                    </div>
                </div>


                <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px", flexWrap: "wrap" }}>
                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                        <img src={GaugeLogo} alt="icon" style={{ marginRight: "8px" }} />
                        <Text size="24" color={"lightDark"} textAlign="center" lineHeight="28.8">
                            1,50,200 KM
                        </Text>
                        <img src={PlateLogo} alt="icon" style={{ marginLeft: "24px", marginRight: "8px" }} />
                        <Text size="24" color={"lightDark"} TextAlign="center" lineHeight="28.8">
                            9067
                        </Text>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }} justifyContent={{ xs: 'start', xl: 'end' }}>
                                <Text size="20" color={"lightGrey"} lineHeight="21.23">
                                    VIN:
                                </Text>
                                <Text size="20" color={"lightDark"} lineHeight="24">
                                    76523
                                </Text>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }} justifyContent={{ xs: 'start', xl: 'end' }}>
                                <Text size="20" color={"lightGrey"} lineHeight="21.23">
                                    <FormattedMessage id="registrationExpiryDate" />:
                                </Text>
                                <Text size="20" color={"lightDark"} lineHeight="24">
                                    12/12/2027
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {props.e_service &&
                    <>
                        <div style={{ marginTop: "36px" }}>
                            <H5Text size="24" marginTop="0" color="purple" >
                                {"E-services"}
                            </H5Text>
                        </div>

                        <Row xs={1} md={2} lg={2} xxl={3}style={{ margin: "0px", padding: "0px", marginTop: "16px" }} >
                            {serviceList.length > 0 && serviceList.map((k, idx) => (
                                <Col key={idx}>
                                    <Box className={k.is_select ? "service_box_selected" : "service_box"} onClick={() => handleSelect(k)}>
                                        <div style={{ height: "32px" }} >
                                            <img src={k.is_select ? k.imageSelect : k.image} alt="icon" />
                                        </div>
                                        <div>
                                            <Text marginTop='4' size="16" fontFamily="KarbonMedium" color={k.is_select ? "white" : "purple"} textAlign="center" lineHeight="21.6">
                                                {k.name}
                                            </Text>
                                        </div>
                                    </Box>
                                </Col>
                            ))}
                        </Row>
                        <Grid item xs={12} marginTop='4px' >
                            <Accordion style={{ boxShadow: 'none' }}>
                                <AccordionSummary className="payment_history"
                                    expandIcon={
                                        <IconButton style={{ color: 'white' }} aria-label="expand">
                                            {/* Add your custom image here */}
                                            <img src={rightIcon} alt="Custom Icon" />
                                        </IconButton>
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                // onClick={handleAccordionChange}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={paperCross} alt="icon" height={"25px"} width={"24px"} style={{ marginRight: locale === 'en' && "16px", marginLeft: locale === 'ar' && "16px" }} />
                                        <Text size="24" marginTop="6" color="purple" lineHeight="36">
                                            Contract Termination
                                        </Text>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ borderLeft: "1px solid #DEDEDE", borderRight: "1px solid #DEDEDE", borderBottom: "1px solid #DEDEDE", borderRadius: '0 0 8px 8px' }}>
                                    {contractTermination.map((data) => (
                                        <Box
                                            className="innerItem"
                                            key={data.heading}
                                            padding={"16px 32px"}
                                            borderBottom={'1px solid #dedede'}
                                            style={{ cursor: 'pointer'}}
                                            // onClick={() => handleSelectContractTermination(data)}
                                            // style={{ cursor: 'pointer', backgroundColor: selectedContractTermination === data.id ? '#3F1956' : 'transparent' }}
                                            // color={selectedContractTermination === data.id ? "white" : "lightDark"}
                                        >
                                            <Text marginTop="0" fontWeight="800" size="18" fontFamily={locale === "en" ? "KarbonSemiBold" : "LumaSemiBold"} lineHeight="27">
                                                {data.heading}
                                            </Text>
                                            <Text marginTop="0" fontWeight="800" size="16" fontFamily={locale === "en" ? "KarbonLight" : "Lumalight"} lineHeight="27">
                                                {data.subHeading}
                                            </Text>
                                        </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} marginTop='16px' >
                            <Accordion style={{ boxShadow: 'none' }}>
                                <AccordionSummary className="payment_history"
                                    expandIcon={
                                        <IconButton style={{ color: 'white' }} aria-label="expand">
                                            {/* Add your custom image here */}
                                            <img src={rightIcon} alt="Custom Icon" />
                                        </IconButton>
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                // onClick={handleAccordionChange}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={paperDownload} alt="icon" height={"25px"} width={"24px"} style={{ marginRight: locale === 'en' && "16px", marginLeft: locale === 'ar' && "16px" }} />
                                        <Text size="24" marginTop="6" color="purple" lineHeight="36">
                                            Document Generation
                                        </Text>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ borderLeft: "1px solid #DEDEDE", borderRight: "1px solid #DEDEDE", borderBottom: "1px solid #DEDEDE", borderRadius: '0 0 8px 8px' }}>
                                    {documentGeneration.map((data) => (
                                        <Box
                                            className="innerItem"
                                            key={data.heading}
                                            padding={"16px 32px"}
                                            borderBottom={'1px solid #dedede'}
                                            display='flex'
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            style={{ cursor: "pointer" }} onClick={() => handleRedirect(data)}
                                        // onClick={() => handleSelectDocumentGeneration(data)}
                                        // style={{ cursor: 'pointer', backgroundColor: selectedDocumentGeneration === data.id ? '#3F1956' : 'transparent' }}
                                        >
                                            <Box >
                                                <Text marginTop="0"
                                                    //  color={selectedDocumentGeneration === data.id ?"white":"lightDark"} 
                                                    fontWeight="800" size="18" fontFamily={locale === "en" ? "KarbonSemiBold" : "LumaSemiBold"} lineHeight="27">
                                                    {data.heading}
                                                </Text>
                                                <Text marginTop="0"
                                                    // color={selectedDocumentGeneration === data.id ?"white":"lightGrey"}
                                                    fontWeight="800" size="16" fontFamily={locale === "en" ? "KarbonLight" : "Lumalight"} lineHeight="27">
                                                    {data.subHeading}
                                                </Text>
                                            </Box>
                                            {(data.heading == "Contract Copy" || data.heading == "Insurance Certificate") &&
                                                <Box>
                                                    <img src={downloadIcon} alt="logo" />
                                                </Box>
                                            }
                                        </Box>
                                    ))}

                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                }

            </Row>
        </StyleWrapper>
    );
};

export default Details;