import React, { useState, useContext, useEffect } from "react";
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import MuiCards from "components/MuiCards";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import StepperHorizontalWithTitle from "components/StepperHorizontalWithTitle";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';
import InitialDataForm from "features/RevisedFinancing/InitialDataForm";
import ExpensesBankForm from "features/RevisedFinancing/ExpensesBankForm";
import PersonalWorkForm from "features/RevisedFinancing/PersonalWorkForm";
import AttachApplyForm from "features/RevisedFinancing/AttachApplyForm";
import OrderSummary from "features/RevisedFinancing/OrderSummary";
import RevisedFinancingForm from "features/RevisedFinancing/RevisedFinancingForm";
import CertificateStatement from "features/RevisedFinancing/CertificateStatement";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import FinancingResultsData from "features/RevisedFinancing/FinancingResults";
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";
import ProductDetails from "features/RevisedFinancing/ProductDetails";
import RevisedAccountFetch from "features/RevisedFinancing/AmountFetch";
import { getDashboardURL, getRevisedFinancingURL } from "routes/AppUrls";

export const WrapperBox = styled(Box)`
    display: "flex";
    background-color: ${(props) => props.theme.lightSecondary}
`;

const Financing = () => {
	const [curStep, setCurStep] = useState(0);
	const [innerCurStep, setInnerCurStep] = useState('PersonalWorkForm');
	const { locale } = useContext(LocaleContext);
	const gosiPension = useSelector((state) => state.basicReducer.financingType);
	const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);
	const ResumeFormData = useSelector((state) => state.ResumeOnlineFormReducer?.data);

	const vehicleDetailData = useSelector((state) => state.vehicleDetailsReducer && state.vehicleDetailsReducer?.payload?.data);

	const handleCurStep = (value) => {
		setCurStep(value);
	};
	const handleInnerCurStep = (value) => {
		setInnerCurStep(value);
	};

	useEffect(() => {
		if (ResumeFormData?.resumeForm) {
			setCurStep(ResumeFormData?.step);
		}
	}, []);

	const steps = [
		<FormattedMessage id="autoFinance.personalDrivingInformation" />,
		<FormattedMessage id="autoFinance.workIncomeDetails" />,
		<FormattedMessage id="autoFinance.expensesBank" />,
		<FormattedMessage id="autoFinance.insuranceRevisedFinancing" />,
	];

	const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: getRevisedFinancingURL(locale)
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])

	return (
		<WrapperBox>
			{curStep !== "AccountFetch" ?
				<Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
					<Grid container>
						<Grid item xs={12}>
							<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} curStep={curStep} option={true}/>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={12} md={4} display='flex' alignItems={'end'}>
							<Box margin='10px 0 12px 0'>
								<MuiBreadcrumbs size='20' list={breadcrumbsList} />
							</Box>
						</Grid>
						<Grid item xs={12} md={8} marginTop={{ xs: '0', md: '40px' }}>
							<StepperHorizontalWithTitle steps={steps} curStep={curStep} />
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12} lg={4} xl={3}>
							<FinancingResultsData title="financingResults" bgColor={"white"} />
							<ProductDetails bgColor={"white"} locale={locale} data={vehicleDetailData} />
						</Grid>
						<Grid item xs={12} lg={8} xl={9} >
							{/* <MuiCards borderRadius="16" padding="0px" margin="0px"> */}
								{/* <CardContent padding="0px" margin="0px" > */}
									{curStep === 1 ? (
										// (isSaudi&&!gosiPension)&&
										innerCurStep === 'PersonalWorkForm' ?
											<PersonalWorkForm handleCurStep={handleCurStep} handleInnerCurStep={handleInnerCurStep} isSaudi={userMeData.data.is_saudi} />
											: innerCurStep === 'CertificateStatement' && <CertificateStatement handleCurStep={handleCurStep} handleInnerCurStep={handleInnerCurStep} isSaudi={userMeData.data.is_saudi} />

									) : curStep === 2 ? (
										<ExpensesBankForm handleCurStep={handleCurStep} />
									) : curStep === 3 ? (
										<RevisedFinancingForm handleCurStep={handleCurStep} />
										// <></>
									) : curStep === 4 ? (
										<AttachApplyForm handleCurStep={handleCurStep} />
									) : curStep === 5 ? (
										<OrderSummary handleCurStep={handleCurStep} />
									) : (
										<InitialDataForm handleCurStep={handleCurStep} isSaudi={userMeData.data.is_saudi} />
									)}

								{/* </CardContent> */}
							{/* </MuiCards> */}
						</Grid>
					</Grid>

					<Grid container style={{ marginTop: "15px" }}>
						<Grid item xs={12}>
						</Grid>
					</Grid>
				</Container>
				:
				<RevisedAccountFetch handleCurStep={handleCurStep} />}
		</WrapperBox>
	);

};

export default Financing;