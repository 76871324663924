import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import MaskInput from "components/Input/MaskInput";
import ButtonInput from "components/Input/ButtonInput";
import MuiLink from "components/MuiLink";
import { getSignUpURL, getForgotPasswordURL } from "routes/AppUrls"
import CheckValidation from "./validation";
import { authCallAPI } from "store/auth/action";
import { AUTH_INITIAL_STATE, AUTH_LOGIN } from "store/auth/constant";
import { setLoginDetails } from "store/login/action";
import { LocaleContext } from 'routes/Router';
import { USER_LOGIN } from "store/auth/login/constant";
import { userLoginCallAPI } from "store/auth/login/action";
import CommonModal from "components/Modals/CommonModal";
import H5Text from "components/Text/H5Text";
import Cash_Finance_Icon from "assets/images/Dashboard/cash_finance.png";
import MuiCards from "components/MuiCards";
import DeleteIcon from "assets/icons/Trash.png";
import moment from "moment";
import { loginDeviceCallAPI } from "store/auth/loginDevice/action";
import { LOGIN_DEVICE } from "store/auth/loginDevice/constant";

function LoginAccount({ handleCurStep, handleFormData }) {
	const isMobile = window.innerWidth < 900;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);
	const [isError, setIsError] = useState(false);
	const [isPassVisible, setIsPassVisible] = useState(false);
	const [formValue, setFormValue] = useState({
		nationalId: "",
		password: "",
		routingKey: "login"
	});
	const AuthError = useSelector((state) => (state.userLoginReducer?.error?.data?.message));
	const AuthData = useSelector((state) => (state.authReducer && state.authReducer?.payload?.data));
	const userLogin = useSelector((state) => (state?.userLoginReducer?.payload?.data));
	const loader = useSelector((state) => state?.userLoginReducer?.loading);
	// const loader = useSelector((state) => state.authReducer?.loading);
	const [isModalActive, setIsModalActive] = useState(false);
	useEffect(() => {
		dispatch(authCallAPI(AUTH_INITIAL_STATE));
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValue((prevState) => ({
			...formValue,
			[name]: value,
		}));
	};

	const handleVisiblePassword = (value) => {
		setIsPassVisible(!value);
	};

	const checkDeviceData = (value) => {
		if (value) {
			setIsModalActive(true)
		}
	}
	const handleSubmit = () => {
		// if (true) {
		// 	setIsModalActive(true)
		// } else {
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			handleFormData(formValue);
			dispatch(setLoginDetails(formValue));
			// dispatch(authCallAPI(AUTH_LOGIN, formValue, { handleStep: handleCurStep, navigate: navigate, step: 2, errorStep: 1 }));
			dispatch(userLoginCallAPI(USER_LOGIN, formValue, { handleStep: handleCurStep, checkDevice: checkDeviceData, navigate: navigate, step: 2, errorStep: 1 }));
		}
		// }
	};
	const deleteDevice = (data) => {
		let body = {
			user_id: data?.user,
			id: data?.id
		}
		dispatch(loginDeviceCallAPI(LOGIN_DEVICE, body, { handleFunction: setIsModalActive(false), navigate: navigate, run: true }));
	}

	return (
		<>
			<H1Text color="primary" marginTop={30} size={isMobile ? 46 : 64} lineHeight="64">
				<FormattedMessage id="login.welcome_account" />
			</H1Text>

			<Text size="24" color="lightDark" lineHeight="36" fontWeight="600" marginTop="0">
				<FormattedMessage id="login.welcome_account_sub_text" />
			</Text>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12} md={10} lg={8}>
					<MaskInput
						mask="9999999999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="idIqamaNumber" />}
						placeholder="0000000000"
						name="nationalId"
						onChange={handleInputChange}
						errorText={isError && isError.nationalId.error && isError.nationalId.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<LabelWithInput
						label={<FormattedMessage id="enterPassword" />}
						placeholder='Enter Password'
						name="password"
						type={isPassVisible ? "text" : "password"}
						onChange={handleInputChange}
						InputProps={{
							endAdornment: (
								<>
									{isPassVisible ? (
										<VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
									) : (
										<VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
									)}
								</>
							)
						}}
						errorText={isError && isError.password.error && isError.password.errorMessage}
					/>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "20px" }}>
					<MuiLink onClick={() => navigate(getForgotPasswordURL(locale))}>
						<FormattedMessage id="forgotPassword" />
					</MuiLink>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<div style={{ display: "flex" }}>
						<Text size="18" marginTop="0" color="lightGrey" fontWeight="600" lineHeight="27">
							<FormattedMessage id="dontHaveAccount" />&nbsp;
						</Text>
						<MuiLink size="18" TextDecoration="underline" onClick={() => navigate(getSignUpURL(locale))}>
							<FormattedMessage id="createAccount" />
						</MuiLink>
					</div>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<Grid container>
						<Grid item xs={12}>
							<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
								<Stack spacing={2} direction="row">
									{/* <ButtonInput
										title={<FormattedMessage id="back" />}
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										minWidth="100px"
										marginRight="40"
									/> */}
									<ButtonInput
										title={<FormattedMessage id="continue" />}
										variant="contained"
										bgColor={"yellow"}
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={loader}
										endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
										onClick={() => handleSubmit()}
									/>
								</Stack>
							</Box>
						</Grid>
						<CommonModal padding={'32px 48px'} width={'1260px'} modalActive={isModalActive} innerContent={
							<Box overflow={"hidden"}>
								<Grid item xs={12} textAlign='center' >
									<Box display={{ xs: "none", lg: "block" }}>
										<img src={require('assets/images/feeling-sorry-animate.png')} alt="" />
									</Box>
									<H1Text size={'40'} lineHeight={'49'} fontFamily="Karbon_bold">
										<FormattedMessage id="Trouble logging in" />
									</H1Text>
									<H5Text size={'24'} lineHeight={'25.48'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='lightGrey' marginTop={'8'}>
										<FormattedMessage id="You are already logged in to the maximum allowed devices, please logout from anyone to continue" />
									</H5Text>
									<Grid container spacing={2} >
										<Grid item xs={12} md={12} lg={12} xl={12} display="flex" flexDirection={"column"} alignItems="center">
											<H5Text size={'24'} lineHeight={'25.48'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='purple' marginTop={'12'}>
												Registered Devices
											</H5Text>
											{userLogin?.data?.active_devices?.length && userLogin?.data?.active_devices?.map((data) => (
												<MuiCards style={{ width: "60%", minWidth: "400px" }} borderRadius="16" boxShadow={"0px 1px 69px 0px #00000017"} backgroundSize="cover" bgColor="white" padding="12px" margin="6px 0" >
													<Grid container style={{ cursor: "pointer" }}>
														<Grid item xs={11} display="flex" justifyContent="start">
															<Grid item xs={12} display="flex">
																<img src={Cash_Finance_Icon} alt='icon' />
																<Box marginLeft={locale == "en" && "10px"} marginRight={locale == "ar" && "10px"}>

																	<H5Text style={{ textAlign: "start" }} size={'20'} lineHeight={'25.48'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='purple'>
																		{data?.device_Name}
																	</H5Text>
																	<div style={{ display: "flex" }}>
																		<Text color="darkGray" size="16" marginTop="0" lineHeight="18">
																			<FormattedMessage id="Version" />:
																		</Text>
																		<Text color="purple" size="16" marginTop="0" lineHeight="18" fontFamily="KarbonMedium">
																			&nbsp;{data?.device_Version}
																		</Text>
																	</div>
																</Box>
															</Grid>
														</Grid>
														<Grid item xs={1} display="flex" justifyContent="end">
															<img src={DeleteIcon} alt='icon' style={{ maxHeight: "24px", maxWidth: "24px" }} onClick={() => deleteDevice(data)} />
														</Grid>
														<Grid container spacing={2} marginTop="0px">
															<Grid item xs={12}>
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Text color="darkGray" size="16" marginTop="0" lineHeight="18">
																		<FormattedMessage id="Last Active" />:
																	</Text>
																	<Text color="dark" size="16" marginTop="0" lineHeight="18" fontFamily="KarbonMedium">
																		&nbsp;{moment(data?.updated_at).format("YYYY/MM/DD HH:MM")}
																	</Text>
																</div>
															</Grid>
														</Grid>
													</Grid>
												</MuiCards>
											))}
										</Grid>
									</Grid>
								</Grid>
								<Box component="form" display='flex' justifyContent='end' marginTop='12px'>
									<ButtonInput
										title="Close"
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="uppercase"
										height='59'
										onClick={() => setIsModalActive(false)}
										style={{ border: "2px solid #3F1956" }}
									/>
								</Box>
							</Box>
						} />
						{!loader &&
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
								{AuthError &&
									<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
										{AuthError}
									</Text>
								}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default LoginAccount;