import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDashboardURL, getRevisedFinancingURL } from 'routes/AppUrls';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import { LocaleContext } from "routes/Router";
import SelectAmongs from "components/SelectAmongs";
import ProductDetails from "../ProductDetails";
import { CardContent, Container } from "@mui/material";
import MuiCards from "components/MuiCards";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import backArrow from 'assets/images/icon/backArrow.png';
import SelectInput from "components/Input/SelectInput";
import { useDispatch } from "react-redux";
import { LEAD_MANAGMENT } from "store/financing/leadManagment/constant";
import { leadManagmentCallAPI } from "store/financing/leadManagment/action";
import MaskInput from "components/Input/MaskInput";
import CheckValidation from "./validation";

const WrapperDiv = styled.div`
background-color: ${(props) => props.theme.lightSecondary}
`;
const RequestCallback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const [marital, setmarital] = useState(1);
    const [isError, setIsError] = useState(false);
    const [formValue, setFormValue] = useState({
        idNumber: "",
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const error = CheckValidation(formValue);
        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };
    const getSelectedValue = (val) => {
        if (val === 1) {
            setmarital(1);
        } else {
            setmarital(2);
        }

    };

    const maritalStatus = [
        {
            id: 1,
            type: <FormattedMessage id="married" />
        },
        {
            id: 2,
            type: <FormattedMessage id="unmarried" />
        },
    ];
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: getRevisedFinancingURL(locale)
        },
        {
            title: <FormattedMessage id="revisedFinance.leadManagment" />,
            Link: "",
            isDisbale: true,
        },
    ])

    const handleSubmit = () => {
        // leadManagmentCallAPI
        const error = CheckValidation(formValue);
        setIsError(error)
        let body = {
            "QuotationSource": "1",
            "IDNumber": formValue.idNumber,
            "First_Name": formValue.firstName,
            "Last_Name": formValue.lastName,
            "Email_Address": formValue.email,
            "Mobile": formValue.mobileNumber,
            "Gender_Code": 1,
            "PreferredLanguageID": 1,
            "Monthly_Income": 5000,
            "Matrial_Status": marital,
            "City_Name": "Abha",
            "Period": 40,
            "Product_Category": "1",
            "Model_Name": "",
            "Model_Year": "2002",
            "Finance_Type": 1,
            "MonthlyPayment": 500,
            "Downpayment": "200",
            "Retail_Price": "25000",
            "Description": "",
            "filename": "",
            "subject": "",
            "mimetype": "",
            "body": "",
            "Title": "5",
            "JobTitle": "Sr.Software Engineer",
            "Priority": "3",
            "Probability": "90",
            "IDType": "1",
            "City_Code": "151001",
            "DateofBirth": "12/29/2020",
            "HijriDateofBirth": "02 Shaban 1443",
            "Branch": 601,
            "LocationID": "601",
            "Model_ID": "1014072",
            "SupplierColourCode": "382112",
            "FormulaType": "5",
            "IsTaxFinanced": 1,
            "Residual_Value": 2000,
            "Promotion_Code": "XL1745",
            "EffectOnParameter": 1,
            "ChangedParameter": 2,
            "CompanyName": "ABC",
            "CR_No": "1111",
            "Product_Brand": "1",
            "Product": "100005",
            "smeAge": "7",
            "smeNoOfCars": 0,
            "retentionStatus": 1,
            "retentionProbability": "35.75",
            "contractExpirationMonth": "12/29/2020",
            "partnerUserIDNumber": "213234",
            "partnerUserName": "riderHailingUserName",
            "rideHailingSource": 1,
            "partnerID": "",
            "partnerBranch": "",
            "crDateHijriDate": "",
            "secondaryContactNumber": "",
            "businessActivity": 1,
            "reasonOfFinancing": ""
        }
        if (error.submit){
            dispatch(leadManagmentCallAPI(LEAD_MANAGMENT, body, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getRevisedFinancingURL(locale) }));
        }
        // dispatch(leadManagmentCallAPI(LEAD_MANAGMENT, body, { navigate: navigate }))
        // navigate(getRevisedFinancingURL(locale))
    }
    return (
        <WrapperDiv>
            <Container maxWidth={'xl'} style={{ padding: "10px", minHeight: "105vh" }} disableGutters>
                <Box paddingTop='22px'>
                    <MuiBreadcrumbs
                        list={breadcrumbsList}
                    />
                </Box>
                <Box display="flex" alignItems={"center"}>
                    {/* getFindVehicleURL(locale) */}
                    <img src={backArrow} onClick={() => navigate(getRevisedFinancingURL(locale))} alt="" style={{ cursor: "pointer", transform: locale === 'ar' ? 'scaleX(-1)' : '', margin: locale === 'en' ? '7px 24px 0 0' : '7px 0 0 24px', width: "24px", height: "20px" }} />
                    <H5Text size="32" marginTop={'14'} color={'purple'}><FormattedMessage id="findVehicle" /></H5Text>
                </Box>
                <Grid container spacing={2} marginTop="24px">
                    <Grid item xs={12} lg={4} xl={4}>
                        <ProductDetails bgColor={"white"} locale={locale} style={{ marginTop: "0px" }} />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={8}>
                        <MuiCards borderRadius="16" padding="0">
                            <CardContent>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
                                            <FormattedMessage id={'revisedFinance.requestCallback'} />
                                            <img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
                                        </H5Text>
                                        <Box component="form" sx={{ padding: "15px" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id='IDNumber' />}
                                                        placeholder="ID Number"
                                                        value={formValue?.idNumber}
                                                        name="idNumber"
                                                        onChange={handleInputChange}
                                                        errorText={isError && isError.idNumber.error && isError.idNumber.errorMessage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    {/* <SelectInput data={financingCategoryDropdown?.level_of_education} label={<FormattedMessage id={"autoFinance.levelofEducation"} />} placeholder="Level of Education" /> */}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id='firstName' />}
                                                        placeholder="First Name"
                                                        value={formValue?.firstName}
                                                        name="firstName"
                                                        onChange={handleInputChange}
                                                        errorText={isError && isError.firstName.error && isError.firstName.errorMessage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id='lastName' />}
                                                        placeholder="Last Name"
                                                        value={formValue?.lastName}
                                                        name="lastName"
                                                        onChange={handleInputChange}
                                                        errorText={isError && isError.lastName.error && isError.lastName.errorMessage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id='email' />}
                                                        placeholder="Email"
                                                        value={formValue?.email}
                                                        name="email"
                                                        onChange={handleInputChange}
                                                        errorText={isError && isError.email.error && isError.email.errorMessage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <MaskInput
                                                     label={<FormattedMessage id='mobileNumber' />}
                                                        mask="+\966 999 999 999"
                                                        disabled={false}
                                                        maskChar=" "
                                                        placeholder="eg: 978-9854-4666"
                                                        name="mobileNumber"
                                                        onChange={handleInputChange}
                                                        errorText={isError && isError.mobileNumber.error && isError.mobileNumber.errorMessage}
                                                    />
                                                    {/* <LabelWithInput
                                                        label={<FormattedMessage id='mobileNumber' />}
                                                        placeholder="Mobile Number"
                                                        value={formValue?.mobileNumber}
                                                        name="mobileNumber"
                                                        onChange={handleInputChange}
                                                    /> */}
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <SelectAmongs data={maritalStatus} marginTop={"10"} selected={maritalStatus[0]?.id} getSelected={(val) => getSelectedValue(val)} label={<FormattedMessage id="cashFinance.maritalStatus" />} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <SelectInput label={<FormattedMessage id={"gender"} />} placeholder="Gender" />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <SelectInput label={<FormattedMessage id={"revisedFinance.city"} />} placeholder="City" />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </MuiCards>

                        <Grid container sx={{ marginTop: "30px" }}>
                            <Grid item xs={12}>
                                <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                    <Box component="form">
                                        <Stack spacing={2} direction="row">
                                            <ButtonInput
                                                title={<FormattedMessage id="cancel" />}
                                                variant="text"
                                                bgColor="transparent"
                                                textColor="purple"
                                                borderRadius="100"
                                                TextTransform="uppercase"
                                                minWidth="100px"
                                                marginRight="30"
                                                padding="0px"
                                                onClick={() => navigate(getRevisedFinancingURL(locale))}
                                            />
                                            <ButtonInput
                                                title={<><FormattedMessage id="submit" /></>}
                                                variant="contained"
                                                bgColor={"yellow"}
                                                textColor="purple"
                                                borderRadius="100"
                                                TextTransform="capitalize"
                                                endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                                onClick={() => handleSubmit()}
                                            />
                                        </Stack>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>

        </WrapperDiv>
    )
}

export default RequestCallback
