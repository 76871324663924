import {
   FINANCING_TYPE,
   LANGUAGE_TOGGLE,
   NET_INCOME,
   SIDEBAR_TOGGLE,
   CONTINUE_WITH,
   WORK_ITEM_APP_ID
} from "./constant";

const initialValue = {
   sidebarToggle: true,
   languageToggle: 'en', // Assuming 'english' is the initial language
   financingType: true,
   netIncome: 3000,
   continueWith: '',
   workItemAppId: ''
};

const basicReducer = (state = initialValue, action) => {
   switch (action.type) {
      case SIDEBAR_TOGGLE:
         return { ...state, sidebarToggle: action.data };
      case LANGUAGE_TOGGLE:
         return { ...state, languageToggle: action.data };
      case FINANCING_TYPE:
         return { ...state, financingType: action.data };
      case NET_INCOME:
         return { ...state, netIncome: action.data };
      case CONTINUE_WITH:
         return { ...state, continueWith: action.data };
      case WORK_ITEM_APP_ID:
         return { ...state, workItemAppId: action.data };
      default:
         return state;
   }
};

export default basicReducer;