import React, { useContext } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LocaleContext } from 'routes/Router';

const WrapperFormLabel = styled(FormLabel)`
	font-size: 16px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
	font-family: ${(props) => (props.locale == "en" ? props.theme.KarbonLight : props.theme.LumaLight)}; !important;
	color: ${(props) => props.theme.darkSecondary} !important;
	margin-top: 15px;
	margin-bottom: 5px;
`;

const WrapperErrorDiv = styled.div`
	font-size: 18px;
	color: ${(props) => props.theme.errorColor};
	line-height: 27px;
	font-weight: 600;
	font-family: ${(props) => (props.locale == "en" ? props.theme.KarbonLight : props.theme.LumaLight)};
	margin-top: 5px;
`;

function DateTimePickerInput({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);
	const disablePastDates = (date) => {
		// const formattedExpiryDate = props?.value;
		const formattedExpiryDate = new Date();
		formattedExpiryDate.setDate(formattedExpiryDate.getDate() + 1);
		return dayjs(date).isBefore(formattedExpiryDate, 'day');
	};
	const disableFutureDates = (date) => {
		const formattedExpiryDate = props?.value;
		return dayjs(date).isAfter(formattedExpiryDate, 'day');
	};
	return (
		<FormControl fullWidth>
			<WrapperFormLabel locale={locale} id="yes-no-input-radio-button">{props.label}</WrapperFormLabel>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker shouldDisableDate={props?.disablePast ? disablePastDates : props?.disableFuture ? disableFutureDates : false} value={dayjs(props?.value)} label={props.placeholder} onChange={props.onChange} />
			</LocalizationProvider>
			{props.errorText &&
				<WrapperErrorDiv locale={locale}>
					<ErrorOutlineIcon /> {props.errorText}
				</WrapperErrorDiv>
			}
		</FormControl>
	);
};

export default DateTimePickerInput;