import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getDashboardURL, getRevisedFinancingCongratulationsURL, getRevisedFinancingConsentFormURL, getRevisedFinancingWaitingPaymentURL } from 'routes/AppUrls';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import OtpInput from "components/Input/OtpInput";
import ButtonInput from "components/Input/ButtonInput";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { LocaleContext } from "routes/Router";
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";
import H5Text from "components/Text/H5Text";

const Success = ({ handleCurStep }) => {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [otp, setOtp] = useState("");
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="home" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="applyFinance" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="onlineFinancing" />,
			Link: "",
			isDisbale: true,
		}
	])

	return (
		<Box style={{ backgroundColor: '#F1EDF5', minHeight: '90vh' }}>
			<Grid margin={'1% 5% 1% 5%'} style={{ marginTop: '0px' }} disableGutters>
				<Box padding='32px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box>
				<Grid container>
					<Grid item xs={12}>
						<MuiCards borderRadius="16" padding={{ sm: "0" }}>
							<CardContent style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px', minHeight: '75vh', height: 'fit-content' }}>
								<Grid width="100%" textAlign={'center'} display='flex' alignItems='center' flexDirection='column'>
								<Container style={{ maxWidth: '689px' }}>
									<Grid container textAlign={'center'}>
										<Grid item xs={12}>
											<Grid container>
												<Grid item xs={12}>
													<div style={{ textAlign: "center" }}>
														<CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />
													</div>
												</Grid>
											</Grid>
											<Grid container display='flex' justifyContent='center'>

												<Grid item xs={12} marginTop='32px'>
													<Text color="black" size="40" fontWeight="800" lineHeight={'48px'} fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} TextAlign="center">
														<FormattedMessage id="applicationSubmittedforApproval" />
													</Text>
												</Grid>
											</Grid>

										</Grid>
									</Grid>
								</Container>

									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<div className="content" style={{ display: "flex", justifyContent: "end" }}>
											<Grid container style={{ marginTop: "30px"}} >
												<Grid item xs={12} marginRight={{xs:"0",lg:"100px"}}>
													<div className="content" style={{ display: "flex", justifyContent: "end" }}>
														<Box component="form">
															<Stack spacing={2} direction="row">
																<ButtonInput
																	title={<FormattedMessage id="revisedFinance.ReturnDashboard" />}
																	variant="contained"
																	bgColor="yellow"
																	textColor="purple"
																	borderRadius="100"
																	TextTransform="capitalize"
																	endIcon={<ArrowForwardIcon />}
																	onClick={() => navigate(getDashboardURL(locale))}
																/>
															</Stack>
														</Box>
													</div>
												</Grid>
											</Grid>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);

};

export default Success;