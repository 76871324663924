import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { LocaleContext } from 'routes/Router';

const WrapperFormLabel = styled(FormLabel)`
	font-size: 16px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
	font-family: ${(props) => (props.theme.KarbonSemiBold)} !important;
	color: ${(props) => (props.theme.darkSecondary)} !important;
`;

const WrapperFormControlLabel = styled(FormControlLabel)`
	margin-top: 15px;

	.MuiButtonBase-root {
		padding: 5px !important;
		.MuiSvgIcon-root {
			display: none;
		}
	}

	.MuiTypography-root {
		border-radius: 50px;
		border: 0.5px solid ${(props) => (props.theme.black)};
		align-items: center;
		display: flex;
		padding: 10px 16px;
		height:100%;
		color: #1D1B20;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		font-weight: 600;
		font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)};
	}
	.checkedItem{
		display:none;
	}
	.checkedItem2{
		display:none !important;
	}
	.Mui-checked + span {
		border: 0.5px solid ${(props) => (props.theme.checkboxPurple)};
		background-color: ${(props) => (props.theme.checkboxPurple)};
		color: #3F1956;
		font-weight: 800;
		font-family: ${(props) => (props.theme.KarbonSemiBold)};
		.checkedItem{
			display:block;
		}
	}
`;

function CheckboxInputDesignable({ containerProps, ...props }) {
	const [value, setValue] = useState(props);
	const { locale } = useContext(LocaleContext);
	const handleCheck = (e) => {
		setValue((prev) => ({
			...prev,
			["check"]: e.target.checked
		}));
	};

	return (
		<FormControl>
			{props.heading ? (
				<WrapperFormLabel id="yes-no-input-radio-button">{props.heading}</WrapperFormLabel>
			) : (
				<WrapperFormLabel id="yes-no-input-radio-button">&nbsp;</WrapperFormLabel>
			)}

			{/* <><CheckOutlinedIcon fontSize='small' style={{marginRight:'5px'}} /> */}
			<WrapperFormControlLabel locale={locale} control={<Checkbox onChange={handleCheck} name={value?.label} checked={value?.check} />} label={<><CheckOutlinedIcon className={props?.hideCheckIcon?'checkedItem2':'checkedItem'} fontSize='small' style={{ marginRight: '5px' }} />{props.label} {props?.amount ? (props?.amount) + " SAR" : ''}</>} />
		</FormControl>
	);
}

export default CheckboxInputDesignable;