import React, { useContext } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { string } from "prop-types";
import { LocaleContext } from 'routes/Router';

const WrapperLink = styled(Link)`
    font-size: ${(props) => (props.size + "px")} !important;
    font-weight: ${(props) => (props.fontWeight)} !important;
    font-family: ${(props) => (props.fontFamily)} !important;
    color: ${(props) => (props.disabled === true ? "#B8B8B8" : props.theme[props.textColor])} !important;
    margin: ${(props) => (props.margin + "px")} !important;
    padding: ${(props) => (props.padding + "px")} !important;
    text-decoration: ${(props) => (props.TextDecoration)} !important;
    cursor: ${(props) => (props.cursor)} !important;
    pointer-events:${(props) => (props.disabled === true ? "none" : "")} 

    ${(props) =>
        props.lineHeight && `
        line-height:  ${props.lineHeight + 'px'} !important;
    `}

    ${(props) =>
        props.borderBottom && `
        border-bottom:  1px solid #FFFFFF;
    `}
`;

function MuiLink(props) {
    const { locale } = useContext(LocaleContext);
    return (
        <WrapperLink {...props} fontFamily={props.fontFamily?props.fontFamily:locale=="en"?"Karbon_semibold":"Luma_semibold"}>
            {props.children}
        </WrapperLink>
    );
}

MuiLink.propTypes = {
    color: string.isRequired,
};

MuiLink.defaultProps = {
    size: 16,
    // fontFamily: 'Karbon_semibold',
    cursor:"pointer",
    fontWeight: 800,
    textColor: 'purple',
    TextDecoration: 'none',
};

export default MuiLink;