import React, { useContext } from "react";
import styled from 'styled-components';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
`;

const WrapperStepLabel = styled(StepLabel)`
	.MuiStepLabel-iconContainer {
		.MuiStepIcon-root {
			color: ${(props) => props.theme.lightPurple};
			width: 32px;
			height: 32px;

			.MuiStepIcon-text {
				font-size: 20px;
				font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)}
				line-height: 24px;
				font-weight: 800;
				fill: ${(props) => props.theme.purple};
			}
		}

		.MuiStepIcon-root.Mui-active {
			color: ${(props) => props.theme.yellow};
		}

		.MuiStepIcon-root.Mui-completed {
			color: ${(props) => props.theme.green};
		}
	}

	.MuiStepLabel-labelContainer {
		.MuiStepLabel-label {
			font-size: 18px;
			line-height: 27px;
			font-weight: 800;
			font-family: ${(props) => props.theme.KarbonSemiBold};
			color: ${(props) => props.theme.purple};
		}

		.MuiStepLabel-label.Mui-disabled { 
			color: ${(props) => props.theme.lightDark};
			font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)}
			font-weight: 600;
		}
	}
	@media (max-width: 993px) {
		p {
			display:none;
		}
	  }
`;

const WrapperStep = styled(Step)`
	.MuiStepConnector-root {
		left: calc(-50% + 16px);
    	right: calc(50% + 16px);

		.MuiStepConnector-line {
			border-color: ${(props) => props.theme.secondaryPurple};
			border-top-width: 8px;
		}
	}
`;

function StepperHorizontalWithTitle({steps, ...props }) {
	const { locale } = useContext(LocaleContext);

	return (
		<WrapperDiv>
			<Stepper activeStep={props.curStep} alternativeLabel
			 sx={{
				direction: locale === 'ar' &&'ltr', // Set direction based on language
			  }}
			>
				{steps.map((label) => (
		          	<WrapperStep key={label} curStep={props.curStep}>
		            	<WrapperStepLabel locale={locale }><p> {label} </p></WrapperStepLabel>
		          	</WrapperStep>
		        ))}
			</Stepper>
		</WrapperDiv>
	);
}

export default StepperHorizontalWithTitle;