import React, { useContext } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocaleContext } from 'routes/Router';

const WrapperSelectField = styled(Select)`
	margin-top: 20px;
	border-radius: ${(props) => props.borderRadius + "px"} !important;
	font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)} !important;
	font-size: ${(props) => props.fontSize + "px"} !important;
	color: ${(props) => props.theme[props.textColor]} !important;
	height: 56px;
	padding: 15px 10px;

	${(props) =>
		props.bgColor && `
    		background-color:  ${props.theme[props.bgColor]};
 	`}

 	.MuiSelect-select.MuiSelect-outlined {
 		opacity: 0;
 	}
 	.MuiOutlinedInput-notchedOutline {
 		border: 1px solid ${(props) => props.theme.lightDarkSecondary};

 		${(props) =>
		props.borderNone && `
	 	    	border: none;
	 	`}

	 	legend {
			height: 56px !important; 
			font-size: 18px;
			line-height: 27px;
			max-width: 100% !important;
	 		span {
 				padding: 20px 10px !important;
 				opacity: 1 !important;
	 		}
	 	}
 	}

 	.MuiSvgIcon-root {
		border: 1px solid ${(props) => props.theme.lightDark};
		border-width: 0 1px 1px 0;
		display: inline-block;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		padding: 3px;
		font-size: 16px;
		right: 15px;
		top: calc(50% - 0.5em);
		color: transparent;
		width: 10px;
		height: 10px;
	}

	.MuiSvgIcon-root.MuiSelect-iconOpen {
		transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
		top: calc(50% - 0em);
	}
`;

function MultiSelectInput({ ...props }) {
	const { locale } = useContext(LocaleContext);
	return (
		<FormControl fullWidth>
			<WrapperSelectField
			locale={locale}
				labelId="multiple-checkbox-label"
				id="multiple-checkbox"
				{...props}
			>
				{props.children}
			</WrapperSelectField>
		</FormControl>
	);
};

MultiSelectInput.defaultProps = {
	borderRadius: 4,
	textColor: "lightGray"
};

export default MultiSelectInput;