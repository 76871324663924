import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LeftBarImage from "components/LeftBarImage";
import Forgot from "features/ForgotPassword/Forgot";
import Verification from "features/ForgotPassword/Verification";
import PasswordVerify from "features/ForgotPassword/PasswordVerify";
import Successfull from "features/ForgotPassword/Successfull";
import layoutImage from "assets/images/LayoutImage/layoutImage.png";
import layoutImageSuccess from "assets/images/LayoutImage/Sucess.png";
import LabelImage from "assets/images/icon/encrypted.png";
import Text from "components/Text";
import { useSelector } from "react-redux";

export const BottomText = styled.div`
position: fixed;
bottom: 30px;
display: flex;

@media screen and (max-width: 900px) {
    position: unset;
    bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}
@media screen and (max-height: 800px) {
    position: unset;
    bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}
@media screen and (min-height: 900px) {
    position:absolute;
    bottom:30px;
    margin-top: 15px;
    margin-bottom: 15px;
 }
`;

const ForgotPassword = () => {
    const [formValue, setFormValue] = useState('');
    const [isSuccessFull, setIsSuccessFull] = useState(false);
    const [curStep, setCurStep] = useState(1);
    const lang = useSelector((state) => state).basicReducer.languageToggle;
    const handleCurStep = (value) => {
        if (value === 'success') {
            setIsSuccessFull(true);
        } else {
            setCurStep(value);
        }
    };

    const handleFormData = (value) => {
        setFormValue(value);
    };

    return (
        <Box>
            <Grid container spacing={{md:10}}>
                <Grid item xs={12} md={5} display={{xs:'none',md:'block'}}>
                    <LeftBarImage bgImage={isSuccessFull ? layoutImageSuccess : layoutImage} width="100%" bgColor="lightPurple" />
                </Grid>

                <Grid item xs={12} md={7} marginTop={{xs:'60px',md:"0px"}} padding={{xs:'10px',md:"0px"}} sx={{ display: "flex", alignItems: "center" }} paddingRight={{ xs:'0',md:'80px' }} >
                    <Grid container padding={{xs:"0 15px", md: "0px"}}>
                        <Grid item xs={12}>
                            {isSuccessFull ? (
                                <Successfull />
                            ) : (
                                <>
                                    {curStep === 2 ? (
                                        <Verification handleCurStep={handleCurStep} postFormData={formValue} />
                                    ) : curStep === 3 ? (
                                        <PasswordVerify handleCurStep={handleCurStep} />
                                    ) : (
                                        <Forgot handleCurStep={handleCurStep} handleFormData={handleFormData} />
                                    )}
                                </>
                            )}
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <BottomText>
                                <img width="24" height="24" style={{ marginRight: '8px', marginLeft: lang == 'ar' ? '8px' : '0' }} src={LabelImage} alt="" />

                                <Text marginTop="3" size="14" color="footerGrayText" lineHeight="16" fontWeight="700">
                                    <FormattedMessage id="footerText" />
                                </Text>
                            </BottomText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ForgotPassword;