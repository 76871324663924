import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H1Text from "components/Text/H1Text";
import H5Text from "components/Text/H5Text";
import Text from "components/Text";
import CheckBoxInput from "components/Input/CheckboxInput";
import SwitchWithLabel from "components/Input/SwitchWithLabelInput";
import ButtonInput from "components/Input/ButtonInput";
import LeftBarImage from "components/LeftBarImage";
import layoutImage from "assets/images/LayoutImage/layoutImage.png";
import CheckValidation from "./validation";
import { getApplicationRejected, getRequestCallback, getRevisedAccountFetch, getRevisedFinancingInitialDataURL } from 'routes/AppUrls';
import { financingCallAPI } from "store/financing/action";
import { CAR_GOSI, CAR_PENSION } from "store/financing/constant";
import { LocaleContext } from 'routes/Router';
import SelectInput from "components/Input/SelectInput";
import { financingType } from "store/basic/actions";
import { FINANCING_TYPE } from "store/basic/constant";
import { ResumeOnlineFormCall } from "store/resumeOnlineForm/actions";
import { RESUME_ONLINE_FORM } from "store/resumeOnlineForm/constant";
import { financingDropDownCallAPI } from "store/financingDropDown/action";
import { FINANCING_DROPDOWN } from "store/financingDropDown/constant";
import CircularProgress from '@mui/material/CircularProgress';
import LabelWithInput from "components/Input/LabelWithInput";
import { updateWorkItemCallAPI } from "store/workItem/updateWorkItem/action";
import { UPDATE_WORK_ITEM } from "store/workItem/updateWorkItem/constant";
import moment from "moment";

export const WrapperArrowBackIcon = styled(ArrowBackIcon)`
    width: "24px";
    height: "20px";
    color: ${(props) => props.theme.purple}
`;

const RevisedFinancing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const { locale } = useContext(LocaleContext);
    const [isError, setIsError] = useState(false);
    const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);
    const financingCategoryDropdown = useSelector((state) => state?.financingDropDownReducer?.payload?.data);
    const financingLoader = useSelector((state) => state?.financingReducer?.loading);
    const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state.fetchWorkItemReducer?.payload?.data?.data);
    const updateWorkItemLoader = useSelector((state) => state?.updateWorkItemReducer?.loading);
    const [dependentNumber, setDependentNumber] = useState(FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.noOfDependents ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.noOfDependents : "");
    const [loanPurpose, setLoanPurpose] = useState(FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.purposeOfPurchasing ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.purposeOfPurchasing : "");
    const [useType, setUseType] = useState(FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.useType ? FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.useType : "");
    const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);

    // residence_type
    const [formValue, setFormValue] = useState({
        gosiPension: false,
        usedFor: false,
        acknowledge: false,
        agree: false
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCheckboxChange = (e, name) => {
        setFormValue((prevState) => ({
            ...formValue,
            [name]: e.target.checked,
        }));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);

        if (error.submit) {

            let body = {
                resumeForm: false,
                step: 0
            };
            dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
            dispatch(financingType(FINANCING_TYPE, formValue.gosiPension ? "pension" : "gosi", { navigate: navigate }));

            const bodyValue = {
                idNumber: userMeData?.data?.nationalId
            };

            if (!userMeData?.data?.is_saudi) {
                if (formValue.gosiPension) {
                    bodyValue.dateOfBirth = userMeData?.data?.HijriDOB;
                    dispatch(financingCallAPI(CAR_PENSION, bodyValue, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getApplicationRejected(locale) }));
                } else {
                    dispatch(financingCallAPI(CAR_GOSI, bodyValue, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getApplicationRejected(locale) }));
                }
            } else {

                if (formValue.gosiPension) {
                    bodyValue.dateOfBirth = moment(userMeData?.data?.HijriDOB, 'DD-MM-YYYY')?.format("YYYY-MM-DD");
                    dispatch(financingCallAPI(CAR_PENSION, bodyValue, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getRevisedAccountFetch(locale) }));
                } else {
                    dispatch(financingCallAPI(CAR_GOSI, bodyValue, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getRevisedAccountFetch(locale) }));
                }
            }

        }
    };

    const UpdateWorkItem = () => {
        FetchWorkItemData['operation'] = "save";

        if (formValue.usedFor) {
            FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['purposeOfPurchasing'] = 3);
        } else {
            FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['purposeOfPurchasing'] = 1);
        }
        FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['useType'] = useType);
        FetchWorkItemData?.basicDetails?.guestDetails?.[0] && (FetchWorkItemData.basicDetails.guestDetails[0]['noOfDependents'] = dependentNumber);
        dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleSubmit, navigate: navigate, run: true }));
    }

    useEffect(() => {
        getCarCategory()
    }, []);

    const getCarCategory = () => {
        dispatch(financingDropDownCallAPI(FINANCING_DROPDOWN, {}, { navigate: navigate }));
    };

    return (
        <Box>
            <Grid container spacing={{ md: 10 }}>
                <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                    <LeftBarImage bgImage={layoutImage} width="100%" bgColor="lightPurple" />
                </Grid>
                <Grid item xs={12} md={7} style={{ marginTop: "30px" }}>

                    <Grid container padding={{ xs: "0 5px", sm: "20px", md: "" }}>
                        <WrapperArrowBackIcon fontSize="large" style={{ cursor: 'pointer', transform: locale === 'ar' ? 'scaleX(-1)' : '' }} onClick={() => navigate(-1)} />
                        <Grid item xs={12} >
                            <H1Text marginTop="40" color="primary" lineHeight="60">
                                <FormattedMessage id="autoFinance.onlineFinancing" />
                            </H1Text>
                            <Text marginTop="0" color="lightDark">
                                <FormattedMessage id="autoFinance.onlineFinancingSubText" />
                            </Text>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} marginTop='40px'>
                            <SelectInput
                                data={financingCategoryDropdown?.use_type}
                                label={<FormattedMessage id="autoFinance.useType" />}
                                placeholder="Use Type"
                                value={useType}
                                onChange={(e) => setUseType(e.target.value)}
                            />
                            {/* <SelectInput
                                label={<FormattedMessage id="autoFinance.usedfor" />}
                                placeholder="Used for"
                            /> */}
                            <Grid item xs={4} sm={6} md={6} lg={6} marginTop='20px'>
                                {/* <H5Text size="16" lineHeight={"24"} marginTop='0' fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"}>
                                    <FormattedMessage id="autoFinance.usedfor" /></H5Text> */}
                                {/* <div style={{ display: "flex" }}>
                                    <SwitchWithLabel
                                        marginTop="8px"
                                        locale={locale}
                                        onText={<FormattedMessage id="autoFinance.business" />}
                                        offText={<FormattedMessage id="familyFriends" />}
                                        checked={formValue.usedFor}
                                        onChange={(e) => handleCheckboxChange(e, 'usedFor')}
                                        name="usedFor"
                                    />
                                </div> */}
                            </Grid>

                            <SelectInput
                                data={financingCategoryDropdown?.purpose_of_purchase}
                                label={<FormattedMessage id="autoFinance.purposeofLoan" />}
                                placeholder="Purpose of Loan"
                                value={loanPurpose}
                                onChange={(e) => setLoanPurpose(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: isMobile ? "10px" : "", marginTop: '24px' }}>
                            <CheckBoxInput
                                label={<FormattedMessage id="autoFinance.acknowledgeBeneficiaryProduct" />}
                                size="16"
                                fontWeight="600"
                                checked={formValue.acknowledge}
                                name="acknowledge"
                                onChange={(e) => handleCheckboxChange(e, 'acknowledge')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: isMobile ? "10px" : "" }}>
                            <CheckBoxInput
                                label={<FormattedMessage id="autoFinance.readAgreeRequesting" />}
                                size="16"
                                fontWeight="600"
                                checked={formValue.agree}
                                name="agree"
                                onChange={(e) => handleCheckboxChange(e, 'agree')}
                            />
                        </Grid>

                        {userMeData?.data?.is_saudi && <Grid item xs={4} sm={6} md={6} lg={6} >
                            <div style={{ display: "flex" }}>
                                <SwitchWithLabel
                                    locale={locale}
                                    onText={<FormattedMessage id="gosi" />}
                                    offText={<FormattedMessage id="pension" />}
                                    checked={formValue.gosiPension}
                                    onChange={(e) => handleCheckboxChange(e, 'gosiPension')}
                                    name="gosiPension"
                                />
                            </div>
                        </Grid>}
                        <Grid item xs={12} sm={12} md={8} lg={8} marginTop='24px'>
                            <LabelWithInput
                                label={<FormattedMessage id={'autoFinance.numberofDependents'} />}
                                placeholder="Number of Dependents"
                                type='number'
                                inputProps={{ maxLength: 3 }}
                                onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, e.target.maxLength) }}
                                value={dependentNumber}
                                onChange={(e) => setDependentNumber(e.target.value)}
                            />
                            {/* <SelectInput
                                data={financingCategoryDropdown?.house_type}
                                label={<FormattedMessage id="autoFinance.houseType" />}
                                placeholder="House Type"
                                value={houseType}
                                onChange={(e) => setHouseType(e.target.value)}
                            /> */}
                        </Grid>
                        <Grid container style={{ marginTop: "50px" }} width='100%'>
                            <Grid item xs={!isMobile ? 8 : 12}>
                                <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                    <Box component="form">
                                        <Stack spacing={2} direction="row">
                                            <ButtonInput
                                                title={<FormattedMessage id="revisedFinance.leadManagment" />}
                                                variant="contained"
                                                bgColor={"transparent"}
                                                textColor="purple"
                                                borderRadius="100"
                                                TextTransform="capitalize"
                                                onClick={() => navigate(getRequestCallback(locale))}
                                            />
                                            <ButtonInput
                                                title={<FormattedMessage id="next" />}
                                                variant="contained"
                                                bgColor={(formValue.acknowledge && formValue.agree) ? "yellow" : "lightYellow"}
                                                textColor="purple"
                                                borderRadius="100"
                                                TextTransform="capitalize"
                                                disabled={updateWorkItemLoader || !(formValue.acknowledge && formValue.agree)}
                                                endIcon={updateWorkItemLoader || financingLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                                onClick={UpdateWorkItem}
                                            />
                                        </Stack>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RevisedFinancing;