import React, { useContext, useEffect, useState } from 'react';
import { LocaleContext } from 'routes/Router';
import styled from 'styled-components';


const WrapperDiv = styled.div`
	display: flex;
    justify-content: center;
    position: relative;
`;

const WrapperCircleDiv = styled.div`
	width: 130px;
    height: 130px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    background-image: conic-gradient(  #3F1956 0deg,  #3F1956 300deg,  #DFD3E7 312deg,  #DFD3E7 360deg);
`;

const WrapperFirstDiv = styled.div`
	width: 120px;
    height: 120px;
    background: #F2F1F6;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const WrapperSecondDiv = styled.div`
	width: 100px;
    height: 100px;
    background: #f9f9f9;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const WrapperCodeDiv = styled.div`
	width: 78px;
    height: 78px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 48px;
    line-height: 58px;
    font-family: ${(props) => props.locale == "en" ? 'Karbon_light' : 'Luma_light'};
    color: #3F1956;
    font-weight: 700;
`;

const WrapperTimerDiv = styled.div`
	width: 70px;
	height: 33px;
	background: #fff;
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	font-family: ${(props) => props.locale == "en" ? 'Karbon_light' : 'Luma_light'};
	// color: #3F1956;
	color: ${props => props.timerCount === "00:00" ? '#EB5555' : '#3F1956'};
	border-radius: 50px;
	text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    bottom: -12px;
`;

function TimerWithCode({ ...props }) {

	const backgroundImageTpl = `conic-gradient(
		#3F1956 0deg,
  		#3F1956 {angle}deg,
  		#DFD3E7 {angle}deg,
  		#DFD3E7 360deg
	)`;

	const [timerCount, setTimerCount] = useState(props?.timeCountValue);
	const [minSecondsLeft, setMinSecondsLeft] = useState(props?.minSecondsLeft);
	const [maxSecondsLeft, setMaxSecondsLeft] = useState(150);
	const { locale } = useContext(LocaleContext);
	useEffect(() => {
		setTimeout(() => {
			countDowntiming();
		}, 1000);

		if (props?.minSecondsLeft === 150) {
			setTimerCount("02:29");
			setMinSecondsLeft(149);
		};
	}, [minSecondsLeft, props?.minSecondsLeft === 150])

	function pad2Digits(text) {
		let out = '00' + text;
		return out.substring(out.length - 2);
	};

	function countDowntiming() {
		let timing = pad2Digits(Math.floor(minSecondsLeft / 60)) + ':' + pad2Digits(minSecondsLeft % 60);
		setTimerCount(timing);
		props?.timerValue(timing);

		let angle = 360 - Math.floor(360 * minSecondsLeft / maxSecondsLeft);

		if (document.getElementById('timecountdown')) {
			document.getElementById('timecountdown').style.backgroundImage = backgroundImageTpl.replace(/\{angle\}/g, angle);
		}

		if (minSecondsLeft > 0) {
			let timingLeft = minSecondsLeft - 1;
			setMinSecondsLeft(timingLeft);
			props?.minSecondsValue(timingLeft);
		}
		// else {
		// 	setMinSecondsLeft(90);
		// }
	}

	return (
		<>
			<WrapperDiv data-seconds-left={props.maxSecond} data-max-seconds={props.maxSecond}>
				<WrapperCircleDiv id="timecountdown">
					<WrapperFirstDiv>
						<WrapperSecondDiv>
							<WrapperCodeDiv locale={locale}>
								{timerCount === "00:00" ? "" : props?.authCode}
							</WrapperCodeDiv>
						</WrapperSecondDiv>
					</WrapperFirstDiv>
				</WrapperCircleDiv>
				<WrapperTimerDiv locale={locale} classname="timertime" timerCount={timerCount}>
					{timerCount}
				</WrapperTimerDiv>
			</WrapperDiv>
		</>
	)
}

export default TimerWithCode;