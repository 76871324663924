import { RESUME_ONLINE_FORM } from "./constant";

export const ResumeOnlineFormCall = (type, data) => {
    switch (type) {
        case RESUME_ONLINE_FORM:
            return {
                type: type,
                data: data
            }
    }
};


