import React, { useContext } from "react";
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { LocaleContext } from 'routes/Router';

const WrapperFormLabel = styled(FormLabel)`
	font-size: 16px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
	font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)} !important;
	color: ${(props) => props.theme.darkSecondary} !important;
	margin-bottom: 5px;
`;

const WrapperRadio = styled(Radio)`
	span {
		display: none;
	}
`;

const WrapperRadioGroup = styled(RadioGroup)`
	.MuiFormControlLabel-root {
		margin-left: 0px !important;
		.MuiFormControlLabel-label {
			border-radius: 4px;
			border: 1px solid ${(props) => props.theme.lightDarkSecondary};
			width: 86px;
			height: 40px;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			text-align: center;
			font-family: ${(props) => (props.locale=="en" ? props.theme.KarbonLight : props.theme.LumaLight)};
			color: #909FA6;
			align-items: center;
		    display: inline-flex;
	    	justify-content: center;
		}

		.Mui-checked + span {
			border: 1px solid ${(props) => props.theme.darkArrow};
			color: ${(props) => props.theme.darkArrow};
		}
	}
	.MuiButtonBase-root{
		display:none;
	}
`;

function YesNoInput({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);
	const handleSelect = (val) => {
		props.getSelectedYesNoValue(val);
	}

	return (
		<FormControl fullWidth>
			<WrapperFormLabel locale={locale} id="yes-no-input-radio-button">{props.label}</WrapperFormLabel>

			<WrapperRadioGroup
			locale={locale}
				row
				aria-labelledby="yes-no-input-radio-button"
				{...props}
			>
				<FormControlLabel
					style={{ marginRight: locale === 'ar' && '0px' }}
					value="no"
					control={<WrapperRadio disableRipple />}
					label={<FormattedMessage id="no" />}
					onClick={() => handleSelect("No")}
					checked={props?.selectedYesNoValue === "No"}
				/>
				<FormControlLabel
					value="yes"
					control={<WrapperRadio disableRipple />}
					label={<FormattedMessage id="yes" />}
					onClick={() => handleSelect("Yes")}
					checked={props?.selectedYesNoValue === "Yes"}
				/>
			</WrapperRadioGroup>
		</FormControl>
	);
}

export default YesNoInput;