import {
   AUTH_NAFATH_AUTHENTICATION,
   AUTH_NAFATH_ERROR,
   AUTH_NAFATH_SUCCESS,
   AUTH_NAFATH_STATE
} from "./constant";

const nafathAuthReducer = (state = {}, action) => {
   switch (action.type) {
      case AUTH_NAFATH_AUTHENTICATION:
         return { ...state, loading: true, error: '' };
      case AUTH_NAFATH_ERROR:
         return { ...state, type: AUTH_NAFATH_ERROR, loading: false, error: action.payload };
      case AUTH_NAFATH_SUCCESS:
         return { ...state, type: AUTH_NAFATH_SUCCESS, loading: false, payload: action.payload, error: '' };
      case AUTH_NAFATH_STATE:
         return { state, loading: false, error: '' };
      default:
         return state;
   }
};

export default nafathAuthReducer;