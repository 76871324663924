import React from "react";
import { FormattedMessage } from "react-intl";

const CheckValidation = value => {

    const formError = {
        submit: true,
        groceriesExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        healthcareExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        educationExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        housingExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        homeLaborExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        communicationAndTransExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        insuranceExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        futureExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        },
        waterAndElectrExpenses: {
            error: false,
            errorMessage: <FormattedMessage id="this field is required" />
        }
    };

    const emptyKeys = [
        "groceriesExpenses",
        "healthcareExpenses",
        "educationExpenses",
        "housingExpenses",
        "homeLaborExpenses",
        "communicationAndTransExpenses",
        "insuranceExpenses",
        "futureExpenses",
        "waterAndElectrExpenses"
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        // if (isNaN(value[emptyKeys[i]])) {
        //     formError[emptyKeys[i]].error = true;
        //     formError.submit = false;
        // }
        if ((value[emptyKeys[i]] <= 0)) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }

    return formError;
}

export default CheckValidation;