import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import AnimatedImage from 'components/AnimatedImage';

import IconWithText from "components/IconWithText";
import Text from "components/Text";
import BrandImage from "assets/images/Application/Monogram.png";
import UnionImg from 'assets/images/icon/Union.png';
import PercentImg from 'assets/images/icon/percent.png';
import CarImg from 'assets/images/carTransprent.png';
import { Box } from '@mui/material';
import { LocaleContext } from 'routes/Router';
import backArrow from 'assets/images/icon/backArrow.png';
import copy from 'assets/images/Contracts/CopySimple.png';
import { useNavigate } from 'react-router-dom';

const WrapperDiv = styled.div`
	background: ${(props) => (props.theme[props.bgColor])};
	border-radius: 15px;
	margin-top: 30px;
	padding: 20px;
`;

const IconGroup = styled.div`
	position: ${(props) => props.listtype ? "" : "absolute"}; 
	right: 0;
	display: flex;
	justify-content: end;
	padding: 10px 20px 0 0;
	border-radius: 12px;
	max-height:25%;
	z-index: 9;
`;

const IconStyle = styled.div`
	background:${(props) => props?.background};
	border-radius:50%;
	min-width:40px;
	min-height:40px;
	max-width:40px;
	max-height:40px; style={{margin:'0'}}
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:${(props) => props?.marginleft};
`;

function ProductDetails({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);
	const navigate = useNavigate();
	return (
		<WrapperDiv {...props}>

			<div style={{ position: "relative" }}>
				{props?.printInvoice &&
					<>
						<Box display="flex">
							<img src={backArrow} onClick={() => navigate(-1)} alt="" style={{ cursor: "pointer", transform: locale === 'ar' ? 'scaleX(-1)' : '', margin: locale === 'en' ? '7px 24px 0 0' : '7px 0 0 24px', width: "24px", height: "20px" }} />
							<Box>
								<Text marginTop="0"
									color="purple"
									//  color={selectedDocumentGeneration === data.id ?"white":"lightDark"} 
									fontWeight="800" size="24" fontFamily={locale === "en" ? "KarbonSemiBold" : "LumaSemiBold"} lineHeight="36">
									Print Invoices
								</Text>
								<Box display="flex">
									<Text marginTop="0"
										color="lightGrey"
										// color={selectedDocumentGeneration === data.id ?"white":"lightGrey"}
										 size="18" fontFamily={locale === "en" ? "KarbonLight" : "Lumalight"} lineHeight="32">
										Service No.
									</Text>
									<Text marginTop="0"
										color="lightGrey"
										// color={selectedDocumentGeneration === data.id ?"white":"lightGrey"}
										 size="22" fontFamily={locale === "en" ? "KarbonLight" : "Lumalight"} lineHeight="32">
										&nbsp;SR/12345674377<img src={copy} alt="" style={{ cursor: "pointer", margin: locale === 'ar' ? '0px 6px 0 0' : '0px 0 0 6px', width: "24px", height: "24px" }} />
									</Text>
								</Box>
							</Box>
						</Box>
					</>
				}
				{!props?.printInvoice && <IconGroup>
					<IconStyle background={'#FEF7FF'} >
						<img alt="" src={UnionImg} />
					</IconStyle>
					<IconStyle background={'var(--primary)'} marginleft={'8px'}>
						<img alt="" src={PercentImg} />
					</IconStyle>
				</IconGroup>}
				<Box style={{ transform: props.locale === 'ar' ? 'scaleX(-1)' : '', minHeight: '240px', display: 'flex', alignItems: 'center' }}>
					<AnimatedImage src={props.data?.data?.vehical_details_en.length > 0 ? props.data?.data?.vehical_details_en[0].image : props.data?.data?.ModelImage} minHeight={"100%"} />
				</Box>
			</div>

			<Box marginTop={'20px'}>
				<IconWithText
					boxShadow={false}
					BrandIcon={props.data?.data?.BrandIcon}
					variant={'rounded'}
					BrandSize={48}
					text={props.data?.data?.ModelDescription}
					textSize="24"
					secondText={"SAR" + " " + props.data?.data?.RetailPrice}
					secondTextSize="20"
					secondFontWeight={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}
					thirdText={props.data?.data?.YearModel + " " + props.data?.data?.ModelGrade}
					thirdTextSize="18"
					thirdTextColor="gray"
					{...props}
				/>
			</Box>

			<div>
				<Text marginTop="0" color="purple" size="20" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>
					<FormattedMessage id="colorsMaterial" />
				</Text>
				<div>
					<Grid container marginTop='8px' marginBottom={'8px'}>
						<Grid item xs={7}>
							<div style={{ display: "flex" }}>
								<Text marginTop="0" color="gray" fontWeight="600" size="18" fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"}>
									<FormattedMessage id="external" />&nbsp;
								</Text>
								<Text marginTop="0" color="darkPrimary" fontWeight="700" size="18" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>

								</Text>
							</div>
						</Grid>
						<Grid item xs={5}>
							<div style={{ display: "flex" }}>
								<Text marginTop="0" color="gray" fontWeight="600" size="18" fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"}>
									<FormattedMessage id="internal" />&nbsp;
								</Text>
								<Text marginTop="0" color="darkPrimary" fontWeight="700" size="18" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>

								</Text>
							</div>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={7}>
							<div style={{ display: "flex" }}>
								<Text marginTop="0" color="gray" fontWeight="600" size="18" fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"}>
									<FormattedMessage id="material" />&nbsp;
								</Text>
								<Text marginTop="0" color="darkPrimary" fontWeight="700" size="18" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>

								</Text>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		</WrapperDiv>
	);
}

export default ProductDetails;