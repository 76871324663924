import React, { useContext } from 'react';
import styled from 'styled-components';
import { bool, string } from "prop-types";
import Grid from '@mui/material/Grid';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	padding: ${(props) => (props.paddingAll)+"px"};
	padding-bottom: ${(props) => (props.pBottom)+"px"};
`;

const GridContainer = styled(Grid)`	
	background: ${(props) => (props.bgColor)};
	
	${(props) =>
      	props.border === true && `
 	 	border: 0.5px solid ${props.theme[props.borderColor]};
 	    padding: 15px 10px;
 	    border-radius: 8px;
    `}

    ${(props) =>
      	props.brTopLeft === false && `
 	    border-radius: 0px 0px 16px 16px;
    `}
`;

const GridLeft = styled(Grid)`
	color: ${(props) => (props.theme[props.leftTextColor])};
	font-size: ${(props) => (props.size)+"px"};
	font-family: ${(props) => (props.leftFontFamily)};
	font-weight: ${(props) => (props.leftFontWight)};
	line-height: 27px;
`;

const GridRight = styled(Grid)`
	text-align: right;
	color: ${(props) => (props.theme[props.rightTextColor])};
	font-weight: 800;
	font-size: ${(props) => (props.size)+"px"};
	font-family: ${(props) => (props.rightFontFamily)};
	line-height: 27px;
`;

const WrapperSub = styled.sub`
    vertical-align: top;
    font-size: 18px;
    font-weight: 600;
    font-family: ${(props) => props.locale=="en" ? 'Karbon_light' : 'Luma_light'};
`;

function SideBySideText({leftText, RightText, containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);
	return (
		<WrapperDiv {...props} leftFontFamily={locale === 'en'?'Karbon_light':'Luma_light'} rightFontFamily={locale === 'en'?'Karbon_semibold':'Luma_semibold'}>
			<GridContainer container {...props}>
				<GridLeft item xs={6} {...props}>
					{leftText}
				</GridLeft>
				<GridRight item xs={6} {...props}>
					{RightText}
					<WrapperSub locale={locale}>{props.subText && props.subText}</WrapperSub>
				</GridRight>
			</GridContainer>	
			
		</WrapperDiv>
	);
}

SideBySideText.propTypes = {
  	leftTextColor: string.isRequired,
  	rightTextColor: string.isRequired,
  	border: bool.isRequired
};

SideBySideText.defaultProps = {
  	border: false,
  	// leftFontFamily: 'Karbon_light',
  	// rightFontFamily: 'Karbon_semibold',
  	pBottom: 15,
  	paddingAll: 0,
	bgColor:'transprent'
};

export default SideBySideText;