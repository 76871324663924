import React, { useContext } from "react";
import styled from 'styled-components';
import { bool } from "prop-types";
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    width: 0;
    margin-top: ${(props) => props.marginTop} !important;
	direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')}; // Set the direction here
	.Mui-checked{
		transform: ${props => (props.locale === 'en' ? "translateX(177px) !important" : "translateX(-177px) !important")};
		input{
			left: ${props => (props.locale === 'en' && "-48% !important")};
			right: ${props => (props.locale === 'ar' && "-48% !important")};
		}
	}
	.MuiSwitch-root{
		height: ${(props) => props.height} !important;
		// thumpHeight
		background:${(props) => props.backgroundColor} !important;
		border-radius:${(props) => props.borderRadius} !important;
	}
	.MuiSwitch-thumb{
		height: ${(props) => props.thumbHeight} !important;
		margin:${(props) => props.thumbMargin} !important;
		width: ${(props) => props.thumbWidth} !important;
		border-radius:${(props) => props.thumbBorderRadius} !important;
	}
`;

const WrapperTypography = styled(Typography)`
	font-size: 16px !important;
    position: absolute;
    z-index: 1;
    color: rgba(51, 51, 51, 1);
    user-select: none;
    pointer-events: none;
    text-align: center;
    width: 178px;
`;

const useStyles = makeStyles({
	root: {
		width: "360px !important",
		height: `42px !important`,
		// height: `${(props)=>props.height} !important`,
		padding: "0px !important",
		// backgroundColor: "rgba(239, 232, 243, 1)",
		borderRadius: "16px",
	},

	switchBase: {
		padding: "2px !important",
		width: "100%",
		justifyContent: "start !important",
		"&:hover": {
			background: "none !important",
		},
		"& input": {
			left: "0px !important",
			width: "100% !important",
		},
		"&$checked": {
			color: "rgba(63, 25, 86, 1) !important",
			"& + $track": {
				backgroundColor: "#000",
				opacity: 1,
				border: "none",
			},
			"& input": {
				width: "100% !important",
			}
		},
	},
	thumb: {
		// width: "178px !important",
		// height: "40px !important",
		borderRadius: "8px !important",
		boxShadow: "none !important",
		backgroundColor: "rgba(63, 25, 86, 1) !important",
		color: "rgba(255, 255, 255, 1) !important",
	},
	track: {
		borderRadius: "8px !important",
		backgroundColor: "rgba(239, 232, 243, 1) !important",
	},

	// Don't remove this empty class.
	checked: {},
});

function SwitchWithLabelInput({ onText, offText, checked, ...props }) {
	const { locale } = useContext(LocaleContext);
	const classes = useStyles({ props });

	return (
		<WrapperDiv locale={locale} {...props}>
			<WrapperTypography
				variant="subtitle1"
				style={{ right: locale === 'ar' && '6px', left: locale === 'en' && '6px', color: (!checked ? "rgba(255, 255, 255, 1)" : "#909FA6") }}
			>
				{onText}
			</WrapperTypography>
			<Switch
				checked={checked}
				disableRipple={true}
				classes={{
					root: classes.root,
					switchBase: classes.switchBase,
					thumb: classes.thumb,
					track: classes.track,
					checked: classes.checked,
					locale: classes.locale
				}}
				{...props}
			/>
			<WrapperTypography
				variant="subtitle1"
				style={{ right: locale === 'ar' && '180px', left: locale === 'en' && '180px', color: (!checked ? "#909FA6" : "rgba(255, 255, 255, 1)") }}
			>
				{offText}
			</WrapperTypography>
		</WrapperDiv>
	);
}

SwitchWithLabelInput.propTypes = {
	checked: bool.isRequired,
};

SwitchWithLabelInput.defaultProps = {
	checked: false,
	height: '42px',
	thumbHeight: '40px',
	backgroundColor: "rgba(239, 232, 243, 1)",
	thumbWidth: '178px',
	thumbMargin: '0px',
	borderRadius: '4px',
	thumbBorderRadius: '5px',
	marginTop: '20px'
};

export default SwitchWithLabelInput;