import React, { useContext } from 'react';
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from "components/Text/H5Text";
import ButtonInput from "components/Input/ButtonInput";
import SideBySideText from "components/SideBySideText";
import LabelWithInput from 'components/Input/LabelWithInput';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	.MuiInputBase-root{
		padding: ${(props)=> props.locale === 'en' ? '15px 3px 15px 15px' : '15px 15px 15px 3px'}  !important;
	}
	.MuiInput-root{
		border:1px dashed #D1D6D8;
	}
`;

const WrapperContentDiv = styled.div`
	border: 0.5px solid #B8B8B8;
	border-radius: 16px;
	margin-top: 16px;
`;

function AttachApplyForm({handleCurStep}) {
	const { locale } = useContext(LocaleContext);

	return (
		<WrapperDiv locale={locale}>
			<Grid container>
			
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id="autoFinance.uploadDocumentsApplicationFees" />
						<img alt="" src={require('assets/images/icon/exclamation.png')}  style={{ marginLeft:'15px'}} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
						<Grid item xs={12} sm={8} md={8} lg={10}>
			       			<LabelWithInput
								label={<FormattedMessage id="uploadLicense"/>}
								placeholder={locale === 'en' ? 'Driving License' : 'رخصة قيادة'}
								
								InputProps = {{
					      			endAdornment: (
					        			<ButtonInput
                                    title={<H5Text size="16" lineHeight='24' fontFamily='Karbon_bold' marginTop='0'><FormattedMessage id="upload" /></H5Text>}
                                    variant="text" 
                                    bgColor="purple"
                                    textColor="white"
                                    borderRadius="4"
                                    borderColor="lightPink"
                                    TextTransform="capitalize"
                                    size="16"
                                    minWidth="112"
                                    height="45"
                                    // onClick={() => props.onClick()}
                                />
					    			)
					      		}}
							/>
			       		</Grid>
							<Grid item xs={12} sm={8} md={8} lg={10}>
								<WrapperContentDiv>
									<SideBySideText 
										leftText={<FormattedMessage  id="autoFinance.applicationFee" />} 
										RightText="40.65 SR" 
										paddingAll="15" 
									/>
									<SideBySideText 
										leftText={<FormattedMessage  id="monthlyPayment" />}
										RightText="3,175 SR" 
										paddingAll="15" 
									/>
									<SideBySideText 
										leftText={<FormattedMessage  id="downPayment" />}
										RightText="1,500 SR" 
										paddingAll="15" 
									/>
									<SideBySideText 
										leftText={<FormattedMessage  id="totalVAT" />}
										RightText="27,000 SR" 
										paddingAll="15" 
									/>
									<SideBySideText 
										leftText={<FormattedMessage  id="autoFinance.totalIncludingVAT" />}
										RightText="31,715.65" 
										subText={<FormattedMessage  id="sar" />}
										leftFontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}
										leftTextColor="green" 
										rightTextColor="green" 
										leftFontWight="800" 
										size="20" 
										border={true} 
										borderColor="green" 
										pBottom="0" 
										brTopLeft={false} 
									/>
								</WrapperContentDiv>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{marginTop: "30px"}}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput 
									title={<FormattedMessage id="back" />}
									variant="text" 
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(3)}
								/>
								<ButtonInput 
									title={<FormattedMessage id="autoFinance.saveProceedTowardsSummary" />}
									variant="contained" 
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => handleCurStep(5)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	)
}


export default AttachApplyForm;