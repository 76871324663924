import messagesGlobalAr from "lang/global/ar.json";
import messagesLoginAr from "lang/login/ar.json";
import messagesRegisterAr from "lang/register/ar.json";
import messagesForgotAr from "lang/forgot/ar.json";
import messagesDashboardAr from "lang/dashboard/ar.json";
import messagesAutoFinanceAr from "lang/autoFinance/ar.json";
import messagesRevisedFinanceAr from "lang/revisedFinance/ar.json";
import messagesCashFinanceAr from "lang/cashFinance/ar.json";
import findVehicleAr from "lang/findVehicle/ar.json";
import financeCalculatorAr from "lang/financeCalculator/ar.json";
import messagesAccountAr from "lang/account/ar.json";
import VehicleComparisonAr from "lang/VehicleComparison/ar.json";

export default function TranslationsAr() {
    return {
        ...messagesGlobalAr,
        ...messagesLoginAr,
        ...messagesRegisterAr,
        ...messagesForgotAr,
        ...messagesDashboardAr,
        ...messagesAutoFinanceAr,
        ...messagesRevisedFinanceAr,
        ...messagesCashFinanceAr,
        ...findVehicleAr,
        ...financeCalculatorAr,
        ...messagesAccountAr,
        ...VehicleComparisonAr
    };
}