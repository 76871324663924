import {
   FETCH_WORK_ITEM,
   FETCH_WORK_ITEM_ERROR,
   FETCH_WORK_ITEM_SUCCESS
} from "./constant";

const fetchWorkItemReducer = (state = {}, action) => {
   switch (action.type) {
      case FETCH_WORK_ITEM:
         return { ...state, loading: true, error: '' };
      case FETCH_WORK_ITEM_ERROR:
         return { ...state, type: FETCH_WORK_ITEM_ERROR, loading: false, error: action.payload };
      case FETCH_WORK_ITEM_SUCCESS:
         return { ...state, type: FETCH_WORK_ITEM_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default fetchWorkItemReducer;