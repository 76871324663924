import { takeLatest, put } from "redux-saga/effects";
import {
    MANUAL_DBR,
    MANUAL_DBR_SUCCESS,
    MANUAL_DBR_ERROR,
} from "./constant";
import url from "utils/services/urls.json";
import { postMethodWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";


function* postManualDbr(action) {
    try {
        const response = yield postMethodWithToken(url.postManualDbr, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: MANUAL_DBR_SUCCESS, payload: response.data });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                if(response?.data?.data) {
                    action.external.handleFetchFunction(response);
                } else {
                    action.external.handleFunction();
                }
                
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: MANUAL_DBR_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: MANUAL_DBR_ERROR, payload: error });
    }
};


function* manualDBRSaga() {
    yield takeLatest(MANUAL_DBR, postManualDbr);
};

export default manualDBRSaga;