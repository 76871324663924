import {
    ONLINE_FINANCE_CALCULATOR,
    ONLINE_FINANCE_CALCULATOR_ERROR,
    ONLINE_FINANCE_CALCULATOR_INITIAL_STATE,
    ONLINE_FINANCE_CALCULATOR_SUCCESS
} from "./constant";


const financeCalculatorReducer = (state = {}, action) => {
    switch (action.type) {
        case ONLINE_FINANCE_CALCULATOR:
            return { ...state, loading: true, error: '' };
        case ONLINE_FINANCE_CALCULATOR_INITIAL_STATE:
            return { state, loading: false, error: '' };
        case ONLINE_FINANCE_CALCULATOR_ERROR:
            return { ...state, type: ONLINE_FINANCE_CALCULATOR_ERROR, loading: false, error: action.payload };
        case ONLINE_FINANCE_CALCULATOR_SUCCESS:
            return { ...state, type: ONLINE_FINANCE_CALCULATOR_SUCCESS, loading: false, payload: action.payload, error: '' };
        default:
            return state;
    }
};

export default financeCalculatorReducer;