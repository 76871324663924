import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckValidation from "./validation";
import { getDashboardURL } from "routes/AppUrls";
import { useNavigate } from "react-router-dom";
import { LocaleContext } from 'routes/Router';
import CheckMigratedUserValidation from "./migratedUserValidation";
import { userCallApi } from "store/user/action";
import { USER_API } from "store/user/constant";
import { CHANGE_PASSWORD } from "store/auth/changePassword/constant";
import { changePasswordCallAPI } from "store/auth/changePassword/action";

function ChangePassword({ handleCurStep }) {
    const isMobile = window.innerWidth < 900;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [isError, setIsError] = useState(false);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isRePassVisible, setIsRePassVisible] = useState(false);
    const [formValue, setFormValue] = useState({
        currentPassword: "",
        password: "",
        rePassword: ""
    });
    const AuthError = useSelector((state) => (state.authReducer?.error?.data?.message));
    const AuthData = useSelector((state) => (state.authReducer && state.authReducer?.payload?.data));
    const ChangePasswordData = useSelector((state) => (state.changePasswordReducer && state.changePasswordReducer?.payload?.data));
    const loader = useSelector((state) => state.authReducer?.loading || state.userReducer?.loading || state.changePasswordReducer?.loading);
    const userDetails = useSelector((state) => (state.userReducer && state.userReducer?.payload?.data));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    const handleVisiblePassword = (value) => {
        setIsPassVisible(!value);
    };

    const handleVisibleRePassword = (value) => {
        setIsRePassVisible(!value);
    };

    const checkConsentFormFunction = () => {
        let body = {};
        dispatch(userCallApi(USER_API, body, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getDashboardURL(locale), handleStep: handleCurStep, step: 'consent-form' }));
    };

    const handleSubmit = () => {
        const error = AuthData?.data?.migratedUser ? CheckMigratedUserValidation(formValue) : CheckMigratedUserValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let postBody = {};
            postBody.nationalId = AuthData?.data?.nationalId;
            (!AuthData?.data?.migratedUser && (postBody.old_password = formValue.currentPassword));
            postBody.password = formValue.password;
            postBody.action = "reset-password";
            dispatch(changePasswordCallAPI(CHANGE_PASSWORD, postBody, { handleFunction: checkConsentFormFunction, run: true, navigate: navigate, handleStep: handleCurStep, step: 2 }));
        }
    };

    return (
        <>
            <H1Text color="primary" size={isMobile ? "40" : "64"} lineHeight={isMobile ? "40" : "96"}>
                {AuthData?.data?.migratedUser ?
                    <FormattedMessage id="Set Passwod" />
                    :
                    <FormattedMessage id="Change Passwod" />
                }
            </H1Text>

            <Grid container>
                {!(AuthData?.data?.migratedUser) &&
                    <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "0px" }}>
                        <LabelWithInput
                            label={<FormattedMessage id="Current Password" />}
                            placeholder="Current Password"
                            name="currentPassword"
                            type={isPassVisible ? "text" : "password"}
                            onChange={handleInputChange}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {isPassVisible ? (
                                            <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                        ) : (
                                            <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                        )}
                                    </>
                                )
                            }}
                            errorText={isError && isError?.currentPassword?.error && isError?.currentPassword?.errorMessage}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
                    <LabelWithInput
                        label={<FormattedMessage id="forgot.newPassword" />}
                        placeholder="New Password"
                        name="password"
                        type={isPassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isPassVisible ? (
                                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.password.error && isError.password.errorMessage}
                        helperInfoPassword={true}
                    />
                </Grid>
                <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
                    <LabelWithInput
                        label={<FormattedMessage id="forgot.confirmPassword" />}
                        placeholder="Confirm Password"
                        name="rePassword"
                        type={isRePassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isRePassVisible ? (
                                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleRePassword(isRePassVisible)} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleRePassword(isRePassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.rePassword.error && isError.rePassword.errorMessage}
                    />
                </Grid>

                <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                <Stack spacing={2} direction="row">
                                    <ButtonInput
                                        title={<FormattedMessage id="back" />}
                                        variant="text"
                                        bgColor="transparent"
                                        textColor="purple"
                                        borderRadius="100"
                                        TextTransform="capitalize"
                                        minWidth="100px"
                                        marginRight="40"
                                        onClick={() => handleCurStep(2)}
                                    />
                                    <ButtonInput
                                        title={<FormattedMessage id="continue" />}
                                        variant="contained"
                                        bgColor={"yellow"}
                                        textColor="purple"
                                        borderRadius="100"
                                        TextTransform="capitalize"
                                        disabled={loader}
                                        endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> :  locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                        onClick={() => handleSubmit()}
                                    />
                                </Stack>
                            </Box>
                        </Grid>

                        {!loader &&
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                {AuthError ?
                                    <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                        {AuthError}
                                    </Text>
                                    :
                                    <Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
                                        {AuthData?.message}
                                    </Text>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ChangePassword;