import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ButtonInput from "components/Input/ButtonInput";
import { InputAdornment } from '@mui/material';
import DateTimePickerInput from 'components/Input/DateTimePickerInput';
import SelectAmongs from 'components/SelectAmongs';
import { useDispatch, useSelector } from 'react-redux';
import { screenScrollTop } from 'utils/general';
import { fetchWorkItemCallAPI } from 'store/workItem/fetchWorkItem/action';
import { FETCH_WORK_ITEM } from 'store/workItem/fetchWorkItem/constant';
import { useNavigate } from 'react-router-dom';
import { updateWorkItemCallAPI } from 'store/workItem/updateWorkItem/action';
import { UPDATE_WORK_ITEM } from 'store/workItem/updateWorkItem/constant';
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import CheckValidation from './validation';
import Text from 'components/Text';
import { financeCalculatorCallAPI } from 'store/financing/financeCalculator/action';
import { ONLINE_FINANCE_CALCULATOR } from 'store/financing/financeCalculator/constant';
import LocalStorageManager from 'managers/LocalStorageManger';
import { ResumeOnlineFormCall } from 'store/resumeOnlineForm/actions';
import { RESUME_ONLINE_FORM } from 'store/resumeOnlineForm/constant';
import { NetIncome } from 'store/basic/actions';
import { NET_INCOME } from 'store/basic/constant';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	border-radius:8px;
	background-color:white;
	
`;

const employmentStatus = [
	{
		id: 1,
		type: <FormattedMessage id="active" />
	},
	{
		id: 2,
		type: <FormattedMessage id="inactive" />
	},
];

const certificateType = [
	{
		id: 1,
		type: <FormattedMessage id="grossIncome" />
	},
	{
		id: 2,
		type: <FormattedMessage id="netIncome" />
	},
];

function PersonalWorkForm({ handleCurStep, isSaudi, handleInnerCurStep }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isError, setIsError] = useState(false);
	const [certificate, setCertificate] = useState("Gross Income");
	const { locale } = useContext(LocaleContext);
	const financingCategoryDropdown = useSelector((state) => (state.financingDropDownReducer && state.financingDropDownReducer?.payload?.data));
	const workItemListData = useSelector((state) => state.applicationListWorkItemReducer && state.applicationListWorkItemReducer?.payload?.data);
	const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state.fetchWorkItemReducer?.payload?.data?.data);
	const updateWorkItemLoader = useSelector((state) => state?.updateWorkItemReducer?.loading || state.financeCalculatorReducer?.loading);
	const storageData = useSelector((state) => state?.basicReducer);
	const FinanceCalculatorPayloadData = LocalStorageManager.getItem('finance_Calculator_payload') !== null && JSON.parse(LocalStorageManager.getItem('finance_Calculator_payload'));

	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);
	const getGosiPensionData = useSelector((state) => (state.financingReducer && state.financingReducer?.payload?.data?.data));
	const userMeData = useSelector((state) => state.userReducer && state?.userReducer?.payload?.data);

	const [formValue, setFormvalue] = useState({
		"occupation": FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.occupation ? FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.occupation : '',
		"employmentType": FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.employmentType ? FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.employmentType : '',
		"employeName": getGosiPensionData?.employmentStatusInfo && getGosiPensionData?.employmentStatusInfo[0]?.employerName ? getGosiPensionData?.employmentStatusInfo[0]?.employerName : '',
		"employementStatus": FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.employmentStatus ? FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.employmentStatus : 'Active',
		"employeNumber": FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.crNumber ? FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.crNumber : '',
		"joiningDate": getGosiPensionData?.employmentStatusInfo && getGosiPensionData?.employmentStatusInfo[0]?.dateOfJoining ? getGosiPensionData?.employmentStatusInfo[0]?.dateOfJoining : '',
		"basicWage": "",
		"housingAllowance": "",
		"otherAllowance": "",
		"netMonthlyIncome": +(storageData?.netIncome)
	});

	const monthlyNetIncome = +(formValue?.basicWage) + +(formValue?.housingAllowance) + +(formValue?.otherAllowance);

	useEffect(() => {
		fetchWorkItemApi();
	}, []);

	const fetchWorkItemApi = () => {
		let body = {
			appId: workItemAppId
		};
		dispatch(fetchWorkItemCallAPI(FETCH_WORK_ITEM, body, { navigate: navigate }));
	};

	const UpdateWorkItem = () => {
		const error = CheckValidation(formValue);

		setIsError(error);
		if (error.submit) {
			if (FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails["employmentDetails"] === undefined)) {
				let employmentDetails = [
					{
						occupation: formValue?.occupation,
						employmentType: formValue?.employmentType,
						employerName: formValue?.employeName,
						incomeCertificateType: '',
						monthlyIncome: 0,
						additionalIncome: 0,
						additionalIncomeConsidered: 0,
						deductionAgency: '',
						contributoryWage: 0,
						basicSalary: 0,
						gosiPensionAmount: 0,
						netMonthlyIncome: 0,
						idType: userMeData?.data?.is_saudi ? 'National ID' : 'Iqama ID',
						idNumber: userMeData?.data?.nationalId ?? '',
						employmentStatus: formValue?.employementStatus,
						occupationCode: '',
						crNumber: formValue?.employeNumber ?? '',
						typeOfRetiree: '',
						deductions: 0,
						allowedMonthlyInstallment: 0,
						dateOfHiring: '',
					},
				];
				FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails['employmentDetails'] = employmentDetails);
			} else {
				FetchWorkItemData?.basicDetails?.employmentDetails?.[0] && (FetchWorkItemData.basicDetails.employmentDetails[0]['occupation'] = formValue?.occupation);
				FetchWorkItemData?.basicDetails?.employmentDetails?.[0] && (FetchWorkItemData.basicDetails.employmentDetails[0]['employmentType'] = formValue?.employmentType);
				FetchWorkItemData?.basicDetails?.employmentDetails?.[0] && (FetchWorkItemData.basicDetails.employmentDetails[0]['employerName'] = formValue?.employeName);
				FetchWorkItemData?.basicDetails?.employmentDetails?.[0] && (FetchWorkItemData.basicDetails.employmentDetails[0]['employmentStatus'] = formValue?.employementStatus);
				FetchWorkItemData?.basicDetails?.employmentDetails?.[0] && (FetchWorkItemData.basicDetails.employmentDetails[0]['crNumber'] = formValue?.employeNumber);
				// FetchWorkItemData?.basicDetails?.employmentDetails?.[0] && (FetchWorkItemData.basicDetails.employmentDetails[0]['dateOfHiring'] = formValue?.joiningDate);
			}
			FetchWorkItemData && (FetchWorkItemData['operation'] = "save");
			dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleSubmit, navigate: navigate, run: true }));
		}
	};

	const handleSubmit = () => {
		if (isSaudi || storageData?.continueWith === "gosi") {
			handleCurStep(2);
			let body = {
				resumeForm: true,
				step: 2
			};
			dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
		} else {
			handleInnerCurStep('CertificateStatement');
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormvalue((prev) => ({
			...prev,
			[name]: value
		}));
	};

	const getSelectedValue = (val) => {
		if (val === 1) {
			setFormvalue((prev) => ({
				...prev,
				['employementStatus']: "Active"
			}));
		} else if (val === 2) {
			setFormvalue((prev) => ({
				...prev,
				['employementStatus']: "Inactive"
			}));
		}
	};

	const getCertificateType = (val) => {
		if (val === 1) {
			setCertificate("Gross Income");
		} else if (val === 2) {
			setCertificate("Net Income");
		}
	};

	const handleJoiningDate = (e) => {
		if (e?.$d) {
			setFormvalue((prev) => ({
				...prev,
				['joiningDate']: moment(e?.$d).format("MM/DD/YYYY")
			}));
		} else {
			setFormvalue((prev) => ({
				...prev,
				['joiningDate']: ''
			}));
		}
	};

	const handleNextStep = () => {
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			if (certificate === 'Net Income') {
				if (formValue.netMonthlyIncome === '') {
					setIsError({
						'netMonthlyIncome': {
							error: true,
							errorMessage: <FormattedMessage id="This field is required" />
						}
					});
				} else {
					PostFinanceCalculatorData(+(formValue.netMonthlyIncome));
				}
			} else {
				if (formValue.basicWage === '') {
					setIsError({
						'basicWage': {
							error: true,
							errorMessage: <FormattedMessage id="This field is required" />
						}
					});
				} else {
					PostFinanceCalculatorData(monthlyNetIncome);
				}
			}
		}
	};

	const PostFinanceCalculatorData = (netIncome) => {
		let body = {
			"guestType": FinanceCalculatorPayloadData?.guestType,
			"netIncome": netIncome,
			"paymentType": FinanceCalculatorPayloadData?.paymentType,
			"brandID": FinanceCalculatorPayloadData?.brandID,
			"autoProductId": FinanceCalculatorPayloadData?.autoProductId,
			"modelId": FinanceCalculatorPayloadData?.modelId,
			"modelYear": FinanceCalculatorPayloadData?.modelYear,
			"vehicleType": FinanceCalculatorPayloadData?.vehicleType,
			"vehiclePrice": FinanceCalculatorPayloadData?.vehiclePrice,
			"tenureInMonths": FinanceCalculatorPayloadData?.tenureInMonths,
			"totalLimitOfAllCC": FinanceCalculatorPayloadData?.totalLimitOfAllCC,
			"financeVATFlag": FinanceCalculatorPayloadData?.financeVATFlag,
			// "insuranceAmount": FinanceCalculatorPayloadData?.insuranceAmount,
			"maxMonthlyAmount": FinanceCalculatorPayloadData?.maxMonthlyAmount,
			"emiAmount": FinanceCalculatorPayloadData?.emiAmount,
			"residualAmount": FinanceCalculatorPayloadData?.residualAmount,
			"downPaymentAmt": FinanceCalculatorPayloadData?.downPaymentAmt,
			"PromoCode": FinanceCalculatorPayloadData?.PromoCode,
			"basePremiumInsuranceAmount": FinanceCalculatorPayloadData?.basePremiumInsuranceAmount,
			"featurePremiumInsuranceAmount": FinanceCalculatorPayloadData?.featurePremiumInsuranceAmount,
			"productCategory": FinanceCalculatorPayloadData?.productCategory,
			"scheme": FinanceCalculatorPayloadData?.scheme,
			"product": FinanceCalculatorPayloadData?.product
		};
		dispatch(NetIncome(NET_INCOME, netIncome, { navigate: navigate }));
		dispatch(financeCalculatorCallAPI(ONLINE_FINANCE_CALCULATOR, body, { handleFunction: handleRedirect, navigate: navigate, run: true }));
	};

	const handleRedirect = () => {
		UpdateWorkItem();
	};

	return (
		<WrapperDiv>
			<Grid container >
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'0px'}>
						<FormattedMessage id={'revisedFinance.workInformation'} />
						<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id="revisedFinance.employmentType" /></span>
										<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Employment Type"
									data={financingCategoryDropdown?.employment_type}
									value={formValue?.employmentType}
									name={"employmentType"}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError?.employmentType?.error && isError?.employmentType?.errorMessage}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.employerName'} />}
									placeholder="Employer Name"
									value={formValue?.employeName}
									name={"employeName"}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError?.employeName?.error && isError?.employeName?.errorMessage}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<FormattedMessage id={'revisedFinance.occupation'} />
									</Box>}
									placeholder="Occupation"
									data={financingCategoryDropdown?.occupation_details}
									value={formValue?.occupation}
									name={"occupation"}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError?.occupation?.error && isError?.occupation?.errorMessage}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<span><FormattedMessage id={'revisedFinance.employerNumber'} /> <span>
										<span style={{ color: 'red' }}>*</span>
									</span>
									</span>}
									placeholder="Employer Number"
									value={formValue?.employeNumber}
									inputProps={{ maxLength: 10 }}
									name={"employeNumber"}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError?.employeNumber?.error && isError?.employeNumber?.errorMessage}
								/>
							</Grid>

							{/* <Grid item xs={12} md={6} sx={{ alignItems: "center" }}>
								<SelectAmongs
									data={employmentStatus}
									label={<FormattedMessage id="revisedFinance.employmentStatus" />}
									selected={formValue?.employementStatus === "Active" ? 1 : 2}
									getSelected={(val) => getSelectedValue(val)}
								/>
							</Grid> */}

							<Grid item xs={12} md={6}>
								<DateTimePickerInput
									disableFuture={formValue?.joiningDate ? true : false}
									label={<FormattedMessage id="autoFinance.joiningDate" />}
									value={formValue?.joiningDate && moment(formValue?.joiningDate).format("MM/DD/YYYY")}
									name={"joiningDate"}
									onChange={(e) => handleJoiningDate(e)}
									errorText={isError && isError?.joiningDate?.error && isError?.joiningDate?.errorMessage}
								/>
							</Grid>
							{/*<Grid container sx={{ marginTop: "15px" }}>
								<Grid item xs={12}>
									<H5Text size='24' lineHeight='36' borderRadius="8" padding="8px 16px" color="lightDark">
										<FormattedMessage id={'revisedFinance.address'} />
									</H5Text>

									<Box component="form" sx={{ padding: "0 15px 15px 15px" }}>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<SelectInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span><FormattedMessage id={'revisedFinance.workCity'} />&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work City"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<FormattedMessage id={'revisedFinance.buildingNumber'} />}
													placeholder="Building Number"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<FormattedMessage id={'revisedFinance.street'} />}
													placeholder="Street"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<SelectInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<FormattedMessage id={'revisedFinance.district'} />
													</Box>}
													placeholder="District"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<FormattedMessage id={'revisedFinance.additionalNumber'} />}
													placeholder="Additional Number"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<FormattedMessage id={'revisedFinance.postalCode'} />}
													placeholder="Postal Code"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<FormattedMessage id={'revisedFinance.unitNumber'} />}
													placeholder="Unit Number"
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span><FormattedMessage id={'revisedFinance.workPostCode'} />&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work Post Code"
												/>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>*/}
						</Grid>
					</Box>
				</Grid>
			</Grid>

			{!isSaudi && storageData?.continueWith === "enteredAmount" &&
				<Grid Grid container style={{ marginTop: "15px" }}>
					<Grid item xs={12}>
						<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
							<FormattedMessage id={'revisedFinance.incomeDetails'} />
							<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
						</H5Text>

						<Box component="form" sx={{ padding: "15px 30px" }}>
							<Grid container spacing={2}>

								<Grid item xs={12}>
									<SelectAmongs data={certificateType}
										selected={certificate === "Gross Income" ? 1 : 2}
										getSelected={(val) => getCertificateType(val)}
										label={<FormattedMessage id="Income Certificate Type" />} />
								</Grid>

								{certificate === "Gross Income" &&
									<>
										<Grid item xs={12} md={12}>
											<div style={{ display: 'flex', marginTop: "30px" }}>
												<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>
													<FormattedMessage id="Net Monthly Income:" /> {monthlyNetIncome} <FormattedMessage id="sar" />
												</Text>
											</div>
										</Grid>

										<Grid item xs={12} md={6}>
											<LabelWithInput
												label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
													<span><FormattedMessage id={'Basic Wage'} /></span>
												</Box>}
												placeholder="0.00"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">SAR</InputAdornment>
													)
												}}
												value={formValue?.basicWage}
												name={"basicWage"}
												onChange={(e) => handleChange(e)}
												errorText={isError && isError?.basicWage?.error && isError?.basicWage?.errorMessage}
											/>
										</Grid>

										<Grid item xs={12} md={6}>
											<LabelWithInput
												label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
													<span> <FormattedMessage id={'Housing Allowance'} /> </span>
												</Box>}
												placeholder="0.00"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">SAR</InputAdornment>
													)
												}}
												value={formValue?.housingAllowance}
												name={"housingAllowance"}
												onChange={(e) => handleChange(e)}
												errorText={isError && isError?.housingAllowance?.error && isError?.housingAllowance?.errorMessage}
											/>
										</Grid>

										<Grid item xs={12} md={6}>
											<LabelWithInput
												label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
													<span> <FormattedMessage id={'Other Allowance'} /> </span>
												</Box>}
												placeholder="Other Allowance"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">SAR</InputAdornment>
													)
												}}
												value={formValue?.otherAllowance}
												name={"otherAllowance"}
												onChange={(e) => handleChange(e)}
												errorText={isError && isError?.otherAllowance?.error && isError?.otherAllowance?.errorMessage}
											/>
										</Grid>
									</>
								}

								{certificate === "Net Income" &&
									<Grid item xs={12} md={6}>
										<LabelWithInput
											label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
												<span> <FormattedMessage id={'revisedFinance.netMonthlyIncome'} /></span>
											</Box>}
											placeholder="0.00"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">SAR</InputAdornment>
												)
											}}
											value={formValue?.netMonthlyIncome}
											name={"netMonthlyIncome"}
											onChange={(e) => handleChange(e)}
											errorText={isError && isError?.netMonthlyIncome?.error && isError?.netMonthlyIncome?.errorMessage}
										/>
									</Grid>
								}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			}

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form" sx={{padding: "20px 30px" }}>
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									minWidth="100px"
									marginRight="30"
									padding="0px"
									onClick={() => handleCurStep(0)}
								/>
								<ButtonInput
									title={<FormattedMessage id="saveProceed" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									disabled={updateWorkItemLoader}
									endIcon={updateWorkItemLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									// onClick={() => { screenScrollTop(); isSaudi ? handleCurStep(2) : handleInnerCurStep('CertificateStatement') }}
									onClick={() => { screenScrollTop(); isSaudi || storageData?.continueWith === "gosi" ? UpdateWorkItem() : handleNextStep() }}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv >
	);
}

export default PersonalWorkForm;