import React, { useContext } from "react";
import MuiCards from "components/MuiCards/";
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import PolygonWithBackgroundImage from "assets/images/Dashboard/PolygonWithBackground.png";
import styled from 'styled-components'
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { LocaleContext } from "routes/Router";
import { getCashFinancingDetailsURL, getFindVehicleURL } from "routes/AppUrls";
import { useNavigate } from "react-router-dom";
import H5Text from "components/Text/H5Text";
import { Skeleton } from "@mui/material";

const StyledWrapper = styled.div`
margin-top:18px;
    .cardContent{
        background-image:url('${PolygonWithBackgroundImage}');
        background-repeat: no-repeat;
        height:204px;
        display:flex;
        background-position: bottom left 0px;
        align-items:end;
        cursor:pointer;
    }
`

const FinancailProduct = ({ ...props }) => {
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();

    return (
        <StyledWrapper>
            {!props?.loader && props?.data &&
                <Row style={{ marginBottom: "8px" }}>
                    <H5Text color="primary" size="24" marginTop="0" lineHeight="24" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                        <FormattedMessage id="dashboard.financialProducts" />
                    </H5Text>
                </Row>
            }

            {!props?.loader ?
                <Grid container spacing={2}>
                    {props?.data && props?.data?.length > 0 && props.data.map((k) => (
                        <>
                            <Grid item xs={12} md={6} lg={6} xl={4}>
                                <MuiCards borderRadius="16" boxShadow={true} padding="0px" backgroundSize="cover" margin="0px" height="204" backgroundImage={k.image}>
                                    <Grid container className="cardContent" onClick={() => { k?.id == 1 ? navigate(getFindVehicleURL(locale)) : k?.id == 2 && navigate(getCashFinancingDetailsURL(locale)) }}>
                                        <Grid item xs={12} >
                                            <div style={{ padding: "20px", display: 'flex', flexDirection: 'column', alignItems: props.locale === 'ar' && 'end' }}>
                                                <Text color="white" size="28" marginTop="0" lineHeight="28" fontFamily="KarbonMedium" fontWeight="700">
                                                    {k?.title}
                                                </Text>
                                                <Text color="white" size="18" marginTop="6" lineHeight="20" fontFamily={locale=="en"?"KarbonLight":"LumaLight"} fontWeight="600">
                                                    {k.sub_title}
                                                </Text>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </MuiCards>
                            </Grid>
                        </>
                    ))}
                </Grid>
                :
                <>
                    <Grid container spacing={2} >
                        {Array.from({ length: 2 }).map(() => (
                            <Grid item xs={12} md={6} lg={6} xl={4} >
                                <Skeleton variant="rectangular" animation="wave" width={"100%"} height={204} style={{ borderRadius: "16px" }} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
        </StyledWrapper>
    );
};


export default FinancailProduct;
