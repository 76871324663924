import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import SearchInput from 'components/SearchInput';
import cancelIcon from 'assets/icons/cancel_button.png';
import Tabs from 'components/Tabs';
import styled from 'styled-components';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { LocaleContext } from 'routes/Router';

export const PopupWrapper = styled.div`
::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  };

`
const style = {
    height: '87vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 20,
    p: 2,
    borderRadius: '16px',
    boxShadow: '-4px 8px 12px 0px #3C105329',
    marginTop: '4vh',
    borderRadius: '8px',
    zIndex: 1,
};

function NotificationPopup({ modalActive, handleModalPopup }) {
    const { locale } = useContext(LocaleContext);
    return (
        <BasePopup id={'simple-popup'} open={modalActive} anchor={modalActive}>
            <PopupWrapper>
                <Box sx={style} minWidth={{ sm: "0px", md: "430px" }} marginLeft={locale == "en" ? '125px' : '-155px'} >
                    <div className="header">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <SearchInput startAdornment={true} placeholder={"Notifications"} size={'24px'} marginTop="0px" height="32px" paddingLeft="0px" />
                            <img src={cancelIcon} alt="icon" height={'20px'} width={'20px'} style={{ cursor: "pointer" }} onClick={(event) => handleModalPopup(event)} />
                        </div>
                    </div>
                    <div className="content" style={{ marginTop: "27px" }}>
                        <Tabs />
                    </div>
                </Box>
            </PopupWrapper>
        </BasePopup>
    );
}

export default NotificationPopup;