import React from "react";
import { FormattedMessage } from "react-intl";

import { Emailverification, IqamaNumberValidation, PhoneNumberValidation } from "utils/general"

const CheckValidation = value => {

    const formError = {
        submit: true,
        idNumber: {
            error: false,
            errorMessage:"Invalid ID Number"
        },
        firstName: {
            error: false,
            errorMessage: "Invalid First Name"
        },
        lastName: {
            error: false,
            errorMessage:  "Invalid Last Name"
        },
        email: {
            error: false,
            errorMessage: "Invalid Email Address"
        },
        mobileNumber: {
            error: false,
            errorMessage: <FormattedMessage id="register.invalidPhoneNumber" />
        }
    };

    const emptyKeys = [
        "idNumber",
        "firstName",
        "lastName",
        "email",
        "mobileNumber",
    ];
    function removeCountryCode(phoneNumber) {
        return phoneNumber.replace(/\+966|\s/g, '');
    }
    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]].length) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
        if (value[emptyKeys[i]] && emptyKeys[i] === "mobileNumber" && !PhoneNumberValidation(removeCountryCode(value[emptyKeys[i]]))) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
        if (value[emptyKeys[i]] && emptyKeys[i] === "email" && !Emailverification(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }

    return formError;
}

export default CheckValidation;