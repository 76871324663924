import {
    FINANCE_GET_QUOTE_SERVICE
} from "./constant";

export const financingGetQuoteServiceCallAPI = (type, data, external = {}) => {
    switch (type) {
        case FINANCE_GET_QUOTE_SERVICE:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};