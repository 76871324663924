import React from 'react';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RobotImage from 'assets/images/rob.png';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";

const Error404 = () => {

    return (
        <Box sx={{ backgroundColor: "#F1EDF5", display: "flex", justifyContent: "center", alignItems: "center", height:"100vh" }}>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <H1Text color="errorColor">
                                404
                            </H1Text>

                            <Text size="24" color="lightGrey">
                                <FormattedMessage id="page404.heading" />
                            </Text>
                            <Text size="18" color="lightGrey">
                                <FormattedMessage id="page404.subscription" />
                            </Text>
                        </div>
                        <div>
                            <img width="150" alt="AJLF" src={RobotImage} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Error404;
