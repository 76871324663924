import React from 'react'
import styled from 'styled-components'

const StyledImage = styled.img`
max-width:${(props)=>props.maxWidth};
   max-height: 75%;
   width: 100%;
   transition: all .3s ease-in-out;
   min-height: ${(props) => props.minHeight};

   &:hover {
      transform: scale(1.08);
      margin-left:15px;
   }
`;

const AnimatedImage = ({ src, ...props }) => {
   return (
      <div style={{display:"flex", justifyContent:"center"}}>
         <StyledImage {...props} className='cardImage' src={src} alt="" />
      </div>
   )
};
AnimatedImage.defaultProps = {
   maxWidth: "100%",
};
export default AnimatedImage;
