import React, { useContext } from 'react'
import { LocaleContext } from 'routes/Router';
import styled from 'styled-components';

const WrapperSpan = styled.span`
    color: ${(props) => props.color};
    font-style: italic;
    font-size: 16px;
    font-weight: 700;
    font-family: ${(props) => props.locale=="en" ? 'Karbon_semibold' : 'Luma_semibold'};
    display: flex;
    align-items: center;

    .TextData {
        margin-left: 5px;
    }
`;
const StartWithIcon = (props) => {
    const { locale } = useContext(LocaleContext);
    return (
        <WrapperSpan {...props} locale={locale}>
            <img src={props.icon} alt="" />
            <div className='TextData'>{props.text}</div>
            {props.children}
        </WrapperSpan>
    )
}
StartWithIcon.defaultProps = {

};
export default StartWithIcon
