import {
   NAFATH_VERIFY,
   NAFATH_ERROR,
   NAFATH_SUCCESS,
   NAFATH_INITIAL_STATE
} from "./constant";

const nafathReducer = (state = {}, action) => {
   switch (action.type) {
      case NAFATH_VERIFY:
         return { ...state, loading: true, error: '' };
      case NAFATH_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case NAFATH_ERROR:
         return { ...state, type: NAFATH_ERROR, loading: false, error: action.payload };
      case NAFATH_SUCCESS:
         return { ...state, type: NAFATH_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default nafathReducer;