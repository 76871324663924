import styled from 'styled-components';

export const SidebarWrapper = styled.div`
    padding-right:1%;
    position: absolute;
    z-index: 1;
    min-width: 312px; 
    max-width:312px;
    background: #3C1053;
    height: 90vh;
    position: absolute;
    z-index: 99;
    overflow-y: auto;

    a {
        color: white;
        text-decoration: none;
    };

    &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0px;
      };
    box-shadow: 0px 6px 12px rgba(228, 228, 228, 0.5);

    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
        --tw-text-opacity: 1;
        align-items: center;
        border-left-width: 2px;
        // color: rgba(55,65,81,var(--tw-text-opacity));
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        // padding: 0.75rem 1.5rem;
        width: 100%;
        margin-left: 18px;
    };
    .LogoutBar{
        // position: absolute;
        // bottom: 0;
        margin-top:50%;
        width:290px;
        .side-navigation-panel-select-option {
            border-bottom:0.5px solid #775887;
            width:158% !important;
            max-width: 250px;
        }
        .textItem{
            margin-top:10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom:32px;
        }
    }
    @media screen and (max-width:576px){
        .LogoutBar{
            position: relative;
        }
    }
    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap {
        align-items: center;
        display: flex;
        color: #ffffff;
        // height:30px;
        max-width:312px;
    };

    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected {
        --tw-border-opacity: 1;
        --tw-bg-opacity: 1;
        --tw-text-opacity: 1;
        background-color: #FFCD00;
        border-radius:8px;
        color: #000000;
        width:250px;
        .side-navigation-panel-select-option-text, svg{
            color:${(props) => props.theme.purple} !important;
            fill:${(props) => props.theme.purple} !important;
        }
        a {
            color: black;
            text-decoration: none;
        }
    };

    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {
        --tw-border-opacity: 1;
        --tw-bg-opacity: 1;
        --tw-text-opacity: 1;
        background-color: #FFCD00;
        border-radius:8px;
        color: #000000;
        width:250px;
    };

    .side-navigation-panel{
        font-size: 20px !important;
        // margin-top:250px;
    };

    .side-navigation-panel-select{
        margin-top: 15px !important;
    };


.side-navigation-panel-select-option, .side-navigation-panel-select-option-selected{
    border-left:0px solid transparent !important;
    color:#000000;
    svg {
        height: 24px;
        width: 24px;
    }
};

[class*="option-selected"]{
    color: #000000;
};

.side-navigation-panel-select-inner-option-text{
    font-size: 20px !important;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    color:#000000;
};

.Sidebar_control &::-webkit-scrollbar {
    display: none;
};

.sidebar_business{
    display: none;
};
`
