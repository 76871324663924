import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LabelWithInput from "components/Input/LabelWithInput";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ButtonInput from "components/Input/ButtonInput";
import { accountCallAPI } from "store/account/action";
import { ACCOUNT_INITIAL_STATE, ACCOUNT_PASSWORD_CHANGE } from "store/account/constant";
import CheckValidation from "features/Account/AccountPasswordVerification";
import CircularProgress from '@mui/material/CircularProgress';
import H5Text from "components/Text/H5Text";
import { LocaleContext } from 'routes/Router';
import Text from "components/Text";
import { useNavigate } from "react-router-dom";
import OtpInput from "components/Input/OtpInput";
import { authSendOtpCallAPI } from "store/auth/otpSend/action";
import { AUTH_SEND_OTP } from "store/auth/otpSend/constant";
import { authCallAPI } from "store/auth/action";
import { AUTH_API_ERROR_RESET } from "store/auth/constant";
import { otpVerifyCallAPI } from "store/auth/verifyOtp/action";
import { OTP_VERIFY, OTP_VERIFY_INITIAL_STATE } from "store/auth/verifyOtp/constant";
import MuiLink from "components/MuiLink";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyleWrapper = styled.div`
    align-items: center;
    margin:${props => props.margin};

    .application_top{
        display:flex;
        align-items:center;
    };
`;

const AccountChangePassword = () => {
    const [isMobile, setIsMobile] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [isErrorOtp, setIsErrorOtp] = useState(false);
    const { locale } = useContext(LocaleContext);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isNewPassVisible, setIsNewPassVisible] = useState(false);
    const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);
    const [formValue, setFormValue] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [otpScreen, setOtpScreen] = useState(false);
    const [isVerificationSuccess, setIsVerificationSuccess] = useState(false);
    const [verificationScreen, setVerificationScreen] = useState(false);
    const [isVerificationError, setIsVerificationError] = useState(false);
    const [verifyOtp, setVerifyOtp] = useState('');
    const [resendCode, setResendCode] = useState(false);
    const [disableResendLink, setDisableResendLink] = useState(false);
    const [timerCount, setTimerCount] = useState("");
    const [minSecondsLeft, setMinSecondsLeft] = useState();

    const AccountData = useSelector((state) => (state.accountReducer && state.accountReducer?.payload?.data));
    const getUserDetails = useSelector(state => state.userReducer?.payload.data?.data);
    const AccountError = useSelector((state) => (state.accountReducer?.error?.data?.message));
    const loader = useSelector((state) => state.accountReducer?.loading);

    const getLoginDetails = useSelector((state) => state.loginReducer?.data?.data);
    const AuthSendOtpData = useSelector((state) => state.authSendOtpReducer && state.authSendOtpReducer?.payload?.data?.data);
    const sendOtpLoader = useSelector((state) => state.authSendOtpReducer?.loading);
    const verifyOtpLoader = useSelector((state) => state?.otpVerifyReducer?.loading);
    const verifyOtpError = useSelector((state) => state?.otpVerifyReducer?.error?.data?.message);
    const verifyOtpData = useSelector((state) => state?.otpVerifyReducer?.payload?.data?.data);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(accountCallAPI(ACCOUNT_INITIAL_STATE));
    }, []);

    const handleVisiblePassword = (value) => {
        setIsPassVisible(!value);
    };

    const handleVisibleNewPassword = (value) => {
        setIsNewPassVisible(!value);
    };

    const handleVisibleConfirmPassword = (value) => {
        setIsConfirmPassVisible(!value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let bodyValue = {};
            bodyValue.nationalId = getLoginDetails?.nationalId;
            bodyValue.countryCode = getLoginDetails?.countryCode;
            bodyValue.contactNumber = getLoginDetails?.contactNumber;
            bodyValue.routingKey = "reset-password";
            dispatch(authSendOtpCallAPI(AUTH_SEND_OTP, bodyValue, { handleFunction: handleShowOtpScreen, navigate: navigate, run: true }));
        }
    };

    const handleShowOtpScreen = () => {
        setOtpScreen(true);
        setVerifyOtp("");
        setIsErrorOtp(false);
        handleCountDownTimer();
    }

    useEffect(() => {
        if (resendCode) {
            setTimeout(() => {
                countDowntiming();
            }, 1000)
        }
    }, [minSecondsLeft]);

    function pad2Digits(text) {
        let out = '00' + text;
        return out.substring(out.length - 2);
    };

    function countDowntiming() {
        let timing = pad2Digits(Math.floor(minSecondsLeft / 60)) + ':' + pad2Digits(minSecondsLeft % 60);
        setTimerCount(timing);
        if (minSecondsLeft > 0) {
            let timingLeft = minSecondsLeft - 1;
            setMinSecondsLeft(timingLeft);
            setDisableResendLink(true);
        } else {
            setDisableResendLink(false);
        }
    };

    const handleOtpChange = (value) => {
        setVerifyOtp(value)
    };

    const CheckValidationOtp = value => {
        if (value.length !== 6) {
            return true;
        }
        return false;
    };

    const handleSubmitOtpVerification = () => {
        const error = CheckValidationOtp(verifyOtp);
        setIsErrorOtp(error);
        if (!error) {
            let bodyValue = {};
            bodyValue.nationalId = getLoginDetails?.nationalId;
            bodyValue.countryCode = getLoginDetails?.countryCode;
            bodyValue.contactNumber = getLoginDetails?.contactNumber;
            bodyValue.routingKey = "reset-password";
            bodyValue.otp = verifyOtp;
            dispatch(otpVerifyCallAPI(OTP_VERIFY, bodyValue, { handleFunction: handleSubmitChangePassword, navigate: navigate, run: true }));
        }
    };

    const handleSubmitChangePassword = () => {
        let bodyValue = {};
        bodyValue.nationalId = getUserDetails?.nationalId;
        bodyValue.password = formValue.newPassword;
        bodyValue.old_password = formValue.currentPassword;
        bodyValue.action = "reset-password";
        setVerificationScreen(true);
        dispatch(accountCallAPI(ACCOUNT_PASSWORD_CHANGE, bodyValue, { handleFunction: setIsVerificationSuccess(true), handleFunctionError: setIsVerificationError(true), navigate: navigate, run: true }));
    };

    const handleCountDownTimer = () => {
        let [hours, minutes, seconds] = AuthSendOtpData?.resend_otp_time?.split(":");
        let totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
        setMinSecondsLeft(totalSeconds);
        setResendCode(true);
        countDowntiming();
    };

    const ResendOTP = () => {
        let resetValue = {};
        dispatch(authCallAPI(AUTH_API_ERROR_RESET, resetValue));
        setVerifyOtp("");
        let bodyValue = {};
        bodyValue.nationalId = getLoginDetails?.nationalId;
        bodyValue.countryCode = getLoginDetails?.countryCode;
        bodyValue.contactNumber = getLoginDetails?.contactNumber;
        bodyValue.routingKey = "reset-password";
        dispatch(authSendOtpCallAPI(AUTH_SEND_OTP, bodyValue, { handleFunction: handleCountDownTimer, navigate: navigate, run: true }));
    };


    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            {!otpScreen ?
                <>
                    <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}>
                        <FormattedMessage id="account.changePassword" />
                    </H5Text>
                    <Grid container sx={{ marginTop: "0px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "0px" }}>
                            <LabelWithInput
                                label={<FormattedMessage id="account.currentPassword" />}
                                placeholder="Current Password"
                                name="currentPassword"
                                type={isPassVisible ? "text" : "password"}
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {isPassVisible ? (
                                                <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                            ) : (
                                                <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                            )}
                                        </>
                                    )
                                }}
                                errorText={isError && isError.currentPassword.error && isError.currentPassword.errorMessage}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "20px" }}>
                            <LabelWithInput
                                label={<FormattedMessage id="account.newPassword" />}
                                placeholder="New Password"
                                name="newPassword"
                                type={isNewPassVisible ? "text" : "password"}
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {isNewPassVisible ? (
                                                <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleNewPassword(isNewPassVisible)} />
                                            ) : (
                                                <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleNewPassword(isNewPassVisible)} />
                                            )}
                                        </>
                                    )
                                }}
                                errorText={isError && isError.newPassword.error && isError.newPassword.errorMessage}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "20px" }}>
                            <LabelWithInput
                                label={<FormattedMessage id="account.confirmPassword" />}
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                type={isConfirmPassVisible ? "text" : "password"}
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {isConfirmPassVisible ? (
                                                <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleConfirmPassword(isConfirmPassVisible)} />
                                            ) : (
                                                <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleConfirmPassword(isConfirmPassVisible)} />
                                            )}
                                        </>
                                    )
                                }}
                                errorText={isError && isError.confirmPassword.error && isError.confirmPassword.errorMessage}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "20px" }} style={{ alignItems: "end", display: "flex", justifyContent: "end" }}>
                            <ButtonInput
                                title={<FormattedMessage id="continue" />}
                                variant="contained"
                                bgColor={"yellow"}
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                disabled={sendOtpLoader}
                                endIcon={sendOtpLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === 'ar' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                                onClick={() => handleSubmit()}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={10} sx={{ marginTop: "10px" }}>
                            {!loader &&
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                    {AccountError ? (
                                        <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                            {AccountError}
                                        </Text>
                                    ) : (
                                        <Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
                                            {AccountData?.message}
                                        </Text>
                                    )}
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </>
                :
                !verificationScreen ?
                    <>

                        <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}>
                            <FormattedMessage id="enterVerificationCode" />
                        </H5Text>

                        <Text marginTop="0" size={isMobile ? "20" : "24"} color="lightDark" lineHeight="36">
                            <FormattedMessage id="enterVerificationCode.sub_text" />&nbsp; *****{AuthSendOtpData?.contactNumber?.substring(5)}
                        </Text>

                        <Grid container sx={{ marginTop: "60px" }}>
                            <Grid xs={12} sm={12} md={12} lg={8}>
                                <OtpInput
                                    marginTop="30"
                                    TextFieldsProps={{ placeholder: 0 }}
                                    value={verifyOtp}
                                    validateChar={(val) => !isNaN(val)}
                                    length={6}
                                    justifyContent="start"
                                    onChange={handleOtpChange}
                                    errorText={isErrorOtp && <FormattedMessage id="enteredIncorrectOTP" />}
                                    gap={isMobile ? "6px" : "20px"}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
                                    <div style={{ display: "flex" }}>
                                        {resendCode ?
                                            <Text size="16" fontWeight="800" color="lightGrey" marginTop="0">
                                                <FormattedMessage id="resendCodeIn" /> <span style={{ color: disableResendLink ? "green" : "", width: "100px" }}>{timerCount == "aN:aN" ? "" : timerCount}&nbsp;</span>
                                            </Text>
                                            :
                                            <Text size="16" fontWeight="800" color="lightGrey" marginTop="0">
                                                <FormattedMessage id="DidNotGetTheCode" />
                                            </Text>
                                        }
                                        {sendOtpLoader ?
                                            <div style={{ marginLeft: "20px" }}>
                                                <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />
                                            </div>
                                            :
                                            <MuiLink size="16" TextDecoration="underline" onClick={() => ResendOTP()} disabled={disableResendLink}>
                                                &nbsp;{timerCount != "aN:aN" ? <FormattedMessage id="resendCode" /> : ""}
                                            </MuiLink>
                                        }
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} lg={9} sx={{ marginTop: "60px" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                            <Stack spacing={2} direction="row">
                                                <ButtonInput
                                                    title={<FormattedMessage id="goBack" />}
                                                    variant="text"
                                                    bgColor="transparent"
                                                    textColor="purple"
                                                    borderRadius="100"
                                                    TextTransform="capitalize"
                                                    minWidth="100px"
                                                    marginRight="40"
                                                    padding="0"
                                                    onClick={() => setOtpScreen(false)}
                                                />
                                                <ButtonInput
                                                    title={<FormattedMessage id="continue" />}
                                                    variant="contained"
                                                    bgColor={"yellow"}
                                                    textColor="purple"
                                                    borderRadius="100"
                                                    TextTransform="capitalize"
                                                    disabled={verifyOtpLoader}
                                                    endIcon={(verifyOtpLoader) ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === 'ar' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                                                    onClick={() => handleSubmitOtpVerification()}
                                                />
                                            </Stack>
                                        </Box>
                                    </Grid>

                                    {!verifyOtpLoader &&
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                            {verifyOtpError ?
                                                <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                                    {verifyOtpError}
                                                </Text>
                                                :
                                                <Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
                                                    {verifyOtpData?.message}
                                                </Text>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid container sx={{ marginTop: "60px" }}>
                        <Grid item xs={12}>
                            <div style={{ textAlign: "center" }}>
                                {isVerificationSuccess && <CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />}
                                {isVerificationError && <CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />}
                                <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}>
                                    {isVerificationSuccess && <FormattedMessage id="Sucessfully Change Password" />}
                                    {isVerificationError && <FormattedMessage id=" Fialed Change Password" />}
                                </H5Text>
                            </div>
                        </Grid>
                    </Grid>
            }

        </StyleWrapper>
    );
};

export default AccountChangePassword;
