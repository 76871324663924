import { Box, Grid } from '@mui/material'
import SideBySideText from 'components/SideBySideText'
import H1Text from 'components/Text/H1Text'
import H5Text from 'components/Text/H5Text'
import Text from 'components/Text'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonInput from 'components/Input/ButtonInput'
import { getRevisedFinancingInitialDataURL, getRevisedFinancingURL } from 'routes/AppUrls'
import { useNavigate } from 'react-router-dom'
import { LocaleContext } from 'routes/Router'
import HeaderBanner from '../HeaderBanner'
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from 'components/MuiBreadcrumbs'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardURL } from "routes/AppUrls"
import { financeCalculatorCallAPI } from 'store/financing/financeCalculator/action'
import { ONLINE_FINANCE_CALCULATOR } from 'store/financing/financeCalculator/constant'
import LocalStorageManager from 'managers/LocalStorageManger'
import CircularProgress from '@mui/material/CircularProgress';

const IneligibleStyle = styled.div`
background:${(props) => props.theme.lightSecondary};
padding:0.5% 5%;
min-height:100vh;
`
const GridStyle = styled(Grid)`
background:${(props) => props.theme.white};
border-radius:16px;
padding:24px 10px 48px 10px;
`
const RevisedAccountFetch = ({ handleCurStep }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const gosiPension = useSelector((state) => state.basicReducer.financingType);
    const userMeData = useSelector((state) => (state.userReducer && state.userReducer?.payload?.data?.data));
    const getGosiPensionData = useSelector((state) => (state.financingReducer && state.financingReducer?.payload?.data?.data));
    const getvehicleDetails = useSelector((state) => (state.vehicleDetailsReducer && state.vehicleDetailsReducer?.payload?.data?.data));
    const FinanceCalculatorPayloadData = LocalStorageManager.getItem('finance_Calculator_payload') !== null && JSON.parse(LocalStorageManager.getItem('finance_Calculator_payload'));
    const financeCalulatorLoader = useSelector(state => state?.financeCalculatorReducer?.loading);
	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);

    const gosiFetchData = gosiPension === 'gosi' ?
        userMeData?.is_saudi ?
            (parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.basicWage) +
                parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.housingAllowance) +
                parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.otherAllowance) -
                0.0975 * (parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.basicWage) +
                    parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.housingAllowance))
            ).toString()
            :
            parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.basicWage) +
            parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.housingAllowance) +
            parseFloat(getGosiPensionData?.employmentStatusInfo?.[0]?.otherAllowance)
        :
        getGosiPensionData?.data?.[0]?.payslipInfo?.netSalary

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: getRevisedFinancingURL(locale)
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])
    const handleSubmit = () => {
        PostFinanceCalculatorData();
    };

    const PostFinanceCalculatorData = () => {
        let body = {
            "guestType": FinanceCalculatorPayloadData?.guestType,
            "netIncome": (+gosiFetchData)?.toFixed(2),
            "paymentType": FinanceCalculatorPayloadData?.paymentType,
            "brandID": FinanceCalculatorPayloadData?.brandID,
            "autoProductId": FinanceCalculatorPayloadData?.autoProductId,
            "modelId": FinanceCalculatorPayloadData?.modelId,
            "modelYear": FinanceCalculatorPayloadData?.modelYear,
            "vehicleType": FinanceCalculatorPayloadData?.vehicleType,
            "vehiclePrice": FinanceCalculatorPayloadData?.vehiclePrice,
            "tenureInMonths": FinanceCalculatorPayloadData?.tenureInMonths,
            "totalLimitOfAllCC": FinanceCalculatorPayloadData?.totalLimitOfAllCC,
            "financeVATFlag": FinanceCalculatorPayloadData?.financeVATFlag,
            "maxMonthlyAmount": +(FinanceCalculatorPayloadData?.maxMonthlyAmount),
            "emiAmount": FinanceCalculatorPayloadData?.emiAmount,
            "downPaymentAmt": FinanceCalculatorPayloadData?.downPaymentAmt,
            "PromoCode": FinanceCalculatorPayloadData?.PromoCode,
            "residualAmount": FinanceCalculatorPayloadData?.residualAmount,
            "basePremiumInsuranceAmount": FinanceCalculatorPayloadData?.basePremiumInsuranceAmount,
            "featurePremiumInsuranceAmount": FinanceCalculatorPayloadData?.featurePremiumInsuranceAmount,
            "productCategory": FinanceCalculatorPayloadData?.productCategory,
            "scheme": FinanceCalculatorPayloadData?.scheme,
            "product": FinanceCalculatorPayloadData?.product
        };
        dispatch(financeCalculatorCallAPI(ONLINE_FINANCE_CALCULATOR, body, { handleFunction: handleRedirect, navigate: navigate, run: true }));
    };

    const handleRedirect = () => {
        navigate(getRevisedFinancingInitialDataURL(locale));
    };

    return (
        <IneligibleStyle>
            <Grid container>
                <Grid item xs={12}>
                    <HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} option={getGosiPensionData?.employmentStatusInfo} />
                </Grid>
            </Grid>

            <Grid container marginTop={"42px"}>
                <Grid item xs={12} md={12} display='flex' alignItems={'end'}>
                    <Box margin='0 0 12px 0'>
                        <Grid item display='flex'>
                            <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                            <Text size={'16'} fontFamily="Karbon_bold" marginTop="0" marginLeft="10">
                                {`[${workItemAppId}]`}
                            </Text>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <GridStyle>
                <Grid container width={'100%'} margin="auto">
                    <Grid item xs={12} textAlign='center' marginTop={'24px'} >
                        {getGosiPensionData?.employmentStatusInfo ? (
                            <img src={require('assets/images/CashFinance/amountFetch.png')} alt="" />
                        ) : (
                            <img src={require('assets/images/feeling-sorry-animate.png')} alt="" />
                        )}

                        {getGosiPensionData?.employmentStatusInfo ? (
                            <H5Text size={'24'} lineHeight={'25.48'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='lightGrey' marginTop={'20'}><FormattedMessage id="cashFinance.amountFetchedFrom" /> {gosiPension == "gosi" ? <FormattedMessage id="gosi" /> : gosiPension == "pension" ? <FormattedMessage id="pension" /> : ""}</H5Text>
                        ) : (
                            <Text size={'40'} lineHeight={'48'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='black' marginTop={'20'}><FormattedMessage id="revisedFinance.incomeDataNotFound" /> {gosiPension == "gosi" ? <FormattedMessage id="gosi" /> : gosiPension == "pension" ? <FormattedMessage id="pension" /> : ""}</Text>
                        )}

                        {getGosiPensionData?.employmentStatusInfo ? (
                            <Text size={'40'} lineHeight={'48'} fontFamily="Karbon_bold">
                                {(+gosiFetchData)?.toFixed(2)}{' SAR'}
                            </Text>
                        ) : (
                            <Text size={'24'} lineHeight={'28'} fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"} color="purple">{getvehicleDetails?.ModelDescription}</Text>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} textAlign='center' marginTop={'64px'}  >
                    <ButtonInput
                        title={getGosiPensionData?.employmentStatusInfo ? <FormattedMessage id="proceed" /> : <FormattedMessage id="cancelApplication" />}
                        variant="contained"
                        bgColor="yellow"
                        textColor="purple"
                        borderRadius="100"
                        TextTransform="capitalize"
                        fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}
                        endIcon={getGosiPensionData?.employmentStatusInfo ? financeCalulatorLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon /> : ''}
                        onClick={() => getGosiPensionData?.employmentStatusInfo ? handleSubmit() : navigate(getDashboardURL(locale))}
                    />
                </Grid>
            </GridStyle>
        </IneligibleStyle>
    )
}

export default RevisedAccountFetch
