import {
    NAFATH_INITIAL_STATE,
    NAFATH_VERIFY
} from "./constant";

export const nafathCallAPI = (type, data, external = {}) => {
    switch (type) {
        case NAFATH_VERIFY:
            return {
                type: type,
                data: data,
                external: external
            }
        case NAFATH_INITIAL_STATE:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}
