import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import H5Text from 'components/Text/H5Text';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ResumeOnlineFormCall } from 'store/resumeOnlineForm/actions';
import { RESUME_ONLINE_FORM } from 'store/resumeOnlineForm/constant';
import { useNavigate } from 'react-router-dom';

const SnackBarStyle = styled.div`
.MuiSnackbar-root{
  position:unset;
  box-shadow:none;
}
.MuiPaper-root {
  width:100%;
  box-shadow:none;
  background:#EAFCF0;
  border:1px solid #53AC70;
  border-radius:8px;
  padding:0 48px 0 32px;
}
.MuiSnackbarContent-message{
  color:#53AC70;
}
.MuiSnackbarContent-message{
  width:97%;
}
.MuiSnackbarContent-action{
  width:3%;
  text-align:end;
  justify-content:end;
}
@media screen and (max-width:576px){
  .MuiPaper-root {
    padding:10px;
  }
}
`
export default function MuiSnackbar(props) {
  const [open, setOpen] = React.useState(props?.open);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    let body = {
      resumeForm: false,
      step: 1
    };
    dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }))
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    handleClick();
    setOpen(false);
  };

  const action = (
    <>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="large" style={{ color: '#53AC70' }} />
      </IconButton>
    </>
  );

  return (
    <SnackBarStyle>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        message={props.message}
        action={action}
      />
    </SnackBarStyle>
  );
}
