import {
    IBAN_BANK_NAME
} from "./constant";

export const bankNameCallAPI = (type, data, external = {}) => {
    switch (type) {
        case IBAN_BANK_NAME:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};