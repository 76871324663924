import { combineReducers } from "redux";
import authReducer from "store/auth/reducer";
import basicReducer from "store/basic/reducer";
import screenReducer from "store/screen/reducer"
import dashboardReducer from "store/token/reducer";
import tokenReducer from "store/token/reducer";
import accountReducer from "store/account/reducer";
import userReducer from "store/user/reducer";
import loginReducer from "store/login/reducer";
import financingReducer from "store/financing/reducer";
import helperReducer from "store/helper/reducer";
import nafathReducer from "store/nafath/reducer";
import tahakukReducer from "store/tahakuk/reducer";
import nafathAuthReducer from "store/nafathAuth/reducer";
import logoutReducer from "store/logout/reducer";
import authSendOtpReducer from "store/auth/otpSend/reducer";
import detailsLoanReducer from "store/contracts/detailsLoan/reducer";
import paymentHistoryReducer from "store/contracts/paymentHistory/reducer";
import paymentScheduleReducer from "store/contracts/paymentSchedule/reducer";
import activeFinanceReducer from "store/dashboard/activeFinance/reducer";
import appStaticImageReducer from "store/dashboard/appStaticImage/reducer";
import contractListReducer from "store/contracts/contractList/reducer";
import applicationListReducer from "store/application/applicationList/reducer";
import financingDropDownReducer from "store/financingDropDown/reducer";
import financingYakeenUserInfoReducer from "store/financing/yakeenUserInfo/reducer";
import usedCarsReducer from "store/screen/usedCar/reducer";
import campaignDetailsReducer from "store/screen/campaignDetails/reducer";
import workItemReducer from "store/workItem/reducer";
import vehicleDetailsReducer from "store/screen/financeCalculator/reducer";
import shimaConsentReducer from "store/auth/shimaForm/reducer";
import ResumeOnlineFormReducer from "store/resumeOnlineForm/reducer";
import changePasswordReducer from "store/auth/changePassword/reducer";
import campaignSearchReducer from "store/screen/campaignDetails/campaignSearch/reducer";
import uploadDocumentReducer from "store/uploadDocument/reducer";
import userLoginReducer from "store/auth/login/reducer";
import bankNameReducer from "store/financing/IbanBankName/reducer";
import accountComplaintsReducer from "store/account/complaints/reducer";
import accountComplaintsFormReducer from "store/account/complaintsForm/reducer";
import leadManagmentReducer from "store/financing/leadManagment/reducer";
import financeCalculatorReducer from "store/financing/financeCalculator/reducer";
import notificationsReducer from "store/notifications/reducer";
import manualDBRReducer from "store/financing/manualDBR/reducer";
import financingGetQuoteReducer from "store/financing/financeGetQuote/reducer";
import financingYakeenUserAddressReducer from "store/financing/yakeenUserAddress/reducer";
import financingGetQuoteServiceReducer from "store/financing/financeGetQuoteService/reducer";
import otpVerifyReducer from "store/auth/verifyOtp/reducer";
import setPasswordReducer from "store/auth/setPassword/reducer";
import createPasswordReducer from "store/auth/createPassword/reducer";
import uploadDocumentIbanReducer from "store/uploadDocument/uploadDocumentIban/reducer";
import applicationListWorkItemReducer from "store/workItem/applicationListWorkItems/reducer";
import fetchWorkItemReducer from "store/workItem/fetchWorkItem/reducer";
import updateWorkItemReducer from "store/workItem/updateWorkItem/reducer";
import expenseBankReducer from "store/financing/expenseBankValidation/reducer";
import nafathPollReducer from "store/nafathStatus/reducer";
import uploadApplicationDocumentReducer from "store/uploadDocument/uploadApplicationDocument/reducer";
import loginDeviceReducer from "store/auth/loginDevice/reducer";

export default combineReducers({
   authReducer,
   basicReducer,
   ResumeOnlineFormReducer,
   dashboardReducer,
   screenReducer,
   tokenReducer,
   accountReducer,
   userReducer,
   loginReducer,
   financingReducer,
   helperReducer,
   nafathReducer,
   tahakukReducer,
   nafathAuthReducer,
   logoutReducer,
   authSendOtpReducer,
   detailsLoanReducer,
   paymentHistoryReducer,
   paymentScheduleReducer,
   activeFinanceReducer,
   appStaticImageReducer,
   contractListReducer,
   applicationListReducer,
   financingDropDownReducer,
   financingYakeenUserInfoReducer,
   usedCarsReducer,
   campaignDetailsReducer,
   workItemReducer,
   vehicleDetailsReducer,
   shimaConsentReducer,
   changePasswordReducer,
   campaignSearchReducer,
   uploadDocumentReducer,
   userLoginReducer,
   bankNameReducer,
   accountComplaintsReducer,
   accountComplaintsFormReducer,
   leadManagmentReducer,
   financeCalculatorReducer,
   notificationsReducer,
   manualDBRReducer,
   financingGetQuoteReducer,
   financingYakeenUserAddressReducer,
   financingGetQuoteServiceReducer,
   otpVerifyReducer,
   setPasswordReducer,
   createPasswordReducer,
   uploadDocumentIbanReducer,
   applicationListWorkItemReducer,
   fetchWorkItemReducer,
   updateWorkItemReducer,
   expenseBankReducer,
   nafathPollReducer,
   uploadApplicationDocumentReducer,
   loginDeviceReducer
})
