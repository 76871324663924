import {
    LEAD_MANAGMENT
} from "./constant";

export const leadManagmentCallAPI = (type, data, external = {}) => {
    switch (type) {
        case LEAD_MANAGMENT:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};