import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from 'styled-components';
import carImage from "assets/images/Application/car.png";
import ShareIcon from "assets/images/Application/ShareNetwork.png";
import CarLogo from "assets/images/Application/Monogram.png";
import GaugeLogo from "assets/images/Application/Gauge.png";
import PlateLogo from "assets/images/Application/Plate.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import carServiceImage from "assets/icons/E_Service/carService.png";
import carServiceImageWhite from "assets/icons/E_Service/carServiceWhite.png";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import { Grid } from "@mui/material";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { FormattedMessage } from "react-intl";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import { getApplicationsURL, getDashboardURL, getRevisedFinancingURL } from 'routes/AppUrls';
const StyleWrapper = styled.div`
    align-items: center;

    .left_container{
          background:#ffffff;
          border-radius:16px;
          padding: 30px 20px;
      };
    
    ul{
        display: inline;
      };
    
    li {
        // display: inline-block;
        // padding-right:30px;
        // float: left;
      };

    .service_box{
        height:100px;
        // width:234px;
        background:#F5ECFF;
        padding:16px 8px 16px 8px;
        color:#6D4C7E;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
    };

    .service_box_selected{
        height:100px;
        // width:234px;
        background:#3F1956;
        padding:16px 8px 16px 8px;
        color:#ffffff;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
    };
 
`

const Details = (props) => {
    const e_ser_arr = [
        { id: 1, name: "Renewal of Registration", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 2, name: "Buy Your Leased Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false },
        { id: 3, name: "Ownership Transfer", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 4, name: "Add Additional Driver", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false },
        { id: 5, name: "Retrieve Your Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 6, name: "Re-lease Your Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }
    ];
    const [selectArray, setSelectArray] = useState([]);
    const [serviceList, setServiceList] = useState(e_ser_arr);
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();

    const handleSelect = (data) => {
        if (selectArray.includes(data.id)) {
            let index = selectArray.findIndex((obj) => obj === data.id);
            selectArray.splice(index, 1);
            let index1 = serviceList.findIndex((obj) => obj.id === data.id);
            setServiceList([...serviceList], serviceList[index1].is_select = false);
        } else {
            selectArray.push(data.id);
            let index = serviceList.findIndex((obj) => obj.id === data.id);
            setServiceList([...serviceList], serviceList[index].is_select = true);
        }
    };
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applications" />,
            Link: getApplicationsURL(locale)
        },
        {
            title: <FormattedMessage id="applicationDetails" />,
            Link: "",
            isDisbale: true,
        },
    ])
    return (
        <StyleWrapper>
            <Row className="left_container">
                <MuiBreadcrumbs
                    list={breadcrumbsList}
                />

                <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "26px" }}>
                    <Grid item xs={12} lg={8} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginBottom: '15px' }}>
                            <ArrowBackIcon sx={{ color: "#3F1956", fontSize: "24", cursor: 'pointer' }} onClick={() => navigate(getApplicationsURL(locale))} />
                        </div>
                        <H5Text size="32" marginTop="0" color="purple" style={{ paddingLeft: '20px' }} >
                            1234 5521 2312 5521 4453
                        </H5Text>
                        <ContentCopyOutlinedIcon style={{ marginLeft: '15px', marginBottom: '15px' }} />
                    </Grid>
                    <Grid item xs={12} lg={4} justifyContent={'end'} display={'flex'}>
                        {/* <img src={ShareIcon} alt="share" /> */}
                    </Grid>
                </Grid>

                <div style={{ display: "flex", marginLeft: "35px" }}>
                    <Text size="18" marginTop="0" color="lighGrey">
                        Application Number
                    </Text>
                </div>

                <div style={{ textAlign: "center" }}>
                    <img src={carImage} alt='carImage' style={{ width: "inherit" }} />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <img src={CarLogo} alt="icon" />
                    </div>
                    <div>
                        <H5Text size="32" marginTop="0" color="purple" lineHeight="38.4">
                            {"Kia Sonet X-Line"}
                        </H5Text>
                        <Text size="20" color={"gray"} textAlign="left" lineHeight="24">
                            2021 GU M/R 4x215
                        </Text>
                    </div>
                </div>


                <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px", flexWrap: "wrap" }}>
                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                        <img src={GaugeLogo} alt="icon" style={{ marginRight: "8px" }} />
                        <Text size="24" color={"lightDark"} textAlign="center" lineHeight="28.8">
                            1,50,200 KM
                        </Text>
                        <img src={PlateLogo} alt="icon" style={{ marginLeft: "24px", marginRight: "8px" }} />
                        <Text size="24" color={"lightDark"} TextAlign="center" lineHeight="28.8">
                            9067
                        </Text>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }} justifyContent={{ xs: 'start', xl: 'end' }}>
                                <Text size="20" color={"lightGrey"} lineHeight="21.23">
                                    VIN:
                                </Text>
                                <Text size="20" color={"lightDark"} lineHeight="24">
                                    76523
                                </Text>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }} justifyContent={{ xs: 'start', xl: 'end' }}>
                                <Text size="20" color={"lightGrey"} lineHeight="21.23">
                                    <FormattedMessage id="registrationExpiryDate" />:
                                </Text>
                                <Text size="20" color={"lightDark"} lineHeight="24">
                                    12/12/2027
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {props.e_service &&
                    <>
                        <div style={{ marginTop: "36px" }}>
                            <H5Text size="24" marginTop="0" color="purple" >
                                {"E-services"}
                            </H5Text>
                        </div>

                        <Row xs={1} md={3} style={{ margin: "0px", padding: "0px" }}>
                            {serviceList.length > 0 && serviceList.map((k, idx) => (
                                <Col key={idx}>
                                    <div className={k.is_select ? "service_box_selected" : "service_box"} onClick={() => handleSelect(k)}>
                                        <div style={{ marginBottom: "8px" }}>
                                            <img src={k.is_select ? k.imageSelect : k.image} alt="icon" style={{ height: "29px" }} />
                                        </div>
                                        <div>
                                            <Text size="18" color={k.is_select ? "white" : "purple"} textAlign="center" lineHeight="21.6">
                                                {k.name}
                                            </Text>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </>
                }

            </Row>
        </StyleWrapper>
    );
};

export default Details;