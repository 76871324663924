import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";
import LeftBarImage from "components/LeftBarImage";
import layoutImage from "assets/images/cashFinanceBanner.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCashFinancingDetailsURL, getCashFinancingInitialDataURL } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import H5Text from "components/Text/H5Text";
import CheckboxInput from "components/Input/CheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import SwitchWithLabelInput from "components/Input/SwitchWithLabelInput";
import { financingType } from "store/basic/actions";
import { FINANCING_TYPE } from "store/basic/constant";

export const WrapperArrowBackIcon = styled(ArrowBackIcon)`
    width: "24px";
    height: "20px";
    color: ${(props) => props.theme.purple}
`;

const CashFinancing = ({ handleCurStep }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const [isMobile, setIsMobile] = useState(false);
    const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);
    const [formValue, setFormValue] = useState({
        gosiPension: false,
        usedFor: false,
        acknowledge: false,
        agree: false
    });
    const handleCheckboxChange = (e, name) => {
        setFormValue((prevState) => ({
            ...formValue,
            [name]: e.target.checked,
        }));
    };
    const handleClick = () => {
        navigate(getCashFinancingDetailsURL(locale), { state: { step: 2 } })
        dispatch(financingType(FINANCING_TYPE, formValue.gosiPension ? "pension" : "gosi"));

    };
    return (
        <Box>
            <Grid container >
                <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                    <LeftBarImage bgImage={layoutImage} widthImage="70%" bgColor="lightPurple" />
                </Grid>
                <Grid item xs={12} md={7} style={{ marginTop: "30px" }} padding={{ xs: '1% 5% 5% 3%', sm: '1% 5% 5% 5%' }}>
                    <WrapperArrowBackIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={() => navigate(getCashFinancingDetailsURL(locale))} />

                    <Grid container>
                        <Grid item xs={12} >
                            <H5Text size={"64"} marginTop="43" color="primary" lineHeight="69">
                                <FormattedMessage id="cashFinance.applyForCashFinancing" />
                            </H5Text>
                            <Text marginTop="0" color="lightDark">
                                <FormattedMessage id="cashFinance.OnlyaFewMoreSteps" />
                            </Text>
                        </Grid>
                        <Grid item xs={7} >
                            <H1Text size={'24'} marginTop="40" color="purple" lineHeight="28.8" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                                <FormattedMessage id="autoFinance.meetFollowingRequirement" />
                            </H1Text>
                        </Grid>
                        <Grid item xs={12} lg={7} >
                            <Box display='flex' marginTop='12px' alignItems={{ xs: 'center', md: 'center' }}>
                                {/* <img src={require('assets/icons/CashFinance/IdentificationCard.png')} /> */}
                                <img src={require('assets/icons/CashFinance/Money.png')} style={{ marginRight: locale == "en" && "8px", marginLeft: locale == "ar" && "8px" }} />
                                <H5Text marginTop="6" size="24" color="dark" fontWeight="800" fontFamily={locale=="en"?'Karbon_light':"Luma_light"}>
                                    <FormattedMessage id="cashFinance.minimumSalary" />:
                                </H5Text>
                                <H5Text marginTop="6" size="24" color="purple" fontWeight="800" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                                    &nbsp; 3000 <FormattedMessage id="sar" />
                                </H5Text>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={7} >
                            <Box display='flex' marginTop='12px' alignItems={{ xs: 'center', md: 'center' }}>
                                <img src={require('assets/icons/CashFinance/IdentificationCard.png')} style={{ marginRight: locale == "en" && "8px", marginLeft: locale == "ar" && "8px" }} />
                                <H5Text marginTop="6" size="24" color="dark" fontWeight="800" fontFamily={locale=="en"?'Karbon_light':"Luma_light"}>
                                    <FormattedMessage id="cashFinance.nationality" />:
                                </H5Text>
                                <H5Text marginTop="6" size="24" color="purple" fontWeight="800" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                                    &nbsp; <FormattedMessage id="cashFinance.saudiNonSaudi" />
                                </H5Text>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: isMobile ? "10px" : "", marginTop: '64px' }}>
                        <CheckboxInput
                            label={<FormattedMessage id="autoFinance.acknowledgeBeneficiaryProduct" />}
                            size="16"
                            fontWeight="600"
                            checked={formValue.acknowledge}
                            name="acknowledge"
                            onChange={(e) => handleCheckboxChange(e, 'acknowledge')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: isMobile ? "10px" : "" }}>
                        <CheckboxInput
                            label={<FormattedMessage id="autoFinance.readAgreeRequesting" />}
                            size="16"
                            fontWeight="600"
                            checked={formValue.agree}
                            name="agree"
                            onChange={(e) => handleCheckboxChange(e, 'agree')}
                        />
                    </Grid>
                    {userMeData.data.is_saudi && <Grid item xs={4} sm={6} md={6} lg={6} >
                        <div style={{ display: "flex" }}>
                            <SwitchWithLabelInput
                                locale={locale}
                                onText={<H5Text size={"16"} lineHeight="19.2" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} marginTop={4}><FormattedMessage id="gosi" /></H5Text>}
                                offText={<H5Text size={"16"} lineHeight="19.2" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} marginTop={4}><FormattedMessage id="pension" /></H5Text>}
                                checked={formValue.gosiPension}
                                onChange={(e) => handleCheckboxChange(e, 'gosiPension')}
                                name="gosiPension"
                            />
                        </div>
                    </Grid>}
                    <Grid container style={{ marginTop: "64px" }}>
                        <Grid item xs={12} md={9} >
                            <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                {/* <ButtonInput
                                    title={<FormattedMessage id="cancel" />}
                                    variant="text"
                                    bgColor="transparent"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                // onClick={() => handleCurStep(2)}
                                /> */}
                                <ButtonInput
                                    title={<FormattedMessage id="cashFinance.getStarted" />}
                                    variant="contained"
                                    bgColor={"yellow"}
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                    onClick={() => handleClick()}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CashFinancing;