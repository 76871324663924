import {
    FINANCE_GET_QUOTE_SERVICE,
    FINANCE_GET_QUOTE_SERVICE_ERROR,
    FINANCE_GET_QUOTE_SERVICE_SUCCESS
} from "./constant";


const financingGetQuoteServiceReducer = (state = {}, action) => {
    switch (action.type) {
        case FINANCE_GET_QUOTE_SERVICE:
            return { ...state, loading: true, error: '' };
        case FINANCE_GET_QUOTE_SERVICE_ERROR:
            return { ...state, type: FINANCE_GET_QUOTE_SERVICE_ERROR, loading: false, error: action.payload };
        case FINANCE_GET_QUOTE_SERVICE_SUCCESS:
            return { ...state, type: FINANCE_GET_QUOTE_SERVICE_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default financingGetQuoteServiceReducer;