import { all, fork } from "redux-saga/effects";
import postRegister from 'store/auth/saga';
import workerScreenSaga from "store/screen/saga";
import accontScreenSaga from "store/account/saga";
import userMeScreenSaga from "store/user/saga"
import workerFinancingSaga from "store/financing/saga";
import helperSaga from "store/helper/saga";
import nafathSaga from "store/nafath/saga";
import tahakukSaga from "store/tahakuk/saga";
import nafathAuthSaga from "store/nafathAuth/saga";
import logoutSaga from "store/logout/saga";
import authSendOtpSaga from "store/auth/otpSend/saga";
import activeFinanceSaga from "store/dashboard/activeFinance/saga";
import paymentHistoryCallSaga from "store/contracts/paymentHistory/saga";
import paymentScheduleCallSaga from "store/contracts/paymentSchedule/saga";
import detailsLoanCallSaga from "store/contracts/detailsLoan/saga";
import appStaticImageSaga from "store/dashboard/appStaticImage/saga";
import contractListCallSaga from "store/contracts/contractList/saga";
import applicationListCallSaga from "store/application/applicationList/saga";
import financingDropDownSaga from "store/financingDropDown/saga";
import financingYakeenUserInfoSaga from "store/financing/yakeenUserInfo/saga";
import usedCarsCallSaga from "store/screen/usedCar/saga";
import campaignDetailsCallSaga from "store/screen/campaignDetails/saga";
import workItemSaga from "store/workItem/saga";
import getVehicleDetailsCallSaga from "store/screen/financeCalculator/saga";
import shimaConsentFormSaga from "store/auth/shimaForm/saga";
import changePasswordSaga from "store/auth/changePassword/saga";
import campaignSearchCallSaga from "store/screen/campaignDetails/campaignSearch/saga";
import uploadDocumentSaga from "store/uploadDocument/saga";
import financeCalculatorSaga from "./financing/financeCalculator/saga";
import userLoginSaga from "store/auth/login/saga";
import bankNameSaga from "store/financing/IbanBankName/saga";
import accountComplaintsSaga from "store/account/complaints/saga";
import accountComplaintsFormSaga from "store/account/complaintsForm/saga";
import leadManagmentSaga from "store/financing/leadManagment/saga";
import notificationsSaga from "store/notifications/saga";
import manualDBRSaga from "./financing/manualDBR/saga";
import financingGetQuoteSaga from "./financing/financeGetQuote/saga";
import financingYakeenUserAddressSaga from "./financing/yakeenUserAddress/saga";
import financingGetQuoteServiceSaga from "./financing/financeGetQuoteService/saga";
import otpVerifySaga from "./auth/verifyOtp/saga";
import setPasswordSaga from "./auth/setPassword/saga";
import createPasswordSaga from "./auth/createPassword/saga";
import uploadDocumentIbanSaga from "./uploadDocument/uploadDocumentIban/saga";
import applicationListWorkItemSaga from "./workItem/applicationListWorkItems/saga";
import fetchWorkItemSaga from "./workItem/fetchWorkItem/saga";
import updateWorkItemSaga from "./workItem/updateWorkItem/saga";
import expenseBankSaga from "./financing/expenseBankValidation/saga";
import nafathPollSaga from "./nafathStatus/saga";
import uploadApplicationDocumentSaga from "store/uploadDocument/uploadApplicationDocument/saga";
import loginDeviceSaga from "./auth/loginDevice/saga";

const rootSaga = function* () {
   yield all([
      fork(postRegister),
      fork(workerScreenSaga),
      fork(accontScreenSaga),
      fork(userMeScreenSaga),
      fork(workerFinancingSaga),
      fork(helperSaga),
      fork(nafathSaga),
      fork(tahakukSaga),
      fork(nafathAuthSaga),
      fork(logoutSaga),
      fork(authSendOtpSaga),
      fork(activeFinanceSaga),
      fork(paymentHistoryCallSaga),
      fork(paymentScheduleCallSaga),
      fork(detailsLoanCallSaga),
      fork(appStaticImageSaga),
      fork(contractListCallSaga),
      fork(applicationListCallSaga),
      fork(financingDropDownSaga),
      fork(financingYakeenUserInfoSaga),
      fork(usedCarsCallSaga),
      fork(campaignDetailsCallSaga),
      fork(workItemSaga),
      fork(getVehicleDetailsCallSaga),
      fork(shimaConsentFormSaga),
      fork(changePasswordSaga),
      fork(campaignSearchCallSaga),
      fork(uploadDocumentSaga),
      fork(userLoginSaga),
      fork(bankNameSaga),
      fork(accountComplaintsSaga),
      fork(accountComplaintsFormSaga),
      fork(leadManagmentSaga),
      fork(financeCalculatorSaga),
      fork(notificationsSaga),
      fork(manualDBRSaga),
      fork(financingGetQuoteSaga),
      fork(financingYakeenUserAddressSaga),
      fork(financingGetQuoteServiceSaga),
      fork(otpVerifySaga),
      fork(setPasswordSaga),
      fork(createPasswordSaga),
      fork(uploadDocumentIbanSaga),
      fork(applicationListWorkItemSaga),
      fork(fetchWorkItemSaga),
      fork(updateWorkItemSaga),
      fork(expenseBankSaga),
      fork(nafathPollSaga),
      fork(uploadApplicationDocumentSaga),
      fork(loginDeviceSaga),

   ]);
};

export default rootSaga;