import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import MaskInput from "components/Input/MaskInput";
import ButtonInput from "components/Input/ButtonInput";
import { getSignInURL } from "routes/AppUrls"
import CheckValidation from "./validation";
import { LocaleContext } from 'routes/Router';
import { authSendOtpCallAPI } from "store/auth/otpSend/action";
import { AUTH_SEND_OTP, AUTH_SEND_OTP_INITIAL_STATE } from "store/auth/otpSend/constant";

function Forgot({ handleCurStep, handleFormData }) {
	const isMobile = window.innerWidth < 700;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [isError, setIsError] = useState(false);
	const [formValue, setFormValue] = useState({
		nationalId: "",
		routingKey: "forgotpassword"
	});
	const AuthError = useSelector((state) => (state.authSendOtpReducer?.error?.data?.message));
	const AuthData = useSelector((state) => (state.authSendOtpReducer && state.authSendOtpReducer?.payload?.data));
	const loader = useSelector((state) => state.authSendOtpReducer?.loading);

	useEffect(() => {
		dispatch(authSendOtpCallAPI(AUTH_SEND_OTP_INITIAL_STATE));
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValue((prevState) => ({
			...formValue,
			[name]: value,
		}));
	};

	const handleSubmit = () => {
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			handleFormData(formValue);
			dispatch(authSendOtpCallAPI(AUTH_SEND_OTP, formValue, { handleStep: handleCurStep, navigate: navigate, step: 2 }));
		}
	};

	return (
		<>
			<H1Text color="primary" size={isMobile ? 46 : 64} lineHeight="50">
				<FormattedMessage id="forgot.forgot" />
			</H1Text>

			<Grid container sx={{ marginTop: "50px" }}>
				<Grid item md={10} xs={12}>
					<MaskInput
						mask="9999999999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="idIqamaNumber" />}
						placeholder="0000000000"
						name="nationalId"
						onChange={handleInputChange}
						errorText={isError && isError.nationalId.error && isError.nationalId.errorMessage}
					/>
				</Grid>

				<Grid item xs={12} md={10} sx={{ marginTop: "80px" }}>
					<Grid container>
						<Grid item xs={12}>
							<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="goBack" />}
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										minWidth="100px"
										marginRight="40"
										padding="0"
										onClick={() => navigate(getSignInURL(locale))}
									/>
									<ButtonInput
										title={<FormattedMessage id="continue" />}
										variant="contained"
										bgColor={"yellow"}
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={loader}
										endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> :  locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
										onClick={() => handleSubmit()}
									/>
								</Stack>
							</Box>
						</Grid>

						{!loader &&
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
								{AuthError ?
									<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
										{AuthError}
									</Text>
									:
									<Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
										{AuthData?.message}
									</Text>
								}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default Forgot;