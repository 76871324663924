import React, { useContext } from 'react';
import styled from 'styled-components';
import { string } from "prop-types";
import { LocaleContext } from 'routes/Router';

const WrapperSpan = styled.span`
	margin-bottom:0;
	font-size: ${(props) => (props.size+"px")};
	color:  ${(props) => (props.theme[props.color])};
	margin-top: ${(props) => (props.marginTop+"px")};
	font-family: ${(props) => (props.fontFamily)};
	font-weight: ${(props) => (props.fontWeight)};
	text-align: ${(props) => (props.TextAlign)};

	${(props) =>
      	props.textTransform && `
 	    text-transform:  ${props.textTransform};
    `}

    ${(props) =>
		props.padding && `
 	    padding:  ${props.padding};
    `}
`;

function SpanText(props) {
	const { locale } = useContext(LocaleContext);
	return (
		<WrapperSpan {...props} fontFamily={props.fontFamily?props.fontFamily:locale=="en"?"Karbon_semibold":"Luma_semibold"} >
			{props.children}
		</WrapperSpan>
	);
}

SpanText.propTypes = {
  	marginTop: string.isRequired,
  	size: string.isRequired,
  	subMarginTop: string,
  	fontWeight: string,
  	fontFamily: string
};

SpanText.defaultProps = {
  	marginTop: 10,
  	size: 16,
  	color:"#3F1956",
  	subMarginTop: 10,
  	fontWeight: 600,
  	// fontFamily: 'Karbon_semibold',
  	TextAlign: 'left',
};

export default SpanText;