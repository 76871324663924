import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCashFinancingConsentFormURL, getCashFinancingInitialDataURL, getFinancingConsentFormURL, getFinancingWaitingPaymentURL, getRevisedFinancingConsentFormURL, getRevisedFinancingWaitingPaymentURL } from 'routes/AppUrls';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import OtpInput from "components/Input/OtpInput";
import ButtonInput from "components/Input/ButtonInput";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { LocaleContext } from "routes/Router";
import HeaderBanner from "features/CashFinancing/HeaderBanner";

const Verification = ({ handleCurStep }) => {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="home" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="applyFinance" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="onlineFinancing" />,
			Link: "",
			isDisbale: true,
		}
	])
	return (
		<Box style={{ backgroundColor: '#F1EDF5', paddingBottom: "30px" }}>
			<Grid margin={'1% 5% 1% 5%'} style={{ marginTop: '5px' }} disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} option={true} />
					</Grid>
				</Grid>
				<Box margin='32px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box>
				<Grid container style={{ marginTop: "30px" }}>
					<Grid item xs={12}>
						<MuiCards borderRadius="16" >
							<CardContent style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
								<Grid maxWidth={'694px'} textAlign={'center'} display='flex' alignItems='center' flexDirection='column'>
									<Grid container>
										<Grid item xs={12}>
											<Text color="black" size="48" lineHeight='50' fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} TextAlign="center">
												<FormattedMessage id="enterVerificationCode" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text color="gray" size="24" lineHeight='36' TextAlign="center" >
												<FormattedMessage id="enterVerificationCode.sms_text" />&nbsp;*****7412
											</Text>
										</Grid>
									</Grid>

									<Grid container maxWidth={'535px'} marginTop='55px'>
										<Grid item xs={12}>
											<OtpInput
												marginTop="30"
												TextFieldsProps={{ placeholder: 0 }}
												validateChar={(val) => !isNaN(val)}
												value={'213'}
											/>
										</Grid>
										<Grid item xs={12} marginTop='25px' >
											<div style={{ display: "flex" }}>
												<Text size="16" fontWeight="800">
													<FormattedMessage id="resendCodeIn" />
												</Text>
												<Text size="16" fontWeight="800">
													&nbsp;<FormattedMessage id="00:00" />
												</Text>
												<Text size="16" color="purple" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>
													&nbsp;<FormattedMessage id="resendOTP" />
												</Text>
											</div>
										</Grid>
									</Grid>

									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<div className="content" style={{ display: "flex", justifyContent: "end" }}>
												<Box component="form">
													<Stack spacing={2} direction="row">
														<ButtonInput
															title={<FormattedMessage id="back" />}
															variant="text"
															bgColor="transparent"
															textColor="purple"
															borderRadius="100"
															TextTransform="capitalize"
															onClick={() => navigate(getCashFinancingConsentFormURL(locale))}
														/>
														<ButtonInput
															title={<FormattedMessage id="continue" />}
															variant="contained"
															bgColor="yellow"
															textColor="purple"
															borderRadius="100"
															TextTransform="capitalize"
															endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
															// onClick={() => navigate(getRevisedFinancingWaitingPaymentURL('en'))}
															onClick={() => navigate(getCashFinancingInitialDataURL(locale))}
														/>
													</Stack>
												</Box>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);

};

export default Verification;