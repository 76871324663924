import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import ButtonInput from "components/Input/ButtonInput";
import CircularProgress from "components/CircularProgressbar";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from "@mui/material";
import moment from "moment";

import { LocaleContext } from 'routes/Router';

const StyleWrapper = styled.div`
    align-items: center;

    .right_container{
        background:#ffffff;
        border-radius:16px;
    };

    .right_container_top{
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
    };

    .right_container_style{
        padding:20px 28px 20px 28px;
    };

    .right_container_body{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
    };

    .finance_container{
        width:95%;
    }
 
`

const ContractInfo = (props) => {
    const { locale } = useContext(LocaleContext);

    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="contractInfo" />
                    </H5Text>
                </div>

                <div className="right_container_style">
                    <div className="finance_container">
                        <H5Text size="18" marginTop="0" color="purple" >
                            <FormattedMessage id="finance" />
                        </H5Text>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: "30%", width: "30%" }}>
                                <CircularProgress percentage={"76"} />
                            </div>
                            <div style={{ marginLeft: locale === 'en' && "24px", marginRight: locale === 'ar' && "24px", width: "100%" }}>
                                <H5Text size={'20'} marginTop='0' lineHeight={'24'} bgColor='secondaryPink' padding='12px 16px' borderRadius='8' fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color="purple"> Monthly Amount: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block',  fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold' }}>{props?.extraLoanData?.loanEmiAmount} SAR</p> </H5Text>
                                <H5Text size={'20'} lineHeight={'24'} padding='0px 16px' borderRadius='8' fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color="purple"> <FormattedMessage id="nextInstallment" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold' }}>{props?.data?.nextEmiAmount} SAR</p> </H5Text>
                                <H5Text size={'20'} lineHeight={'24'} padding='0px 16px' borderRadius='8' fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color="purple"> <FormattedMessage id="balanceAmount" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold' }}>{props?.extraLoanData?.loanBalancePrincipal} SAR</p> </H5Text>
                                <H5Text size={'20'} lineHeight={'24'} padding='0px 16px' borderRadius='8' fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color="purple"> <FormattedMessage id="remainingPeriod" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold' }}>{props?.extraLoanData?.loanTenure - props?.extraLoanData?.billedInstallment}</p> </H5Text>
                            </div>
                        </div>
                        <Grid container display="flex" justifyContent="space-between" marginTop="16px">
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ height: "14px", width: "14px", background: "#24174C" }}></div>
                                <H5Text marginTop={'0'} size={'20'} lineHeight={'24'} padding='0px 6px' borderRadius='8' fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color="lightGrey">   <FormattedMessage id="collectionToDate" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold', color: '#3F1956' }}>{props?.data?.totalAmountPaid} SAR</p> </H5Text>
                            </Grid>

                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ height: "14px", width: "14px", background: "#DBD7EA" }}></div>
                                <H5Text marginTop={'0'} size={'20'} lineHeight={'24'} padding='0px 6px' borderRadius='8' fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color="lightGrey">  <FormattedMessage id="overdueAmount" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold', color: '#3F1956' }}> {props?.extraLoanData?.loanOverdueAmount} SAR</p> </H5Text>
                            </Grid>
                        </Grid>
                    </div>
                    <Box marginTop='12px'>
                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"startDate"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {props?.extraLoanData?.loanDisbursalDate ? moment(props?.extraLoanData?.loanDisbursalDate).format("YYYY-MM-DD") : ""}
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"endDate"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {props?.extraLoanData?.loanMaturityDate ? moment(props?.extraLoanData?.loanMaturityDate).format("YYYY-MM-DD") : ""}
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"tenure"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {props?.data?.tenure} Months
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"downPayment"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {props?.data?.loanDownPayment} SAR
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"residualValue"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {props?.data?.residualValue} SAR
                            </Text>
                        </div>

                    </Box>

                    <Grid container display="flex" justifyContent="space-between" marginTop="20px">
                        <Grid item>
                            <Text size="18" marginTop="0" color="lightGrey">
                                <FormattedMessage id="settlementAmount" />
                            </Text>
                            <Text size="32" marginTop="10" color="purple" style={{ fontFamily: locale === 'en' ? 'Karbon_semibold' : 'Luma_semibold' }}>
                                {props?.data?.settlementAmount}&nbsp;<FormattedMessage id="sar" />
                            </Text>
                        </Grid>
                        <Grid item sx={{ marginTop: { xs: '10px', xl: '0' } }}>
                            <ButtonInput
                                title={<FormattedMessage id="earlySettlement" />}
                                variant="text"
                                bgColor="transparent"
                                borderColor="purple"
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                size={"24"}
                                padding={"10px 16px"}
                                endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Row>
        </StyleWrapper >
    );
};

export default ContractInfo;