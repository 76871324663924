import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Text from "components/Text";
import { LocaleContext } from 'routes/Router';

const WrapperTextField = styled(TextField)`
	width: 100%;
	margin-top: 20px !important;

	.MuiInputLabel-root {
		font-size: ${(props) => props.fontSize + "px"};
		line-height: 24px;
		font-weight: 700;
		transform: none;
		font-family: ${(props) => props.theme.KarbonMedium};
		color: ${(props) => props.theme.darkSecondary};
		top: -5px;
		width:100%;
	}

	.MuiInputLabel-root.Mui-focused  {
		color: ${(props) => props.theme.darkSecondary};
	}

	.MuiInput-root {
		border: 1px solid ${(props) => props.theme.lightDarkSecondary};
		border-radius: ${(props) => props.borderRadius + "px"};
		height: ${(props) => props.height ? props.height : "56px"};
		padding: 15px;
		color: ${(props) => props.theme[props.textColor]};
		font-size: ${(props) => props.fontSize + "px"};
		margin-top: ${(props) => props.marginTop ? props.marginTop + "px" : "25px"};
		font-family: ${(props) => (props.locale == "en" ? props.theme.KarbonLight : props.theme.LumaLight)};

		${(props) =>
		props.bgColor && `
	 	    background-color:  ${props.theme[props.bgColor]};
	    `}

	    ${(props) =>
		props.borderNone && `
	 	    border: none;
	    `}

		&:before {
			border: none !important;
		}
		&:after {
			border: none !important;
		}

		.MuiInput-input {
			color: ${(props) => props.theme[props.textColor]};
			font-weight: 600;
			cursor:  ${(props) => props.cursor === 'not-allowed' ? 'not-allowed' : "pointer"};
		}

		.MuiInputAdornment-root {
			.MuiTypography-root {
				font-size: 16px;
				line-height: 20px;
				color: ${(props) => props.theme[props.textColor]};
				font-family: ${(props) => (props.locale == "en" ? props.theme.KarbonLight : props.theme.LumaLight)};
				font-weight: 700;
			}
		}
	}

	.MuiInput-root.Mui-focused {
		border: 1px solid ${(props) => props.theme.lightDark};
		color: ${(props) => props.theme.darkSecondary};

		.MuiInput-input {
			color: ${(props) => props.theme.darkSecondary};
		}

		&:before {
			border: none !important;
		}
		&:after {
			border: none !important;
		}
	}
`;

const WrapperErrorDiv = styled.div`
	font-size: 18px;
	color: ${(props) => props.theme.errorColor};
	line-height: 27px;
	font-weight: 600;
	font-family: ${(props) => (props.locale == "en" ? props.theme.KarbonLight : props.theme.LumaLight)};
	margin-top: 5px;
`;

function LabelWithInput({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);
	return (
		<FormControl fullWidth>
			<WrapperTextField
				locale={locale}
				id="standard-required"
				variant="standard"
				fullWidth={true}
				InputLabelProps={{
					shrink: true,
				}}

				{...props}
			/>
			{props.errorText &&
				<WrapperErrorDiv locale={locale}>
					<ErrorOutlineIcon /> {props.errorText}
				</WrapperErrorDiv>
			}
			{props.helperInfoPassword &&
				<div>
					<Text size="16" color="lightDark" lineHeight="24">
						<FormattedMessage id="forgot.helperPasswordTest" />
					</Text>
				</div>
			}
		</FormControl>
	);
};

LabelWithInput.defaultProps = {
	borderRadius: 4,
	fontSize: 16,
	textColor: "lightGray"
};

export default LabelWithInput;