import React, { createContext, useEffect, useState } from 'react';
import { Routes, Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import Navbar from 'components/Navbar/Navbar';
import Sidebar from 'components/Sidebar/Sidebar';
import Error404 from 'screens/Pages/404/404';
import { availableLocales, isAuthenticated, getDefaultLanguage } from 'utils/general';
import { getLandingURL, getDashboardURL } from './AppUrls';
import { allRoutes } from './RoutesConfig';

export const RenderRouteElement = ({ children, isNoSidebar, isSiderBarIcon, isPrivate, isCommon, locale }) => {
    return (
        <React.Fragment>
            {!isAuthenticated() && (!isPrivate || isCommon) ? (
                <>
                    <Navbar sideBarIcon={isSiderBarIcon} />
                    {!isNoSidebar && <Sidebar />}
                    {children}
                </>
            ) : isAuthenticated() && (!isPrivate) ? (
                <>
                    <Navigate replace to={getDashboardURL(locale)} />
                </>
            ) : isAuthenticated() && (isPrivate) ? (
                <>
                    <Navbar sideBarIcon={isSiderBarIcon} />
                    {!isNoSidebar && <Sidebar />}
                    {children}
                </>
            ) : (
                <>
                    <Navigate replace to={getLandingURL(locale)} />
                </>
            )}

        </React.Fragment>
    )
}

export const LocaleContext = createContext({
    locale: '',
    setLocale: (newLocale) => { },
});

const Router = ({ LanguageSwitcher }) => {
    const navigate = useNavigate();
    const { pathname, search, hash } = useLocation();
    const defaultLocale = getDefaultLanguage('ar');
    const pathnameLocale = pathname.substring(1, 3).toLowerCase();

    const [locale, setLocale] = useState(defaultLocale);

    useEffect(() => {
        if (availableLocales.includes(pathnameLocale)) {
            updateLocale(pathnameLocale)
        } else if (pathname === "/") {
            updateLocale(defaultLocale);
        } else if (!availableLocales.includes(pathnameLocale)) {
            updateLocale(pathnameLocale);
        }
    }, [pathname]);

    useEffect(() => {
        let lang = defaultLocale;

        if (availableLocales.includes(pathnameLocale)) {
            lang = pathnameLocale;
            setLanguageHandler(lang);
        } else if (pathname === '/') {
            setLanguageHandler(lang);
        }
    }, [locale]);

    const setLanguageHandler = (lang) => {
        //set language attribute on HTML element
        document.documentElement.setAttribute('lang', lang);

        if (lang === 'en') {
            LanguageSwitcher('en');
        } else {
            LanguageSwitcher('ar');
        }
    }

    const updateLocale = (newLocale) => {

        const newPath = `/${newLocale}` + pathname.substring(3);

        if (locale !== newLocale) {
            if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === "/") {
                navigate(getLandingURL(newLocale));
            } else if (!availableLocales.includes(pathnameLocale)) {
                navigate(`${defaultLocale}${newPath}${hash}${search}`);
            } else {
                navigate(`${newPath}${hash}${search}`);
            }
        } else if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === "/") {
            if (isAuthenticated()) {
                navigate(getDashboardURL(newLocale));
            } else {
                navigate(getLandingURL(newLocale));
            }
        }

        setLocale(newLocale);
    }

    const renderRouteWithChildren = (routes) => {
        return routes.map((route, index) => (
            <Route
                key={index}
                path={route.path(locale)}
                element={
                    <RenderRouteElement
                        isNoSidebar={route.isNoSidebar}
                        isSiderBarIcon={route.isSiderBarIcon}
                        isPrivate={route.isPrivate}
                        isCommon={route.isCommon}
                        locale={locale}
                    >
                        {route.element}
                    </RenderRouteElement>
                }
            >
                {route.children && renderRouteWithChildren(route.children)}
            </Route>
        ))
    }

    return (
        <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
            <Routes baseName={`/:${locale}`}>
                {renderRouteWithChildren(allRoutes)}
                {pathnameLocale && <Route path={"*"} element={<Error404 />} />}
            </Routes>
        </LocaleContext.Provider>
    );
}
export default Router;
