import { Box, Grid } from '@mui/material'
import SideBySideText from 'components/SideBySideText'
import H1Text from 'components/Text/H1Text'
import H5Text from 'components/Text/H5Text'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonInput from 'components/Input/ButtonInput'
import { FormattedMessage } from 'react-intl'
import MuiBreadcrumbs from 'components/MuiBreadcrumbs'
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import HeaderBanner from 'features/RevisedFinancing/HeaderBanner'
import { getDashboardURL, getRevisedFinancingInitialDataURL, getRevisedFinancingURL } from 'routes/AppUrls'
import { useNavigate } from 'react-router-dom'
import { LocaleContext } from 'routes/Router'
import { useDispatch, useSelector } from 'react-redux'
import { CONTINUE_WITH } from 'store/basic/constant'
import { continueWith } from "store/basic/actions";
import { financeCalculatorCallAPI } from 'store/financing/financeCalculator/action'
import LocalStorageManager from 'managers/LocalStorageManger'
import { ONLINE_FINANCE_CALCULATOR } from 'store/financing/financeCalculator/constant'

const IneligibleStyle = styled.div`
background:${(props) => props.theme.lightSecondary};
padding:0.5% 5% 2.4% 5%;
min-height:100vh;
`
const GridStyle = styled(Grid)`
background:${(props) => props.theme.white};
border-radius:16px;
display:flex;
justify-content:center;
padding:24px 10px 72px 10px;
`
const ApplicationRejected = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { locale } = useContext(LocaleContext);
    const gosiPensionData = useSelector((state) => state?.financingReducer?.payload?.data?.data?.employmentStatusInfo);
    const getEnteredAmount = useSelector((state) => state?.basicReducer?.netIncome);
    const getGosiAmount = useSelector((state) => state?.financingReducer?.payload?.data?.data?.employmentStatusInfo?.[0]?.basicWage);
    const getVehicleListDetails = useSelector((state) => (state.vehicleDetailsReducer && state.vehicleDetailsReducer?.payload?.data));

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: getRevisedFinancingURL(locale)
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])

    // const handleSubmit = (value)=>{
    //     navigate(getRevisedFinancingInitialDataURL(locale));
    //     dispatch(continueWith(CONTINUE_WITH, value))
    // }

    const FinanceCalculatorPayloadData = LocalStorageManager.getItem('finance_Calculator_payload') !== null && JSON.parse(LocalStorageManager.getItem('finance_Calculator_payload'));
    const handleSubmit = (value) => {
        let body = {
            "guestType": FinanceCalculatorPayloadData?.guestType,
            "netIncome": value == "gosi" ? parseFloat(getGosiAmount) : value == "enteredAmount" ? getEnteredAmount : "",
            "paymentType": FinanceCalculatorPayloadData?.paymentType,
            "brandID": FinanceCalculatorPayloadData?.brandID,
            "autoProductId": FinanceCalculatorPayloadData?.autoProductId,
            "modelId": FinanceCalculatorPayloadData?.modelId,
            "modelYear": FinanceCalculatorPayloadData?.modelYear,
            "vehicleType": FinanceCalculatorPayloadData?.vehicleType,
            "vehiclePrice": FinanceCalculatorPayloadData?.vehiclePrice,
            "tenureInMonths": FinanceCalculatorPayloadData?.tenureInMonths,
            "totalLimitOfAllCC": FinanceCalculatorPayloadData?.totalLimitOfAllCC,
            "financeVATFlag": FinanceCalculatorPayloadData?.financeVATFlag,
            "maxMonthlyAmount": FinanceCalculatorPayloadData?.maxMonthlyAmount,
            "emiAmount": FinanceCalculatorPayloadData?.emiAmount,
            "residualAmount": FinanceCalculatorPayloadData?.residualAmount,
            "downPaymentAmt": FinanceCalculatorPayloadData?.downPaymentAmt,
            "PromoCode": FinanceCalculatorPayloadData?.PromoCode,
            "basePremiumInsuranceAmount": FinanceCalculatorPayloadData?.basePremiumInsuranceAmount,
            "featurePremiumInsuranceAmount": FinanceCalculatorPayloadData?.featurePremiumInsuranceAmount,
            "productCategory": FinanceCalculatorPayloadData?.productCategory,
            "scheme": FinanceCalculatorPayloadData?.scheme,
            "product": FinanceCalculatorPayloadData?.product
        };

        dispatch(financeCalculatorCallAPI(ONLINE_FINANCE_CALCULATOR, body, { handleFunction: navigate(getRevisedFinancingInitialDataURL(locale)), navigate: navigate, run: true }));
        dispatch(continueWith(CONTINUE_WITH, value));
    };

    return (
        <IneligibleStyle>
            <Grid container>
                <Grid item xs={12}>
                    <HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} option={true} />
                </Grid>
            </Grid>
            <Box margin='52px 0 12px 0'>
                <MuiBreadcrumbs size='20' list={breadcrumbsList} />
            </Box>
            <GridStyle  >
                <Grid container justifyContent={'center'}>
                    <Grid container maxWidth={'720px'}>
                        <Grid item xs={12} textAlign='center' marginTop={'24px'} >
                            <img src={require('assets/images/feeling-sorry-animate.png')} alt="" />
                            {(getGosiAmount === null || getGosiAmount === undefined) ?
                                <>
                                    <H1Text size={'40'} lineHeight={'49'} fontFamily="Karbon_bold">
                                        <FormattedMessage id="Data Not Found In Gosi" />
                                    </H1Text>
                                    {/* <H5Text size={'24'} lineHeight={'25.48'} fontFamily={locale=="en"?'Karbon_light':"Luma_light"} color='lightGrey' marginTop={'8'}> */}
                                    {/* <H5Text size={'24'} lineHeight={'25.48'} fontFamily="Karbon_light" color='lightGrey' marginTop={'8'}>
>>>>>>> a6f3a9771e8c6a6aad8779a4b814f8fb365934b4
                                        <FormattedMessage id="You are not eligible for the selected product" />
                                    </H5Text> */}
                                </>
                                :
                                <>
                                    <H1Text size={'40'} lineHeight={'49'} fontFamily="Karbon_bold">
                                        <FormattedMessage id="autoFinance.Discrepancies" />
                                    </H1Text>
                                    <H5Text size={'24'} lineHeight={'25.48'} fontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} color='lightGrey' marginTop={'8'}>
                                        <FormattedMessage id="autoFinance.rejectAmount" />
                                    </H5Text>
                                </>
                            }
                            <H1Text size={'24'} lineHeight={'28.8'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} color='purple'>{getVehicleListDetails?.data?.ModelDescription}</H1Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container marginTop='20px' maxWidth={'672px'}>

                                <Grid item xs={12} paddingTop='18px'>
                                    <SideBySideText leftText={<FormattedMessage id="autoFinance.enteredAmount" />} RightText={getEnteredAmount.toFixed(2) + " SAR"} rightFontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} leftfontFamily={locale == "en" ? 'Karbon_light' : "Luma_light"} leftTextColor="lightGrey" rightTextColor="lightDark" leftFontWight="800" size="20" pBottom='0' />
                                </Grid>
                                {(getGosiAmount === null || getGosiAmount === undefined) ? "" :
                                    <Grid item xs={12} paddingTop='18px'>
                                        <SideBySideText
                                            leftText={<FormattedMessage id="autoFinance.amountFetchedGOSI" />}
                                            RightText={(parseFloat(gosiPensionData?.[0]?.basicWage) + parseFloat(gosiPensionData?.[0]?.housingAllowance) + parseFloat(gosiPensionData?.[0]?.otherAllowance)) + " SAR"}
                                            rightFontFamily="Karbon_semibold"
                                            leftFontFamily="Karbon_light"
                                            leftTextColor="lightGrey"
                                            rightTextColor="lightDark"
                                            leftFontWight="800"
                                            size="20"
                                            pBottom='0'
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} marginTop='156px'>
                        {(getGosiAmount === null || getGosiAmount === undefined) ?
                            <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                <ButtonInput
                                    title={<FormattedMessage id="autoFinance.proceedEnteredAmount" />}
                                    variant="contained"
                                    bgColor={"yellow"}
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                    onClick={() => handleSubmit('enteredAmount')}
                                />
                                <ButtonInput
                                    title={<FormattedMessage id="Cancel Application" />}
                                    variant="text"
                                    bgColor="transparent"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    onClick={() => navigate(getDashboardURL(locale))}
                                />
                            </Box>
                            :
                            <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                                <ButtonInput
                                    title={<FormattedMessage id="autoFinance.proceedEnteredAmount" />}
                                    variant="text"
                                    bgColor="transparent"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    onClick={() => handleSubmit('enteredAmount')}
                                />
                                <ButtonInput
                                    title={<FormattedMessage id="autoFinance.continuewithGOSI" />}
                                    variant="contained"
                                    bgColor={"yellow"}
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                    onClick={() => handleSubmit('gosi')}
                                />
                            </Box>
                        }
                    </Grid>
                </Grid>
            </GridStyle>
        </IneligibleStyle>
    )
}

export default ApplicationRejected;
