import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import IconWithText from 'components/IconWithText';
import SlickCarousel from 'components/SlickCarousel';
import H5Text from 'components/Text/H5Text';
import IconImage from "assets/images/icon/UnionSmall.png";
import Text from 'components/Text';
import { Box, Grid, InputAdornment, Skeleton } from '@mui/material';
import StartWithIcon from 'components/Text/StartWithIcon';
import LabelWithInput from 'components/Input/LabelWithInput';
import ButtonInput from "components/Input/ButtonInput";
import OneWaySlider from 'components/RangeSlider/OneWaySlider';
import backArrow from 'assets/images/icon/backArrow.png';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import MuiBreadcrumbs from 'components/MuiBreadcrumbs';
import { getRevisedFinancingURL, getFindVehicleURL, getDashboardURL } from "routes/AppUrls";
import { LocaleContext } from 'routes/Router';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleDetailsCallAPI } from 'store/screen/financeCalculator/action';
import { GET_VEHICLE_DETAILS, GET_VEHICLE_DETAILS_ERROR, GET_VEHICLE_DETAILS_SUCCESS } from 'store/screen/financeCalculator/constant';
import { workItemCallAPI } from 'store/workItem/action';
import { WORK_ITEM_CREATE } from 'store/workItem/constant';
import { ONLINE_FINANCE_CALCULATOR } from 'store/financing/financeCalculator/constant';
import { financeCalculatorCallAPI } from 'store/financing/financeCalculator/action';
import CircularProgress from '@mui/material/CircularProgress';
import { manualDBRCallAPI } from 'store/financing/manualDBR/action';
import { MANUAL_DBR } from 'store/financing/manualDBR/constant';
import LocalStorageManager from 'managers/LocalStorageManger';
import { fetchWorkItemCallAPI } from 'store/workItem/fetchWorkItem/action';
import { FETCH_WORK_ITEM } from 'store/workItem/fetchWorkItem/constant';
import { NetIncome, WorkItemAppId } from 'store/basic/actions';
import { NET_INCOME, WORK_ITEM_APP_ID } from 'store/basic/constant';
import moment from "moment";
import { ReactImageTurntable } from 'react-image-turntable';


const ContainerStyle = styled.div`
    padding: 0 5% 5% 5%;
    background:#f1edf5;

    .BackHeading {
        display: flex;
        align-items: center;
        img{
            margin-top: 7px;
            margin-right:24px;
        }
    }
    .slick-slide{
       min-height:262px;
       display: flex;
        align-items: center;
        justify-content:center;
    }
    @media screen and (max-width:768px) {
        .BackHeading {
            img {
                margin-top: 8px;
                margin-right:12px !important;
                height:18px;
            }
        }
    }

    .labelInput {
        margin-top:24px;
    }

    .labelDesign {
        font-family: Karbon_medium;
        font-size: 16px;
        font-weight: 700;

        .star {
            color:red;
        }
    }

    .image {
        height:20px;
        margin-left:14px;
    }

    .percent {
        margin-left:14px;
    }

    .ApplyButton {
        color:var(--primary);
        font-family: Karbon_medium;
        font-size: 18px;
        font-weight: 800;
        cursor:pointer;
    }
`;

const ProductDetail = styled.div`
    background:#ffffff;
    padding:32px;
    border-radius:16px;
    height:100%;

    .IconWithText {
        margin-top:24px;
    }

    @media screen and (max-width:767px) {
        padding: 32px 16px;
    }
`;

const FinanceCal = styled.div`
    background:#ffffff;
    border-radius:16px;
    height:100%;
    position: relative;

    .innerFinance {
        padding:20px 24px;
    }
    .Slider {
        margin-top:25px;
    }

    .CalBtn {
        margin:120px 0px 28px;
        display:flex;
        justify-content:end;
    }

    @media screen and (max-width:1199px) {
        margin-top:40px;
    }

    @media screen and (max-width:1199px) {
        height:fit-content;
        .CalBtn{
            margin: 20px 0px 0px;
            width:100% !important;
            button {
                width:100%;
            }
        }

        .innerFinance {
            padding: 20px 16px;
        }
    }
`;

const FinanceResult = styled.div`
    background:#ffffff;
    border-radius:16px;

    @media screen and (max-width:1399px) {
        margin-top:40px;
    }

    @media screen and (max-width:991px) {
        margin-top:40px;
    }
`;

const IconGroup = styled.div`
    right: 0;
    display: flex;
    justify-content: end;
    padding: 10px 20px 0 0;
    border-radius: 12px;
    max-height:25%;
`;

const IconStyle = styled.div`
    background:${(props) => props?.background};
    border-radius:50%;
    min-width:40px;
    min-height:40px;
    max-width:40px;
    max-height:40px; style={{margin:'0'}}
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left:${(props) => props?.marginLeft};
`;
const UpperHead = styled.div`
    display: flex;
    justify-content: space-between;
`
const FinanceData = styled.div`
    font-family:${(props) => props.locale == "en" ? 'Karbon_light' : 'Luma_light'};
    font-weight:600;
    color:#1D1B20;
    padding:0 28px 0 28px;
    font-size:20px;

    .containerData {
        padding:20px 0 14px 0;
        border-bottom:1px solid #E8E8E8;

        .star {
            color:red;
        }

        .Price {
            color:var(--primary);
            font-size:32px;
            font-family:${(props) => props?.locale == "en" ? "Karbon_semibold" : "Luma_semibold"};
            font-weight:800;
            display: flex;
            justify-content: end;
            align-items: center;

            span {
                font-size:20px;
                font-family:Karbon_medium;
                font-weight:700;
                color:#8F8D95;
                margin-left:8px;
            }
        }
    }

    @media screen and (max-width:1199px) {
        .containerData {
            .Price {
                justify-content: start;
            }
        }
    }
`;

const GirdContainer = styled.div`
    margin-top:30px;

    .GirdItem {
        border:1.44px solid #DEDEDE;
        border-radius:11.52px;
        cursor:pointer;
        position:relative;
    }

    .selected {
        border:2px solid #3F1956;
    }
    
    .unionimg {
        position:absolute;
        height:18px;
        width:25px;
        margin-left:-20px;
        margin-top:-7px;
        background:#ffffff;
        left:${(props) => props.locale === 'ar' && '10px'};
        right:${(props) => props.locale === 'en' && '-10px'};
    }
`;

const SelectAmong = styled.div`
    margin-top:24px;
  
    .itemContainer{
        display:flex;
        margin-top:8px !important;
        gap: 10px;
       

        .item {
            margin-right: 10px;
            color:#909FA6;
            border:1px solid #909FA6;
            border-radius:5px;
            width:156px;
            height:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor:pointer;
        }
        
        .selected {
           color:#14303A;
           border:1px solid #14303A;
        }
    }
`

const FinanceCalculator = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedCar, setSelectedCar] = useState(null);
    const [selectedCarData, setSelectedCarData] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState(1);
    const [selectedFinanceVAT, setSelectedFinanceVAT] = useState(1);
    const [vehicleProductDetail, setvehicleProductDetail] = useState([]);

    const [vehicleProductImage, setvehicleProductImage] = useState([]);
    const { locale } = useContext(LocaleContext);

    const [monthlyIncome, setMonthlyIncome] = useState(LocalStorageManager.getItem("monthlyIncome") !== null ? LocalStorageManager.getItem("monthlyIncome") : 10000);
    const [promoCode, setPromoCode] = useState('');
    const [monthlyAmount, setMonthlyAmount] = useState(0);
    const [downPayAmount, setDownPayAmount] = useState(0);
    const [creditCardLimit, setcreditCardLimit] = useState(0);
    const [residualValue, setResidualValue] = useState('');
    const [obligation, setObligation] = useState(0);
    const [period, setPeriod] = useState(60);
    const [rotateView, setRotateView] = useState(false);

    const userDetails = useSelector((state) => (state.userReducer && state.userReducer?.payload?.data));
    const getVehicleListDetails = useSelector((state) => (state.vehicleDetailsReducer && state.vehicleDetailsReducer?.payload?.data));
    const vehicleDetails = useSelector((state) => (state?.vehicleDetailsReducer));
    const loader = useSelector((state) => state?.vehicleDetailsReducer?.loading || state.screenReducer?.loading);
    const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);
    const financingCalculatorData = useSelector((state) => state.financeCalculatorReducer && state.financeCalculatorReducer?.payload?.data);
    const financingCalculatorLoader = useSelector((state) => state?.financeCalculatorReducer?.loading || state.financeCalculatorReducer?.loading);
    const manualDBRData = useSelector((state) => state.manualDBRReducer && state.manualDBRReducer?.payload?.data?.['soap:Envelope']?.['soap:Body']?.['ProcessApplicationXMLResponse']?.['ProcessApplicationXMLResult']?.['Response']?.['Decision']);
    const workItemLoader = useSelector((state) => state?.applicationListWorkItemReducer?.loading || state?.workItemReducer?.loading || state?.fetchWorkItemReducer?.loading);
    const manualDBRLoader = useSelector((state) => state?.manualDBRReducer?.loading || state.manualDBRReducer?.loading);
    const workItemListData = useSelector((state) => state?.applicationListWorkItemReducer && state?.applicationListWorkItemReducer?.payload?.data);
    const [isResidualValueError, setIsResidualValueError] = useState(false);
    const [isMonthlyIncomeError, setIsMonthlyIncomeError] = useState(false);

    ///////////////////////HIJRI DATE TO GREGORAIN DATE//////////////////////////
    const momenHijri = require('moment-hijri');
    const convertHijriToGregorian = (hijriDate) => {
        // Parse the Hijri date using moment-hijri
        const parsedHijriDate = momenHijri(hijriDate, 'iYYYY/iMM/iDD');
        // Convert the parsed Hijri date to a Gregorian date
        const gregorianDate = parsedHijriDate.toDate();
        return gregorianDate;
    };
    ////////////////////////////////////////////////////

    useEffect(() => {
        vehicleDetailsData(location?.state?.id);
        ManualDbrVerify();
    }, []);

    const vehicleDetailsData = (id) => {
        let body = `${id}/details/`
        dispatch(getVehicleDetailsCallAPI(GET_VEHICLE_DETAILS, body, { navigate: navigate }));
    };

    const items = [
        {
            id: 1,
            img: vehicleProductDetail?.data?.ModelImage
        },
        {
            id: 2,
            img: vehicleProductDetail?.data?.ModelImage
        },
    ];

    const paymentType = [
        {
            id: 1,
            type: <FormattedMessage id="monthlyPayment" />
        },
        {
            id: 2,
            type: <FormattedMessage id="downPayment" />
        },
    ];

    const financeVAT = [
        {
            id: 1,
            type: <FormattedMessage id="no" />
        },
        {
            id: 2,
            type: <FormattedMessage id="yes" />
        },
    ];

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: getDashboardURL(locale)
        },
        {
            title: <FormattedMessage id="vehicleList" />,
            Link: getFindVehicleURL(locale)
        },
        {
            title: <FormattedMessage id="financeCalculator" />,
            Link: "",
            isDisbale: true,
        }
    ]);

    useEffect(() => {
        if (vehicleDetails && vehicleDetails?.type === GET_VEHICLE_DETAILS_ERROR && vehicleDetails?.payload?.data && vehicleDetails.payload?.data?.results) {
        } else if (vehicleDetails && vehicleDetails?.type === GET_VEHICLE_DETAILS_SUCCESS && vehicleDetails?.payload?.data) {
            setvehicleProductDetail(vehicleDetails?.payload?.data);
        }
    }, [vehicleDetails]);

    const handleCarSelect = (data, id) => {
        setSelectedCar(id);
        setSelectedCarData(data);
    };

    useEffect(() => {
        setSelectedCar(0)
        if (vehicleProductDetail?.data?.ColorsDetails) {
            setSelectedCarData(vehicleProductDetail?.data?.ColorsDetails?.[0]);
        }
    }, []);

    useEffect(() => {
        if (vehicleProductDetail?.data?.ColorsDetails) {
            setSelectedCarData(vehicleProductDetail?.data?.ColorsDetails?.[0]);
        }

        if (vehicleProductDetail?.data?.vehical_details_en) {
            let enImages = [];
            vehicleProductDetail?.data?.vehical_details_en.forEach((value, index) => {
                enImages.push({ "id": index + 1, "img": value.image });
            });

            setvehicleProductImage(enImages);
        } else if (vehicleProductDetail?.data?.ModelImage) {
            let enImages = [];
            enImages.push({ "id": 1, "img": vehicleProductDetail?.data?.ModelImage });

            setvehicleProductImage(enImages);
        }

    }, [vehicleProductDetail]);

    const handleChange = (e) => {
        if (e.target.name === 'monthlyIncome') {
            setMonthlyIncome(e.target.value);
        } else if (e.target.name === "promoCode") {
            setPromoCode(e.target.value);
        } else if (e.target.name === "residualValue") {
            setResidualValue(e.target.value);
        }
    };

    const handleBlur = (e) => {
        const { value } = e.target;
        const minValue = 3000;
        const maxValue = 60000;
        let parsedValue = parseFloat(value);

        if (parsedValue > maxValue) {
            parsedValue = maxValue;
        }

        if (isNaN(parsedValue) || value === '') {
            parsedValue = 0;
            parsedValue = Math.max(parsedValue, minValue);
        };

        if (!isNaN(parsedValue)) {
            parsedValue = Math.max(parsedValue, minValue);
        };

        setMonthlyIncome(parsedValue);
    }

    const handleTenureChange = (data) => {
        setPeriod(data);
    };

    const ManualDbrVerify = () => {
        let body = {
            "Type": "Guest",
            "IDType": userMeData?.data?.id_type,
            "IDNumber": userMeData?.data?.nationalId,
            "GuestTypeID": "Retail",
            "SubFinanceTypeID": 0,
            "MonthlyIncome": +monthlyIncome,
            "MonthlyAdditionalIncome": 0,
            "DirectDebit": 0,
            "GOSIDeduction": 0,
            "CreditCardLimit": creditCardLimit,
            "OtherMonthlyInstallment": obligation,
            "IsGovernmentRetiree": "N",
            "HasMortgage": "N",
            "COLA": 0,
            "ALJUFOtherMonthlyPayments": 0,
            "ALJUFDirectDebits": 0
        };

        const isMonthlyIncomeValid = !monthlyIncome || monthlyIncome <= 0;
        setIsMonthlyIncomeError(isMonthlyIncomeValid);

        if (!isMonthlyIncomeValid) {
            dispatch(manualDBRCallAPI(MANUAL_DBR, body, { handleFetchFunction: (data) => PostFinanceCalculatorData(data), navigate: navigate, run: true }));
        }
    };

    const calculateAge = (dob) => {
        // Calculate the difference in milliseconds between the current date and the provided date of birth
        var diff_ms = Date.now() - dob.getTime();
        // Create a new Date object representing the difference in milliseconds and store it in the variable age_dt (age Date object)
        var age_dt = new Date(diff_ms);
        // Calculate the absolute value of the difference in years between the age Date object and the year 1970 (UNIX epoch)
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    };

    const createWorkItemApi = () => {
        const hijriDob = moment(userDetails?.data?.HijriDOB, 'DD-MM-YYYY')?.format("YYYY-MM-DD");
        const hijriToGregorian = convertHijriToGregorian(hijriDob);
        const dob = userDetails?.data?.is_saudi ? moment(hijriToGregorian, 'DD-MM-YYYY') : moment(userDetails?.data?.DOB, 'DD-MM-YYYY');

        let body = {
            "SourceChannel": "Digital",
            "entityType": "Retail",
            "basicDetails": {
                "guestDetails": [
                    {
                        "applicantType": "Main Applicant",
                        "idType": userDetails?.data?.is_saudi ? "National ID" : "Iqama ID",
                        "idNumber": userDetails?.data?.nationalId,
                        "givenNameEg": userDetails?.data?.first_name,
                        "fatherNameEg": userDetails?.data?.second_name,
                        "grandfatherNameEg": userDetails?.data?.third_name,
                        "familyNameEg": userDetails?.data?.last_name,
                        "givenNameAr": "محمد",
                        "fatherNameAr": "عبدالكريم",
                        "grandfatherNameAr": "",
                        "familyNameAr": "صالح",
                        "dobGr": userDetails?.data?.is_saudi ? "" : moment(userDetails?.data?.DOB, 'DD-MM-YYYY')?.format('YYYY-MM-DD'),
                        "dobHj": userDetails?.data?.is_saudi ? moment(hijriToGregorian, 'DD-MM-YYYY')?.format("YYYY-MM-DD") : "",
                        "age": String(calculateAge(new Date(dob?.format("YYYY, MM, DD"))))
                    }
                ]
            },
            "product": {
                "productDetails": {
                    "productType": "AUTO FINANCE",
                    "productTypeCode": "AF",
                    "subProduct": "AUTO LEASE",
                    "subProductCode": "ATL",
                    "variant": "LEASE FINANCE FLEET",
                    "variantCode": "LF",
                    "useType": "Not-Applicable",
                    "useTypeCode": "NA",
                    "guarantorApplicable": false,
                    "insuranceApplicable": true,
                }
            },
            "quotationDetails": {
                "gracePeriod": false,
                "paymentFrequency": "",
                "payInRamadan": false,
                "noOfRamadan": 0,
                "vehicleDetails": [
                    {
                        "vehicleType": location?.state?.type,
                        "product": vehicleProductDetail?.data?.product,
                        "dealer": "1053-UIS Release Vehicle",
                        "dealerType": "21267 - UIS Al Ameer Soultan",
                        "brand": vehicleProductDetail?.data?.BrandDescription,
                        "model": vehicleProductDetail?.data?.ModelDescription,
                        "modelCode": vehicleProductDetail?.data?.ModelCode,
                        "suffix": vehicleProductDetail?.data?.suffix,
                        "vehicleExteriorColour": "",
                        "noOfVehicles": 1,
                        "vehicleWarranty": 0,
                        "modelYear": vehicleProductDetail?.data?.YearModel,
                        "retailPrice": vehicleProductDetail?.data?.RetailPrice,
                        "guestWantsToFinVat": selectedFinanceVAT === 1 ? "Yes" : "No"
                    }
                ]
            },
            "operation": "create"
        };
        dispatch(workItemCallAPI(WORK_ITEM_CREATE, body, { handleFetchFunction: (data) => fetchWorkItemApi(data), navigate: navigate, run: true }));
    };

    const fetchWorkItemApi = (data) => {
        let body = {
            appId: data?.data?.data?.appId
        };
        dispatch(WorkItemAppId(WORK_ITEM_APP_ID, data?.data?.data?.appId, { navigate: navigate }));
        dispatch(NetIncome(NET_INCOME, +monthlyIncome, { navigate: navigate }));
        dispatch(fetchWorkItemCallAPI(FETCH_WORK_ITEM, body, { redirect: true, redirectNavigate: navigate, navigate: navigate, path: getRevisedFinancingURL(locale) }));
    };

    const PostFinanceCalculatorData = (data) => {
        let maxMonthlyIncome;
        if (data?.data?.data?.['soap:Envelope']?.['soap:Body']?.['ProcessApplicationXMLResponse']?.['ProcessApplicationXMLResult']?.['Response']?.['Decision']) {
            maxMonthlyIncome = data?.data?.data?.['soap:Envelope']?.['soap:Body']?.['ProcessApplicationXMLResponse']?.['ProcessApplicationXMLResult']?.['Response']?.['Decision']?.MaxAllowedMonthlyInstallment;
        } else if (manualDBRData?.MaxAllowedMonthlyInstallment) {
            maxMonthlyIncome = manualDBRData?.MaxAllowedMonthlyInstallment;
        }

        let body = {
            "PromoCode": "Abc",
            "basePremiumInsuranceAmount": 0,
            "brandID": getVehicleListDetails?.data?.tBrandID,
            'autoProductId': getVehicleListDetails?.data?.tPortfolioID,
            "downPaymentAmt": selectedPaymentType === 2 ? +downPayAmount : 0,
            "emiAmount": selectedPaymentType === 1 ? +monthlyAmount : 0,
            "featurePremiumInsuranceAmount": 0,
            "financeVATFlag": selectedFinanceVAT === 1 ? "N" : "Y",
            "guestType": "Retail",
            "maxMonthlyAmount": maxMonthlyIncome,
            "modelId": getVehicleListDetails?.data?.tModelID,
            "modelYear": getVehicleListDetails?.data?.YearModel,
            "netIncome": +monthlyIncome,
            "paymentType": selectedPaymentType === 1 ? "MonthlyPayment" : "Downpayment",
            "product": "ATL",
            "productCategory": "AF",
            "residualAmount": +residualValue,
            "scheme": "LI",
            "tenureInMonths": period,
            "totalLimitOfAllCC": creditCardLimit,
            "vehiclePrice": getVehicleListDetails?.data?.RetailPrice,
            "vehicleType": "New"
        };
        dispatch(financeCalculatorCallAPI(ONLINE_FINANCE_CALCULATOR, body, { navigate: navigate }));
        LocalStorageManager.setItem("finance_Calculator_payload", JSON.stringify(body));
    };

    const calculateValidation = () => {
        let validate = false
        // period!==0
        if ((residualValue !== "" && residualValue >= 1) && (monthlyIncome !== "" && monthlyIncome >= 1)) {
            validate = true
        }
        return validate
    };

    const handleClick = () => {
        setAutoRotateDisabled(false);
        setRotateView(!rotateView);
    };

    const basePath = vehicleDetails?.payload?.data?.data?.["360_images"]?.length > 0 && vehicleDetails?.payload?.data?.data?.["360_images"].map((k) => k?.image);
    const [autoRotateDisabled, setAutoRotateDisabled] = useState(false);

    useEffect(() => {
        if (rotateView) {
            const timer = setTimeout(() => {
                setAutoRotateDisabled(true);
            }, 6500);
            return () => clearTimeout(timer);
        }
    }, [rotateView]);

    return (
        <ContainerStyle locale={locale}>
            <Box paddingTop='32px'>
                <MuiBreadcrumbs
                    list={breadcrumbsList}
                />
            </Box>
            <div className='BackHeading'>
                {/* getFindVehicleURL(locale) */}
                <img src={backArrow} onClick={() => navigate(getFindVehicleURL(locale))} alt="" style={{ cursor: "pointer", transform: locale === 'ar' ? 'scaleX(-1)' : '', margin: locale === 'en' ? '7px 24px 0 0' : '7px 0 0 24px' }} />
                <H5Text size="32" marginTop={'14'} color={'purple'}><FormattedMessage id="findVehicle" /></H5Text>
            </div>
            <Row style={{ marginTop: '24px' }}>
                <Col sm={12} md={12} lg={12} xl={6} xxl={4}>
                    <ProductDetail>
                        <UpperHead>
                            <H5Text color="purple" size="32"><FormattedMessage id="productDetails" /></H5Text>
                            <IconGroup>
                                {/* <IconStyle background={'#FEF7FF'} marginLeft={locale === 'ar' && '24px'} >
                                    <img src={require('assets/images/icon/Union.png')} alt="" />
                                </IconStyle> */}
                                <IconStyle background={'var(--primary)'} marginLeft={locale === 'en' && '24px'} >
                                    <img src={require('assets/images/icon/percent.png')} alt="" />
                                </IconStyle>
                            </IconGroup>
                        </UpperHead>
                        {!loader ? (
                            <>
                                {rotateView ?
                                    <ReactImageTurntable images={basePath} autoRotate={{ disabled: autoRotateDisabled }} style={{ height: "272px" }} />
                                    :
                                    <SlickCarousel items={vehicleProductImage} />
                                }
                                {vehicleDetails?.payload?.data?.data?.["360_images"].length &&
                                    <img className='iconImg' src={rotateView ? require('assets/icons/360DegreeColor.png') : require('assets/icons/360DegreeWithoutColor.png')} alt="" onClick={() => handleClick()} style={{ cursor: "pointer", position: "relative", zIndex: "99", height: '50px', width: '50px' }} />
                                }
                            </>
                        ) : (
                            <>
                                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={242} style={{ borderRadius: "10px", marginTop: "10px" }} />
                            </>
                        )}
                        <div className='IconWithText'>
                            <IconWithText
                                boxShadow={false}
                                textLineHeight={"38.4"}
                                BrandIcon={vehicleProductDetail?.data?.BrandIcon}
                                variant={'rounded'}
                                BrandSize={48}
                                text={!loader ? vehicleProductDetail?.data?.ModelDescription : <Skeleton animation="wave" variant="rectangular" width={'200px'} height={20} style={{ borderRadius: "10px" }} />}
                                textSize="32"
                                secondText={!loader ? `SAR ${vehicleProductDetail?.data?.RetailPrice}` : <Skeleton animation="wave" variant="rectangular" width={'80%'} height={20} style={{ borderRadius: "10px" }} />}
                                secondTextSize="28"
                                secondLineHeight="33.6"
                                secondFontWeight={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}
                                thirdText={vehicleProductDetail?.data?.YearModel + ' ' + vehicleProductDetail?.data?.ModelGrade}
                                subText={false}
                                thirdTextSize="20"
                                thirdLineHeight="24"
                                thirdTextColor="gray"
                                {...props}
                                iconAlignment="top"
                            />
                            <Grid container>
                                <Grid item xs={12} md={7}>
                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                        <Text marginTop="0" color="darkSecondary" fontWeight="600" fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"} size="16">
                                            <FormattedMessage id="color" />
                                        </Text>
                                        {!loader ?
                                            <Text marginTop="0" lineHeight="27" color="darkPrimary" fontFamily='KarbonMedium' fontWeight="700" size="18">
                                                &nbsp;{selectedCarData?.BodyColour}
                                            </Text> :
                                            <>
                                                &nbsp;<Skeleton animation="wave" variant="rectangular" width={'50%'} height={10} style={{ borderRadius: "10px" }} />
                                            </>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <div style={{ display: "flex", alignItems: 'center', width: '250px' }}>
                                        <Text marginTop="0" color="darkSecondary" fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"} fontWeight="600" size="16">
                                            <FormattedMessage id="internal" />
                                        </Text>
                                        {!loader ?
                                            <Text marginTop="0" lineHeight="27" color="darkPrimary" fontFamily='KarbonMedium' fontWeight="700" size="18">
                                                &nbsp;{selectedCarData?.InternalColour}
                                            </Text> :
                                            <>
                                                &nbsp;<Skeleton animation="wave" variant="rectangular" width={'50%'} height={10} style={{ borderRadius: "10px" }} />
                                            </>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                        <Text marginTop="0" color="darkSecondary" fontWeight="600" size="16" fontFamily={locale == "en" ? "KarbonLight" : "LumaLight"}>
                                            <FormattedMessage id="material" />
                                        </Text>
                                        {!loader ?
                                            <Text marginTop="0" lineHeight="27" color="darkPrimary" fontFamily='KarbonMedium' fontWeight="700" size="18">
                                                &nbsp;{selectedCarData?.Material}
                                            </Text> :
                                            <>
                                                &nbsp;<Skeleton animation="wave" variant="rectangular" width={'50%'} height={10} style={{ borderRadius: "10px" }} />
                                            </>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            <StartWithIcon color={'var(--primary)'} text={<FormattedMessage id="availableFor" />} icon={IconImage} {...props} />
                        </div>
                        <GirdContainer locale={locale}>
                            {!loader ?
                                <Grid container spacing={2}>
                                    {vehicleProductDetail?.data?.ColorsDetails?.map((data, index) => (
                                        <Grid item xs={6} md={4} key={index}>
                                            <div
                                                className={`GirdItem ${selectedCar === index ? 'selected' : ''}`}
                                                onClick={() => handleCarSelect(data, index)}
                                            >
                                                <img src={require('assets/images/carTransprent.png')} alt="" width={'100%'} style={{ transform: locale === 'ar' ? 'scaleX(-1)' : '' }} />
                                                <img
                                                    className='unionimg'
                                                    src={require('assets/images/icon/UnionExtraSmall.png')}
                                                    alt=""
                                                />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid> :
                                <>
                                    <Grid container spacing={2} >
                                        {Array.from({ length: 5 }).map(() => (
                                            <Grid item xs={6} md={4}  >
                                                <Skeleton variant="rectangular" animation="wave" width={"100%"} height={72} style={{ borderRadius: "16px" }} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            }
                        </GirdContainer>
                        {/* <div style={{ marginTop: '24px' }}>
                            <SelectInput label={<FormattedMessage id="vehicleClass" />} placeholder={"Vehicle Class"} />
                        </div> */}
                    </ProductDetail>
                </Col>

                <Col sm={12} md={12} lg={6} xl={6} xxl={4} >
                    <FinanceCal>
                        <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={0}><FormattedMessage id="financeCalculator" /></H5Text>
                        <div className='innerFinance'>
                            <LabelWithInput
                                label={
                                    <span className='labelDesign'>
                                        <span><FormattedMessage id="monthlyNetIncome" /> <span className='star'>*</span></span>
                                        <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                    </span>
                                }
                                placeholder="0000"
                                InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                        <InputAdornment position="end">SAR</InputAdornment>
                                    )
                                }}
                                errorText={isMonthlyIncomeError && <FormattedMessage id="fillMonthlyNetIncomeAfterDeductions" />}
                                type="number"
                                name="monthlyIncome"
                                value={monthlyIncome}
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleBlur(e)}
                            />
                            <SelectAmong>
                                <span className='labelDesign'>
                                    <span><FormattedMessage id="paymentType" /></span>
                                    <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                </span>
                                <Grid className='itemContainer' container  >
                                    {paymentType.map((data) => (
                                        <Grid
                                            className={`item ${selectedPaymentType === data.id ? 'selected' : ''}`}
                                            item
                                            key={data.id}
                                            onClick={() => setSelectedPaymentType(data.id)}
                                        >
                                            <span>{data.type}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </SelectAmong>
                            {selectedPaymentType === 1 &&
                                <div className='Slider'>
                                    <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={(monthlyIncome * 45) / 100} label={<FormattedMessage id="monthlyPaymentAmount" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
                                </div>
                            }
                            {selectedPaymentType === 2 &&
                                <div className='Slider'>
                                    <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={getVehicleListDetails?.data?.RetailPrice && Math.trunc(getVehicleListDetails?.data?.RetailPrice)} label={<FormattedMessage id="downPaymentAmount" />} defaultValue={downPayAmount} value={downPayAmount} setValue={(data) => setDownPayAmount(data)} />
                                </div>
                            }
                            <div className='Slider'>
                                <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={monthlyIncome} label={<FormattedMessage id="obligations" />} defaultValue={obligation} value={obligation} setValue={(data) => setObligation(data)} />
                            </div>
                            <div className='Slider'>
                                <OneWaySlider measure={"Months"} sliderLabel={true} startLimit={6} step={6} endLimit={60} label={<FormattedMessage id="tenure" />} defaultValue={period} value={period} setValue={(data) => handleTenureChange(data)} />
                            </div>
                            <div className='Slider'>
                                <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={200000} label={<FormattedMessage id="totalLimitofallCreditCards" />} defaultValue={creditCardLimit} value={creditCardLimit} setValue={(data) => setcreditCardLimit(data)} />
                            </div>

                            <LabelWithInput
                                label={
                                    <span className='labelDesign'>
                                        <span><FormattedMessage id="residualAmount" /></span>
                                        <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                    </span>
                                }
                                placeholder="0000"
                                InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                        <InputAdornment position="end">SAR</InputAdornment>
                                    )
                                }}
                                errorText={isResidualValueError && <FormattedMessage id="fillResidualAmount" />}
                                type="number"
                                name="residualValue"
                                value={residualValue}
                                onChange={(e) => handleChange(e)}
                            />

                            {/* <SelectAmong >
                                <span className='labelDesign'>
                                    <span><FormattedMessage id="VATPaymentType" /></span>
                                    <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                </span>
                                <Grid className='itemContainer' container  >
                                    {VATpaymentType.map((data) => (
                                        <Grid
                                            className={`item ${selectedVATPaymentType === data.id ? 'selected' : ''}`}
                                            item
                                            key={data.id}
                                            onClick={() => setSelectedVATPaymentType(data.id)}
                                        >
                                            <span>{data.type}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </SelectAmong> */}
                            <SelectAmong >
                                <span className='labelDesign'>
                                    <span><FormattedMessage id="financeVAT" /></span>
                                    <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                </span>
                                <Grid className='itemContainer' container  >
                                    {financeVAT.map((data) => (
                                        <Grid
                                            className={`item ${selectedFinanceVAT === data.id ? 'selected' : ''}`}
                                            item
                                            key={data.id}
                                            onClick={() => setSelectedFinanceVAT(data.id)}
                                        >
                                            <span>{data.type}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </SelectAmong>
                            <div className='labelInput'>
                                <LabelWithInput
                                    label={
                                        <span className='labelDesign'>
                                            <span><FormattedMessage id="promoCode" /></span>
                                        </span>
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <span className='ApplyButton'><FormattedMessage id="apply" /></span>
                                        )
                                    }}
                                    name="promoCode"
                                    value={promoCode}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className='CalBtn'>
                                <ButtonInput
                                    title={<FormattedMessage id="calculate" />}
                                    variant="text"
                                    bgColor={'purple'}
                                    textColor="white"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    size={"24"}
                                    disabled={financingCalculatorLoader || manualDBRLoader}
                                    endIcon={(financingCalculatorLoader || manualDBRLoader) && <CircularProgress style={{ 'color': 'white', 'width': '25px', 'height': '25px' }} />}
                                    padding={"10px 16px"}
                                    onClick={() => ManualDbrVerify()}
                                />
                            </div>
                        </div>
                    </FinanceCal>
                </Col>

                {financingCalculatorData &&
                    <Col sm={12} md={12} lg={6} xl={6} xxl={4}>
                        <FinanceResult>
                            <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={0}><FormattedMessage id="financingResults" /></H5Text>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="monthlyPayment" />} <span className='star'>*</span></span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.emiAmt}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="profitRate" />} <span className='star'>*</span></span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.profitRate}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"%"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="sellingPrice" />}</span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.vehiclePrice}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="requiredPayment" />}</span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.requiredPayment}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="downPayment" />} <span className='star'>*</span></span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.downPayment}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="issueExpenses" />}</span>
                                                <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.issueExpense}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="advancePayment" />}</span>
                                                <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.advancePayment}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="period" />}</span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.financePeriod}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"Months"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="residualAmount" />} <span className='star'>*</span></span>
                                                <div>
                                                    <span className='percent' >{financingCalculatorData?.residualPerCent}{"%"}</span>
                                                </div>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.residualValue}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <FinanceData locale={locale}>
                                <div className="containerData">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={8}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{<FormattedMessage id="totalVAT" />}</span>
                                                <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className='Price'>{financingCalculatorData?.totalVat}<span style={{ marginRight: locale === 'ar' && '10px' }}>{"SAR"}</span></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </FinanceData>

                            <Grid container spacing={4} style={{ padding: '64px 28px 20px' }}>
                                <Grid item xs={12} md={6}>
                                    <ButtonInput
                                        title={<FormattedMessage id="offlineFinancing" />}
                                        variant="text"
                                        bgColor="transparent"
                                        borderColor="purple"
                                        textColor="purple"
                                        borderRadius="100"
                                        TextTransform="capitalize"
                                        size={"24"}
                                        padding={"10px 16px"}
                                        width={'100%'}
                                        minWidth={'0'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ButtonInput
                                        title={<FormattedMessage id="fastFinancing" />}
                                        variant="text"
                                        bgColor="yellow"
                                        textColor="purple"
                                        borderRadius="100"
                                        TextTransform="capitalize"
                                        size={"24"}
                                        padding={"10px 16px"}
                                        width={'100%'}
                                        minWidth={'0'}
                                        disabled={workItemLoader}
                                        endIcon={workItemLoader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
                                        onClick={() => createWorkItemApi()}
                                    //onClick={() => navigate(getRevisedFinancingURL(locale))}
                                    />
                                </Grid>
                            </Grid>
                        </FinanceResult>
                    </Col>
                }
            </Row>
        </ContainerStyle>
    )
}

export default FinanceCalculator
