import {
    LEAD_MANAGMENT,
    LEAD_MANAGMENT_ERROR,
    LEAD_MANAGMENT_SUCCESS
} from "./constant";


const leadManagmentReducer = (state = {}, action) => {
    switch (action.type) {
        case LEAD_MANAGMENT:
            return { ...state, loading: true, error: '' };
        case LEAD_MANAGMENT_ERROR:
            return { ...state, type: LEAD_MANAGMENT_ERROR, loading: false, error: action.payload };
        case LEAD_MANAGMENT_SUCCESS:
            return { ...state, type: LEAD_MANAGMENT_ERROR, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default leadManagmentReducer;