import {
   UPDATE_WORK_ITEM,
   UPDATE_WORK_ITEM_ERROR,
   UPDATE_WORK_ITEM_SUCCESS
} from "./constant";

const updateWorkItemReducer = (state = {}, action) => {
   switch (action.type) {
      case UPDATE_WORK_ITEM:
         return { ...state, loading: true, error: '' };
      case UPDATE_WORK_ITEM_ERROR:
         return { ...state, type: UPDATE_WORK_ITEM_ERROR, loading: false, error: action.payload };
      case UPDATE_WORK_ITEM_SUCCESS:
         return { ...state, type: UPDATE_WORK_ITEM_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default updateWorkItemReducer;