import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import H5Text from "components/Text/H5Text";
import IconWithText from "components/IconWithText";
import SelectInput from "components/Input/SelectInput";
import CheckboxInputDesignable from "components/Input/CheckboxInputDesignable";
import DiscountBadge from "components/DiscountBadge";
import ButtonInput from "components/Input/ButtonInput";
import H1Text from 'components/Text/H1Text';
import AccordianImage from "assets/images/financing/alrajhi-takaful.png";
import discountBadge from "assets/images/financing/discountBadge.png";
import discountIcon from "assets/images/financing/discountIcon.png";
import { LocaleContext } from 'routes/Router';
import { financingGetQuoteCallAPI } from "store/financing/financeGetQuote/action";
import { FINANCE_GET_QUOTE } from "store/financing/financeGetQuote/constant";
import { financingGetQuoteServiceCallAPI } from "store/financing/financeGetQuoteService/action";
import { FINANCE_GET_QUOTE_SERVICE } from "store/financing/financeGetQuoteService/constant";
import { ResumeOnlineFormCall } from "store/resumeOnlineForm/actions";
import { RESUME_ONLINE_FORM } from "store/resumeOnlineForm/constant";
import { Skeleton } from "@mui/material";
import MuiCards from "components/MuiCards/";
import { fetchWorkItemCallAPI } from "store/workItem/fetchWorkItem/action";
import { FETCH_WORK_ITEM } from "store/workItem/fetchWorkItem/constant";
import { updateWorkItemCallAPI } from "store/workItem/updateWorkItem/action";
import { UPDATE_WORK_ITEM } from "store/workItem/updateWorkItem/constant";
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { getContractDetailsURL } from "routes/AppUrls";

const WrapperDiv = styled.div`
	border-radius:8px;
	background-color:white;
`;

const WrapperAccordion = styled(Accordion)`
	border-radius: 8px !important;
	border: 1px solid ${(props) => props.theme.gray} !important;
	box-shadow: none !important;
	padding: 0px 15px !important;
	.MuiAccordionSummary-content{
		padding:0;
	}
`;

const WrapperAccordionSummary = styled(AccordionSummary)`
	margin: 0 0 !important;
	padding-${(props) => props.locale === 'en' ? 'right' : 'left'}: 5.4% !important;
`;

function RevisedFinancingForm({ handleCurStep }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [expanded, setExpanded] = React.useState('');

	const [companyCode, setCompanyCode] = useState('40');
	const [basePremiumValue, setBasePremiumValue] = useState("");
	const [discountValue, setDiscountValue] = useState([]);
	const [deductibleName, setDeductibleName] = useState("");
	const [deductibleAmount, setDeductibleAmount] = useState("");
	const [basePremiumWithVAT, setBasePremiumWithVAT] = useState('');

	const workItemData = useSelector((state) => state.workItemReducer && state.workItemReducer?.payload?.data?.data);
	const getVehicleListDetails = useSelector((state) => state.vehicleDetailsReducer && state.vehicleDetailsReducer?.payload?.data?.data);
	const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data?.data);
	const yakeenUserInfoData = useSelector((state) => state.financingYakeenUserInfoReducer && state.financingYakeenUserInfoReducer?.payload?.data?.data);
	const yakeenUserAddressData = useSelector((state) => state.financingYakeenUserAddressReducer && state.financingYakeenUserAddressReducer?.payload?.data);
	const getQuoteServiceListData = useSelector((state) => state.financingGetQuoteServiceReducer && state.financingGetQuoteServiceReducer?.payload?.data?.data);
	const getQuoteDatas = useSelector((state) => state.financingGetQuoteReducer && state.financingGetQuoteReducer?.payload?.data?.data?.['body']);
	const financeGetQuoteLoader = useSelector((state) => state?.financingGetQuoteReducer?.loading);

	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);
	const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state.fetchWorkItemReducer?.payload?.data?.data);
	const updateWorkItemLoader = useSelector((state) => state?.updateWorkItemReducer?.loading);

	const params = useParams();
	/////////////////////checkApplicationListJSON////////////////////
	function isJSONString(str) {
		const jsonPattern = /^\s*(\{.*\}|\[.*\])\s*$/;
		return jsonPattern.test(str);
	};
	/////////////////////checkApplicationListJSON////////////////////

	useEffect(() => {
		generateRandomNumber();
		getCarInsuranceQuote();
		fetchWorkItemApi();
	}, []);

	const fetchWorkItemApi = () => {
		let body = {
			appId: workItemAppId
		};
		dispatch(fetchWorkItemCallAPI(FETCH_WORK_ITEM, body, { navigate: navigate, run: true }));
	};

	const generateRandomNumber = () => {
		const newRandomNumber = Math.floor(10000000 + Math.random() * 90000000);
		return newRandomNumber;
	};

	///////////////////////HIJRI DATE TO GREGORAIN DATE//////////////////////////
	const momenHijri = require('moment-hijri');
	const convertHijriToGregorian = (hijriDate) => {
		// Parse the Hijri date using moment-hijri
		const parsedHijriDate = momenHijri(hijriDate, 'iYYYY/iMM/iDD');
		// Convert the parsed Hijri date to a Gregorian date
		const gregorianDate = parsedHijriDate.toDate();
		return gregorianDate;
	};
	////////////////////////////////////////////////////

	const getCarInsuranceQuote = () => {
		const hijriDob = moment(userMeData?.HijriDOB, 'DD-MM-YYYY')?.format("YYYY-MM-DD");
		const hijriToGregorian = convertHijriToGregorian(hijriDob);

		let payloadInsurance = {
			RequestReferenceNo: generateRandomNumber()?.toString() ?? '',
			InsuranceCompanyCode: 40,
			Details: {
				LessorCR: 0,
				LessorNameEN: 'Abdul Latif Jameel United Finance Co.',
				LessorID: 7001715155,
				LessorBranch: 838,
				LessorNationalAddress: [
					{
						BuildingNumber: 7327,
						Street: 'شارع الأمير ماجد بن عبد العزيز ',
						District: 'حي الصفا',
						City: 'جدة',
						ZipCode: 23451,
						AdditionalNumber: 2254,
					},
				],
				IsRenewal: false,
				PolicyNumber: null,
				PurposeofVehicleUseID: 1,
				Cylinders: null,
				VehicleMileage: 0,
				VehicleExpectedMileageYear: null,
				VehicleEngineSizeCC: null,
				VehicleTransmission: 1,
				VehicleNightParking: 0,
				VehicleCapacity: 5,
				VehicleBodyCode: 5,
				VehicleMakeCodeNIC: 11,
				VehicleMakeTextNIC: 'تويوتا',
				VehicleMakeCodeIHC: 11,
				VehicleModelCodeNIC: 5,
				VehicleModelTextNIC: 'كامري',
				VehicleModelCodeIHC: 275,
				ManufactureYear: 2024,
				VehicleColorCode: 19,
				VehicleModifications: '',
				VehicleSumInsured: 99820,
				DepreciationratePercentage: 18.0,
				RepairMethod: 1,
				LesseeID: 2389371523,
				FullName: 'موجتبا علي',
				ArabicFirstName: 'موجتبا',
				ArabicMiddleName: 'علي',
				ArabicLastName: '',
				EnglishFirstName: 'MUJTABA',
				EnglishMiddleName: 'ALI',
				EnglishLastName: '',
				LesseeNationalityID: 321,
				VehicleUsagePercentage: 100,
				LesseeOccupation: 'امير',
				LesseeEducation: 'Unknown/مجهول',
				LesseeChildrenBelow16: 0,
				LesseeWorkCompanyName: 'شركة ماني للتشغيل والصيانة',
				LesseeWorkCityID: 1,
				CountriesValidDrivingLicense: null,
				LesseeNoOfClaims: 0,
				LesseeTrafficViolationsCode: '5',
				LesseeHealthConditionsCode: '1',
				LesseeDateOfBirthG: '31-01-1987',
				LesseeDateOfBirthH: '',
				LesseeGender: 1,
				LesseeMaritalStatus: 1,
				LesseeLicenseType: 1,
				LesseeLicenseOwnYears: 1,
				LesseeNCDCode: '2',
				LesseeNCDReference: 'NCD15061756985',
				LesseeNoOfAccidents: 0,
				NajmCaseDetails: null,
				LesseeNationalAddress: [
					{
						BuildingNumber: 7170,
						Street: 'Abu Muslim Almuradaa',
						District: 'As Safa Dist',
						City: 'جدة',
						ZipCode: 23453,
						AdditionalNumber: 3891,
					},
				],
				DriverDetails: null,
				CustomizedParameter: null,
			},
		}

		// const payloadInsurance = {
		// 	RequestReferenceNo: String(generateRandomNumber()) ?? '',
		// 	InsuranceCompanyCode: 40,
		// 	Details: {
		// 		LessorCR: 0,
		// 		LessorNameEN: 'Abdul Latif Jameel United Finance Co.',
		// 		LessorID: 7001715155,
		// 		LessorBranch: 838,
		// 		LessorNationalAddress: [{
		// 			BuildingNumber: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6 === null ? ''
		// 					: yakeenUserAddressData?.AddressList6[0]?.buildingNumberField.toString() ?? ''
		// 				: yakeenUserAddressData?.AddressList7 === null ? ''
		// 					: yakeenUserAddressData?.AddressList7[0]?.buildingNumberField.toString() ?? '',
		// 			Street: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6 === null ? ''
		// 					: yakeenUserAddressData?.AddressList6[0]?.streetNameField.toString() ?? ''
		// 				: yakeenUserAddressData?.AddressList7 === null ? ''
		// 					: yakeenUserAddressData?.AddressList7[0]?.streetNameField.toString() ?? '',
		// 			District: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6 === null ? ''
		// 					: yakeenUserAddressData?.AddressList6[0]?.districtField.toString() ?? ''
		// 				: yakeenUserAddressData?.AddressList7 === null ? ''
		// 					: yakeenUserAddressData?.AddressList7[0]?.districtField.toString() ?? '',
		// 			City: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6 === null ? ''
		// 					: yakeenUserAddressData?.AddressList6[0]?.cityField.toString() ?? ''
		// 				: yakeenUserAddressData?.AddressList7 === null ? ''
		// 					: yakeenUserAddressData?.AddressList7[0]?.cityField.toString() ?? '',
		// 			ZipCode: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6 === null ? ''
		// 					: yakeenUserAddressData?.AddressList6[0]?.postCodeField.toString() ?? ''
		// 				: yakeenUserAddressData?.AddressList7 === null ? ''
		// 					: yakeenUserAddressData?.AddressList7[0]?.postCodeField.toString() ?? '',
		// 			AdditionalNumber: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6 === null ? ''
		// 					: yakeenUserAddressData?.AddressList6[0]?.additionalNumberField.toString() ?? ''
		// 				: yakeenUserAddressData?.AddressList7 === null ? ''
		// 					: yakeenUserAddressData?.AddressList7[0]?.additionalNumberField.toString() ?? '',
		// 		}],
		// 		LesseeNoOfAccidents: 0,
		// 		LesseeNCDReference: '',
		// 		LesseeNCDCode: '',
		// 		VehicleModelCodeIHC: 275,
		// 		VehicleMakeCodeIHC: 11,
		// 		PurposeofVehicleUseID: 1,
		// 		IsRenewal: false,
		// 		PolicyNumber: null,
		// 		Cylinders: getVehicleListDetails?.Cylinders === 'NULL' ? 0 : getVehicleListDetails?.Cylinders,
		// 		VehicleMileage: 0,
		// 		VehicleExpectedMileageYear: null,
		// 		VehicleEngineSizeCC: getVehicleListDetails?.VehicleEngineSizeCC === 'NULL' ? 0 : getVehicleListDetails?.VehicleEngineSizeCC === 0 ? null : getVehicleListDetails?.VehicleEngineSizeCC,
		// 		VehicleTransmission: getVehicleListDetails?.VehicleTransmission ?? '',
		// 		VehicleNightParking: 0,
		// 		VehicleCapacity: getVehicleListDetails?.VehicleCapacity ?? '',
		// 		VehicleBodyCode: getVehicleListDetails?.VehicleBodyCode ?? '',
		// 		VehicleMakeCodeNIC: 301,
		// 		VehicleMakeTextNIC: 'تويوتا ',
		// 		VehicleModelCodeNIC: 301,
		// 		VehicleModelTextNIC: 'كامري ',
		// 		ManufactureYear: getVehicleListDetails?.YearModel ?? '',
		// 		VehicleColorCode: 19,
		// 		VehicleModifications: '',
		// 		VehicleSumInsured: getVehicleListDetails?.VehicleSumInsured ?? '',
		// 		DepreciationratePercentage: getVehicleListDetails?.tBrandID === 1 ? 18.0 : 20.0,
		// 		RepairMethod: 2,
		// 		LesseeID: userMeData?.nationalId,
		// 		FullName: userMeData?.is_saudi
		// 			? (yakeenUserInfoData?.EnglishFirstName ?? '') + ' ' +
		// 			(yakeenUserInfoData?.EnglishSecondName ?? '') + ' ' +
		// 			(yakeenUserInfoData?.EnglishThirdName ?? '') + ' ' +
		// 			(yakeenUserInfoData?.EnglishLastName ?? '')
		// 			:
		// 			(yakeenUserAddressData?.EnglishFirstName ?? '') + ' ' +
		// 			(yakeenUserAddressData?.EnglishSecondName ?? '') + ' ' +
		// 			(yakeenUserAddressData?.EnglishThirdName ?? '') + ' ' +
		// 			(yakeenUserAddressData?.EnglishLastName ?? ''),
		// 		ArabicFirstName: userMeData?.is_saudi
		// 			? yakeenUserInfoData?.FirstName ?? ''
		// 			: yakeenUserAddressData?.FirstName ?? '',
		// 		ArabicMiddleName: userMeData?.is_saudi
		// 			? yakeenUserInfoData?.FatherName ?? ''
		// 			: yakeenUserAddressData?.SecondName ?? '',
		// 		ArabicLastName: userMeData?.is_saudi
		// 			? yakeenUserInfoData?.FamilyName ?? ''
		// 			: yakeenUserAddressData?.LastName ?? '',
		// 		EnglishFirstName: userMeData?.is_saudi
		// 			? yakeenUserInfoData?.EnglishFirstName ?? ''
		// 			: yakeenUserAddressData?.EnglishFirstName ?? '',
		// 		EnglishMiddleName: userMeData?.is_saudi
		// 			? yakeenUserInfoData?.EnglishSecondName ?? ''
		// 			: yakeenUserAddressData?.EnglishSecondName ?? '',
		// 		EnglishLastName: userMeData?.is_saudi
		// 			? yakeenUserInfoData?.EnglishLastName ?? ''
		// 			: yakeenUserAddressData?.EnglishLastName ?? '',
		// 		LesseeNationalityID: userMeData?.is_saudi
		// 			? 113
		// 			: yakeenUserAddressData?.NationalityCode ?? '',
		// 		VehicleUsagePercentage: 100,
		// 		LesseeOccupation: userMeData?.is_saudi ? '' : yakeenUserAddressData?.OccupationDesc ?? '',
		// 		LesseeEducation: 'Unknown/مجهول',
		// 		LesseeChildrenBelow16: 0,
		// 		LesseeWorkCompanyName: '',
		// 		LesseeWorkCityID: 1,
		// 		CountriesValidDrivingLicense: null,
		// 		LesseeNoOfClaims: 0,
		// 		LesseeTrafficViolationsCode: '5',
		// 		LesseeHealthConditionsCode: '1',
		// 		LesseeDateOfBirthG: userMeData?.is_saudi ? "" : moment(userMeData?.DOB, 'DD-MM-YYYY')?.format('YYYY-MM-DD'),
		// 		LesseeDateOfBirthH: userMeData?.is_saudi ? moment(hijriToGregorian, 'DD-MM-YYYY')?.format("YYYY-MM-DD") : "",
		// 		LesseeGender: yakeenUserInfoData?.Gender ?? '',
		// 		LesseeMaritalStatus: 0,
		// 		LesseeLicenseType: null,
		// 		LesseeLicenseOwnYears: null,
		// 		NajmCaseDetails: null,
		// 		LesseeNationalAddress: [{
		// 			BuildingNumber: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6?.[0]?.buildingNumberField ?? ''
		// 				: yakeenUserAddressData?.AddressList7?.[0]?.buildingNumberField ?? '',
		// 			Street: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6?.[0]?.streetNameField ?? ''
		// 				: yakeenUserAddressData?.AddressList7?.[0]?.streetNameField ?? '',
		// 			District: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6?.[0]?.districtField ?? ''
		// 				: yakeenUserAddressData?.AddressList7?.[0]?.districtField ?? '',
		// 			City: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6?.[0]?.cityField ?? ''
		// 				: yakeenUserAddressData?.AddressList7?.[0]?.cityField ?? '',
		// 			ZipCode: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6?.[0]?.postCodeField ?? ''
		// 				: yakeenUserAddressData?.AddressList7?.[0]?.postCodeField ?? '',
		// 			AdditionalNumber: userMeData?.is_saudi
		// 				? yakeenUserAddressData?.AddressList6?.[0]?.additionalNumberField ?? ''
		// 				: yakeenUserAddressData?.AddressList7?.[0]?.additionalNumberField ?? '',
		// 		}],
		// 		DriverDetails: null,
		// 		CustomizedParameter: null,
		// 	},
		// };
		dispatch(financingGetQuoteCallAPI(FINANCE_GET_QUOTE, payloadInsurance, { handleFunction: (val) => getServiceList(val), navigate: navigate, run: true }));
	};

	const getServiceList = (val) => {
		const ServiceListResponse = [];
		val?.data?.data?.['body']?.length > 0 && val?.data?.data?.['body']?.map((k, i) => (
			isJSONString(k?.[Object.keys(k)]?.['body']) && JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.PolicyPremiumFeatures.length > 0 &&
			JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.PolicyPremiumFeatures.map((j, l) => {
				ServiceListResponse.push(j?.FeatureID);
			})
		))
		let body = {
			"featureId_list": ServiceListResponse.length > 0 ? ServiceListResponse : ""
		};
		if (ServiceListResponse.length > 0) {
			dispatch(financingGetQuoteServiceCallAPI(FINANCE_GET_QUOTE_SERVICE, body, { navigate: navigate }));
		}

	};

	const UpdateWorkItem = () => {
		if (FetchWorkItemData?.quotationDetails && (FetchWorkItemData.quotationDetails["insuranceDetails"] === undefined)) {
			let insuranceDetails = [{
				companyCode: companyCode,
				insuranceCompany: deductibleName?.toString(),
				basePremium: basePremiumValue,
				basePremiumWithVAT: 3269.45,
				quoteReferenceNumber: basePremiumWithVAT,
				totalVatOnFeatures: 69.0,
				totalFeatureAmtWithoutVAT: 460.0,
				totalFeatureAmtWithVAT: 529.0,
				totalAmountWithoutVAT: 3303.0,
				najmID: null,
				issuedPolicyNo: null,
				deductibleAmount: deductibleAmount,
			}];
			FetchWorkItemData?.quotationDetails && (FetchWorkItemData.quotationDetails['insuranceDetails'] = insuranceDetails);
		} else {
			FetchWorkItemData?.quotationDetails?.insuranceDetails?.[0] && (FetchWorkItemData.quotationDetails.insuranceDetails[0]['companyCode'] = companyCode);
			FetchWorkItemData?.quotationDetails?.insuranceDetails?.[0] && (FetchWorkItemData.quotationDetails.insuranceDetails[0]['insuranceCompany'] = deductibleName?.toString());
			FetchWorkItemData?.quotationDetails?.insuranceDetails?.[0] && (FetchWorkItemData.quotationDetails.insuranceDetails[0]['basePremium'] = basePremiumValue);
			FetchWorkItemData?.quotationDetails?.insuranceDetails?.[0] && (FetchWorkItemData.quotationDetails.insuranceDetails[0]['basePremiumWithVAT'] = basePremiumWithVAT);
			FetchWorkItemData?.quotationDetails?.insuranceDetails?.[0] && (FetchWorkItemData.quotationDetails.insuranceDetails[0]['deductibleAmount'] = deductibleAmount);
		}
		FetchWorkItemData && (FetchWorkItemData['operation'] = "save");
		dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleSubmit, navigate: navigate, run: true }));
	};

	const handleSubmit = () => {
		handleCurStep(4);
		let body = {
			resumeForm: true,
			step: 4
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
	};

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
		setDeductibleName("");
	};

	const handleDeductibleChange = (event, data) => {
		const { name, value } = event.target;
		setDeductibleName(name);
		setDeductibleAmount(value);
		getBasePremiumValue(name, value, data);
	};

	const getBasePremiumValue = (name, value, item) => {
		name?.toString() === (Object.keys(item))?.toString() && isJSONString(item?.[Object.keys(item)]?.['body']) &&
			JSON.parse(item?.[Object.keys(item)]?.['body'])?.CompQuotes?.[0]?.Deductibles.map((k) => {
				if (k?.DeductibleAmount === value) {
					setBasePremiumValue(k?.BasePremium);
					setDiscountValue(k?.Discounts);
				}
			})
	};

	let freeCount = 1;
	const checkfreeService = () => {
		const val = freeCount === 1;
		freeCount++;
		return val;
	};

	let paidCount = 1;
	const checkPaidService = () => {
		const val = paidCount === 1;
		paidCount++;
		return val;
	};

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'0px'}>
						<FormattedMessage id="insuranceDetails" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box sx={{ marginTop: "30px", padding:"0px 30px" }}>
						<Grid container>
							{!financeGetQuoteLoader && getQuoteDatas !== 'Malformed JSON request' ?
								getQuoteDatas?.length > 0 && getQuoteDatas.map((k, i) => (
									<Grid item xs={12} sx={{ marginTop: "30px" }}>
										<WrapperAccordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} >

											<WrapperAccordionSummary locale={locale}
												marginTop='0px'
												expandIcon={<Box display={'flex'} flexDirection='column' alignItems='center'><ExpandMoreIcon /></Box>}
												aria-controls="alrajhi-takaful"
												id="alrajhi-takaful"
											>
												<ButtonInput
													title={expanded === `panel${i}` && deductibleName?.toString() === (Object.keys(k)?.toString()) ? <FormattedMessage id='selected' /> : <FormattedMessage id='select' />}
													variant="contained"
													bgColor={expanded === `panel${i}` && deductibleName?.toString() === (Object.keys(k)?.toString()) ? "yellow" : "white"}
													textColor="purple"
													borderRadius="6"
													TextTransform="capitalize"
													borderColor={expanded === `panel${i}` && deductibleName?.toString() === (Object.keys(k)?.toString()) ? "yellow" : "purple"}
													width='100%'
													style={{ maxWidth: '100px', position: 'absolute', right: locale === 'en' ? '2%' : '', left: locale === 'ar' ? '2%' : '', marginTop: '14px' }}
													height='40'
													minWidth='100'
													fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"}
													padding='0'
													size='16'
													lineHeight='17.16'
												/>
												<Box>
													<Box display={'flex'} alignItems='center' marginBottom='10px'>
														<GppGoodOutlinedIcon style={{ color: 'green' }} />
														<Box maxWidth={{ xs: '135px', sm: '100%' }}>
															<H1Text size='24' lineHeight='36' color='green' fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} marginTop={'4'}>
																Sum Insured: {getVehicleListDetails?.VehicleSumInsured} SAR
															</H1Text>
														</Box>
													</Box>
													<IconWithText
														boxShadow={false}
														BrandIcon={AccordianImage}
														text={Object.keys(k)}
														textSize="18"
														secondText={`Insurance Price VAT ${deductibleName?.toString() === (Object.keys(k))?.toString() && basePremiumValue ? ((basePremiumValue * 15) / 100).toFixed(2) : 0} SAR`}
														secondTextSize="18"
														secondFontWeight={locale == "en" ? 'Karbon_light' : "Luma_light"}
														thirdText={deductibleName?.toString() === (Object.keys(k))?.toString() && basePremiumValue}
														thirdTextSize="24"
														thirdTextColor="lightDark"
														thirdSubText="SAR"
														variant={'rounded'}
														BrandSize={80}
														marginBottom="0"
													/>
												</Box>
											</WrapperAccordionSummary>

											<AccordionDetails>
												<Grid container spacing={2}>
													{isJSONString(k?.[Object.keys(k)]?.['body']) && JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.Deductibles &&
														<Grid item xs={12} md={6}>
															<SelectInput
																label={<FormattedMessage id="autoFinance.deductible" />}
																placeholder="Deductible"
																marginTop='0'
																name={Object.keys(k)}
																data={isJSONString(k?.[Object.keys(k)]?.['body']) && JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.Deductibles}
																onChange={(e) => handleDeductibleChange(e, k)}
																value={deductibleName?.toString() === (Object.keys(k))?.toString() ? deductibleAmount : ""}
															/>
														</Grid>
													}
													<Grid item xs={12} md={6}>
													</Grid>

													<Grid item xs={12}>
														{deductibleName?.toString() === (Object.keys(k))?.toString() && isJSONString(k?.[Object.keys(k)]?.['body']) && JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.PolicyPremiumFeatures.length > 0 &&
															JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.PolicyPremiumFeatures.map((j, l) => (
																getQuoteServiceListData?.policyFeatureList?.length > 0 && getQuoteServiceListData?.policyFeatureList.map((k, i) => {
																	if (+(k?.Code) === j?.FeatureID && j.FeatureTypeID === 2) {
																		return (
																			< CheckboxInputDesignable heading={checkfreeService() && <FormattedMessage id="autoFinance.freeServices" />} label={k?.Description} check={true} />
																		)
																	}
																})
															))}
													</Grid>

													<Grid item xs={12}>
														{deductibleName?.toString() === (Object.keys(k))?.toString() && isJSONString(k?.[Object.keys(k)]?.['body']) && JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.PolicyPremiumFeatures.length > 0 &&
															JSON.parse(k?.[Object.keys(k)]?.['body'])?.CompQuotes?.[0]?.PolicyPremiumFeatures.map((j, l) => (
																getQuoteServiceListData?.policyFeatureList?.length > 0 && getQuoteServiceListData?.policyFeatureList.map((k, i) => {
																	if (+(k?.Code) === j?.FeatureID && j.FeatureTypeID === 1) {
																		return (
																			< CheckboxInputDesignable heading={checkPaidService() && < FormattedMessage id="autoFinance.paidServices" />} label={k?.Description} amount={j?.FeatureAmount} check={false} />
																		)
																	}
																})
															))}
													</Grid>

													<Grid item xs={12}>
														{deductibleName?.toString() === (Object.keys(k))?.toString() && discountValue.length > 0 && discountValue.map((k, i) => {
															if (k?.DiscountAmount > 0) {
																return (
																	<DiscountBadge heading={<FormattedMessage id="autoFinance.discountsApplied" />} bgImage={discountBadge} icon={discountIcon} data={k} />
																)
															}
														})}
													</Grid>
												</Grid>
											</AccordionDetails>
										</WrapperAccordion>
									</Grid>
								))
								:
								Array.from({ length: 3 }).map(() => (
									<Grid item xs={12} sx={{ marginTop: "30px" }}>
										<WrapperAccordion>
											<Box marginTop={"20px"}>
												<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
													<Skeleton animation="wave" height={30} width="40%" />
													<Skeleton animation="wave" variant="rectangular" width={100} height={40} style={{ borderRadius: "6px" }} />
												</Box>

												<Box>
													<Box width="100%" display={'flex'} alignItems='center' marginBottom='10px'>
														<Skeleton animation="wave" variant="rectangular" width={80} height={80} style={{ borderRadius: "12px" }} />

														<Box width="100%" marginLeft={"20px"}>
															<Skeleton animation="wave" height={20} width="30%" />
															<Skeleton animation="wave" height={20} width="30%" />
															<Skeleton animation="wave" height={20} width="30%" />
														</Box>
													</Box>
												</Box>

											</Box>
										</WrapperAccordion>
									</Grid>
								))
							}
						</Grid>
					</Box>

				</Grid>
			</Grid>
			{params.postId == "Issue_Authorization_Letter" ?
				<Grid container style={{ marginTop: "30px" }}>
					<Grid item xs={12}>
						<div className="content" style={{ display: "flex", justifyContent: "end" }}>
							<Box component="form">
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="back" />}
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="uppercase"
										minWidth="100px"
										marginRight="30"
										padding="0px"
										onClick={() => navigate(-1)}
									/>
									<ButtonInput
										title={<FormattedMessage id="Save & Next" />}
										variant="contained"
										bgColor={"yellow"}
										opacity={deductibleName === '' ? 0.5 : 1}
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={updateWorkItemLoader || financeGetQuoteLoader || deductibleName === ''}
										endIcon={updateWorkItemLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
										onClick={() => navigate(getContractDetailsURL(locale) + '/' + params.postId + '/success')}
									/>
								</Stack>
							</Box>
						</div>
					</Grid>

				</Grid>
				:
				<Grid container style={{ marginTop: "30px" }}>
					<Grid item xs={12}>
						<div className="content" style={{ display: "flex", justifyContent: "end" }}>
							<Box component="form">
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="back" />}
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="uppercase"
										minWidth="100px"
										marginRight="30"
										padding="0px"
										onClick={() => handleCurStep(2)}
									/>
									<ButtonInput
										title={<FormattedMessage id="autoFinance.SaveProceedtoPersonalWork" />}
										variant="contained"
										bgColor={"yellow"}
										opacity={deductibleName === '' ? 0.5 : 1}
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={updateWorkItemLoader || financeGetQuoteLoader || deductibleName === ''}
										endIcon={updateWorkItemLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
										onClick={UpdateWorkItem}
									/>
								</Stack>
							</Box>
						</div>
					</Grid>
				</Grid>
			}
		</WrapperDiv>
	);
}

export default RevisedFinancingForm;