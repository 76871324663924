import MaskInput from 'components/Input/MaskInput'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Grid, Stack } from '@mui/material';
import ButtonInput from 'components/Input/ButtonInput';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from 'components/Text/H5Text';
import { getCashFinancingApplicationApprovedURL, getCashFinancingCongratulationsURL, getCashFinancingConsentFormURL, getRevisedFinancingApplicationApprovedURL, getVerificationCall } from 'routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import LocalStorageManager from 'managers/LocalStorageManger';
import SelectInput from 'components/Input/SelectInput';
import LabelWithInput from 'components/Input/LabelWithInput';
import DateTimePickerInput from 'components/Input/DateTimePickerInput';
import CheckboxInput from 'components/Input/CheckboxInput';

const CertificateStatement = ({ handleCurStep }) => {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const handleClick=()=>{
		
		// navigate(getCashFinancingApplicationApprovedURL(locale))
		navigate(getVerificationCall(locale))
		
		LocalStorageManager.setItem('approved', true);
	}
	return (
		<>
				<Grid container >
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'autoFinance.workInformation'} />
						<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id={'revisedFinance.employmentType'} /></span>
										<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Employment Type"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id={'revisedFinance.employerName'} />}
									placeholder="Employer Name"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id={'revisedFinance.occupation'} /></span>
									</Box>}
									placeholder="Occupation"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<span><FormattedMessage id={'revisedFinance.employerNumber'} /> <span>
										<span style={{ color: 'red' }}>*</span>
									</span>
									</span>}
									placeholder="Employer Number"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<DateTimePickerInput
									label={<FormattedMessage id="autoFinance.joiningDate" />}
								/>
							</Grid>
							<Grid container sx={{ marginTop: "15px" }}>
								<Grid item xs={12}>
									<H5Text size='24' lineHeight='36' borderRadius="8" padding="8px 16px" color="lightDark">
										<FormattedMessage id="revisedFinance.address" />
									</H5Text>

									<Box component="form" sx={{ padding: "0 15px 15px 15px" }}>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<SelectInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span><FormattedMessage id="revisedFinance.workCity" />&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work City"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span><FormattedMessage id="revisedFinance.workAddress" />&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work Address"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<LabelWithInput
													label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
														<span><FormattedMessage id="revisedFinance.workPostCode" />&nbsp;<span style={{ color: 'red' }}>*</span></span>
													</Box>}
													placeholder="Work Post Code"
												/>
											</Grid>

										</Grid>

									</Box>
								</Grid>
							</Grid>

						</Grid>
					</Box>
				</Grid>
			</Grid>
						<Grid container style={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="autoFinance.bankDetails" />
						<img alt="" src={require('assets/images/icon/exclamation.png')}  style={{ marginLeft:'15px'}} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.IBAN" />}
									placeholder="IBAN"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.reEnterIBAN" />}
									placeholder="Re-enter IBAN"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.bankName" />}
									placeholder="Bank Name"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.agreeIBAN" />}
									size="18"
									fontWeight="600"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="cashFinance.preferredPaymentMode"/>} placeholder="Preferred Payment Mode" />
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="cashFinance.virtualIBAN"/>} placeholder="Virtual IBAN" />
							</Grid>
							{/* <Grid item xs={12} sm={6} md={6} lg={6}>
								<H5Text size='16' lineHeight='24' fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
								<FormattedMessage id="cashFinance.preferredPaymentMode"/>
								</H5Text>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.payonDelivery" />} 
									subLabel={<FormattedMessage id="autoFinance.payWithCashonDelivery" />}
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6}>
								
								<Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.creditDebitCards" />} 
									subLabel={<FormattedMessage id="autoFinance.payWithcreditDebitCards" />} 
									size="16"
									fontWeight="800"
								/>
								<Box textAlign='end'>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>

								</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.directBankTransfer" />}  
									subLabel={<FormattedMessage id="autoFinance.makePaymentDirectlyThroughBankAccount" />} 
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.otherPaymentMethods" />}  
									subLabel={<FormattedMessage id="autoFinance.makePaymentThroughGpay" />} 
									size="16"
									fontWeight="800"
								/>
								<Box textAlign='end'>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>
								<img alt="" src={require('assets/images/financing/visa.png')} style={{height:'26px', width:'26px',marginLeft:'5px', marginTop:'5px'}}/>

								</Box>
								</Box>
							</Grid> */}
						</Grid>
					</Box>
				</Grid>
			</Grid>
		<Grid container sx={{ marginTop: "79px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(0)}
								/>
								<ButtonInput
									title={<FormattedMessage id="saveProceed" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => handleClick()}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default CertificateStatement
