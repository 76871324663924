import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckboxInput from "components/Input/CheckboxInput";
import ExpensesModal from "components/Modals/ExpensesModal";
import Text from "components/Text";
import NotePencilIcon from "assets/images/icon/NotePencil.png";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bankNameCallAPI } from 'store/financing/IbanBankName/action';
import { IBAN_BANK_NAME } from 'store/financing/IbanBankName/constant';
import { screenScrollTop } from 'utils/general';
import { fetchWorkItemCallAPI } from 'store/workItem/fetchWorkItem/action';
import { FETCH_WORK_ITEM } from 'store/workItem/fetchWorkItem/constant';
import { UPDATE_WORK_ITEM } from 'store/workItem/updateWorkItem/constant';
import { updateWorkItemCallAPI } from 'store/workItem/updateWorkItem/action';
import CircularProgress from '@mui/material/CircularProgress';
import CheckValidation from './validation';
import { expenseBankCallAPI } from 'store/financing/expenseBankValidation/action';
import { EXPENSE_BANK_VALIDATION } from 'store/financing/expenseBankValidation/constant';
import { financeCalculatorCallAPI } from 'store/financing/financeCalculator/action';
import { ONLINE_FINANCE_CALCULATOR } from 'store/financing/financeCalculator/constant';
import LocalStorageManager from 'managers/LocalStorageManger';
import { manualDBRCallAPI } from 'store/financing/manualDBR/action';
import { MANUAL_DBR } from 'store/financing/manualDBR/constant';
import { ResumeOnlineFormCall } from 'store/resumeOnlineForm/actions';
import { RESUME_ONLINE_FORM } from 'store/resumeOnlineForm/constant';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	border-radius:8px;
	background-color:white;
`;

function ExpensesBankForm({ handleCurStep }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isError, setIsError] = useState(false);
	const [isModalActive, setIsModalActive] = useState(false);
	const { locale } = useContext(LocaleContext);
	const expenseDetails = useSelector((state) => (state?.expenseBankReducer?.payload?.data?.['soap:Envelope']?.['soap:Body']?.['ProcessApplicationXMLResponse']?.['ProcessApplicationXMLResult']?.['Response']?.['Decision']['Expenses']));
	const updateWorkItemLoader = useSelector((state) => state?.updateWorkItemReducer?.loading || state.manualDBRReducer.loading || state.financeCalculatorReducer?.loading);
	const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data?.data);
	const yakeenUserInfoData = useSelector((state) => (state.financingYakeenUserInfoReducer && state.financingYakeenUserInfoReducer?.payload?.data?.data));
	const yakeenUserAddressData = useSelector((state) => (state.financingYakeenUserAddressReducer && state.financingYakeenUserAddressReducer?.payload?.data?.data));
	const IbanBankName = useSelector((state) => state.bankNameReducer && state.bankNameReducer?.payload?.data);
	const financingCategoryDropdown = useSelector((state) => state.financingDropDownReducer && state.financingDropDownReducer?.payload?.data);
	const workItemListData = useSelector((state) => state.applicationListWorkItemReducer && state.applicationListWorkItemReducer?.payload?.data);
	const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state.fetchWorkItemReducer?.payload?.data?.data);
	const storageData = useSelector((state) => state?.basicReducer);
	const gosiIncome = useSelector((state) => state.financingReducer && state?.financingReducer?.payload?.data);
	const FinanceCalculatorPayloadData = LocalStorageManager.getItem('finance_Calculator_payload') !== null && JSON.parse(LocalStorageManager.getItem('finance_Calculator_payload'));
	const manualDBRData = useSelector((state) => state.manualDBRReducer && state.manualDBRReducer?.payload?.data?.["Response"]?.["Decision"]);

	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);
	const expanse = (FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.groceriesExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.groceriesExpenses ?? 0 : +(expenseDetails?.FoodExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.healthcareExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.healthcareExpenses ?? 0 : +(expenseDetails?.HealthCareExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.educationExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.educationExpenses ?? 0 : +(expenseDetails?.EducationExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.housingExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.housingExpenses ?? 0 : +(expenseDetails?.HousingAndServicesExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.homeLaborExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.homeLaborExpenses ?? 0 : +(expenseDetails?.HouseHelpExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.communicationAndTransExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.communicationAndTransExpenses ?? 0 : +(expenseDetails?.TransportationAndCommunicationExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.insuranceExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.insuranceExpenses ?? 0 : +(expenseDetails?.InsuranceExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.futureExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.futureExpenses ?? 0 : +(expenseDetails?.FutureExpenses) ?? 0) +
		(FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.waterAndElectrExpenses ? FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.waterAndElectrExpenses ?? 0 : +(expenseDetails?.WaterAndElectricityExpenses) ?? 0);

	const [formValue, setFormValue] = useState({
		iban: "",
		re_iban: "",
		// currentObligationSource: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.currentSource ?? '',
		currentMonthlyOblgation: FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.currentAmount ?? ''
	});

	useEffect(() => {
		fetchWorkItemApi();
	}, []);

	const fetchWorkItemApi = () => {
		let body = {
			appId: workItemAppId
		};
		dispatch(fetchWorkItemCallAPI(FETCH_WORK_ITEM, body, { handleFunction: (val) => handleSubmitValidation(val), navigate: navigate, run: true }));
	};

	const handleModalPopup = (value) => {
		setIsModalActive(value);
	};

	const getTotalExpenses = (val) => {
		expanse = val;
	};

	const handleSubmitValidation = (FetchWorkItem) => {
		let body = {
			Application: {
				ApplicationNo: FetchWorkItem?.loanApplicationNumber ?? '',
				ApplicationDate: '',
				Parties: {
					Party: {
						Type: 'Guest',
						IDType: userMeData?.id_type,
						IDNumber: userMeData?.nationalId,
						ArFirstName: yakeenUserInfoData?.FirstName ?? '',
						ArFatherName: yakeenUserInfoData?.FatherName ?? '',
						ArGrandFatherName: yakeenUserInfoData?.GrandFatherName ?? '',
						ArFamilyName: yakeenUserInfoData?.FamilyName ?? '',
						ArFullName: yakeenUserInfoData?.FirstName ?? '' + yakeenUserInfoData?.FatherName ?? '' + yakeenUserInfoData?.GrandFatherName ?? '',
						MobileNo1: userMeData?.contactNumber,
						Nationality: userMeData?.is_saudi ? 'SAU' : '',
						GuestTypeID: 'Retail',
						SubFinanceTypeID: '0',
						MonthlyIncome: storageData?.netIncome,
						MonthlyAdditionalIncome: 0,
						GOSIDeduction: storageData?.financingType === 'gosi' ? 0.0975 *
							(parseFloat(gosiIncome?.data?.employmentStatusInfo?.[0]?.basicWage ?? 0) + parseFloat(gosiIncome?.data?.employmentStatusInfo?.[0]?.housingAllowance ?? 0))
							: gosiIncome?.data?.data?.[0]?.payslipInfo?.totalDeductions ?? '',
						COLA: '0',
						NoOfDependents: FetchWorkItem?.basicDetails?.guestDetails?.[0]?.noOfDependents ?? '',
						HouseType: FetchWorkItem?.basicDetails?.guestDetails?.[0]?.houseType ?? '',
						IsALJEmployee: FetchWorkItem?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelated ?? '',
						Gender: userMeData?.Gender?.name,
						MaritalStatus: FetchWorkItem?.basicDetails?.guestDetails?.[0]?.maritalStatus,
						BirthDate: userMeData?.is_saudi ? userMeData?.HijriDOB : userMeData?.DOB,
						Region: '3',
						Province: '11',
						District: FetchWorkItem?.basicDetails?.addressDetails?.[0]?.district ?? '',
						Locality: '112004',
						WorkJoinDate: userMeData?.is_saudi ? gosiIncome?.data?.employmentStatusInfo?.[0]?.dateOfJoining ?? '' : '',
						IsJobPermanent: 'N',
						Occupation: FetchWorkItem?.basicDetails?.employmentDetails?.[0]?.occupation ?? '',
						IsSelfEmployed: 'N',
						IsEmployed: 'Y',
						EducationLevel: FetchWorkItem?.basicDetails?.guestDetails?.[0]?.levelOfEducation ?? '',
					},
				},
			},
		};
		dispatch(expenseBankCallAPI(EXPENSE_BANK_VALIDATION, body, { navigate: navigate }));
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValue((prev) => ({
			...prev,
			[name]: value
		}))
	};

	useEffect(() => {
		if (formValue.iban === formValue.re_iban && (formValue.iban).length === 24) {
			let body = {
				IbanNumber: formValue.iban
			};
			dispatch(bankNameCallAPI(IBAN_BANK_NAME, body, { navigate: navigate }));
		}
	}, [formValue.iban, formValue.re_iban]);

	const UpdateWorkItem = () => {
		if (FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails["expenseDetails"] === undefined)) {
			const addresObject = [{
				"currentAmount": formValue?.currentMonthlyOblgation,
				"currentSource": formValue?.currentObligationSource,
			}];
			FetchWorkItemData?.basicDetails && (FetchWorkItemData.basicDetails['expenseDetails'] = addresObject);
		} else {
			FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['currentAmount'] = formValue?.currentMonthlyOblgation);
			FetchWorkItemData?.basicDetails?.expenseDetails?.[0] && (FetchWorkItemData.basicDetails.expenseDetails[0]['currentSource'] = formValue?.currentObligationSource);
		}
		FetchWorkItemData && (FetchWorkItemData['operation'] = "save");
		dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleRedirect, navigate: navigate, run: true }));
	};

	const ManualDbrVerify = () => {
		let body = {
			"Type": "Guest",
			"IDType": userMeData?.id_type,
			"IDNumber": userMeData?.nationalId,
			"GuestTypeID": "Retail",
			"SubFinanceTypeID": 0,
			"MonthlyIncome": +(storageData?.netIncome),
			"MonthlyAdditionalIncome": 0,
			"DirectDebit": 0,
			"GOSIDeduction": 0,
			"CreditCardLimit": 0,
			"OtherMonthlyInstallment": +(formValue?.currentMonthlyOblgation),
			"IsGovernmentRetiree": "N",
			"HasMortgage": "N",
			"COLA": 0,
			"ALJUFOtherMonthlyPayments": 0,
			"ALJUFDirectDebits": 0
		};
		dispatch(manualDBRCallAPI(MANUAL_DBR, body, { handleFetchFunction: PostFinanceCalculatorData, navigate: navigate, run: true }));
	};

	const PostFinanceCalculatorData = () => {
		let body = {
			"guestType": FinanceCalculatorPayloadData?.guestType,
			"netIncome": +(storageData?.netIncome),
			"paymentType": FinanceCalculatorPayloadData?.paymentType,
			"brandID": FinanceCalculatorPayloadData?.brandID,
			"autoProductId": FinanceCalculatorPayloadData?.autoProductId,
			"modelId": FinanceCalculatorPayloadData?.modelId,
			"modelYear": FinanceCalculatorPayloadData?.modelYear,
			"vehicleType": FinanceCalculatorPayloadData?.vehicleType,
			"vehiclePrice": FinanceCalculatorPayloadData?.vehiclePrice,
			"tenureInMonths": FinanceCalculatorPayloadData?.tenureInMonths,
			"totalLimitOfAllCC": FinanceCalculatorPayloadData?.totalLimitOfAllCC,
			"financeVATFlag": FinanceCalculatorPayloadData?.financeVATFlag,
			"maxMonthlyAmount": +(FinanceCalculatorPayloadData?.maxMonthlyAmount),
			"emiAmount": FinanceCalculatorPayloadData?.emiAmount,
			"downPaymentAmt": FinanceCalculatorPayloadData?.downPaymentAmt,
			"PromoCode": FinanceCalculatorPayloadData?.PromoCode,
			"residualAmount": FinanceCalculatorPayloadData?.residualAmount,
			"basePremiumInsuranceAmount": FinanceCalculatorPayloadData?.basePremiumInsuranceAmount,
			"featurePremiumInsuranceAmount": FinanceCalculatorPayloadData?.featurePremiumInsuranceAmount,
			"productCategory": FinanceCalculatorPayloadData?.productCategory,
			"scheme": FinanceCalculatorPayloadData?.scheme,
			"product": FinanceCalculatorPayloadData?.product
		};

		dispatch(financeCalculatorCallAPI(ONLINE_FINANCE_CALCULATOR, body, { handleFunction: UpdateWorkItem, navigate: navigate, run: true }));
	};

	const handleRedirect = () => {
		screenScrollTop();
		handleCurStep(3);
		let body = {
			resumeForm: true,
			step: 3
		};
		dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
	};

	const handleSubmit = () => {
		if (formValue?.currentMonthlyOblgation <= +(storageData?.netIncome)) {
			const error = CheckValidation(formValue);
			setIsError(error);
			if (error.submit) {
				if (FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.currentAmount === formValue?.currentMonthlyOblgation) {
					UpdateWorkItem();
				} else {
					ManualDbrVerify();
				}
			}
		} else {
			setIsError({
				'currentMonthlyOblgation': {
					error: true,
					errorMessage: <FormattedMessage id="Current Monthly Obligations should not greater than Net Monthly Income" />
				}
			});
		}
	};

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'0px'}>
						<FormattedMessage id="autoFinance.expensesObligations" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div style={{ display: 'flex', marginTop: "30px" }}>
									<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily="KarbonSemiBold">
										<FormattedMessage id="autoFinance.totalExpenses" /> {expanse?.toFixed(2) ?? ""} <FormattedMessage id="sar" />
									</Text>
									<div style={{ marginLeft: "15px", cursor: "pointer" }}>
										<img src={NotePencilIcon} onClick={() => setIsModalActive(true)} />
									</div>
								</div>
								<ExpensesModal modalActive={isModalActive} handleModalPopup={handleModalPopup} expenseDetails={expenseDetails} getTotalExpenses={getTotalExpenses} />
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.currentMonthlyObligations" />}
									placeholder="Current Monthly Obligations"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end"><FormattedMessage id="sar" /></InputAdornment>
										)
									}}
									name={'currentMonthlyOblgation'}
									value={formValue?.currentMonthlyOblgation}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError?.currentMonthlyOblgation?.error && isError?.currentMonthlyOblgation?.errorMessage}
								/>
							</Grid>
							{/* <Grid item xs={12} sm={6} md={6} lg={6}>
								<SelectInput
									label={<FormattedMessage id="autoFinance.currentMonthlyObligationSource" />}
									placeholder="Current Monthly Obligation Source"
									data={financingCategoryDropdown?.crObligation_source}
									value={formValue?.currentObligationSource}
									name={'currentObligationSource'}
									onChange={(e) => handleChange(e)}
									errorText={isError && isError?.currentObligationSource?.error && isError?.currentObligationSource?.errorMessage}
								/>
							</Grid> */}
							{/* <Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.expectedMonthlyObligations" />}
									placeholder="Expected Monthly Obligations"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end"><FormattedMessage id="sar" /></InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<SelectInput
									label={<FormattedMessage id="autoFinance.expectedMonthlyObligationsSource" />}
									placeholder="Expected Monthly Obligations Source"
								/>
							</Grid> */}
						</Grid>
					</Box>
				</Grid>
			</Grid>

			{/* <Grid container style={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="autoFinance.bankDetails" />
						<img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.IBAN" />}
									placeholder="IBAN"
									name="iban"
									value={formValue.iban}
									onChange={(e) => handleChange(e)}
									inputProps={{ maxLength: 24 }}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.reEnterIBAN" />}
									placeholder="Re-enter IBAN"
									name="re_iban"
									value={formValue.re_iban}
									onChange={(e) => handleChange(e)}
									inputProps={{ maxLength: 24 }}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<FormattedMessage id="autoFinance.bankName" />}
									placeholder="Bank Name"
									value={IbanBankName?.Description}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.agreeIBAN" />}
									size="18"
									fontWeight="600"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<H5Text size='16' lineHeight='24' fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
									<FormattedMessage id="revisedFinance.prefferedPaymentMode" />
								</H5Text>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.payonDelivery" />}
									subLabel={<FormattedMessage id="autoFinance.payWithCashonDelivery" />}
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6}>

								<Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
									<CheckboxInput
										label={<FormattedMessage id="autoFinance.creditDebitCards" />}
										subLabel={<FormattedMessage id="autoFinance.payWithcreditDebitCards" />}
										size="16"
										fontWeight="800"
									/>
									<Box textAlign='end'>
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />

									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<CheckboxInput
									label={<FormattedMessage id="autoFinance.directBankTransfer" />}
									subLabel={<FormattedMessage id="autoFinance.makePaymentDirectlyThroughBankAccount" />}
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
									<CheckboxInput
										label={<FormattedMessage id="autoFinance.otherPaymentMethods" />}
										subLabel={<FormattedMessage id="autoFinance.makePaymentThroughGpay" />}
										size="16"
										fontWeight="800"
									/>
									<Box textAlign='end'>
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />
										<img src={require('assets/images/financing/visa.png')} style={{ height: '26px', width: '26px', marginLeft: '5px', marginTop: '5px' }} />

									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid> */}

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form" sx={{padding:"20px 30px"}}>
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									minWidth="100px"
									marginRight="30"
									padding="0px"
									onClick={() => handleCurStep(1)}
								/>
								<ButtonInput
									title={<FormattedMessage id="autoFinance.saveProceedtoAttachApply" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									disabled={updateWorkItemLoader}
									endIcon={updateWorkItemLoader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									// onClick={() => { screenScrollTop(); handleCurStep(3) }}
									onClick={() => { handleSubmit() }}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default ExpensesBankForm;