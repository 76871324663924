import React, { useContext } from "react";
import Grid from '@mui/material/Grid';
import PolygonWithBackground from "assets/images/Dashboard/Polygon_e_service.png";
import Background_People_Image from "assets/images/Dashboard/Background_people.png";
import styled from 'styled-components'
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import { Skeleton } from "@mui/material";
import { LocaleContext } from "routes/Router";

const StyledWrapper = styled.div`
margin-top:15px;
    .cardContent{
        background-image:url('${PolygonWithBackground}');
        background-repeat: no-repeat;
        // height:204px;
        display:flex;
        background-position: bottom right 0px;
        align-items:center;
    }

    .cardText{
        // background-image:url('${Background_People_Image}');
        background-repeat: no-repeat;
        height:204px;
        display:flex;
        // background-position: right 30px center;
        background-position: 92% 70%;
        align-items:center;
        // padding:28px 26px;
    }
`
const AvailableService = ({ ...props }) => {
    const { locale } = useContext(LocaleContext);
    return (
        <StyledWrapper {...props}>
            {!props?.loader && props?.data &&
                <Row style={{ marginBottom: "8px" }}>
                    <H5Text color="primary" size="24" marginTop="0" lineHeight="24" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                        <FormattedMessage id="dashboard.availableEServices" />
                    </H5Text>
                </Row>
            }

            {!props?.loader ?
                <Grid container spacing={1} marginLeft="-16px">
                    {props?.data && props?.data?.length > 0 && props?.data.map((k) => (
                        <>
                            <Grid item xs={12} md={6} lg={6} xl={4}>
                                <img src={k?.image} alt="" style={{ width: "100%" }} />
                            </Grid>
                        </>
                    ))}
                </Grid>
                :
                <>
                    <Grid container spacing={2} >
                        {Array.from({ length: 2 }).map(() => (
                            <Grid item xs={12} md={6} lg={6} xl={4} >
                                <Skeleton variant="rectangular" animation="wave" width={"100%"} height={206} style={{ borderRadius: "16px" }} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
        </StyledWrapper >
    );
};


export default AvailableService;
