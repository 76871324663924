import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { string } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import H5Text from "components/Text/H5Text";
import SideBySideText from "components/SideBySideText";
import Text from "components/Text";
import MuiCards from "components/MuiCards";
import { Box } from '@mui/system';
import CommonModal from 'components/Modals/CommonModal';
import ButtonInput from 'components/Input/ButtonInput';
import OneWaySlider from 'components/RangeSlider/OneWaySlider';
import { useDispatch, useSelector } from 'react-redux';
import LocalStorageManager from 'managers/LocalStorageManger';
import { financeCalculatorCallAPI } from 'store/financing/financeCalculator/action';
import { ONLINE_FINANCE_CALCULATOR } from 'store/financing/financeCalculator/constant';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { LocaleContext } from 'routes/Router';

const WrapperContent = styled.div`
	padding: 10px 15px;
`;

function FinancingResultsData({ containerProps, ...props }) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [isModalActive, setIsModalActive] = useState(false);
	const [isTotalModalActive, setIsTotalModalActive] = useState(false);

	const financeCalculator = useSelector((state) => state.financeCalculatorReducer && state.financeCalculatorReducer?.payload);
	const [monthlyAmount, setMonthlyAmount] = useState(financeCalculator?.data?.emiAmt ? financeCalculator?.data?.emiAmt : 0)
	const [period, setPeriod] = useState(financeCalculator?.data?.financePeriod ? financeCalculator?.data?.financePeriod : 0);
	const financingCalculatorLoader = useSelector((state) => state?.financeCalculatorReducer?.loading);
	const FinanceCalculatorPayloadData = LocalStorageManager.getItem('finance_Calculator_payload') !== null && JSON.parse(LocalStorageManager.getItem('finance_Calculator_payload'));

	const PostFinanceReCalculatorData = () => {
		
		let body = {
			"guestType": FinanceCalculatorPayloadData?.guestType,
			"netIncome": monthlyAmount,
			"paymentType": FinanceCalculatorPayloadData?.paymentType,
			"brandID": FinanceCalculatorPayloadData?.brandID,
			"autoProductId": FinanceCalculatorPayloadData?.autoProductId,
			"modelId": FinanceCalculatorPayloadData?.modelId,
			"modelYear": FinanceCalculatorPayloadData?.modelYear,
			"vehicleType": FinanceCalculatorPayloadData?.vehicleType,
			"vehiclePrice": FinanceCalculatorPayloadData?.vehiclePrice,
			"tenureInMonths": period,
			"totalLimitOfAllCC": FinanceCalculatorPayloadData?.totalLimitOfAllCC,
			"financeVATFlag": FinanceCalculatorPayloadData?.financeVATFlag,
			// "insuranceAmount": FinanceCalculatorPayloadData?.insuranceAmount,
			"maxMonthlyAmount": FinanceCalculatorPayloadData?.maxMonthlyAmount,
			"emiAmount": FinanceCalculatorPayloadData?.emiAmount,
			"residualAmount":FinanceCalculatorPayloadData?.residualAmount,
			"downPaymentAmt": FinanceCalculatorPayloadData?.downPaymentAmt,
			"PromoCode": FinanceCalculatorPayloadData?.PromoCode,
			"basePremiumInsuranceAmount": FinanceCalculatorPayloadData?.basePremiumInsuranceAmount,
			"featurePremiumInsuranceAmount": FinanceCalculatorPayloadData?.featurePremiumInsuranceAmount,
			"productCategory": FinanceCalculatorPayloadData?.productCategory,
			"scheme": FinanceCalculatorPayloadData?.scheme,
			"product": FinanceCalculatorPayloadData?.product
		};
		dispatch(financeCalculatorCallAPI(ONLINE_FINANCE_CALCULATOR, body, { handleFunction: setIsModalActive(false), navigate: navigate, run: true }));
	};

	return (
		<MuiCards borderRadius="16" boxShadow={false} padding="0">
			<H5Text bgColor="secondary" color="lightDark" borderRadius="8" marginTop="0" padding="8px 16px">
				<FormattedMessage id={props.title} />
			</H5Text>

			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<SideBySideText
							bgColor='#EAFCF0'
							leftText={<FormattedMessage id="monthlyPayment" />}
							RightText={monthlyAmount}
							subText={<FormattedMessage id="sar" />}
							leftFontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}
							leftTextColor="green"
							rightTextColor="green"
							leftFontWight="800"
							size='24'
							border={true}
							borderColor="green"
						/>
						<CommonModal padding={'32px 48px'} width={'1260px'} modalActive={isModalActive} innerContent={
							<Box >
								<Box >
									<Box maxWidth={'415px'}>
										<H5Text size={40} lineHeight={60} fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} marginTop={0}>Change Details</H5Text>
									</Box>
									<Grid container spacing={10} >
										<Grid item xs={12} lg={6} className='Slider' marginTop='32px'>
											<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={10} endLimit={900000} label={<FormattedMessage id="monthlyPaymentAmount" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
										</Grid>
										<Grid item xs={12} lg={6} className='Slider' marginTop='32px'>
											<OneWaySlider measure={"Months"} sliderLabel={true} startLimit={0} step={6} endLimit={60} label={<FormattedMessage id="Tenure" />} defaultValue={period} value={period} setValue={(data) => setPeriod(data)} />
										</Grid>
									</Grid>
								</Box>
								<Box component="form" display='flex' justifyContent='end' marginTop='48px'>
									<ButtonInput
										title="Cancel"
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="uppercase"
										height='59'
										onClick={() => setIsModalActive(false)}
									/>
									<ButtonInput
										title="Save"
										variant="contained"
										bgColor="yellow"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										height='59'
										disabled={financingCalculatorLoader}
										endIcon={financingCalculatorLoader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
										onClick={() => PostFinanceReCalculatorData()}
									// onClick={() => navigate(PUBLIC_ROUTE.APPLICATION_REFERRED)}
									/>
								</Box>
							</Box>
						} />
						<CommonModal padding={'32px 48px'} width={'736px'} modalActive={isTotalModalActive} innerContent={
							<Box >
								<Box display='flex' justifyContent={'space-between'}>
									<H5Text size={40} lineHeight={60} fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"} marginTop={0}>Total VAT</H5Text>
									<img src={require('assets/icons/cancel_button.png')} onClick={() => setIsTotalModalActive(false)} style={{ display: 'flex', justifyContent: 'end', height: '18px', width: '18px', cursor: 'pointer' }} />
								</Box>
								<Box marginTop={'17px'}>
									<SideBySideText
										leftText={<FormattedMessage id="autoFinance.issueExpenseVAT" />}
										RightText={`${financeCalculator?.data?.issueExpenseVat} SAR`}
										size={'24'}
										pBottom={'24'}
									/>
									<SideBySideText
										leftText={<FormattedMessage id="sellingPriceVAT" />}
										RightText={`${financeCalculator?.data?.sellingPriceVat} SAR`}
										size={'24'}
										pBottom={'24'}
									/>
									<SideBySideText
										leftText={<FormattedMessage id="autoFinance.premiumVAT" />}
										RightText={`${financeCalculator?.data?.insurancePremiumVat} SAR`}
										size={'24'}
										pBottom={'24'}
									/>
								</Box>
							</Box>
						} />
						<SideBySideText
							leftText={<FormattedMessage id="financePeriod" />}
							RightText={<Box>{financeCalculator?.data?.financePeriod} Months <img src={require('assets/icons/Financing/PencilSimple.png')} onClick={() => setIsModalActive(true)} /></Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>

						<SideBySideText
							leftText={<FormattedMessage id="residualValue" />}
							RightText={<Box>{financeCalculator?.data?.residualValue} SAR <img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '6px' }} /></Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>

						<SideBySideText
							leftText={<FormattedMessage id="downPayment" />}
							RightText={<Box>{financeCalculator?.data?.downPayment} SAR <img src={require('assets/icons/Financing/PencilSimple.png')} onClick={() => setIsModalActive(true)} /></Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>

						<SideBySideText
							leftText={<FormattedMessage id="requiredPayment" />}
							RightText={financeCalculator?.data?.requiredPayment + " SAR"}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>

						<SideBySideText
							leftText={<FormattedMessage id="totalVAT" />}
							RightText={<Box>{financeCalculator?.data?.totalVat} SAR <img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '6px' }} onClick={() => setIsTotalModalActive(true)} /></Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>

						<SideBySideText
							leftText={<FormattedMessage id="issueExpenses" />}
							RightText={`${financeCalculator?.data?.issueExpense} SAR`}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>
					</Grid>
					<Grid item xs={12}>
						<Text fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} size="16" color="lightDark">
							<FormattedMessage id="totalCostVATIncluded" /> : 144,844 <FormattedMessage id="sar" /> , <FormattedMessage id="annualRate" /> {financeCalculator?.data?.profitRate}%
						</Text>
					</Grid>
					<Grid item xs={12}>
						<Text size="14" color="lightDark">
							<FormattedMessage id="autoFinance.desclaimer" />
						</Text>
					</Grid>
				</Grid>
			</CardContent>
		</MuiCards>
	);
}

FinancingResultsData.propTypes = {
	bgColor: string.isRequired,
};

export default FinancingResultsData;