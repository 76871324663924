import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Stack } from "@mui/material";
import ButtonInput from "components/Input/ButtonInput";
import { getSignInURL } from "routes/AppUrls";
import { useNavigate } from "react-router-dom";
import { LocaleContext } from 'routes/Router';

function Successfull() {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);

	return (
		<Grid sx={{ textAlign: "center" }}>
			<Grid item={12}>
				<CheckCircleOutlineIcon sx={{ width: "130px", height: "130px", color: "#1D8841" }} />

				<H1Text color="primary" size="64" lineHeight="96">
					<FormattedMessage id="register.success" />
				</H1Text>

				<Text marginTop="0" size="24" color="lightDark" lineHeight="36" TextAlign="center">
					<FormattedMessage id="register.successText" />
				</Text>
			</Grid>

			<Grid item xs={12} md={12} sx={{ marginTop: "80px" }}>
				<div className="content" style={{ display: "flex", justifyContent: "center" }}>
					<Box component="form">
						<Stack spacing={2} direction="row">
							<ButtonInput
								title={<FormattedMessage id="gotologin" />}
								variant="contained"
								bgColor="yellow"
								textColor="purple"
								borderRadius="100"
								TextTransform="capitalize"
								onClick={() => navigate(getSignInURL(locale))}
							/>
						</Stack>
					</Box>
				</div>
			</Grid>
		</Grid>
	);
}

export default Successfull;