import {
    UPDATE_WORK_ITEM,
} from "./constant";

export const updateWorkItemCallAPI = (type, data, external = {}) => {
    switch (type) {
        case UPDATE_WORK_ITEM:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

