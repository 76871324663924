import React, { useContext } from "react";
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Text from "components/Text";
import { Box } from '@mui/material';
import { LocaleContext } from 'routes/Router';

const WrapperFormLabel = styled(FormLabel)`
	font-size: 16px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
	font-family: ${(props) => (props.locale=="en"?props.theme.KarbonSemiBold:props.theme.LumaSemiBold)} !important;
	color: ${(props) => (props.theme.darkSecondary)} !important;
`;

const WrapperDiv = styled.div`
	background: linear-gradient(267deg, rgba(60, 16, 83, 0.79) 22.21%, rgba(60, 16, 83, 0) 36.67%), 
		linear-gradient(88.35deg, #3C1053 14.18%, rgba(60, 16, 83, 0) 72.19%);
	background-image: url('${(props) => (props.bgImage)}');
	background-repeat: no-repeat;
    margin-${(props) => props.locale === 'en' ? 'right' : 'left'} : 15px;
`;

const WrapperDivIcon = styled.div`
    display: inline-flex;
    align-items: center;
    border-${(props) => props.locale === 'en' ? 'right' : 'left'} : 1px dotted ${(props) => (props.theme.green)};
    padding: 0px 15px;
`;

function DiscountBadge({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);

	return (
		<FormControl>
			{props.heading ? (
				<WrapperFormLabel locale={locale} id="yes-no-input-radio-button">{props.heading}</WrapperFormLabel>
			) : (
				<WrapperFormLabel locale={locale} id="yes-no-input-radio-button">&nbsp;</WrapperFormLabel>
			)}

			<WrapperDiv {...props} locale={locale}>
				<div style={{ display: "flex" }}>
					<WrapperDivIcon locale={locale}>
						<img alt="" width="24" height="24" src={props.icon} />
					</WrapperDivIcon>
					<Box padding={{ xs: "5px 12px", sm: "5px 15px" }} display='flex' flexDirection='column'>
						<Text color="green" size="24" marginTop="2" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}lineHeight='36'>
							{props?.data?.DiscountTypeID === 2 && props?.data?.DiscountPercentage}% off
						</Text>
						<Text color="dark" size="16" marginTop="0" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}lineHeight='24'>
							NCD Discount: {props?.data?.DiscountTypeID === 2 && props?.data?.DiscountAmount} SAR
						</Text>
					</Box>
				</div>
			</WrapperDiv>
		</FormControl>
	);
}

export default DiscountBadge;