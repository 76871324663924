import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getotpSuccess, getotpVerification, getRevisedFinancingCongratulationsURL, getRevisedFinancingConsentFormURL, getRevisedFinancingWaitingPaymentURL } from 'routes/AppUrls';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import OtpInput from "components/Input/OtpInput";
import ButtonInput from "components/Input/ButtonInput";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { LocaleContext } from "routes/Router";
import HeaderBanner from "features/RevisedFinancing/HeaderBanner";

const Verification = ({ handleCurStep }) => {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const [otp, setOtp] = useState("");
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="home" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="applyFinance" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="onlineFinancing" />,
			Link: "",
			isDisbale: true,
		}
	])

	return (
		<Box style={{ backgroundColor: '#F1EDF5', paddingBottom: "30px", minHeight: '100vh' }}>
			<Grid margin={'1% 5% 1% 5%'} style={{ marginTop: '5px' }} disableGutters>
				<Box padding='32px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box>
				<Grid container>
					<Grid item xs={12}>
						<MuiCards borderRadius="16" padding={{ sm: "0" }}>
							<CardContent style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px', minHeight: '70vh', height: 'fit-content' }}>
								<Grid maxWidth={'694px'} width="100%" textAlign={'center'} display='flex' alignItems='center' flexDirection='column'>
									<Grid container>
										<Grid item xs={12}>
											<Text color="black" size="48" lineHeight='50' fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} TextAlign="center">
												<FormattedMessage id="Verification" />
											</Text>
										</Grid>
										<Grid item xs={12}>
											<Text color="gray" size="24" lineHeight='36' TextAlign="center" >
												<FormattedMessage id="enterVerificationCode.sms_text" />&nbsp;*****7412
											</Text>
										</Grid>
									</Grid>

									<Grid container maxWidth={'535px'} marginTop='55px'>
										<Grid item xs={12}>
											<OtpInput
												marginTop="30"
												TextFieldsProps={{ placeholder: 0 }}
												validateChar={(val) => !isNaN(val)}
												value={otp}
												onChange={(value) => setOtp(value)}
											/>
										</Grid>
										<Grid item xs={12} marginTop='25px' >
											<div style={{ display: "flex" }}>
												<Text size="16" fontWeight="800">
													<FormattedMessage id="resendCodeIn" />
												</Text>
												<Text size="16" fontWeight="800">
													&nbsp;<FormattedMessage id="00:00" />
												</Text>
												<Text size="16" color="purple" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"}>
													&nbsp;<FormattedMessage id="resendOTP" />
												</Text>
											</div>
										</Grid>
									</Grid>

									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<div className="content" style={{ display: "flex", justifyContent: "end" }}>
												<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
													<ButtonInput
														title={<FormattedMessage id="back" />}
														variant="text"
														bgColor="transparent"
														textColor="purple"
														borderRadius="100"
														TextTransform="uppercase"
														// onClick={() => navigate(getotpVerification(locale) + '/' + 'otpVerification')}
													// onClick={() => setIsCommonModalActive(false)}

													/>
													<ButtonInput
														title={<FormattedMessage id="next" />}
														variant="contained"
														bgColor={"yellow"}
														textColor="purple"
														borderRadius="100"
														// disabled={!isValid || loader}
														endIcon={<ArrowForwardIcon />}
														TextTransform="capitalize"
														onClick={() => navigate(getotpSuccess(locale))}
													// endIcon={loader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
													// onClick={() => handlePostData()}
													// endIcon={<ArrowForwardIcon />}
													// onClick={() => handlePostData()}
													/>

												</Box>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);

};

export default Verification;