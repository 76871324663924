import {
    NAFATH_POLLING,
} from "./constant";

export const nafathPollAPI = (type, data, external = {}) => {
    switch (type) {
        case NAFATH_POLLING:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}

