import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckboxInput from "components/Input/CheckboxInput";
import ExpensesModal from "components/Modals/ExpensesModal";
import Text from "components/Text";

import NotePencilIcon from "assets/images/icon/NotePencil.png";
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	
`;

function ExpensesBankForm({ handleCurStep }) {
	const [isModalActive, setIsModalActive] = useState(false);
	const { locale } = useContext(LocaleContext);
	const handleModalPopup = (value) => {
		setIsModalActive(value);
	}

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="autoFinance.expensesObligations" />
						<img alt="" src={require('assets/images/icon/exclamation.png')}  style={{ marginLeft:'15px'}} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div style={{ display: 'flex', marginTop: "30px" }}>
									<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
										<FormattedMessage id="autoFinance.totalExpenses" /> 3,400.00 <FormattedMessage id="sar" />
									</Text>
									<div style={{ marginLeft: "15px", cursor: "pointer" }}>
										<img alt="" src={NotePencilIcon} onClick={() => setIsModalActive(true)} />
									</div>
								</div>
								<ExpensesModal modalActive={isModalActive} handleModalPopup={handleModalPopup} />
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
									<span><FormattedMessage id="autoFinance.currentMonthlyObligations" /></span>
									<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
								</Box>}
									placeholder="Current Monthly Obligations"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end"><FormattedMessage id="sar" /></InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<SelectInput
										label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id="autoFinance.currentMonthlyObligationSource" /></span>
										<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Current Monthly Obligation Source"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<LabelWithInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id="autoFinance.expectedMonthlyObligations" /></span>
										<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
									</Box>}
									placeholder="Expected Monthly Obligations"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end"><FormattedMessage id="sar" /></InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
									<span><FormattedMessage id="autoFinance.expectedMonthlyObligationsSource" /></span>
									<img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' />
								</Box>}
									placeholder="Expected Monthly Obligations Source"
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(1)}
								/>
								<ButtonInput
									title={<FormattedMessage id="cashFinance.saveProceedDocument" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => handleCurStep(2)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default ExpensesBankForm;