import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getRevisedFinancingCongratulationsURL } from 'routes/AppUrls';
import H5Text from "components/Text/H5Text";
import ButtonInput from "components/Input/ButtonInput";
import Text from "components/Text";
import { LocaleContext } from 'routes/Router';
import { useDispatch, useSelector } from "react-redux";
import { FETCH_WORK_ITEM } from "store/workItem/fetchWorkItem/constant";
import { fetchWorkItemCallAPI } from "store/workItem/fetchWorkItem/action";
import { updateWorkItemCallAPI } from "store/workItem/updateWorkItem/action";
import { UPDATE_WORK_ITEM } from "store/workItem/updateWorkItem/constant";

const WrapperDiv = styled.div`
	border-radius:8px;
	background-color:white;
`;

const WrapperBox = styled(Box)`
	border-top: 1px solid #B8B8B8;
    padding: 10px 0px;
    margin: 15px 0px;
`;

function OrderSummary({ handleCurStep }) {
	const intl = useIntl();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);

	const FetchWorkItemData = useSelector((state) => state.fetchWorkItemReducer && state.fetchWorkItemReducer?.payload?.data?.data);
	const workItemAppId = useSelector((state) => state?.basicReducer?.workItemAppId);

	useEffect(() => {
		fetchWorkItemApi();
	}, []);

	const fetchWorkItemApi = () => {
		let body = {
			appId: workItemAppId
		};
		dispatch(fetchWorkItemCallAPI(FETCH_WORK_ITEM, body, { navigate: navigate }));
	};

	const UpdateWorkItem = () => {
		FetchWorkItemData && (FetchWorkItemData['operation'] = "submit");
		dispatch(updateWorkItemCallAPI(UPDATE_WORK_ITEM, FetchWorkItemData, { workItemAppId: workItemAppId, handleFunction: handleSubmit, navigate: navigate, run: true }));
	};

	const handleSubmit = () => {
		navigate(getRevisedFinancingCongratulationsURL(locale));
	};

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" padding="8px 16px" color="purple" size={'32'} fontFamily={locale == "en" ? 'Karbon_semibold' : "Luma_semibold"} marginTop={'0px'}>
						<FormattedMessage id="autoFinance.yourOrderSummary" />
					</H5Text>

					<div className="content" style={{ padding: "0 30px 30px 30px" }}>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.initialInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.employerType" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.occupation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.anyMonthlyPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.totalCreditCardLimit" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.netIncome" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.anyMortage" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.employmentType ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.occupation ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.monthlyIncome ?? ''} SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.netMonthlyIncome ?? ''} SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.netMonthlyIncome ?? ''} SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.mortgage ?? ''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.drivingLicense" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.licenseType" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.drivingLicenseExpiryDate" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.LicenseHeldforYears" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseType ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.licenseExpiry ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.noOfYears ?? ''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="insuranceDetails" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="name" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="amount" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="insurancePriceVAT" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="deductible" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="NCDDiscount" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						{/* start=============== */}
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="financingResults" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="monthlyPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="financePeriod" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="downPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="residualValue" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="issueExpenses" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="requiredPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="totalVAT" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.personalInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.numberofDependents" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.levelofEducation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.residenceType" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.houseType" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.noOfDependents ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.levelOfEducation ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.houseType ?? ''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.workInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.employerNumber" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.workPostCode" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.workLocation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.workCity" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.employmentDetails?.[0]?.crNumber ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.relativeInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.relativeName" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.designation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.relation" />&nbsp;ALJUF
											</Text>

										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelatedName ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelatedDesignation ?? ''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.guestDetails?.[0]?.isGuestALJFSRelated ?? ''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.expensesObligations" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.totalExpenses" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.totalObligations" />
											</Text>

										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.currentAmount ?? ''} SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{FetchWorkItemData?.basicDetails?.expenseDetails?.[0]?.currentSource ?? ''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						{/* <WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.bankDetails" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.IBAN" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.bankName" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.purposeofLoan" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.PreferredPaymentMode" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
												1254687951653232611
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
												National Saudi Bank
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
												0 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily={locale=="en"?"KarbonSemiBold":"LumaSemiBold"}>
												Bank Transfer
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox> */}
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="documents" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6} display='flex' alignItems={'center'}>
											<img src={require('assets/images/financing/pdf.png')} height='25.74' width='24' style={{ marginRight: '16px', marginTop: '10px' }} />
											<Text size="20" color="dark" fontWeight="600">
												Driver’s License.pdf
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											{''}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox style={{ borderBottom: '1px solid #B8B8B8' }}>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily={locale == "en" ? "KarbonSemiBold" : "LumaSemiBold"} textTransform="uppercase">
										<FormattedMessage id="autoFinance.applicationFees" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.totalIncludingVAT" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.applicationFees" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="monthlyPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="downPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="totalVAT" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection='column' alignItems={{ xs: 'end', lg: 'start' }}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												{''}
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						{/* start=============== */}

					</div>
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form" sx={{padding:'20px 30px'}}>
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									minWidth="100px"
									marginRight="30"
									padding="0px"
									onClick={() => handleCurStep(4)}
								/>
								<ButtonInput
									title={<FormattedMessage id="continue" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={UpdateWorkItem}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	)
}


export default OrderSummary;