export const getLandingURL = (locale) => `/${locale}`;
export const getSignInURL = (locale) => `/${locale}/signin`;
export const getSignUpURL = (locale) => `/${locale}/signup`;
export const getForgotPasswordURL = (locale) => `/${locale}/forgot-password`;
export const getResetPasswordURL = (locale) => `/${locale}/reset-password`;
export const get404URL = (locale) => `/${locale}/404`;
export const consentForm = (locale) => `/${locale}/consent-form`;

/** PRIVATE ROUTE **/
export const getDashboardURL = (locale) => `/${locale}/dashboard`;
export const getContractsURL = (locale) => `/${locale}/contracts`;
export const getContractDetailsURL = (locale) => `/${locale}/contracts/contract-details`;
export const getApplicationsURL = (locale) => `/${locale}/applications`;
export const getApplicationDetailsURL = (locale) => `/${locale}/applications/application-details`;
export const getAccountURL = (locale) => `/${locale}/account`;
export const getAccountWithIdURL = (locale) => `${getAccountURL(locale)}/:postId`;
export const getSupportURL = (locale) => `/${locale}/support`;
export const getRevisedFinancingURL = (locale) => `/${locale}/financing`;

export const getRevisedFinancingInitialDataURL = (locale) => `/${locale}/financing/initial-data`;
export const getRevisedFinancingCongratulationsURL = (locale) => `/${locale}/financing/congratulations`;
export const getRevisedFinancingConsentFormURL = (locale) => `/${locale}/financing/consent-form`;
export const getRevisedFinancingVerificationURL = (locale) => `/${locale}/financing/verification`;
export const getRevisedFinancingWaitingPaymentURL = (locale) => `/${locale}/financing/waiting-payment`;
export const getRevisedFinancingApplicationApprovedURL = (locale) => `/${locale}/financing/application-approved`;
export const getRevisedFinancingVehicleDeliveredURL = (locale) => `/${locale}/financing/vehicle-delivered`;
export const getApplicationRejected = (locale) => `/${locale}/financing/application-rejected`;
export const getRevisedAccountFetch = (locale) => `/${locale}/financing/account-fetch`;
export const getRequestCallback = (locale) => `/${locale}/financing/request-callback`;

export const getCashFinancingURL = (locale) => `/${locale}/cash-financing`;
export const getCashFinancingDetailsURL = (locale) => `/${locale}/cash-financing/details`;
export const getCashFinancingConsentFormURL = (locale) => `/${locale}/cash-financing/consent-form`;
export const getCashFinancingInitialDataURL = (locale) => `/${locale}/cash-financing/initial-data`;
export const getCashFinancingCongratulationsURL = (locale) => `/${locale}/cash-financing/congratulations`;
export const getCashFinanceApplicationRejected = (locale) => `/${locale}/cash-financing/application-rejected`;
export const getCashFinancingVerificationURL = (locale) => `/${locale}/cash-financing/verification`;
export const getCashFinancingWaitingPaymentURL = (locale) => `/${locale}/cash-financing/waiting-payment`;
export const getCashFinancingApplicationApprovedURL = (locale) => `/${locale}/cash-financing/application-approved`;
export const getAccountFetch = (locale) => `/${locale}/cash-financing/account-fetch`;
export const getVerificationCall = (locale) => `/${locale}/cash-financing/verification-call`;
export const getRequestIneligible = (locale) => `/${locale}/cash-financing/request-ineligible`;
export const getSurveyIneligible = (locale) => `/${locale}/cash-financing/survey-ineligible`;

// contract 
export const getotpVerification = (locale) => `/${locale}/otp-verification`;
export const getotpSuccess = (locale) => `/${locale}/success`;
export const getInvoiceGeneration = (locale) => `${getContractDetailsURL(locale)}/:postId`;
export const getInvoiceSuccess= (locale) => `${getInvoiceGeneration(locale)}/:additionalId`;
// contract 
/** COMMON ROUTE **/
export const getFindVehicleURL = (locale) => `/${locale}/find-vehicle`;
export const getCampaignDetailsURL = (locale) => `/${locale}/campaign-details`;
export const getFinanceCalculatorURL = (locale) => `/${locale}/finance-calculator`;
export const getVehicleComparisonURL = (locale) => `/${locale}/vehicle-comparison`;


