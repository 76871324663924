import MaskInput from 'components/Input/MaskInput'
import React, { useContext, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Grid, Stack } from '@mui/material';
import ButtonInput from 'components/Input/ButtonInput';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import H5Text from 'components/Text/H5Text';
import { screenScrollTop } from 'utils/general';
import { uploadDocumentCallAPI } from 'store/uploadDocument/action';
import { UPLOAD_DOCUMENT } from 'store/uploadDocument/constant';
import { useDispatch, useSelector } from 'react-redux';
import CheckValidation from './validation';
import { ResumeOnlineFormCall } from 'store/resumeOnlineForm/actions';
import { RESUME_ONLINE_FORM } from 'store/resumeOnlineForm/constant';
import { useNavigate } from 'react-router-dom';
import { uploadApplicationDocumentCallAPI } from 'store/uploadDocument/uploadApplicationDocument/action';
import { APP_UPLOAD_DOCUMENT } from 'store/uploadDocument/uploadApplicationDocument/constant';
import { LocaleContext } from 'routes/Router';


const CertificateStatement = ({ handleCurStep, handleInnerCurStep }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);
	const salaryCertificateInputRef = useRef(null);
	const bankStatementInputRef = useRef(null);
	const [isError, setIsError] = useState(false);

	const updateWorkItemData = useSelector((state) => state?.updateWorkItemReducer && state?.updateWorkItemReducer?.payload?.data);

	const [formValue, setFormValue] = useState({
		salaryCertificate: "",
		bankStatement: "",
	});

	const handleUploadSalaryClick = () => {
		salaryCertificateInputRef.current.click();
	};

	const handleUploadBankClick = () => {
		bankStatementInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0]; // Get the first selected file
		if (file) {
			setFormValue((prev) => ({
				...prev,
				[e.target.name]: file.name
			}));
			let formData = new FormData();
			formData.append("file", e.target.files[0]);
			formData.append("loanAppNumber", updateWorkItemData?.data?.appId);
			formData.append("documentId", e.target.name === "salaryCertificate" ? 101 : 102);
			dispatch(uploadApplicationDocumentCallAPI(APP_UPLOAD_DOCUMENT, formData));
		}
	};

	const handleSubmit = () => {
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			screenScrollTop();
			handleCurStep(2);
			let body = {
				resumeForm: true,
				step: 2
			};
			dispatch(ResumeOnlineFormCall(RESUME_ONLINE_FORM, body, { navigate: navigate }));
		}
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} >
					<H5Text size={'20'} lineHeight='21.45' color='purple'>As you have agreed to proceed with the amount different from GOSI, provide Salary Certificate & Bank Statement.</H5Text>
				</Grid>


				<Grid item xs={12} md={6}>
					<input
						ref={salaryCertificateInputRef}
						type="file"
						disabled={false}
						style={{ display: 'none' }} // Hide the file input
						onChange={handleFileChange}
						name="salaryCertificate"
					/>
					<MaskInput
						type="text"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="Salary Certificate" />}
						name="salaryCertificate"
						value={formValue?.salaryCertificate ?? ''}
						endAdornment={true}
						InputProps={{
							endAdornment: (
								<>
									<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadSalaryClick} />
									<MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
								</>
							),
						}}
						errorText={isError && isError.salaryCertificate.error && isError.salaryCertificate.errorMessage}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<input
						ref={bankStatementInputRef}
						type="file"
						disabled={false}
						style={{ display: 'none' }} // Hide the file input
						onChange={handleFileChange}
						name="bankStatement"
					/>
					<MaskInput
						type="text"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="Bank Statement" />}
						name="bankStatement"
						value={formValue?.bankStatement ?? ''}
						endAdornment={true}
						InputProps={{
							endAdornment: (
								<>
									<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadBankClick} />
									<MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
								</>
							),
						}}
						errorText={isError && isError.bankStatement.error && isError.bankStatement.errorMessage}
					/>
				</Grid>

				{/* <Grid item xs={12} md={6}>
					<MaskInput
						disabled={false}
						maskChar=" "
						label={<FormattedMessage id="Salary Certificate" />}
						name="IBAN Certificate"
						endAdornment={true}
						InputProps={{
							endAdornment: (
								<>
									<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px' }} />
									<MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
								</>
							),
						}}
					// onChange={handleInputChange}
					// errorText={isError && isError.contactNumber.error && isError.contactNumber.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<MaskInput
						disabled={false}
						maskChar=" "
						label={<FormattedMessage id="Bank Statement" />}
						name="IBAN Certificate"
						endAdornment={true}
						InputProps={{
							endAdornment: (
								<>
									<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px' }} />
									<MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
								</>
							),
						}}
					// onChange={handleInputChange}
					// errorText={isError && isError.contactNumber.error && isError.contactNumber.errorMessage}
					/>
				</Grid> */}

			</Grid>

			<Grid container sx={{ marginTop: "79px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									minWidth="100px"
									marginRight="30"
									padding="0px"
									onClick={() => handleInnerCurStep('PersonalWorkForm')}
								/>
								<ButtonInput
									title={<FormattedMessage id="Save & Proceed to Expenses & Bank" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
									onClick={() => { handleSubmit() }}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default CertificateStatement
