import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import SonetCarImage from "assets/images/Dashboard/sonet_car.png";
import PolygonWithoutBackground from "assets/images/Dashboard/PolygonWithoutBackground.png";
import styled from 'styled-components';
import Text from "components/Text";
import { string } from "prop-types";
import ButtonInput from "components/Input/ButtonInput";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SideBySideText from "components/SideBySideText";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getCampaignDetailsURL, getFindVehicleURL } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";
import moment from "moment";
import { Box, Skeleton } from "@mui/material";

const StyleWrapper = styled.div`
    height:230px;

    .rightCard{
        background:#3F1956;
        height:230px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        background-image:url('${PolygonWithoutBackground}');
        background-repeat: no-repeat;
        background-position: right;
        display:flex;
        justify-content:space-between;
        padding:35px;
    }
    @media only screen and (max-width: 1200px) {
        .rightCard{
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }
    }

    .rightCardBody{
        display:flex;
        align-items:center;
    }

    .rightCardButton{
        display:flex;
        justify-content:end;
        align-items:end;
        text-align:end;
    }
    .bannerImage{
        height: 100%;
        width: 100%;
        background-position: left !important;
        background: #f0f0f0 url('${(props) => props?.data?.image_en !== "" ? props?.data?.image_en : SonetCarImage}') no-repeat center center;
    }
`

const Dashboard = (props) => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    return (
        !(props?.loader) ?
            <StyleWrapper {...props}>
                <Grid container>
                    <Grid item md={6} display={{ xs: 'none', lg: 'flex' }}>
                        <div className="bannerImage"></div>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Grid container className="rightCard">
                            <Grid item xs={12} lg={12} className="rightCardBody">
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Text size={isMobile?"32":"38"} color="white" marginTop="0" lineHeight="48">
                                            {props?.data?.CampaignName}
                                        </Text>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text size="24" color="white" marginTop="0" lineHeight="32">
                                            {"Booking starts from"}
                                        </Text>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text size="24" color="white" marginTop="0" lineHeight="32">
                                            {moment(props?.data?.StartDate).format("Do MMM YYYY")}
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12} className="rightCardButton">
                                <ButtonInput
                                    title={<FormattedMessage id="applyNow" />}
                                    variant="contained"
                                    bgColor="yellow"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    size={"24"}
                                    padding={"10px 16px"}
                                    minWidth={"202"}
                                    height={"62"}
                                    endIcon={ locale === "ar" ? < ArrowBackIcon /> : <ArrowForwardIcon />}
                                    onClick={() => navigate(getCampaignDetailsURL(locale), { state: { campaignCode: props?.data?.CampaignCode } })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyleWrapper >
            :
            <StyleWrapper {...props}>
                <Grid container>
                    <Skeleton variant="rectangular" animation="wave" height={230} width="100%" style={{ borderRadius: "16px" }} />
                </Grid>
            </StyleWrapper >
    );
};

Dashboard.propTypes = {
    color: string.isRequired,
};

export default Dashboard;
