import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LeftBarImage from "components/LeftBarImage";
import LoginAccount from "features/Login/LoginAccount";
import Verification from "features/Login/Verification";
import layoutImage from "assets/images/LayoutImage/layoutImage.png";
import LabelImage from "assets/images/icon/encrypted.png";
import Text from "components/Text";
import ChangePassword from "features/Login/ChangePasword";
import ConsentForm from "features/Login/ConsentForm";
import { LocaleContext } from "routes/Router";

export const BottomText = styled.div`
    position: fixed;
    bottom: 30px;
    display: flex;

    @media screen and (max-width: 900px) {
        position: unset;
        bottom: 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    @media screen and (max-height: 800px) {
        position: unset;
        bottom: 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    @media screen and (min-height: 900px) {
       position:absolute;
       bottom:30px;
       margin-top: 15px;
       margin-bottom: 15px;
    }
`;

const Login = () => {
    const [curStep, setCurStep] = useState(1);
    const [formValue, setFormValue] = useState('');
    const [isConsentForm, setIsConsentForm] = useState(false);
    const { locale } = useContext(LocaleContext);

    const handleCurStep = (value) => {
        if (value === 'consent-form') {
            setIsConsentForm(true);
        } else {
            setCurStep(value);
            setIsConsentForm(false);
        }

    };

    const handleFormData = (value) => {
        setFormValue(value);
    };
    return (
        <Box>
            <Grid container spacing={{ md: 10 }}>

                {!isConsentForm &&
                    <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                        <LeftBarImage bgImage={layoutImage} width="100%" bgColor="lightPurple" />
                    </Grid>
                }

                <Grid item xs={12} marginTop={{ xs: '3%', md: "0px" }} md={isConsentForm ? 12 : 7} sx={{ display: "flex", alignItems: "center" }} paddingRight={{ xs: isConsentForm && '0', md: !isConsentForm && '40px' }}>
                    <Grid container padding={{ xs: !isConsentForm && "0 15px", md: "0px" }}>
                        <Grid item xs={12}>
                            {curStep === 2 ? (
                                isConsentForm ?
                                    <ConsentForm handleCurStep={handleCurStep} postFormData={formValue} />
                                    :
                                    <Verification handleCurStep={handleCurStep} postFormData={formValue} />
                            ) : curStep === 3 ? (
                                isConsentForm ?
                                    <ConsentForm handleCurStep={handleCurStep} postFormData={formValue} />
                                    :
                                    <ChangePassword handleCurStep={handleCurStep} />
                            ) : (
                                <>
                                    <LoginAccount handleCurStep={handleCurStep} handleFormData={handleFormData} />
                                </>

                            )}
                        </Grid>

                        {!isConsentForm &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <BottomText>
                                    <img width="24" height="24" style={{ marginRight: '8px', marginLeft: locale === 'ar' ? '8px' : '0' }} src={LabelImage} alt="" />

                                    <Text marginTop="3" size="14" color="footerGrayText" lineHeight="16" fontWeight="700">
                                        <FormattedMessage id="footerText" />
                                    </Text>
                                </BottomText>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
};

export default Login;