import React, { useEffect, useState, useContext } from "react";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import H5Text from "components/Text/H5Text";
import { LocaleContext } from 'routes/Router';
import moment from "moment";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonInput from "components/Input/ButtonInput";
import AddIcon from "assets/images/Account/AddIcon.png";
import MuiCards from "components/MuiCards/";
import { accountComplaintsCallAPI } from "store/account/complaints/action";
import { ACCOUNT_COMPLAINTS } from "store/account/complaints/constant";

const StyleWrapper = styled(Box)`
    align-items: center;
    margin:${props => props.margin};
    .application_top{
        display:flex;
        align-items:center;
    };
`

const AccountComplaints = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ComplaintsList = useSelector((state) => (state.accountComplaintsReducer && state.accountComplaintsReducer?.payload?.data?.data?.Model?.Tickets));
    const error = useSelector((state) => (state.accountComplaintsReducer?.error?.data?.message));
    const loader = useSelector((state) => state.accountComplaintsReducer?.loading);
    const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getComplaintsList = () => {
        let body = {
            idnumber: userMeData?.data?.nationalId
        }
        dispatch(accountComplaintsCallAPI(ACCOUNT_COMPLAINTS, body, { navigate: navigate }));
    };

    useEffect(() => {
        getComplaintsList();
    }, []);

    const handleComplaintsForm = () => {
        navigate("/" + locale + "/account/complaint-form");
    };

    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <Grid container >
                <Grid item xs={12} sm={10} md={12} lg={11}>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection={{xs:"column",sm:"row"}}>
                        <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily={locale=="en"?'Karbon_semibold':"Luma_semibold"}>
                            <FormattedMessage id="account.complaints" />
                        </H5Text>

                        <ButtonInput
                            title={<FormattedMessage id="account.addNewComplaint" />}
                            variant="contained"
                            bgColor={"transparent"}
                            textColor="purple"
                            borderRadius="100"
                            TextTransform="capitalize"
                            borderColor="lightPink"
                            startIcon={<img src={AddIcon} alt="icon" style={{ marginRight: "10px", marginLeft: "10px" }} />}
                            onClick={() => handleComplaintsForm()}
                        />
                    </Box>
                </Grid>
            </Grid>

            {!loader ?
                ComplaintsList?.length > 0 && ComplaintsList.map((k) => (
                    <Grid container marginTop={'24px'} padding="0 5px">
                        <Grid item xs={12} sm={10} md={12} lg={11}>
                            <MuiCards borderRadius="20" boxShadow='0px 0px 10px 0px #0000003D' padding="0px" backgroundSize="cover" minHeight="140" bgColor="white">
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} padding={'20px 20px 20px 20px'}>
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <H5Text color="lightDark" size="24" fontfamily="KarbonMedium" fontWeight="700" marginTop="0" lineHeight="26">
                                                    {k?.ticketNumber}
                                                </H5Text>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                <H5Text color="lightGrey" size="16" fontfamily={locale=="en"?"KarbonLight":"LumaLight"} fontWeight="600" marginTop="0" lineHeight="24">
                                                    {moment(k?.CreatedDate).format("Do MMM YYYY")}
                                                </H5Text>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ marginTop: "12px" }}>
                                            <Grid item xs={12}>
                                                <Text color="lightDark" size="16" fontfamily={locale=="en"?"KarbonLight":"LumaLight"} fontWeight="600" marginTop="0" lineHeight="24">
                                                    {k?.ticketDescription}
                                                </Text>
                                            </Grid>
                                        </Grid>

                                        <Grid container marginTop={'20px'}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ButtonInput
                                                    title={<FormattedMessage id={k?.ticketStatusEn} />}
                                                    variant="contained"
                                                    bgColor={"pinkLight"}
                                                    textColor="darkPinkBrown"
                                                    borderRadius="50"
                                                    TextTransform="capitalize"
                                                    height="25"
                                                    minWidth="200px"
                                                    size="16"
                                                // onClick={() => handleSubmit()}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </MuiCards>
                        </Grid>
                    </Grid >
                ))
                :
                Array.from({ length: 3 }).map((data) => (
                    <Grid container sx={{ marginTop: "20px" }}>
                        <Grid item xs={12} sm={10} md={12} lg={11}>
                            <Skeleton variant="rectangular" animation="wave" height={170} width="100%" style={{ borderRadius: "20px" }} />
                        </Grid>
                    </Grid >
                ))
            }

        </StyleWrapper >
    );
};

export default AccountComplaints;
