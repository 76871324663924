import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import enLogo from "assets/images/Navbar/EnglishLangLogo.png";
import saLogo from "assets/images/Navbar/SaudiLangLogo.png";
import notificationLogo from "assets/images/Navbar/notifications.png";
import languageLogo from "assets/images/Navbar/language.png";
import arabiclogo from "assets/images/Navbar/arabiclogo.png";
import englishlogo from "assets/images/Navbar/englishlogo.png";
import styled from 'styled-components';
import Popup from 'components/Popup';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { ToggleSideBar } from "store/basic/actions";
import { SIDEBAR_TOGGLE } from "store/basic/constant";
import { LocaleContext } from 'routes/Router';
import LocalStorageManager from "managers/LocalStorageManger";
import { getSignInURL, getDashboardURL } from "routes/AppUrls"
import { isAuthenticated } from 'utils/general';

export const NavbarWrapper = styled.div`
    width: 100%;
    height: 10vh;
    min-height:85px;
    background: #3C1053;
    justify-content: end;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    direction:ltr !important;

`;

export const NavbarStyle = styled.div`
    margin-right: 2%;
    display:flex;
    align-items:center;
       
    .notification{
        cursor:pointer;
        margin-right: 16px;
        // margin-left:${(props) => props.locale === 'ar' ? '20px' : '0'};
        margin-left:0;
    };

    .isoDotShow{
        position: absolute;
        margin-left:-5px;
        // margin-right:${(props) => props.locale === 'ar' ? '28px' : '0'};
        margin-right:0;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        z-index:1;
        background:#FF2F2F;
        padding:2px;
        color:#FCFCFE;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor:pointer;
    };

    .language{
       cursor:pointer;
    };
`;

export const NavbarLogo = styled.div`
    // margin-right: ${(props) => props.locale === 'ar' ? '2%' : '3%'};
    margin-right:3%;
    display:flex;
    align-items:center;
    // margin-left:${(props) => props.locale === 'ar' ? '3%' : '0'};
    margin-left:0;

    .saimage{
        margin-right: 8px;
        padding-right: 10px;
        border-right: 1px solid #FFFFFF;
    };

    @media screen and (max-width:576px){
        .saimage{
            width:80px !important;
        }
        .usimage{
            width:100px !important;
        }
    }
`;

export const Modal = styled.div`
   margin-top: 3%;
`;

export const StyledMenuIcon = styled(MenuIcon)`
    height: 60px !important;
    width: 35px !important;
    // margin-${(props) => props.locale === 'en' ? 'left' : 'right'}: 10%;
    margin-left: 10%;
    
    @media (max-width: 576px) {
        margin-left: 20px !important; 
    }       
`;

const Navbar = ({ onLocaleChange, sideBarIcon }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale, setLocale } = useContext(LocaleContext);
    const [lang, setLang] = useState(locale);
    const [isModalActive, setIsModalActive] = useState(null);
    const data = useSelector((state) => state);
    const [isShow, setIsShow] = useState(true)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
        setLang(locale)
    }, [locale]);

    const LanguageSwitcher = (value) => {
        setLocale(value);
        setLang(value);
        window.location.reload(true);
    };

    useEffect(() => {
        if (isMobile) {
            dispatch(ToggleSideBar(SIDEBAR_TOGGLE, false))
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleModalPopup = (value) => {
        setIsModalActive(isModalActive ? null : value.currentTarget);
    };

    const handleNotification = (event) => {
        setIsModalActive(isModalActive ? null : event.currentTarget);
    };

    const Toggle = () => {
        setIsShow(!isShow);
        dispatch(ToggleSideBar(SIDEBAR_TOGGLE, !data.basicReducer.sidebarToggle))
    };

    return (
        <NavbarWrapper >
            {
                (sideBarIcon && isMobile) &&
                <div style={{ width: '20%', color: '#ffffff' }} ><StyledMenuIcon locale={locale} onClick={() => Toggle()} /></div>
            }
            <div style={{ display: 'flex', width: '80%', justifyContent: 'end' }}>
                <NavbarStyle locale={locale}>
                    <div style={{ display: "flex" }}>
                        {LocalStorageManager.getItem("token") !== null &&
                            <div onClick={handleNotification}>
                                {/* <span className="isoDotShow" >4</span> */}
                                <img src={notificationLogo} alt={"logo"} className="notification" onClick={() => isMobile && dispatch(ToggleSideBar(SIDEBAR_TOGGLE, false))} />
                            </div>
                        }
                        {locale === "en" ?
                            <img src={languageLogo} alt={"logo"} className="language" onClick={() => LanguageSwitcher("ar")} />
                            :
                            <img src={languageLogo} alt={"logo"} className="language" onClick={() => LanguageSwitcher("en")} />
                        }
                    </div>
                </NavbarStyle>

                <NavbarLogo locale={locale}>
                    {/* {locale === "en" ?
                        <div>
                            <a href={!isAuthenticated() ? getSignInURL(locale) : getDashboardURL(locale)}>
                                <img src={saLogo} alt={"logo"} className="saimage" />
                                <img src={enLogo} alt={"logo"} className="usimage" />
                            </a>
                        </div>
                        : */}
                        <div>
                            <a href={!isAuthenticated() ? getSignInURL(locale) : getDashboardURL(locale)}>
                                {/* <img src={enLogo} alt={"logo"} className="usimage" />
                                <img src={arabiclogo} alt={"logo"} className="saimage" /> */}
                                 {/* <a href={!isAuthenticated() ? getSignInURL(locale) : getDashboardURL(locale)}>
                                <img src={saLogo} alt={"logo"} className="saimage" />
                                <img src={enLogo} alt={"logo"} className="usimage" />
                            </a> */}
                                <img src={locale=="en"?englishlogo:arabiclogo} alt={"logo"} style={{maxWidth:"241px"}} />
                            </a>
                        </div>
                    {/* } */}
                </NavbarLogo>

                <Modal>
                    <Popup modalActive={isModalActive} handleModalPopup={handleModalPopup} />
                </Modal>
            </div>
        </NavbarWrapper>
    );

};

export default Navbar;